import React from "react";
import { useTranslation } from "react-i18next";

function TrashRed(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.delete"),onClick} = props;

    return(
        <svg onClick={onClick} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <title>{title}</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Recursos" transform="translate(-745.000000, -1787.000000)" fill="#DA291C" fillRule="nonzero">
                    <path d="M759,1793 L759,1801 C759,1802.1 758.1,1803 757,1803 L757,1803 L749,1803 C747.9,1803 747,1802.1 747,1801 L747,1801 L747,1793 L759,1793 Z M756,1787 C756.6,1787 757,1787.4 757,1788 L757,1788 L757,1790 L761,1790 L761,1792 L745,1792 L745,1790 L749,1790 L749,1788 C749,1787.4 749.4,1787 750,1787 L750,1787 Z M755,1789 L751,1789 L751,1790 L755,1790 L755,1789 Z" id="Combined-Shape-Copy-6"></path>
                </g>
            </g>
        </svg>
  );
}

export default TrashRed;