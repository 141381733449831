import { connect } from "react-redux";
import offerDetails from "../../Views/offerDetails";

function mapDispatchToProps(dispatch) {
  return {
    actions: {
    }
  };
}

function mapStateToProps(state) {
  return {
    paises: state?.maestros.paises,
    locations: state?.maestros?.localidades
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(offerDetails);
