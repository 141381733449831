import React from "react";
import { useTranslation } from "react-i18next";

function OtherData(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.other_data")} = props;

    return (
        <svg width="14px" height="16px" viewBox="0 0 14 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="39-Tu-CV-sin-cubrir" transform="translate(-548.000000, -740.000000)" fill="#000000" fillRule="nonzero">
                    <g id="document-2" transform="translate(548.000000, 740.000000)">
                        <path d="M13,0 L1,0 C0.4,0 0,0.4 0,1 L0,15 C0,15.6 0.4,16 1,16 L9,16 L14,11 L14,1 C14,0.4 13.6,0 13,0 Z M2,2 L12,2 L12,10 L8,10 L8,14 L2,14 L2,2 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default OtherData;