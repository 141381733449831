import React from "react";
import { useTranslation } from "react-i18next";

function YourFormation(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.your_formation")} = props;

    return (
        <svg width="16px" height="14px" viewBox="0 0 16 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="39-Tu-CV-sin-cubrir" transform="translate(-548.000000, -339.000000)" fill="#000000" fillRule="nonzero">
                    <g id="book" transform="translate(548.000000, 339.000000)">
                        <path d="M11.5,0 C10.1,0 8.8,0.4 8,1.1 C7.2,0.4 5.9,0 4.5,0 C1.9,0 0,1.3 0,3 L0,13 C0,13.6 0.4,14 1,14 C1.6,14 2,13.6 2,13 C2,12.7 2.9,12 4.5,12 C6.1,12 7,12.7 7,13 C7,13.6 7.4,14 8,14 C8.6,14 9,13.6 9,13 C9,12.7 9.9,12 11.5,12 C13.1,12 14,12.7 14,13 C14,13.6 14.4,14 15,14 C15.6,14 16,13.6 16,13 L16,3 C16,1.3 14.1,0 11.5,0 Z M4.5,10 C3.6,10 2.7,10.2 2,10.5 L2,3 C2,2.7 2.9,2 4.5,2 C6.1,2 7,2.7 7,3 L7,10.5 C6.3,10.2 5.4,10 4.5,10 Z M14,10.5 C13.3,10.2 12.4,10 11.5,10 C10.6,10 9.7,10.2 9,10.5 L9,3 C9,2.7 9.9,2 11.5,2 C13.1,2 14,2.7 14,3 L14,10.5 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default YourFormation;