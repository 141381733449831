import React from "react";
import { useTranslation } from "react-i18next";

function SearchMenuIcon(props) {
    const { t } = useTranslation();
    const { title=t("icons_tooltips.search") } = props;

    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
            <title>{title}</title>
            <g id="Portal-de-empleo---móvil" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Recursos" transform="translate(-40.000000, -924.000000)">
                    <g id="icon-menu-search" transform="translate(40.000000, 924.000000)">
                        <rect id="Rectangle" x="0" y="0" width="20" height="20"/>
                        <g id="Group-6" transform="translate(3.000000, 3.000000)">
                            <g id="zoom"/>
                            <circle id="Oval" stroke="#DA291C" strokeLinecap="round" strokeLinejoin="round" cx="6.5" cy="6.5" r="6"/>
                            <line x1="14.5" y1="14.5" x2="10.742" y2="10.742" id="Path" stroke="#DA291C" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
  );
}

export default SearchMenuIcon;