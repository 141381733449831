import React from 'react'
import Lottie from 'react-lottie';
import animationData from "../assets/animations/loading_logo.json"

function Loading(props) {
  const { isStopped, isPaused, height, width , className, display=true} = props;
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

    return (
        <div 
        className={className}>
          {display && <Lottie options={defaultOptions}
                  height={height}
                  width={width}
                  isStopped={isStopped}
                  isPaused={isPaused}/>
          }
        </div>
  );
}

export default Loading;
