import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles(theme => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
    marginLeft: 24,
    display: "inline-flex",
    alignItems: "center"
  },
  switchBase: {
    padding: 0,
    paddingLeft: "2px",
    color: "#FFFFFF",//theme.palette.primary.main,
    "&$checked": {
      transform: "translateX(24px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main
      }
    },
    "&$disabled": {
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#DDDDDD"
      }
    }
  },
  thumb: {
    width: 20,
    height: 20,
    marginTop: 2,
    boxShadow: "none"
  },
  track: {
    // border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 12,
    opacity: 1,
    backgroundColor: "#DDDDDD"
  },
  checked: {},
  disabled: {}
}));

function AntSwitch(props) {
  const { checked, setter, disabled } = props;
  const classes = useStyles();

  return <Switch classes={classes} checked={checked} onChange={setter} disabled={disabled}/>;
}

export default AntSwitch;
