import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../components/loading";
import { useTranslation } from "react-i18next";
import { downloadDocumentInform } from "../api/maestro";
import CandidatePhoto from "../assets/icons/candidatePhoto";
import {
  getDatosContacto,
  obtenerAlertasDashboard,
  updateFechaUltimamodificacion,
  getNotificaciones,
  deleteNotificacion,
} from "../api/candidato";
import { getCandidaturasRecientes } from "../api/candidaturas";
import { formatToLocalDate, base64ToArrayBuffer, getProvincias, isForeingCountry } from "../utils/functions";
import { withSnackbar } from "notistack";
import ProfileProgressPath from "../assets/icons/profileProgressPath";
import CandidaturasIcon from "../assets/icons/candidaturasIcon";
import ContratacionIcon from "../assets/icons/contratacionIcon";
import CurriculumIcon from "../assets/icons/curriculumIcon";
import DocumentosIcon from "../assets/icons/documentosIcon";
import OffersSearcher from "../components/offersSearcher";
import Trash from "../assets/icons/trash";
import Layout from "../components/basicLayout";
import i18next from "../i18n.js";
import { returnLangId } from "../utils/functions";
import PriorityLow from "../assets/icons/priorityLow";
import ButtonOutlined from "../components/buttonOutlined";

const useStyles = makeStyles((theme) => ({
  body_container: {
    gridArea: "body_container",
    display: "grid",
    gridTemplateRows: ".6fr .4fr",
    [theme.breakpoints.down("sm")]: {
      gridTemplateRows: "1fr",
    },
  },
  body_info_container: {
    display: "grid",
    gridTemplateColumns: "30% minMax(0,1fr)",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateRows: "auto",
      gridTemplateColumns: "auto",
    },
  },
  background_header: {
    backgroundColor: "rgb(0, 0, 0, 0.3)",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    position: "relative",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      background:
        "linear-gradient(bottom, #FBFBFB, #FBFBFB 40%, transparent 40%, transparent 100%)",
      height: 130,
    },
  },
  body_header: {
    backgroundImage: "url(/images/header-background.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none",
      height: 130,
    },
  },
  body_content: {
    gridArea: "body_content",
    display: "grid",
    gridTemplateColumns: "30% minMax(0,1fr)",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateRows: "auto",
      gridTemplateColumns: "auto",
    },
  },
  body_info_left_col: {
    marginTop: 47,
    display: "grid",
    gridTemplateRows: "160px auto",
    rowGap: "33px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateRows: "190px auto",
      marginTop: 0
    },
  },
  candidate_info_container: {
    display: "grid",
    padding: 20,
    paddingLeft: 35,
    paddingTop: 20,
    background:"#FBFBFB",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingTop: 27,
      background: "#FBFBFB",
    },
  },
  candidate_photo_container: {
    position: "absolute",
    bottom: -20,
    left: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 127,
    width: 127,
    borderRadius: 6,
    background: "linear-gradient(180deg, #EEEEEE 0%, #D8D8D8 100%)",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
    [theme.breakpoints.down("sm")]: {
      left: 22,
    },
  },
  photo: {
    width: "100%",
    height: "100%",
    borderRadius: 6,
    objectFit: "cover",
  },
  candidate_last_update_container: {
    display: "flex",
    direction: "rows",
    justifyContent: "space-between",
  },
  candidate_fullname: {
    color: "rgba(0,0,0,0.87)",
    fontSize: 18,
    fontWeight: 900,
    wordBreak: "break-word",
  },
  candidate_last_update: {
    display: "inline-block",
    color: "rgba(0,0,0,0.87)",
    fontSize: 12,
    fontWeight: 500,
  },
  candidate_update_date: {
    display: "inline-block",
    color: "#DA291C",
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: 12,
    fontWeight: 500,
  },
  candidate_phone_and_email: {
    fontSize: 16,
    fontWeight: 600,
    color: "rgba(0,0,0,0.87)",
    wordBreak: "break-all",
    overflowWrap: "break-word",
  },
  email: (props) => ({
    overflow: "hidden",
    whiteSpace: props.email < 95 ? "break-spaces" : "nowrap",
    textOverflow: "ellipsis",
    width: props.email < 95 ? "auto" : "290px",
  }),
  linkbutton_14px: {
    color: "#DA291C",
    fontSize: 14,
    fontWeight: 500,
    textDecoration: "underline",
    cursor: "pointer",
    border: "none",
    background: "none",
    textAlign: "start",
  },
  linkbutton_black_14px: {
    color: "black",
    fontSize: 14,
    fontWeight: 500,
    textDecoration: "underline",
    cursor: "pointer",
  },
  profile_completed_container: {
    height: 323,
    padding: 20,
    rowGap: 20,
    paddingLeft: 35,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingTop: 20,
    },
    background: "#FBFBFB",
    display: "grid",
  },
  profile_completed_container_minimized: {
    padding: 20,
    height: 70,
    background: "#FBFBFB",
    display: "grid",
    cursor: "pointer",
  },
  profile_completed_info_container: {
    display: "grid",
    gridTemplateColumns: "auto 75%",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  profile_completed_info_grid: {
    position: "relative",
    left: -33,
    height: "100%",
    width: "100%",
    display: "grid",
    justifyContent: "flex-start",
    alignItems: "center",
    columnGap: 9,
    gridTemplateColumns: "auto",
    gridTemplateRows: "15% 30% 10% 40%",
    gridTemplateAreas: `
        '. .'
        'percentage text1'
        '. text2'
        '. .'`,
  },
  profile_completed_percentage_text: {
    gridArea: "percentage",
    color: "#DA291C",
    fontSize: 28,
    fontWeight: 900,
  },
  profile_completed_profile_text1: {
    gridArea: "text1",
    fontSize: 18,
    fontWeight: 900,
    color: "black",
  },
  profile_completed_profile_text2: {
    gridArea: "text2",
    fontSize: 18,
    fontWeight: 900,
    color: "black",
  },
  profile_completed_profile_text_16px: {
    whiteSpace: "pre-line",
    fontSize: 18,
    fontWeight: 900,
    color: "black",
  },
  profile_completed_percentage_text_16px: {
    color: "#DA291C",
    fontSize: 16,
    fontWeight: 900,
  },
  padding_grid: {
    paddingLeft: 15,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  profile_info_text_black_16px: {
    fontSize: 16,
    fontWeight: 400,
    color: "black",
  },
  profile_info_text_bold_16px: {
    fontSize: 16,
    fontWeight: "bold",
    color: "black",
  },
  rectangleProgressBar: {
    height: 2,
    width: "100%",
    background: "#DDDDDD",
  },
  rectangleProgressBarProgress: {
    height: "100%",
    background: "#DA291C",
  },
  menu_rapido_container: {
    display: "grid",
    padding: 20,
    paddingTop: 40,
    gridTemplateRows: "min-content min-content min-content min-content",
    rowGap: "19px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateRows: "215px auto auto",
      paddingTop: 20,
    },
  },
  menu_rapido_buttons: {
    display: "grid",
    gridTemplateColumns: "25% 25% 25% 25%",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateRows: "auto",
      gridTemplateColumns: "50% 50%",
    },
  },
  menu_rapido_button_container: {
    cursor: "pointer",
    display: "grid",
    rowGap: "20px",
    height: "fit-content",
    alignItems: "start",
    gridTemplateRows: ".6fr .4fr",
    justifyItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      rowGap: 0,
      height: "auto",
    },
  },
  menu_rapido_button_text_style: {
    color: "#DA291C",
    fontSize: 18,
    fontWeight: 900,
    textAlign: "center",
    whiteSpace: "pre-line",
    alignSelf: "top",
    lineHeight: 1,
    margin: 0,
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  seccion_principal_headers: {
    fontSize: 22,
    fontWeight: 900,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
    marginBottom: "10px",
  },
  seccion_principal_descriptions: {
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  grid_nominations_searches: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: 16,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "auto",
      rowGap: "33px",
    },
  },
  searcher_container: {
    minWidth: 570,
    marginRight: 55,
    marginBottom: 36,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  searcher_link: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  notificaciones_grid: {
    display: "grid",
    width: "100%",
    gridAutoRows: "min-content",
    rowGap: "5px",
  },
  notificacion: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    lineHeight: "1.6",
  },
  your_data_list_style: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "auto",
    margin: "-5px",
    fontSize: 14,
    width: "95%",
  },
  data_list_row: {
    cursor: "pointer",
    margin: "5px",
    "&:hover": {
      background: "#FCFCFC",
    },
  },
  inactive_alert: {
    color: "#B8B8B8",
  },
  candidate_data_grid: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 15,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  see_notifications_button: {
    display: "flex",
    flexDirection: "row",
    margin: "5px",
    fontSize: 14,
    color: "#DA291C",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    [theme.breakpoints.down("sm")]: {
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  rotate_icon: {
    "& svg": {
      transform: "rotate(180deg)",
    },
  },
  formation: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    width: "50%",
    marginBottom: "20px",
  },
}));

function CandidateDashboard(props) {
  const { actions } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dateLoading, setDateLoading] = useState(false);
  const [datosContacto, setDatosContacto] = useState(null);
  const [nombre, setName] = useState("");
  const [primerApellido, setPrimerApellido] = useState("");
  const [segundoApellido, setSegundoApellido] = useState("");
  const [prefijo, setPrefijo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");
  const [fechaUltimaModificacion, setFechaUltimaModificacion] = useState("");
  const [idFotoPerfil, setidFotoPerfil] = useState(null);
  const [avancePerfil, setAvancePerfil] = useState("0");
  const [refPicture, setRefPicture] = useState(null);
  const [loadingPic, setLoadingPic] = useState(false);
  const [minimizado, setMinimizado] = useState(false);
  const [notificaciones, setNotificaciones] = useState(null);
  const [candidaturas, setCandidaturas] = useState(null);
  const [alertas, setAlertas] = useState(null);
  const [showAllNotifications, setShowAllNotifications] = useState(false);

  const [selectedNotificationToDelete, setSelectedNotificationToDelete] =
    useState(null);

  const [notificacionesLoaded, setNotificacionesLoaded] = useState(false);
  const [candidaturasLoaded, setCandidaturasLoaded] = useState(false);
  const [busquedasLoaded, setBusquedasLoaded] = useState(false);

  const [errorLoading, setErrorLoading] = useState(false);

  const classes = useStyles({ email: correo?.length });

  const dataListProvincias = getProvincias(i18next.languages[0]);

  useEffect(() => {
    actions.clearSavedSearchAction();
  }, [])

  useEffect(() => {
    if (!datosContacto) {
      setLoading(true);
      handleDatosContacto();
    } else {
      handleSetDatosContacto();
    }
  }, [datosContacto]);

  useEffect(() => {
    if (!refPicture && idFotoPerfil) {
      getProfilePic();
    }
  }, [idFotoPerfil]);

  useEffect(() => {
    if (!notificaciones) {
      handleNotificaciones();
    }
  }, [notificaciones]);

  useEffect(() => {
    if (!candidaturas) {
      getCandidaturas();
    }
  }, [candidaturas]);

  useEffect(() => {
    if (!alertas) {
      handleAlertasCandidato();
    }
  }, [alertas]);

  async function handleDatosContacto() {
    const response = await getDatosContacto();
    if (response.status === 200) {
      const result = await response.json();
      setDatosContacto(result);
      localStorage.setItem("vinculacionLaboral", result?.vinculacionLaboral);
    } else {
      setLoading(false);
      setErrorLoading(true);
      props.enqueueSnackbar(t("candidateDashboard.error"), {
        variant: "error",
      });
      setDatosContacto(null);
    }
  }

  async function handleAlertasCandidato() {
    const response = await obtenerAlertasDashboard(
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200 || response.status === 201) {
      setBusquedasLoaded(true);
      const result = await response.json();
      setAlertas(result);
    } else {
      setErrorLoading(true);
      setBusquedasLoaded(true);
      props.enqueueSnackbar(t("candidateDashboard.alert_error"), {
        variant: "error",
      });
    }
  }

  async function handleSetDatosContacto() {
    setName(datosContacto.nombre ? datosContacto.nombre : "");
    setPrimerApellido(
      datosContacto.primerApellido ? datosContacto.primerApellido : ""
    );
    setSegundoApellido(
      datosContacto.segundoApellido ? datosContacto.segundoApellido : ""
    );
    setCorreo(
      datosContacto.correoElectronico ? datosContacto.correoElectronico : ""
    );
    setAvancePerfil(
      datosContacto.avancePerfil ? datosContacto.avancePerfil : 0
    );
    setidFotoPerfil(
      datosContacto.idFotoPerfil ? datosContacto.idFotoPerfil : null
    );
    setFechaUltimaModificacion(
      datosContacto.fechaUltimaModificacion
        ? formatToLocalDate(new Date(datosContacto.fechaUltimaModificacion))
        : "-"
    );
    setTelefono(
      datosContacto.telefono
        ? datosContacto.telefono.numero
          ? datosContacto.telefono.numero.trim()
          : ""
        : ""
    );
    setPrefijo(
      datosContacto.telefono
        ? datosContacto.telefono.prefijo
          ? datosContacto.telefono.prefijo.trim()
          : ""
        : ""
    );
    setLoading(false);
  }

  async function handleNotificaciones() {
    let response = await getNotificaciones(returnLangId(i18next.languages[0]));
    if (response.status === 200 || response.status === 201) {
      setNotificacionesLoaded(true);
      let result = await response.json();
      setNotificaciones(result);
    } else {
      setErrorLoading(true);
      setNotificacionesLoaded(true);
      props.enqueueSnackbar(t("candidateDashboard.notifications_error"), {
        variant: "error",
      });
    }
  }

  async function borrarNotificacion(id) {
    setSelectedNotificationToDelete(id);
    let response = await deleteNotificacion(
      id,
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200 || response.status === 201) {
      setSelectedNotificationToDelete(null);
      let notificacionesAux = notificaciones.filter(
        (notificacion) => notificacion.idNotificacion != id
      );
      setNotificaciones(notificacionesAux);
    } else {
      setSelectedNotificationToDelete(null);
      props.enqueueSnackbar(
        t("candidateDashboard.delete_notifications_error"),
        {
          variant: "error",
        }
      );
    }
  }

  async function updateUltimaFechaModificacion() {
    setDateLoading(true);
    let response = await updateFechaUltimamodificacion();
    if (response.status === 200 || response.status === 201) {
      let result = await response.json();
      setFechaUltimaModificacion(formatToLocalDate(new Date(result)));
      setDateLoading(false);
    } else {
      switch (response.status) {
        case 400:
          props.enqueueSnackbar(t("candidateDashboard.get_error"), {
            variant: "error",
          });
          break;
        case 403:
          props.enqueueSnackbar(t("candidateDashboard.get_warning"), {
            variant: "error",
          });
          break;
        default:
          props.enqueueSnackbar(t("candidateDashboard.error"), {
            variant: "error",
          });
          break;
      }
    }
  }

  async function getProfilePic() {
    setLoadingPic(true);
    try {
      const response = await downloadDocumentInform(idFotoPerfil);
      if (response.status === 200 || response.status === 201) {
        const result = await response.json();
        const obj_url = URL.createObjectURL(
          new Blob([base64ToArrayBuffer(result)], {
            type: `application/image/*`,
          })
        );
        setRefPicture(obj_url);
      }
      setLoadingPic(false);
    } catch (error) {
      setLoadingPic(false);
      props.enqueueSnackbar(t("candidateDashboard.error_loading_img"), {
        variant: "error",
      });
    }
  }

  function profilePicture() {
    return loadingPic ? (
      <Loading height={64} width={64} />
    ) : refPicture ? (
      <img src={refPicture} className={classes.photo} alt="" />
    ) : (
      <CandidatePhoto title={t("icons_tooltips.candidate_picture")} />
    );
  }

  async function getCandidaturas() {
    setLoading(true);
    const response = await getCandidaturasRecientes(
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200 || response.status === 201) {
      setCandidaturasLoaded(true);
      const result = await response.json();
      setCandidaturas(result);
    } else {
      setErrorLoading(true);
      setCandidaturasLoaded(true);
      props.enqueueSnackbar(t("candidateDashboard.error"), {
        variant: "error",
      });
    }
  }

  function minimizar() {
    setMinimizado(true);
  }

  function maximizar() {
    setMinimizado(false);
  }

  function abrirAlerta(alerta) {
    const data = {
      palabrasClave: alerta.palabrasClave,
      provincias: alerta.provincias,
      localidades: alerta.localidades,
      sectores: alerta.sectores,
      puestos: alerta.puestos,
      tiposJornada: alerta.tiposJornada,
      tiposContrato: alerta.tiposContrato,
      ciudades:alerta.ciudades
    };
    actions.setSavedSearchAction(data);
    props.history.push(
      `search_offers/${alerta.provincias.length > 1 ?  isForeingCountry(dataListProvincias,alerta.provincias): alerta.provincias}/${
        alerta.palabrasClave ? alerta.palabrasClave : ""
      }`
    );
  }

  function completedProfile() {
    return minimizado ? (
      <div
        className={classes.profile_completed_container_minimized}
        onClick={maximizar}
      >
        <div>
          <span className={classes.profile_completed_percentage_text_16px}>
            {`${avancePerfil}% `}{" "}
          </span>
          <span className={classes.profile_completed_profile_text_16px}>{`${t(
            "candidateDashboard.completed_profile_text1"
          )} ${t("candidateDashboard.completed_profile_text2")}`}</span>
        </div>
        <div className={classes.rectangleProgressBar}>
          <div
            className={classes.rectangleProgressBarProgress}
            style={{ width: `${avancePerfil}%` }}
          />
        </div>
      </div>
    ) : (
      <div className={classes.profile_completed_container}>
        <div className={classes.profile_completed_info_container}>
          <div className={classes.img_container}>
            <ProfileProgressPath
              title={t("icons_tooltips.progress_path")}
              percentage={avancePerfil / 100}
            />
          </div>
          <div className={classes.profile_completed_info_grid}>
            <div className={classes.profile_completed_percentage_text}>
              {`${avancePerfil}%`}{" "}
            </div>
            <div className={classes.profile_completed_profile_text1}>
              {t("candidateDashboard.completed_profile_text1")}
            </div>
            <div className={classes.profile_completed_profile_text2}>
              {t("candidateDashboard.completed_profile_text2")}
            </div>
          </div>
        </div>
        <div className={classes.profile_info_text_black_16px}>
          {t("candidateDashboard.completed_profile_info_text")}
        </div>
        <div>
          <span className={classes.profile_info_text_black_16px}>
            {t("candidateDashboard.completed_profile_suggestion1")}
          </span>
          <span className={classes.profile_info_text_bold_16px}>
            {t("candidateDashboard.completed_profile_suggestion2")}
          </span>
          <span className={classes.profile_info_text_black_16px}>
            {t("candidateDashboard.completed_profile_suggestion3")}
          </span>
          <span className={classes.profile_info_text_bold_16px}>
            {t("candidateDashboard.completed_profile_suggestion4")}
          </span>
        </div>
        <a className={classes.linkbutton_black_14px} onClick={minimizar}>
          {t("candidateDashboard.minimize")}
        </a>
      </div>
    );
  }

  function candidateInfo() {
    return (
      <div className={classes.candidate_info_container}>
        <div className={classes.candidate_fullname}>
          {`${nombre} ${primerApellido} ${segundoApellido}`}
        </div>
        <div className={classes.candidate_last_update_container}>
          <div className={classes.candidate_last_update}>
            {fechaUltimaModificacion &&
              `${t(
                "candidateDashboard.last_update"
              )} ${fechaUltimaModificacion}`}
          </div>
          {!dateLoading ? (
            <a
              className={classes.candidate_update_date}
              onClick={updateUltimaFechaModificacion}
            >
              {t("candidateDashboard.update")}
            </a>
          ) : (
            <Loading height={22} width={22} />
          )}
        </div>
        <div className={classes.candidate_phone_and_email}>
          <div className={classes.email}>{correo}</div>
          <div> {prefijo && `+${prefijo} ${telefono}`}</div>
        </div>
        <a className={classes.linkbutton_14px} onClick={goEdit}>
          {t("candidateDashboard.edit_basic_data")}
        </a>
      </div>
    );
  }

  function goEdit(event) {
    event.preventDefault();
    props.history.push("/edit_personal_data");
  }

  function menuRapido() {
    return (
      <div className={classes.menu_rapido_buttons}>
        <div
          className={classes.menu_rapido_button_container}
          onClick={() => {
            props.history.push("candidaturas");
          }}
        >
          <CandidaturasIcon title={t("icons_tooltips.candidaturas")} />
          <p className={classes.menu_rapido_button_text_style}>
            {t("candidateDashboard.candidaturasButton")}
          </p>
        </div>
        <div
          className={classes.menu_rapido_button_container}
          onClick={() => {
            props.history.push("curriculum");
          }}
        >
          <CurriculumIcon title={t("icons_tooltips.curriculum")} />
          <p className={classes.menu_rapido_button_text_style}>
            {t("candidateDashboard.curriculumButton")}
          </p>
        </div>
        {datosContacto?.vinculacionLaboral ? (
          <div
            className={classes.menu_rapido_button_container}
            onClick={() => {
              props.history.push("datos_contratacion");
            }}
          >
            <ContratacionIcon title={t("icons_tooltips.hire_data")} />
            <p className={classes.menu_rapido_button_text_style}>
              {t("candidateDashboard.contratacionButton")}
            </p>
          </div>
        ) : null}
        <div
          className={classes.menu_rapido_button_container}
          onClick={() => {
            props.history.push("my_documents");
          }}
        >
          <DocumentosIcon title={t("icons_tooltips.documents")} />
          <p className={classes.menu_rapido_button_text_style}>
            {t("candidateDashboard.documentosButton")}
          </p>
        </div>
      </div>
    );
  }

  function singleNotification(titulo, id) {
    const isThisDeletedOne = selectedNotificationToDelete === id;
    return (
      <div className={classes.notificacion} key={id}>
        <div>{titulo}</div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            !selectedNotificationToDelete && borrarNotificacion(id)
          }
          key={id}
        >
          {isThisDeletedOne ? (
            <Loading height={22} width={22} />
          ) : (
            !selectedNotificationToDelete && (
              <Trash title={t("icons_tooltips.delete")} />
            )
          )}
        </div>
      </div>
    );
  }

  function yourAlerts() {
    return (
      <div className={classes.candidate_data_grid}>
        <div className={classes.seccion_principal_headers}>
          {t("candidateDashboard.searches")}
        </div>
        {alertas && alertas.length > 0 ? (
          <div className={classes.your_data_list_style}>
            {alertas.map((alerta, index) =>
              alerta.activa ? (
                <div
                  className={classes.data_list_row}
                  onClick={() => abrirAlerta(alerta)}
                  key={index}
                >
                  {`${alerta.numeroOfertas} ${t(
                    "candidateDashboard.offers_for"
                  )}`}{" "}
                  <span style={{ fontWeight: 900 }}>{alerta.nombre}</span>
                </div>
              ) : (
                <div key={index} className={classes.inactive_alert}>{`${
                  alerta.nombre
                } ${t("candidateDashboard.inactive")}`}</div>
              )
            )}
            <a
              className={classes.see_notifications_button}
              onClick={() => props.history.push("saved_searches")}
            >
              {t("candidateDashboard.see_all")}
            </a>
          </div>
        ) : (
          <div className={classes.seccion_principal_descriptions}>
            <a
              className={classes.searcher_link}
              onClick={() => {
                props.history.push("search_offers/0");
              }}
            >
              {t("candidateDashboard.empty_searches1")}
            </a>{" "}
            {t("candidateDashboard.empty_searches2")}
          </div>
        )}
      </div>
    );
  }

  function yourNotifications() {
    return (
      <div className={classes.candidate_data_grid}>
        <div className={classes.seccion_principal_headers}>
          {t("candidateDashboard.notifications")}
        </div>
        {notificaciones && notificaciones.length > 0 ? (
          <div className={classes.notificaciones_grid}>
            {notificaciones
              .slice(0, showAllNotifications ? notificaciones.length : 3)
              .map((notificacion, index) =>
                singleNotification(
                  notificacion.texto,
                  notificacion.idNotificacion
                )
              )}
            {notificaciones.length > 3 && (
              <div
                className={classes.see_notifications_button}
                onClick={() => setShowAllNotifications(!showAllNotifications)}
              >
                <div>
                  {`${
                    showAllNotifications
                      ? t("candidateDashboard.see_less")
                      : t("candidateDashboard.see_all")
                  }`}
                </div>
                <div
                  className={`${
                    showAllNotifications ? classes.rotate_icon : ""
                  }`}
                  style={{ marginLeft: "10px" }}
                >
                  <PriorityLow
                    title={t("icons_tooltips.show_all_notifications")}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className={classes.seccion_principal_descriptions}>
            {t("candidateDashboard.empty_notifications")}
          </div>
        )}
      </div>
    );
  }

  function yourNominations() {
    return (
      <div className={classes.candidate_data_grid}>
        <div className={classes.seccion_principal_headers}>
          {t("candidateDashboard.nominations")}
        </div>
        {candidaturas && candidaturas.length > 0 ? (
          <div className={classes.your_data_list_style}>
            {candidaturas.map((candidatura, index) => (
              <div
                className={classes.data_list_row}
                onClick={() =>{
                  props.history.push(`offer/${candidatura.idOferta}`)
                }
              }
                key={index}
              >
                {candidatura.titulo}
              </div>
            ))}
            <a
              className={classes.see_notifications_button}
              onClick={() => props.history.push("candidaturas")}
            >
              {t("candidateDashboard.seeAll")}
            </a>
          </div>
        ) : (
          <div className={classes.seccion_principal_descriptions}>
            {t("candidateDashboard.empty_nominations")}
          </div>
        )}
      </div>
    );
  }

  function yourFormation() {
    return (
      <div className={classes.padding_grid}>
        <div className={classes.seccion_principal_headers}>
          {t("candidateDashboard.formation")}
        </div>
        <div className={classes.formation}>
          {t("candidateDashboard.formationText")}
        </div>
        <ButtonOutlined
          text={t("candidateDashboard.ntFORButton")}
          customClass={classes.custom_button_size}
          onClick={() => window.open("https://ntfor.com/")}
        />
      </div>
    );
  }

  function seccionPrincipal() {
    return (
      <div className={classes.menu_rapido_container}>
        {menuRapido()}
        <div className={classes.grid_nominations_searches}>
          {yourNotifications()}
          {yourNominations()}
          {yourAlerts()}
          {yourFormation()}
        </div>
      </div>
    );
  }

  return (
    <Layout
      history={props.history}
      loading={
        loading ||
        !notificacionesLoaded ||
        !busquedasLoaded ||
        !candidaturasLoaded ||
        errorLoading
      }
    >
      <div className={classes.body_container}>
        <div className={classes.body_header}>
          <div className={classes.background_header}>
            <div className={classes.candidate_photo_container}>
              {profilePicture()}
            </div>
            <div className={classes.searcher_container}>
              <OffersSearcher 
                whiteTitle whiteLabel displayInline={true}
              />
            </div>
          </div>
        </div>
        <div className={classes.body_info_container}>
          <div className={classes.body_info_left_col}>
            {candidateInfo()}
            {completedProfile()}
          </div>
          {seccionPrincipal()}
        </div>
      </div>
    </Layout>
  );
}

export default withSnackbar(CandidateDashboard);
