import { connect } from "react-redux";
import RedireccionAlerta from "../../Views/redireccionAlerta";
import { bindActionCreators } from "redux";
import { setSavedSearchAction } from "../actions/webCandidate"

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setSavedSearchAction: bindActionCreators(setSavedSearchAction, dispatch)
    }
  };
}

function mapStateToProps(state) {
  return {
    state: state.webCandidate
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RedireccionAlerta);
