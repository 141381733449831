
import jwt_decode from "jwt-decode";
import {sendHeader,dataHeader,responseCheck,dataHeaderWithoutAuthorization} from "../utils/functions";

const baseUrl = window._env_.BASE_URL_CANDIDATOS;
const baseUrlMaestros = window._env_.BASE_URL_MAESTROS;

sendHeader().then(dato=>dataHeader.Authorization=`Bearer ${dato}`);

export function loginUser(data) {
  return fetch(baseUrl + "/api/candidato/login", {
    method: "POST",
    headers: dataHeaderWithoutAuthorization,
    body: JSON.stringify(data),
  }).then((response) => {
    return response;
  });
}

export async function registerUser(data, token) {
  var decoded = jwt_decode(token.access_token);
  let email = "";
  if (decoded.authenticationSource === "localAccountAuthentication") {
    email = decoded.signInName;
  } else {
    email = decoded.email;
  }

  data = {
    CorreoElectronico: email,
  };

  return fetch(baseUrl + `/api/candidato`, {
    method: "POST",
    headers: dataHeader,
    body: JSON.stringify(data),
  }).then((response) => {
    return response;
  });
}

export async function getDatosContacto(tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/datosContacto?idPortal=1`, {
    method: "GET",
    headers: dataHeader,
  }).then((response) => 
    responseCheck(response,tryNumber,(tryNumerIncremented) => getDatosContacto(tryNumerIncremented))
  );
}

export async function updateFechaUltimamodificacion(tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/ultimaModificacion`, {
    method: "PUT",
    headers: dataHeader,
  }).then((response) => 
    responseCheck(response,tryNumber,(tryNumerIncremented) => updateFechaUltimamodificacion(tryNumerIncremented))
  );
}

export async function getCandidaturas(idIdioma, time, status, tryNumber = 0) {
 
  return fetch(
    `${baseUrl}/api/candidatura?idioma=${idIdioma}&periodo=${time}&estado=${status}`,
    {
      method: "GET",
      headers: dataHeader,
    }
  ).then((response) => 
      responseCheck(response,tryNumber,(tryNumerIncremented) => getCandidaturas(idIdioma, time, status,tryNumerIncremented))
  );
}
export async function getDatosContratacion(tryNumber = 0) {
 
  return fetch(`${baseUrl}/api/datosContratacion`, {
    method: "GET",
    headers: dataHeader,
  }).then((response) => 
      responseCheck(response,tryNumber,(tryNumerIncremented) => getDatosContratacion(tryNumerIncremented))
  );
}

export async function agregarAlerta(data, idioma, tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/alerta?idioma=${idioma}`, {
    method: "POST",
    headers: dataHeader,
    body: JSON.stringify(data),
  }).then((response) => 
    responseCheck(response,tryNumber,(tryNumerIncremented) => agregarAlerta(data, idioma,tryNumerIncremented))
  );
}

export async function obtenerAlertasDashboard(idioma, tryNumber = 0) {
 
  return fetch(`${baseUrl}/api/alerta/alertasDashboard?idioma=${idioma}`, {
    method: "GET",
    headers: dataHeader,
  }).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => obtenerAlertasDashboard(idioma,tryNumerIncremented))
  );
}

export async function obtenerAlerta(idAlerta, idioma, tryNumber = 0) {
 
  return fetch(`${baseUrl}/api/alerta/${idAlerta}?idioma=${idioma}`, {
    method: "GET",
    headers: dataHeader,
  }).then((response) => 
    
    responseCheck(response,tryNumber,(tryNumerIncremented) => obtenerAlerta(idAlerta, idioma,tryNumerIncremented))
  );
}

export async function getNotificaciones(idioma, tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/notificacion?idioma=${idioma}`, {
    method: "GET",
    headers: dataHeader,
  }).then((response) => 
    
    responseCheck(response,tryNumber,(tryNumerIncremented) => getNotificaciones(idioma,tryNumerIncremented))
  );
}

export async function deleteNotificacion(notificacion, idioma, tryNumber = 0) {

  return fetch(`${baseUrl}/api/notificacion/${notificacion}?idioma=${idioma}`, {
    method: "DELETE",
    headers: dataHeader,
  }).then((response) =>
  responseCheck(response,tryNumber,(tryNumerIncremented) => deleteNotificacion(notificacion,idioma,tryNumerIncremented))
  );
}

export async function getSavedSearches(tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/alerta`, {
    method: "GET",
    headers: dataHeader,
  }).then((response) => 
  
    responseCheck(response,tryNumber,(tryNumerIncremented) => deleteNotificacion(tryNumerIncremented))
    
  );
}

export async function updateSavedSearch(searchId, stateId, tryNumber = 0) {
  return fetch(`${baseUrl}/api/alerta`, {
    method: "GET",
    headers: dataHeader,

  }).then((response) =>  
     responseCheck(response,tryNumber,(tryNumerIncremented) => updateSavedSearch(searchId, stateId,tryNumerIncremented))
  );
}

export async function deleteSavedSearch(searchId, tryNumber = 0) {

  return fetch(`${baseUrl}/api/alerta/${searchId}`, {
    method: "DELETE",
    headers: dataHeader,
  }).then((response) =>

  responseCheck(response,tryNumber,(tryNumerIncremented) => deleteSavedSearch(searchId,tryNumerIncremented))
  );
}

export async function getDatosPersonales(idioma, tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/datosPersonales?idioma=${idioma}`, {
    method: "GET",
    headers: dataHeader,
  }).then((response) =>
   responseCheck(response,tryNumber,(tryNumerIncremented) => getCandidateDocuments(idioma,tryNumerIncremented))
   );
}

export async function updateDatosPersonales(data, idioma, tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/datosPersonales?idioma=${idioma}`, {
    method: "PUT",
    headers: dataHeader,
    body: JSON.stringify(data),
  }).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) =>  updateDatosPersonales(data, idioma,tryNumerIncremented))
  );
}

export async function getCandidateDocuments(idioma, tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/documentos?idioma=${idioma}`, {
    method: "GET",
    headers: dataHeader,
  }).then((response) => 
  
  responseCheck(response,tryNumber,(tryNumerIncremented) =>  getCandidateDocuments(idioma,tryNumerIncremented))
  );
}

export async function uploadCandidateDocuments(
  documentos,
  idioma,
  tryNumber = 0
) {

  return fetch(`${baseUrl}/api/listaDocumentos?idioma=${idioma}`, {
    method: "PUT",
    headers:dataHeader,
    body: JSON.stringify(documentos),
  }).then((response) => 
      responseCheck(response,tryNumber,(tryNumerIncremented) =>  uploadCandidateDocuments(documentos, idioma,tryNumerIncremented))
  );
}

export async function deleteCandidateDocuments(
  documentId,
  idioma,
  tryNumber = 0
) {
 
  return fetch(`${baseUrl}/api/documentos/${documentId}?idioma=${idioma}`, {
    method: "DELETE",
    headers:dataHeader
  }).then((response) => 

    responseCheck(response,tryNumber,(tryNumerIncremented) => deleteCandidateDocuments(documentId,idioma, tryNumerIncremented))
  );
}

export async function getBasicData(idIdioma, tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/datosBasicos?idioma=${idIdioma}`, {
    method: "GET",
    headers: dataHeader,
  }).then((response) => 
  
  responseCheck(response,tryNumber,(tryNumerIncremented) => getBasicData(idIdioma, tryNumerIncremented))
  );
}

export async function saveBasicData(data, tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/datosBasicos`, {
    method: "PUT",
    headers: dataHeader,
    body: JSON.stringify(data),
  }).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => saveBasicData(data, tryNumerIncremented))
  );
}

export async function downloadDocumentInform(idDocumento, tryNumber = 0) {


  return fetch(`${baseUrlMaestros}/api/documentos/${idDocumento}`, {
    method: "GET",
    headers: dataHeader,
  }).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => downloadDocumentInform(idDocumento, tryNumerIncremented))
  );
}

export async function addFormation(data, idioma, tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/formacion?idioma=${idioma}`, {
    method: "POST",
    headers: dataHeader,
    body: JSON.stringify(data),
  }).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => addFormation(data, idioma, tryNumerIncremented))
  );
}

export async function updateFormation(
  idFormacion,
  data,
  idioma,
  tryNumber = 0
) {
  
  return fetch(`${baseUrl}/api/formacion/${idFormacion}?idioma=${idioma}`, {
    method: "PUT",
    headers: dataHeader,
    body: JSON.stringify(data),
  }).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => updateFormation(idFormacion, data, idioma, tryNumerIncremented))
  );
}

export async function getFormations(idioma, tryNumber = 0) {
 
  return fetch(`${baseUrl}/api/formacion?idioma=${idioma}`, {
    method: "GET",
    headers: dataHeader,
  }).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => getFormations(idioma, tryNumerIncremented))
  );
}

export async function deleteFormation(idFormacion, idioma, tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/formacion/${idFormacion}?idioma=${idioma}`, {
    method: "DELETE",
    headers: dataHeader,
  }).then((response) => 
  
  responseCheck(response,tryNumber,(tryNumerIncremented) => deleteFormation(idFormacion, idioma, tryNumerIncremented))
  );
}

export async function addLanguage(data, idioma, tryNumber = 0) {
 
  return fetch(`${baseUrl}/api/idioma?idioma=${idioma}`, {
    method: "POST",
    headers: dataHeader,
    body: JSON.stringify(data),
  }).then((response) => 
  
  responseCheck(response,tryNumber,(tryNumerIncremented) => addFormation(data, idioma, tryNumerIncremented))
  );
}

export async function getLanguages(idioma, tryNumber = 0) {
 
  return fetch(`${baseUrl}/api/idioma?idioma=${idioma}`, {
    method: "GET",
    headers: dataHeader,
  }).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => getLanguages(idioma, tryNumerIncremented))
  );
}

export async function updateLanguage(data, idioma, tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/idioma?idioma=${idioma}`, {
    method: "PUT",
    headers: dataHeader,
    body: JSON.stringify(data),
  }).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => updateFormation(data, idioma, tryNumerIncremented))
  );
}

export async function deleteLanguage(idIdioma, idioma, tryNumber = 0) {
 
  return fetch(`${baseUrl}/api/idioma/${idIdioma}?idioma=${idioma}`, {
    method: "DELETE",
    headers: dataHeader,
  }).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => deleteLanguage(idIdioma, idioma, tryNumerIncremented))
  );
}

export async function getWorkExperience(languageId, tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/experienciaLaboral?idioma=${languageId}`, {
    method: "GET",
    headers: dataHeader,
  }).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => getWorkExperience(languageId, tryNumerIncremented))
  );
}

export async function addWorkExperience(languageId, data, tryNumber = 0) {
 
  return fetch(`${baseUrl}/api/experienciaLaboral?idioma=${languageId}`, {
    method: "POST",
    headers: dataHeader,
    body: JSON.stringify(data),
  }).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => addWorkExperience(languageId, data, tryNumerIncremented))
  );
}

export async function updateWorkExperience(
  languageId,
  experienceId,
  data,
  tryNumber = 0
) {
  
  return fetch(
    `${baseUrl}/api/experienciaLaboral/${experienceId}?idioma=${languageId}`,
    {
      method: "PUT",
      headers: dataHeader,
      body: JSON.stringify(data),
    }
  ).then((response) => 
     responseCheck(response,tryNumber,(tryNumerIncremented) => updateWorkExperience(languageId,experienceId,data, tryNumerIncremented))
  );
}

export async function deleteWorkExperience(
  languageId,
  experienceId,
  tryNumber = 0
) {
  
  return fetch(
    `${baseUrl}/api/experienciaLaboral/${experienceId}?idioma=${languageId}`,
    {
      method: "DELETE",
      headers: dataHeader,
    }
  ).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => deleteWorkExperience(languageId, experienceId,tryNumerIncremented))
  );
}

export async function getCandidateKnowledge(languageId, tryNumber = 0) {
 
  return fetch(`${baseUrl}/api/conocimiento?idioma=${languageId}`, {
    method: "GET",
    headers: dataHeader,
  }).then((response) =>
  responseCheck(response,tryNumber,(tryNumerIncremented) => getCandidateKnowledge(languageId,tryNumerIncremented))
  );
}

export async function addCandidateKnowledge(languageId, data, tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/conocimiento?idioma=${languageId}`, {
    method: "POST",
    headers: dataHeader,
    body: JSON.stringify(data),
  }).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => addCandidateKnowledge(languageId, data,tryNumerIncremented))
  
  );
}

export async function deleteCandidateKnowledge(
  languageId,
  idConocimiento,
  tryNumber = 0
) {
 
  return fetch(
    `${baseUrl}/api/conocimiento/${idConocimiento}?idioma=${languageId}`,
    {
      method: "DELETE",
      headers: dataHeader,
    }
  ).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => deleteCandidateKnowledge(languageId,idConocimiento,tryNumerIncremented))
  
  );
}

export async function getOtrosDatos(languageId, tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/otrosDatos?idioma=${languageId}`, {
    method: "GET",
    headers: dataHeader,
  }).then((response) =>  
  responseCheck(response,tryNumber,(tryNumerIncremented) => getOtrosDatos(languageId,tryNumerIncremented))
  );
}

export async function actualizarOtrosDatos(data, languageId, tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/otrosDatos?idioma=${languageId}`, {
    method: "PUT",
    headers: dataHeader,
    body: JSON.stringify(data),
  }).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => actualizarOtrosDatos(data, languageId,tryNumerIncremented))
  );
}

export async function updateIndicadoresCvCandidato(
  data,
  languageId,
  tryNumber = 0
) {
 
  return fetch(`${baseUrl}/api/indicadoresCvCandidato?idioma=${languageId}`, {
    method: "PUT",
    headers: dataHeader,
    body: JSON.stringify(data),
  }).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => updateIndicadoresCvCandidato(data, languageId,tryNumerIncremented))
  );
}

export async function deleteProfileCandidate(tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/candidato`, {
    method: "DELETE",
    headers: dataHeader,
  }).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => deleteProfileCandidate(tryNumerIncremented))
  );
}

export async function addExpEtts(data, tryNumber = 0) {
  
  return fetch(`${baseUrl}/api/experienciaEtt`, {
    method: "POST",
    headers: dataHeader,
    body: JSON.stringify(data),
  }).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => addExpEtts(data,tryNumerIncremented))
  );
}
