import React from "react";
import { useTranslation } from "react-i18next";

function ClosesessionMenuItem(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.close_menu")} = props;

    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
            <title>{title}</title>
            <g id="Portal-de-empleo---móvil" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Recursos" transform="translate(-129.000000, -924.000000)">
                    <g id="icon-menu-logout" transform="translate(129.000000, 924.000000)">
                        <rect id="Rectangle-Copy-10" x="0" y="0" width="20" height="20"/>
                        <g id="Group-10" transform="translate(2.000000, 3.000000)">
                            <g id="logout"/>
                            <line x1="5.5" y1="7.5" x2="15.5" y2="7.5" id="Path" stroke="#DA291C" strokeLinecap="round" strokeLinejoin="round"/>
                            <polyline id="Path" stroke="#DA291C" strokeLinecap="round" strokeLinejoin="round" points="11.5 3.5 15.5 7.5 11.5 11.5"/>
                            <path d="M8.5,14.5 L1.5,14.5 C0.948,14.5 0.5,14.052 0.5,13.5 L0.5,1.5 C0.5,0.948 0.948,0.5 1.5,0.5 L8.5,0.5" id="Path" stroke="#DA291C" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
  )
}

export default ClosesessionMenuItem;