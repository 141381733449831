import React from "react";
import { useTranslation } from "react-i18next";

function YourCV(props) {
  const { t } = useTranslation();
  const { title = t("icons_tooltips.your_curriculum") } = props;

  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="39-Tu-CV-sin-cubrir"
          transform="translate(-548.000000, -812.000000)"
          fill="#000000"
          fillRule="nonzero"
        >
          <g id="folder-e-03" transform="translate(548.000000, 812.000000)">
            <path
              d="M15,16 L1,16 C0.4,16 0,15.6 0,15 L0,1 C0,0.4 0.4,0 1,0 L5,0 C5.3,0 5.5,0.1 5.7,0.3 L8.4,3 L15,3 C15.6,3 16,3.4 16,4 L16,15 C16,15.6 15.6,16 15,16 Z M2,14 L14,14 L14,5 L8,5 C7.7,5 7.5,4.9 7.3,4.7 L4.6,2 L2,2 L2,14 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default YourCV;
