import { connect } from "react-redux";
import Candidaturas from "../../Views/candidaturas";
import { bindActionCreators } from "redux";
import {
  setCandidaturasSnapshot,
  clearCandidaturasSnapshot,
} from "../actions/webCandidate";

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setSnapshot: bindActionCreators(setCandidaturasSnapshot, dispatch),
      clearSnapshot: bindActionCreators(clearCandidaturasSnapshot, dispatch),
    },
  };
}

function mapStateToProps(state) {
  return {
    state: state.webCandidate,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Candidaturas);
