import React from "react";
import { useTranslation } from "react-i18next";

function CVMenuIcon(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.curriculum")} = props;
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
            <title>{title}</title>
            <g id="Portal-de-empleo---móvil" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Recursos" transform="translate(-159.000000, -924.000000)">
                    <g id="icon-menu-tu-cv" transform="translate(159.000000, 924.000000)">
                        <rect id="Rectangle-Copy-11" x="0" y="0" width="20" height="20"/>
                        <g id="Group-7" transform="translate(3.000000, 2.000000)">
                            <g id="file-text"/>
                            <line x1="3.5" y1="11.5" x2="10.5" y2="11.5" id="Path" stroke="#DA291C" strokeLinecap="round" strokeLinejoin="round"/>
                            <line x1="3.5" y1="8.5" x2="10.5" y2="8.5" id="Path" stroke="#DA291C" strokeLinecap="round" strokeLinejoin="round"/>
                            <line x1="3.5" y1="5.5" x2="5.5" y2="5.5" id="Path" stroke="#DA291C" strokeLinecap="round" strokeLinejoin="round"/>
                            <polygon id="Path" stroke="#DA291C" strokeLinecap="round" strokeLinejoin="round" points="8.5 0.5 0.5 0.5 0.5 15.5 13.5 15.5 13.5 5.5"/>
                            <polyline id="Path" stroke="#DA291C" strokeLinecap="round" strokeLinejoin="round" points="8.5 0.5 8.5 5.5 13.5 5.5"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
  );
}

export default CVMenuIcon;