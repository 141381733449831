import React from "react";
import { useTranslation } from "react-i18next";

function YourKnowledge(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.your_knowledges")} = props;

    return (
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="39-Tu-CV-sin-cubrir" transform="translate(-548.000000, -581.000000)" fill="#000000" fillRule="nonzero">
                    <g id="book-bookmark" transform="translate(548.000000, 581.000000)">
                        <path d="M13,0 L7,0 L7,6 L5,5 L3,6 L3,0 C1.3,0 0,1.3 0,3 L0,13 C0,14.7 1.3,16 3,16 L13,16 C14.7,16 16,14.7 16,13 L16,9 L16,3 C16,1.3 14.7,0 13,0 Z M14,13 C14,13.6 13.6,14 13,14 L3,14 C2.4,14 2,13.6 2,13 C2,12.4 2.4,12 3,12 L13,12 C13.6,12 13.9,11.9 14,11.8 L14,13 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default YourKnowledge;