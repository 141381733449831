import React from "react";
import { useTranslation } from "react-i18next";

function Linkedin(props) {
    const { t } = useTranslation();
    const { onClick, className, title=t(`icons_tooltips.linkedin`) } = props;
    return (
        <svg onClick={onClick} className={className} width="24px" height="24px" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>Linkedin</title>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Dashboard-v1" transform="translate(-1060.000000, -1234.000000)" fill="#FFFFFF">
                    <path d="M1084.00001,1234 L1084.00001,1258.00001 L1060,1258.00001 L1060,1234 L1084.00001,1234 Z M1063.19996,1239.3 C1063.19996,1238.14021 1064.14015,1237.20002 1065.29994,1237.20002 C1066.45973,1237.20002 1067.39991,1238.14021 1067.39991,1239.3 C1067.41359,1239.86103 1067.19667,1240.40309 1066.79982,1240.79988 C1066.40309,1241.19673 1065.86097,1241.41359 1065.29994,1241.40004 C1064.14015,1241.40004 1063.19996,1240.45985 1063.19996,1239.3 Z M1080.50001,1254.50001 L1080.50001,1248.30006 C1080.50001,1245.20002 1079.80006,1242.80006 1076.20008,1242.80006 C1074.8202,1242.72126 1073.51063,1243.41456 1072.80006,1244.60005 L1072.80006,1243.00001 L1069.40004,1243.00001 L1069.40004,1254.50001 L1073.00001,1254.50001 L1073.00001,1248.80006 C1073.00001,1247.30006 1073.20008,1245.90004 1075.09999,1245.90004 C1076.90004,1245.90004 1076.90004,1247.60005 1076.90004,1248.90004 L1076.90004,1254.50001 L1080.50001,1254.50001 Z M1067.19996,1254.50001 L1067.19996,1243.00001 L1063.59999,1243.00001 L1063.59999,1254.50001 L1067.19996,1254.50001 Z" id="ico_linkedin"></path>
                </g>
            </g>
        </svg>
    );
}

export default Linkedin;
