import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import i18next from "../i18n.js";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import {
  Typography,
  Box,
  Link,
  Icon,
  Button,
  FormControl,
  Drawer,
} from "@material-ui/core";
import { searchOffers } from "../api/oferta";
import PriorityLow from "../assets/icons/priorityLow";
import Tune from "../assets/icons/tune";
import Layout from "../components/basicLayout";
import Snackbar from "../components/customSnackBar";
import { withSnackbar } from "notistack";
import OffersSearcher from "../components/offersSearcher";
import { getContractTypes, getProvincias, handleGetMaestro, returnLangId } from "../utils/functions";
import { EXTRANJERO, SPAIN, TODA_ESPANHA } from "../constants.js";
import AutocompleteWithChips from "../redux/containers/autocompleteWithChips.js";
import useMaestros from "../Hooks/useMaestros.js";

const provincias = getProvincias(i18next.languages[0]);
const tiposContrato = getContractTypes(i18next.languages[0]);

const useStyles = makeStyles((theme) => ({
  body_container: {
    display: "grid",
    gridArea: "body_container",
    gridTemplateAreas: "'bodyLeft separator bodyRight'",
    gridTemplateColumns: "3fr 1fr 8fr",
    gridTemplateRows: "1fr",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto auto auto",
      gridTemplateAreas: "'topFilters' 'bodyRight' 'bodyLeft'",
    },
  },
  body_left_col: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gridArea: "bodyLeft",
    maxWidth: 275,
  },
  body_right_col: {
    display: "flex",
    gridArea: "bodyRight",
  },
  body_right_col_container: {
    width: "100%",
    padding: "20px",
  },
  buttonFullWidth: {
    width: "100%",
    color: "#FFFFFF",
    height: "36px",
    marginTop: "31px",
    textTransform: "none",
    fontWeight: "bold",
  },
  filters_container: {
    backgroundColor: "#FBFBFB",
    padding: "0 20px",
    [theme.breakpoints.down("sm")]: {
      gridArea: "topFilters",
    },
  },
  filter_delete: {
    textDecoration: "underline",
  },
  filter_footer_button: {
    display: "inline-block",
    width: "50%",
  },
  filter_separator: {
    marginTop: "25px",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
    height: "100vh",
  },
  modify_filter: {
    paddingLeft: "8px",
    fontSize: "19px"
  },
  modify_filter_button: {
    cursor: "inherit",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  no_margin: {
    margin: "0px",
  },
  offer: {
    borderBottom: "1px solid #DDDDDD",
    paddingTop: "6px",
    cursor: "pointer",
    minHeight: "93px",
  },
  offers_list: {
    paddingLeft: "0px",
    marginTop: "-16px",
    "list-style-type": "none",
  },
  offer_subtitle: {
    marginTop: "0px",
  },
  offers_showed: {
    color: theme.palette.primary.main,
    paddingBottom: "16px",
  },
  rotate_icon: {
    "& svg": {
      transform: "rotate(180deg)",
    },
  },
  save_search: {
    float: "right",
    marginTop: "7px",
  },
  searcher: {
    padding: "10px 20px 40px 20px",
  },
  seeMoreButton_container: {
    "&:hover, &:focus": {
      cursor: "pointer",
    },
    marginLeft: "8px",
  },
  seeMoreButton_icon: {
    display: "inline-block",
    marginRight: "10px",
    height: "24px",
  },
  seeMoreButton_text: {
    display: "inline-block",
    textDecoration: "none",
    "&:hover, &:link, &:active, &:visited": {
      textDecoration: "none",
    },
  },
  show_offers_button: {
    width: "95%",
    marginLeft: "5px",
    marginBottom: "25px",
  },
  save_filters_button: {
    width: "95%",
    marginLeft: "5px",
    marginBottom: "15px",
    marginTop: 0,
  },
  snackBar: {
    backgroundColor: "#FFFFFF",
    color: "#000000",
  },
  summary: {
    marginBottom: "16px",
    padding: "10px",
  },
  text_float_right: {
    float: "right",
  },
  date_offer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    lineHeight: "2px",
    marginTop: "10px",
    fontSize: "16px",
    paddingRight: "6px"
  }
}));

function SearchOffers(props) {
  const { t } = useTranslation();
  const { paises,localidades,state, actions, userNotAuthenticated  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const maestros = useMaestros();

  const [loading, setLoading] = useState(true);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [puestoBusqueda, setPuestoBusqueda] = useState("");
  const [provinciaBusqueda, setProvinciaBusqueda] = useState(null);
  const [allOffers, setAllOffers] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [areAllFilteredOffersVisisbles, setAreAllFilteredOffersVisisbles] =
    useState(false);
  const [localidadesOfertas, setLocalidadesOfertas] = useState([]);
  const [localidadesOfertasFiltradas, setLocalidadesOfertasFiltradas] =
    useState([]);
  const [ciudadesOfertas, setCiudadesOfertas] = useState([]);
  const [ciudadesOfertasFiltradas, setCiudadesOfertasFiltradas] =
    useState([]);
  const [offersJobs, setOffersJobs] = useState([]);
  const [isFilterVisible, setIsFilterVisible] = useState(true);
  const [areAllProvincesVisibles, setAreAllProvincesVisibles] = useState(false);
  const [provinciasFiltro, setProvinciasFiltro] = useState([]);
  const [localidadesFiltro, setLocalidadesFiltro] = useState([]);
  const [ciudadesFiltro, setCiudadesFiltro] = useState([]);
  const [localidadAutoComplete, setLocalidadAutoComplete] = useState(null);
  const [puestosFiltro, setPuestosFiltro] = useState([]);
  const [sectoresFiltro, setSectoresFiltro] = useState([]);
  const [tiposJornadaFiltro, setTiposJornadaFiltro] = useState([]);
  const [tiposContratoFiltro, setTiposContratoFiltro] = useState([]);
  const [areAllTiposJornadaVisibles, setAreAllTiposJornadaVisibles] =
    useState(false);
  const [previousFilters, setPreviousFilters] = useState({});
  const [tituloBusqueda, setTituloBusqueda] = useState("");
  const [allSectors, setAllSectors] = useState([]);
  const [tipoHorarioOptions, setTipoHorarioOptions] = useState([]);

  const provincesAreTodaEspana = { id: TODA_ESPANHA, name: t("offers.allProvinces") };
  const provincesAreEnExtranjero = { id: EXTRANJERO, name: t("offers.abroad") };

  useEffect(() => {
    handleGetMaestro(tipoHorarioOptions,setTipoHorarioOptions,maestros.getTipoHorario());
  }, [tipoHorarioOptions]);

  useEffect(() => {
    if (state && state.filtersAction) {
      actions.clearFiltersToSaveAction();
    }
  }, [state.filtersAction]);

  useEffect(() => {
    if (state && state.succesfulSave) {
      setTituloBusqueda(state.succesfulSave);
      setIsSnackBarOpen(true);
      actions.clearSuccesfulSave();
    }
  }, [state.succesfulSave]);

  useEffect(() => {
    if (
      state &&
      state.savedSearch &&
      state.savedSearch.localidades.length === localidadesFiltro.length &&
      state.savedSearch.tiposContrato.length === tiposContratoFiltro.length &&
      state.savedSearch.ciudades.length === ciudadesFiltro.length &&
      state.savedSearch.tiposJornada.length === tiposJornadaFiltro.length &&
      state.savedSearch.provincias
        .filter((provincia) => provincia !== 0 && provincia !== -1)
        .length === provinciasFiltro.length &&
      state.savedSearch.puestos.length === puestosFiltro.length &&
      state.savedSearch.sectores.length === sectoresFiltro.length
    ) {
      filterOffers();
      actions.clearSavedSearchAction();
    }
  }, [
    localidadesFiltro,
    tiposContratoFiltro,
    tiposJornadaFiltro,
    provinciasFiltro,
    puestosFiltro,
    sectoresFiltro,
    ciudadesFiltro
  ]);

  useEffect(() => {
    if (state && state.soSnapshot) {
      getSnapshot(state.soSnapshot);
      actions.clearSnapshot();
    } else if (localidades?.length) {
      setPuestoBusqueda(props.match.params.puestoBusqueda || "");

      setProvinciaBusqueda(getProvinciaBusquedaFromUrl());

      handleSearchOffers(
        props.match.params.puestoBusqueda,
        props.match.params.provinciaBusqueda
      );
    }
  }, [props.match.params.puestoBusqueda, props.match.params.provinciaBusqueda, localidades]);

  useEffect(() => {
    if (matchesMobile) {
      setIsFilterVisible(false);
    }
  }, [matchesMobile]);

  useEffect(() => {
    if (provinciasFiltro.length > 0) {
      const newCiudadesFiltro = ciudadesOfertas.filter((city) =>
        provinciasFiltro.find((p) => {
          return Number(p.id_country) === city.idPais;
        })
      );
      const newLocalidadesFiltro = localidadesOfertas.filter((localidad) =>
        provinciasFiltro.find((p) => {
          return Number(p.id) === localidad.provincia;
        })
      );

      if (isCountryForeign()) {
        setCiudadesOfertasFiltradas(newCiudadesFiltro);
        setCiudadesFiltro(
          ciudadesFiltro.filter((c) =>
            provinciasFiltro.find((p) => {
              return Number(p.id_country) === Number(c.idPais);
            })));
      } else {
        setLocalidadesOfertasFiltradas(newLocalidadesFiltro);
        setLocalidadesFiltro(
          localidadesFiltro.filter((localidad) =>
            provinciasFiltro.find((p) => {
              return (
                Number(p.id) === Number(localidad.id_province) ||
                Number(p.id) === Number(localidad.provincia)
              );
            })
          )
        );
      }
      if (!newLocalidadesFiltro.includes(localidadAutoComplete)) {
        setLocalidadAutoComplete(null);
      }
    } else {
      setLocalidadesOfertasFiltradas(localidadesOfertas);
      setCiudadesOfertasFiltradas(ciudadesOfertas);
    }
  }, [provinciasFiltro]);

  function getProvinciaBusquedaFromUrl() {
    let provinciaBusquedaFromUrl = props.match.params.provinciaBusqueda;
    switch (provinciaBusquedaFromUrl) {
      case EXTRANJERO:
        return provincesAreEnExtranjero;
      case TODA_ESPANHA:
        return provincesAreTodaEspana
      default:
        return getProvinceById(Number(provinciaBusquedaFromUrl));
    }
  }

  function getProvinceById(id) {
    return provincias.find((prov) => Number(prov.id) === id);
  }

  const getNombrePais = (idPais) => 
    paises.find(
      (pais) => Number(pais.id) === Number(idPais)
    )?.name ?? "";
  const getNombreLocalidad = (idLocalidad) =>
    localidades?.find(
      (localidad) => Number(localidad.id) === Number(idLocalidad)
    )?.name ?? "";
  const getProvinciaLocalidad = (idLocalidad) =>
    Number(
      localidades?.find(
        (localidad) => Number(localidad.id) === Number(idLocalidad)
      )?.id_province
    ) ?? 0;
  const getNombreTipoHorario = (idTipoHorario) =>
    tipoHorarioOptions.find(
      (tipoHorario) => Number(tipoHorario.id) === Number(idTipoHorario)
    )?.name ?? "";
  const getFormattedDate = (date) => {
    const d = date.split("T")[0].split("-");
    return `${d[2]}/${d[1]}/${d[0]}`;
  };
  const getFormattedSearch = (titulo) => {
    return `"${titulo}"`;
  };

  const getNumberOfOffersByProvince = 
    (provinciaId) => 
      allOffers.filter((off) => off.idProvincia === Number(provinciaId)).length;
  const existsOfferInProvince = 
    (provinciaId) => 
      allOffers.some((offer) => offer.idProvincia === Number(provinciaId));

      const getOffersProvinces = () =>
      provincias.filter((prov) =>
        existsOfferInProvince(prov.id)
      );
    
  const filterOffers = () => { 
    let filterCities = ciudadesFiltro.map((c) => c.name.trim().slice(0, -3).trim());
    
    setFilteredOffers(
      allOffers.filter(
        (offer) =>
          (provinciasFiltro.length === 0 ||
            provinciasFiltro
              .map((province) => province.id)
              .includes(offer.idProvincia.toString())) &&
          (localidadesFiltro.length === 0 ||
            localidadesFiltro
              .map((loc) => Number(loc.id))
              .includes(offer.idLocalidad)) &&
         (ciudadesFiltro.length === 0 ||
          filterCities
              .includes(offer.ciudad))&&
          (puestosFiltro.length === 0 ||
            puestosFiltro.map((job) => job.id).includes(offer.puesto.id)) &&
          (sectoresFiltro.length === 0 ||
            sectoresFiltro
              .map((sector) => sector.id)
              .includes(offer?.sector?.idSector)) &&
          (tiposJornadaFiltro.length === 0 ||
            tiposJornadaFiltro
              .map((tipos) => tipos.id)
              .includes(offer.idTipoHorario.toString())) &&
          (tiposContratoFiltro.length === 0 ||
            tiposContratoFiltro
              .map((tipos) => tipos.id)
              .includes(offer.idTipoContrato.toString()))
                 )
    );
  };

  const setInitialFilters = (offers) => {
    setProvinciasFiltro([]);
    setSectoresFiltro([]);
    setTiposJornadaFiltro([]);
    setTiposContratoFiltro([]);
  };

  function handleGetCities(result) {
    const cities = result
      .map((offer,index) => ({
        id:index,
        idPais: offer.idPais,
        name: offer.ciudad
      }))
      .filter((value, index, self) =>index === self.findIndex((t) => (
        t.name === value.name && value.name !== null)));


    const mappedCities = cities.map((value) => ({
      id: value.id,
      name: `${value.name} (${result.filter((offerResult) => offerResult.ciudad === value.name && offerResult.ciudad !== null)
        .length
        })`,
      idPais: value.idPais
    }));
    return mappedCities;
  }

 function isCountryForeign(){
  let isProvBusquedaInSpain = provincias.find((prov) => 
                               prov.id === props.match.params.provinciaBusqueda
                               && prov.id_country != SPAIN);
  return props.match.params.provinciaBusqueda == EXTRANJERO || isProvBusquedaInSpain;
 }

  async function handleSearchOffers(puesto, provincia) {
    setLoading(true);
    const response = await searchOffers(
      puesto ? puesto : "",
      provincia,
      returnLangId(i18next.languages[0])
    );

    if (response.status === 200) {
      const result = await response.json();
      setAllOffers(result);
      setFilteredOffers(result);
      handleGetCities(result);
      const localidadesOfertas = result
        .map((offer) => offer.idLocalidad)
        .filter((value, index, self) => self.indexOf(value) === index);

      const mappedLocalidadesOfertas = localidadesOfertas.map((localidad) => ({
        id: localidad,
        name: `${getNombreLocalidad(localidad)} (${
          result.filter((offerResult) => offerResult.idLocalidad === localidad)
            .length
        })`,
        provincia: getProvinciaLocalidad(localidad),
      }));
  
      if (isCountryForeign()) {
        setCiudadesOfertas(handleGetCities(result));
        setCiudadesOfertasFiltradas(handleGetCities(result));
      } else {
        setLocalidadesOfertas(mappedLocalidadesOfertas);
        setLocalidadesOfertasFiltradas(mappedLocalidadesOfertas);
      }
    
      const puestosOfertas = result
        .map((offer) => offer.puesto)
        .filter(
          (value, index, self) =>
            self.map((job) => job.id).indexOf(value.id) === index
        ).sort((a, b) => a.nombre.localeCompare(b.nombre));

      setOffersJobs(puestosOfertas);

      const sectoresOfertas = result
        .map((offer) => ({
          id: offer?.sector?.idSector,
          name: offer?.sector?.nombre,
        }))
        .filter(
          (value, index, self) =>
            self.map((sector) => sector.id).indexOf(value.id) === index
        );
      setAllSectors(sectoresOfertas);

      if (state && state.savedSearch) {
        assignSavedSearch(
          state.savedSearch,
          puestosOfertas,
          sectoresOfertas,
          result,
          handleGetCities(result)
        );
      } else {
        setPuestosFiltro([]);
        setLocalidadesFiltro([]);
        setCiudadesFiltro([]);
        setInitialFilters(result);
      }
    } else {
      switch (response.status) {
        case 400:
          props.enqueueSnackbar(t("offers.get_error"), {
            variant: "error",
          });
          break;
        default:
          props.enqueueSnackbar(t("offers.error"), {
            variant: "error",
          });
          break;
      }
      setAllOffers([]);
      setLocalidadesOfertasFiltradas([]);
      setCiudadesOfertasFiltradas([]);
    }

   setLoading(false);
  }

  const handleOfferClick = (offerId) => {
    saveSearch();
    props.history.push(`/offer/${offerId}`);
  };
  
  function getForeignSearchProvince(){
   return isCountryForeign() ? t("offers.abroad"): t("offers.allProvinces");
  }

  function separatorLocalidadAndTipoHorario(offer) {
    return (
      offer.idLocalidad && offer.idTipoHorario ? ` ${"·"} ` : ""
    );
  }

  function summary() {
    return (
      <Typography component="div" className={classes.summary}>
        <Box
          display="inline-block"
          fontSize={30}
          lineHeight={"38px"}
          fontWeight="900"
          m={1}
          className={classes.no_margin}
        >
          {t("offers.offers_mayus")}{" "}
          {puestoBusqueda === "" || !puestoBusqueda ? "" : t("offers.of")}{" "}
          {puestoBusqueda} {t("offers.in")}{" "}
          {provinciaBusqueda
            ? provinciaBusqueda.name
            : getForeignSearchProvince()}
        </Box>
      </Typography>
    );
  }

  function noOffers() {
    return (
      <Typography component="div">
        <Box display="inline-block" fontSize={30} fontWeight="900" m={1}>
          {t("offers.noOffers")}
        </Box>
      </Typography>
    );
  }

  function offers() {
    return (
      <>
        <Typography align="right" className={classes.offers_showed}>
          {`${t("offers.showed")} ${filteredOffers.length} ${t(
            "offers.offers"
          )}`}
        </Typography>
        <ul className={classes.offers_list}>
          {filteredOffers
            .slice(
              0,
              areAllFilteredOffersVisisbles ? filteredOffers.length : 20
            )
            .map((offer, index) => (
              <li key={index}>
                <div className={classes.offer}>
                  <Typography
                    component="div"
                    onClick={() => {
                      handleOfferClick(offer.idOferta);
                    }}
                  >
                    <Box
                      display="inline-block"
                      fontSize="subtitle1.fontSize"
                      textAlign="right"
                      m={1}
                      className={classes.date_offer}
                    >
                      {getFormattedDate(offer.fechaPublicacion)}
                    </Box>
                    <Box>
                      <Box
                        display="inline-block"
                        fontSize={22}
                        fontWeight="900"
                        ml={1}
                      >
                        {offer.titulo}
                      </Box>
                      <Box
                        fontSize="subtitle1.fontSize"
                        m={1}
                      >
                        {offer.idPais !== SPAIN ? getNombrePais(offer.idPais) : null}
                        {offer.idPais === SPAIN ? getNombreLocalidad(offer.idLocalidad) : null}
                        {separatorLocalidadAndTipoHorario(offer)}
                        {getNombreTipoHorario(offer.idTipoHorario)}
                      </Box>
                    </Box>
                  </Typography>
                </div>
              </li>
            ))}
        </ul>
      </>
    );
  }

  function seeMoreButton(arrowVisibilty, iconFloatingRight, handleClick) {
    return (
      <div
        className={classes.seeMoreButton_container}
        style={
          iconFloatingRight
            ? { borderBottom: "1px solid #DDDDDD", height: "38px" }
            : null
        }
        onClick={handleClick}
      >
        <Typography
          className={`${classes.seeMoreButton_icon} ${
            arrowVisibilty ? classes.rotate_icon : ""
          }`}
          style={
            iconFloatingRight ? { float: "right", marginTop: "-8px" } : null
          }
        >
          <Icon color="primary">
            <PriorityLow title={t("icons_tooltips.expand")} />
          </Icon>
        </Typography>
        <Typography className={classes.seeMoreButton_text}>
          <Link component="button">
            {arrowVisibilty ? t("offers.seeLess") : t("offers.seeMore")}
          </Link>
        </Typography>
      </div>
    );
  }

  function ContractsFilter() {
    let optionsContrato = tiposContrato
      .filter((contrato) =>
        allOffers.some((offer) => offer.idTipoContrato === Number(contrato.id))
      )
      .map((tipoContrato) => ({
        ...tipoContrato,
        name: `${tipoContrato.name} (${
          allOffers.filter(
            (offer) => offer.idTipoContrato === Number(tipoContrato.id)
          ).length
        })`,
      }));
    return (
      <>
        <FormControl
          variant="outlined"
          fullWidth
          className={`${classes.inputRoot} ${classes.filter_separator}`}
        >
          <AutocompleteWithChips
            options={optionsContrato}
            values={tiposContratoFiltro}
            label={t("offers.contractType")}
            setter={setTiposContratoFiltro}
            moveLabel={true}
            maxLength={100}
            noAdd
          />
        </FormControl>
      </>
    );
  }

  function WorkdaysFilter() {
    let optionsHorario = tipoHorarioOptions?.filter((horario) =>
        allOffers.some((offer) => offer.idTipoHorario === Number(horario.id))
      )
      .map((tipoHorario) => ({
        ...tipoHorario,
        name: `${tipoHorario.name} (${
          allOffers.filter(
            (offer) => offer.idTipoHorario === Number(tipoHorario.id)
          ).length
        })`,
      }))
      .sort((a, b) => parseInt(a.order) - parseInt(b.order));
    return (
      <>
        <FormControl
          variant="outlined"
          fullWidth
          className={`${classes.inputRoot} ${classes.filter_separator}`}
        >
          <AutocompleteWithChips
            options={optionsHorario}
            values={tiposJornadaFiltro}
            label={t("offers.workdayType")}
            setter={setTiposJornadaFiltro}
            moveLabel={true}
            maxLength={100}
            noAdd
          />
        </FormControl>
      </>
    );
  }

  function sectorsFilter() {
    let optionsSector = allSectors
      .filter((sector) =>
        allOffers.some(
          (offer) => Number(offer?.sector?.idSector) === Number(sector.id)
        )
      )
      .map((sector2) => ({
        ...sector2,
        name: `${sector2.name} (${
          allOffers.filter(
            (offer) => Number(offer?.sector?.idSector) === Number(sector2.id)
          ).length
        })`,
      }));
    return (
      <>
        <FormControl
          variant="outlined"
          fullWidth
          className={`${classes.inputRoot} ${classes.filter_separator}`}
        >
          <AutocompleteWithChips
            options={optionsSector}
            values={sectoresFiltro}
            label={t("offers.sector")}
            setter={setSectoresFiltro}
            moveLabel={true}
            maxLength={100}
            noAdd
          />
        </FormControl>
      </>
    );
  }

  function provincesFilter() {
    let optionsProvincias = getOffersProvinces().map((provincia) => ({
      id: provincia.id,
      name: `${provincia.name !== "" ? provincia.name : paises.find(
        (pais) => Number(pais.id) === Number(provincia.id_country)
      )?.name} (${getNumberOfOffersByProvince(provincia.id)
        })`,
        id_country: provincia.id_country,
        codigo_ine: provincia.codigo_ine,
      }));

    return (
      <FormControl
        variant="outlined"
        fullWidth
        className={`${classes.inputRoot} ${classes.filter_separator}`}
      >
        <AutocompleteWithChips
          options={optionsProvincias}
          values={provinciasFiltro}
          label={isCountryForeign() ? t("offers.region") : t("offers.province")}
          setter={setProvinciasFiltro}
          moveLabel={true}
          maxLength={100}
          noAdd
        />
      </FormControl>
    );
  }

  function citiesFilter() {
    return (
      <>
        <FormControl
          variant="outlined"
          fullWidth
          className={`${classes.inputRoot} ${classes.filter_separator}`}
        >
          <AutocompleteWithChips
            options={ciudadesOfertasFiltradas}
            values={ciudadesFiltro}
            label={t("offers.city")}
            setter={setCiudadesFiltro}
            setterOptions={setCiudadesOfertasFiltradas}
            moveLabel={true}
            maxLength={100}
            noAdd
          />
        </FormControl>
      </>
    );
  }

  function locationsFilter() {
    const optionsLocalidades =localidadesOfertasFiltradas;
    return (
      <>
        <FormControl
          variant="outlined"
          fullWidth
          className={`${classes.inputRoot} ${classes.filter_separator}`}
        >
          <AutocompleteWithChips
            options={optionsLocalidades}
            values={localidadesFiltro}
            label={t("offers.location")}
            setter={setLocalidadesFiltro}
            setterOptions={setLocalidadesOfertasFiltradas}
            moveLabel={true}
            maxLength={100}
            noAdd
          />
        </FormControl>
      </>
    );
  }

  function assignSavedSearch(data, puestosOfertas, sectoresOfertas, offerAll,cities) { 
    let horarios = tipoHorarioOptions
      .filter((horario) =>
        offerAll.some((offer) => offer.idTipoHorario === Number(horario.id))
      )
      .map((tipoHorario) => ({
        ...tipoHorario,
        name: `${tipoHorario.name} (${
          offerAll.filter(
            (offer) => offer.idTipoHorario === Number(tipoHorario.id)
          ).length
        })`,
      }))
      .sort((a, b) => parseInt(a.order) - parseInt(b.order));

    setPuestoBusqueda(data.palabrasClave);
    setLocalidadesFiltro(
      localidades.filter((loc) => data.localidades.includes(Number(loc.id)))
    );
    setCiudadesFiltro(cities.filter((city) => data.ciudades?.includes(city.name.trim().slice(0,-3).trim())));
    setProvinciaBusqueda(getSavedProvinciaBusqueda(data));
    
    setProvinciasFiltro(
      provincias.filter((prov) => data.provincias.includes(Number(prov.id)))
    );
    setSectoresFiltro(
      sectoresOfertas.filter((sector) =>
        data.sectores.includes(Number(sector.id))
      )
    );
    setPuestosFiltro(
      puestosOfertas.filter((puesto) => data.puestos.includes(puesto.id))
    );
    setTiposJornadaFiltro(
      horarios.filter((jornada) =>
        data.tiposJornada.includes(Number(jornada.id))
      )
    );
    setTiposContratoFiltro(
      tiposContrato.filter((cont) =>
        data.tiposContrato.includes(Number(cont.id))
      )
    );
  }

  function getSavedProvinciaBusqueda(data) {
    if(data.provincias.length > 1){
      return isCountryForeign() ? provincesAreEnExtranjero : provincesAreTodaEspana;
    }
    let singleProvince = getProvinceById(data.provincias[0]);
    if(singleProvince) {
      if (singleProvince.name!=="") {
        return singleProvince;
      } else {
        return paises.find((p) => p.id === singleProvince.id_country);
      }
    }
    else{
      return data.provincias[0] === 0 
        ? provincesAreTodaEspana
        : provincesAreEnExtranjero
    }
  }

  function getSnapshot(data) {
    setLoading(data.loading);
    setPuestoBusqueda(data.puestoBusqueda);
    setProvinciaBusqueda(data.provinciaBusqueda);
    setAllOffers(data.allOffers);
    setFilteredOffers(data.filteredOffers);
    setAreAllFilteredOffersVisisbles(data.areAllFilteredOffersVisisbles);
    setLocalidadesOfertas(data.localidadesOfertas);
    setLocalidadesOfertasFiltradas(data.localidadesOfertas);
    setOffersJobs(data.offersJobs);
    setIsFilterVisible(data.isFilterVisible);
    setAreAllProvincesVisibles(data.areAllProvincesVisibles);
    setProvinciasFiltro(data.provinciasFiltro);
    setLocalidadesFiltro(data.localidadesFiltro);
    setPuestosFiltro(data.puestosFiltro);
    setAllSectors(data.allSectors);
    setSectoresFiltro(data.sectoresFiltro);
    setTiposJornadaFiltro(data.tiposJornadaFiltro);
    setTiposContratoFiltro(data.tiposContratoFiltro);
    setAreAllTiposJornadaVisibles(data.areAllTiposJornadaVisibles);
    setCiudadesFiltro(data.ciudadesFiltro);
    setCiudadesOfertas(data.ciudadesOfertas);
    setCiudadesOfertasFiltradas(data.ciudadesOfertasFiltradas);
  }

  function setSnapshot() {
    const data = {
      loading: loading,
      isSnackBarOpen: isSnackBarOpen,
      puestoBusqueda: puestoBusqueda,
      provinciaBusqueda: provinciaBusqueda,
      allOffers: allOffers,
      filteredOffers: filteredOffers,
      areAllFilteredOffersVisisbles: areAllFilteredOffersVisisbles,
      localidadesOfertas: localidadesOfertas,
      offersJobs: offersJobs,
      isFilterVisible: isFilterVisible,
      areAllProvincesVisibles: areAllProvincesVisibles,
      provinciasFiltro: provinciasFiltro,
      localidadesFiltro: localidadesFiltro,
      puestosFiltro: puestosFiltro,
      allSectors: allSectors,
      sectoresFiltro: sectoresFiltro,
      tiposJornadaFiltro: tiposJornadaFiltro,
      tiposContratoFiltro: tiposContratoFiltro,
      areAllTiposJornadaVisibles: areAllTiposJornadaVisibles,
      ciudadesFiltro: ciudadesFiltro,
      ciudadesOfertas: ciudadesOfertas,
    };
    actions.setSnapshot(data);
  }

  function setFiltersToSave() {
    const data = {
      palabraClave: puestoBusqueda,
      provincias:
        provinciasFiltro && provinciasFiltro.length > 0
          ? provinciasFiltro.map((item) => Number(item.id))
          : [Number(provinciaBusqueda.id)],
      localidades: localidadesFiltro.map((item) => item.id),
      sectores: sectoresFiltro.map((item) => item.id),
      puestos: puestosFiltro.map((item) => item.id),
      tiposJornada: tiposJornadaFiltro.map((item) => Number(item.id)),
      tiposContrato: tiposContratoFiltro.map((item) => Number(item.id)),
      ciudades: ciudadesFiltro.map((item) =>item.name),
    };
    actions.setFiltersToSaveAction(data);
  }

  function saveSearch() {
    setFiltersToSave();
    setSnapshot();
  }

  function handleSaveSearch() {
    saveSearch();
    if (props.userNotAuthenticated) {
      sessionStorage.setItem("origin:login", "/save_search");
      props.auth.login();
    } else {
      props.history.push("save_search");
    }
  }

  function puestosFilter() {
    return (
      <>
        <FormControl
          variant="outlined"
          fullWidth
          className={`${classes.inputRoot} ${classes.filter_separator}`}
        >
          <AutocompleteWithChips
            options={offersJobs}
            values={puestosFiltro}
            label={t("offers.job")}
            setter={setPuestosFiltro}
            setterOptions={setOffersJobs}
            moveLabel={true}
            maxLength={100}
            noAdd
          />
        </FormControl>
      </>
    );
  }

  function filters() {
    return (
      <div className={classes.filters_container}>
        {matchesMobile ? (
          <Typography component="div">
            <Box
              display="inline-block"
              fontSize={14}
              m={1}
              className={classes.no_margin}
            >
              <Link
                component="button"
                className={classes.modify_filter_button}
                onClick={(event) => {
                  event.preventDefault();
                  setPreviousFilters({
                    provinces: provinciasFiltro,
                    locations: localidadesFiltro,
                    ciudad: ciudadesFiltro,
                    jobs: puestosFiltro,
                    sectors: sectoresFiltro,
                    workdayTypes: tiposJornadaFiltro,
                    contractTypes: tiposContratoFiltro,
                  });
                  setIsFilterVisible(!matchesMobile || !isFilterVisible);
                }}
              >
                <Icon color="primary">
                  <Tune />
                </Icon>
                {provinciasFiltro.length > 0 ||
                localidadesFiltro.length > 0 ||
                puestosFiltro.length > 0 ||
                sectoresFiltro.length > 0 ||
                tiposJornadaFiltro.length > 0 ||
                ciudadesFiltro.length > 0 ||
                tiposContratoFiltro.length > 0 ? (
                  <span className={classes.modify_filter}>
                    {t("offers.modifyFilter")}
                  </span>
                ) : (
                  <span className={classes.modify_filter}>
                    {t("offers.filter")}
                  </span>
                )}
              </Link>
            </Box>
          </Typography>
        ) : null}
        {isFilterVisible ? (
          <>
            {provincesFilter()}
            {isCountryForeign() ? citiesFilter() : locationsFilter()}
            {puestosFilter()}
            {sectorsFilter()}
            {WorkdaysFilter()}
            {ContractsFilter()}

            <Button
              variant="contained"
              color="primary"
              className={`${classes.buttonFullWidth} ${classes.show_offers_button}`}
              onClick={() => {
                filterOffers();
                setIsFilterVisible(!matchesMobile);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              {t("offers.showOffers")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={`${classes.buttonFullWidth} ${classes.save_filters_button}`}
              onClick={() => {
                saveSearch();
                props.history.push("/save_search");
              }}
            >
              {t("offers.saveSearch")}
            </Button>

            {matchesMobile ? (
              <Typography
                component="div"
                className={classes.filter_footer_button}
              >
                <Box fontSize={14} textAlign="left" m={1}>
                  <Link
                    component="button"
                    className={classes.filter_delete}
                    onClick={(event) => {
                      event.preventDefault();
                      setProvinciasFiltro(previousFilters.provinces);
                      setLocalidadesFiltro(previousFilters.locations);
                      setCiudadesFiltro(previousFilters.ciudad);
                      setPuestosFiltro(previousFilters.jobs);
                      setSectoresFiltro(previousFilters.sectors);
                      setTiposJornadaFiltro(previousFilters.workdayTypes);
                      setTiposContratoFiltro(previousFilters.contractTypes);

                      setIsFilterVisible(false);
                    }}
                  >
                    {t("offers.cancel")}
                  </Link>
                </Box>
              </Typography>
            ) : null}
            <Typography
              component="div"
              className={matchesMobile ? classes.filter_footer_button : ""}
            >
              <Box
                fontSize={14}
                textAlign={matchesMobile ? "right" : "center"}
                m={1}
              >
                <Link
                  component="button"
                  className={classes.filter_delete}
                  onClick={(event) => {
                    event.preventDefault();
                    setProvinciasFiltro([]);
                    setLocalidadesFiltro([]);
                    setPuestosFiltro([]);
                    setSectoresFiltro([]);
                    setCiudadesFiltro([]);
                    setTiposJornadaFiltro([]);
                    setTiposContratoFiltro([]);
                    setLocalidadAutoComplete(null);
                  }}
                >
                  {t("offers.deleteFilters")}
                </Link>
              </Box>
            </Typography>
          </>
        ) : null}
      </div>
    );
  }

  const closeDrawer = () => {
    setIsFilterVisible(false);
  };

  function filterMobile() {
    return (
      <Drawer anchor={"top"} open={isFilterVisible} onClose={closeDrawer}>
        <div
          className={`${classes.list} ${classes.fullList}`}
          role="presentation"
        >
          {filters()}
        </div>
      </Drawer>
    );
  }

  return (
    <Layout
      history={props.history}
      loading={loading}
      isLogged={!userNotAuthenticated}
    >
      <Snackbar
        text={t("offers.savedSearch", {
          search: getFormattedSearch(tituloBusqueda),
        })}
        isSnackBarOpen={isSnackBarOpen}
        handleIsSnackBarOpen={setIsSnackBarOpen}
      />
      {matchesMobile ? filterMobile() : null}
      <div className={classes.body_container}>
        {matchesMobile ? (
          <div>
            {allOffers.length > 0 ? summary() : null}
            {filters()}
          </div>
        ) : null}
        <div className={classes.body_left_col}>
          {!matchesMobile ? (
            <div>
              {allOffers.length > 0 ? summary() : null}
              {!matchesMobile ? filters() : null}
            </div>
          ) : null}
          <div className={classes.searcher}>
            <OffersSearcher />
          </div>
        </div>
        <div className={classes.body_right_col}>
          <div className={classes.body_right_col_container}>
            {filteredOffers.length === 0 && loading === false ? noOffers() : offers()}
            {filteredOffers.length < 20
              ? null
              : seeMoreButton(areAllFilteredOffersVisisbles, true, () =>
                  setAreAllFilteredOffersVisisbles(
                    !areAllFilteredOffersVisisbles
                  )
                )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default withSnackbar(SearchOffers);
