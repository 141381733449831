import React from "react";
import { useTranslation } from "react-i18next";

function EditIcon(props) {
  const { t } = useTranslation();
  const { title = t("icons_tooltips.edit")} = props;

  return (
      <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Recursos" transform="translate(-782.000000, -1788.000000)" fill="#DA291C" fillRule="nonzero">
                  <g id="pen-01-copy-9" transform="translate(782.000000, 1788.000000)">
                      <path d="M8.1,3.5 L12.5,7.9 L4.7,15.7 C4.5,15.9 4.3,16 4,16 L4,16 L1,16 C0.4,16 0,15.6 0,15 L0,15 L0,12 C0,11.7 0.1,11.5 0.3,11.3 L0.3,11.3 L8.1,3.5 Z M11.3,0.3 C11.7,-0.1 12.3,-0.1 12.7,0.3 L12.7,0.3 L15.7,3.3 C16.1,3.7 16.1,4.3 15.7,4.7 L15.7,4.7 L13.9,6.5 L9.5,2.1 Z" id="Combined-Shape"></path>
                  </g>
              </g>
          </g>
      </svg>
  );
}

export default EditIcon;
