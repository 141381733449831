import React from "react";

function Tune(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>4D0FDFBB-8DE2-4E3E-8980-80AE75AAAF0A</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Recursos" transform="translate(-224.000000, -1787.000000)" fill="#DA291C" fillRule="nonzero">
              <g id="Group" transform="translate(224.000000, 1787.000000)">
                  <path d="M11,10 L11,15 C11,15.6 11.4,16 12,16 C12.6,16 13,15.6 13,15 L13,10 C13,9.4 12.6,9 12,9 C11.4,9 11,9.4 11,10 Z" id="Path"></path>
                  <path d="M16,6 L16,3 C16,2.4 15.6,2 15,2 L13,2 L13,1 C13,0.4 12.6,0 12,0 C11.4,0 11,0.4 11,1 L11,2 L9,2 C8.4,2 8,2.4 8,3 L8,6 C8,6.6 8.4,7 9,7 L15,7 C15.6,7 16,6.6 16,6 Z" id="Path"></path>
                  <path d="M4,0 C3.4,0 3,0.4 3,1 L3,6 C3,6.6 3.4,7 4,7 C4.6,7 5,6.6 5,6 L5,1 C5,0.4 4.6,0 4,0 Z" id="Path"></path>
                  <path d="M1,14 L3,14 L3,15 C3,15.6 3.4,16 4,16 C4.6,16 5,15.6 5,15 L5,14 L7,14 C7.6,14 8,13.6 8,13 L8,10 C8,9.4 7.6,9 7,9 L1,9 C0.4,9 0,9.4 0,10 L0,13 C0,13.6 0.4,14 1,14 Z" id="Path"></path>
              </g>
          </g>
      </g>
  </svg>
  );
}

export default Tune;
