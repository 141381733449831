import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../components/loading";
import Header from "../components/header";
import Footer from "../components/footer";

const useStyles = makeStyles((theme) => ({
  layout_container: {
    width: "100%",
    height: "100vh",
    height: "calc(var(--vh, 1vh)*100)",
    minHeight: "100vh",
    minHeight: "calc(var(--vh, 1vh)*100)",
    display: "grid",
    gridTemplateRows: "min-content 1fr min-content",
    gridTemplateAreas: "'header' 'body' 'footer'",
  },
  layout_body: {
    gridArea: "body",
    display: "grid",
    gridTemplateColumns: "minMax(0, 1fr) minMax(980px, 1100px) minMax(0, 1fr)",
    gridTemplateRows: "minMax(0, 1fr)",
    gridTemplateAreas: "'. body_container .'",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      gridTemplateRows: "auto",
      gridTemplateColumns: "0% minMax(0, 1fr) 0%",
    },
  },
  layout_body_full: {
    gridArea: "body",
    display: "grid",
    gridTemplateColumns: "0% minMax(0, 1fr) 0%",
    gridTemplateAreas: "'. body_container .'",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      gridTemplateRows: "auto",
      gridTemplateColumns: "0% minMax(0, 1fr) 0%",
    },
  },
  loading: {
    display: "flex",
    width: "100%",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
  },
  hasBackgroundImage: {
    backgroundImage: "url(images/fondo1920x1080.jpg)",
    backgroundPosition: "center",
  },
}));

function Layout(props) {
  const {
    children,
    loading = false,
    history,
    fullBody = false,
    hasBackgroundImage = false,
    isLogged = true,
    className,
  } = props;

  const classes = useStyles();
  const [height, setHeight] = useState(window.innerHeight);
  const [paddingTop, setPaddingTop] = useState(0);

  const handleResize = () => {
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loading]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={classes.layout_container}
      style={{ "--vh": height * 0.01 + "px" }}
    >
      <Header
        history={history}
        isLogged={isLogged}
        setPadding={setPaddingTop}
      />
      {loading ? (
        <div className={classes.loading}>
          <Loading display={true} height={64} width={64} />
        </div>
      ) : (
      <div
          className={
            fullBody
              ? `${classes.layout_body_full}`
              : `${classes.layout_body} ${
                  hasBackgroundImage && classes.hasBackgroundImage
                } ${className}`
          }
          style={{ paddingTop: paddingTop }}
        >
          {children}
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Layout;
