import { connect } from "react-redux";
import OtrosDatos from "../../Views/otrosDatos";

function mapDispatchToProps(dispatch) {
  return {
    actions: {
    }
  };
}

function mapStateToProps(state) {
  return {
    state: state.webCandidate
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OtrosDatos);
