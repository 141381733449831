import React from "react";
import { useTranslation } from "react-i18next";

function PerfilDigitalLinkedin(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.linkedin")} = props;

    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Recursos" transform="translate(-142.000000, -1858.000000)" fill="#DA291C">
                    <path d="M166.000011,1858 L166.000011,1882.00001 L142,1882.00001 L142,1858 L166.000011,1858 Z M145.199962,1863.3 C145.199962,1862.14021 146.140148,1861.20002 147.299938,1861.20002 C148.459728,1861.20002 149.399913,1862.14021 149.399913,1863.3 C149.413585,1863.86103 149.196666,1864.40309 148.799816,1864.79988 C148.403087,1865.19673 147.860973,1865.41359 147.299938,1865.40004 C146.140148,1865.40004 145.199962,1864.45985 145.199962,1863.3 Z M162.500011,1878.50001 L162.500011,1872.30006 C162.500011,1869.20002 161.80006,1866.80006 158.200084,1866.80006 C156.820201,1866.72126 155.510631,1867.41456 154.80006,1868.60005 L154.80006,1867.00001 L151.400035,1867.00001 L151.400035,1878.50001 L155.000011,1878.50001 L155.000011,1872.80006 C155.000011,1871.30006 155.200084,1869.90004 157.099987,1869.90004 C158.900035,1869.90004 158.900035,1871.60005 158.900035,1872.90004 L158.900035,1878.50001 L162.500011,1878.50001 Z M149.199962,1878.50001 L149.199962,1867.00001 L145.599987,1867.00001 L145.599987,1878.50001 L149.199962,1878.50001 Z" id="ico_linkedin"></path>
                </g>
            </g>
        </svg>
    );
}

export default PerfilDigitalLinkedin;