export const perfilesDigitalesEnum = {
    LINKEDIN : 1,
    FACEBOOK : 2,
    INSTAGRAM : 3, 
    TWITTER : 4, 
    BLOG : 5, 
    PORTFOLIO : 6,
    OTRO : 7
}

export const tiposDocumentos = {
    INFORME_FASE_RESULTADOS: 1,
    INFORME_FASE_ENTREVISTA_TELEFONICA: 2,
    INFORME_FASE_DINAMICAS: 3,
    INFORME_PRUEBAS_TECNICAS: 4,
    INFORME_PRUEBAS_PSICOTECNICAS: 5,
    INFORMES_PRUEBAS_IDIOMAS: 6,
    INFORME_FASE_ENTREVISTA_PERSONAL: 7,
    INFORME_FASE_INFORME_CANDIDATO: 8,
    INFORME_FASE_PRESENTACION_CLIENTE: 9,
    INFORME_FASE_ENTREVISTA_CLIENTE: 10,
    INFORME_FASE_PRUEBAS_CLIENTE: 11,
    IDENTIFICACION: 12,
    CERTIFICADO_CUENTA_BANCARIA: 13,
    PERMISO_TRABAJO: 14,
    CERTIFICADO_ANTECEDENTES_PENALES: 15,
    FOTOGRAFIA: 16,
    CERTIFICADO_DISCAPACIDAD: 17,
    CURRICULUM_VITAE: 18,
    TITULO_FORMACION_REGLADA: 19,
    CARNE_CONDUCIR: 20
}

export const indicadorCv = {
    EXPERIENCIA_LABORAL: 1,
    FORMACIONES: 2,
    CONOCIMIENTOS: 3,
    IDIOMAS: 4
  }