export const CURRICULUM_URL = "/curriculum";
export const DASHBOARD_URL = "/candidate_dashboard"
export const ETT_URL = "/ett";

//Busqueda por provincias
export const EXTRANJERO = "-1";
export const TODA_ESPANHA = "0";

//Country
export const SPAIN = 724;
export const SPAIN_TEXT = "España";

//Experience level
export const INTERN = "Becario/a - Prácticas";
export const EMPLOYEE = "Empleado/a";
export const INTERMEDIATE = "Mando intermedio";
export const SPECIALIST = "Especialista";

//Job description size
export const MAX_CHAR_DESCRIPTION = 4000;

//Comments languagges
export const MAX_CHAR_COMMENTS = 200;

//Id Application
export const ID_APLICACION_WC = 2;
export const ID_APLICACION_GESTOR = 3;

//Id office
export const ID_CENTRAL_OFFICE = 1;

//Id documento
export const ID_CARTA_MOTIVACION = 27;