import React from "react";
import { useTranslation } from "react-i18next";

function TimeClock(props) {
  const { t } = useTranslation();
  const { title = t("icons_tooltips.schedule_type")} = props;

  return(
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <title>{title}</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Recursos" transform="translate(-408.000000, -1787.000000)" fill="#000000" fillRule="nonzero">
            <g id="time-clock" transform="translate(408.000000, 1787.000000)">
                <path d="M8,0 C3.6,0 0,3.6 0,8 C0,12.4 3.6,16 8,16 C12.4,16 16,12.4 16,8 C16,3.6 12.4,0 8,0 Z M8,14 C4.7,14 2,11.3 2,8 C2,4.7 4.7,2 8,2 C11.3,2 14,4.7 14,8 C14,11.3 11.3,14 8,14 Z" id="Shape"></path>
                <polygon id="Path" points="9 4 7 4 7 9 12 9 12 7 9 7"></polygon>
            </g>
        </g>
    </g>
  </svg>
  );
}

export default TimeClock;