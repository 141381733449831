import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../components/basicLayout";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import TuCurriculumHeader from "../components/tuCurriculumHeader";
import { useTranslation } from "react-i18next";
import OtherData from "../assets/icons/otherData";
import {
  optionEquality,
  returnLangId,
  optionEqualityString,
  getPermisosConduccion,
  getTiposCambioResidencia,
  getTiposDesplazamientos,
  getTiposSalario,
  getSalaryRange,
  getContractTypes,
  handleGetMaestro,
} from "../utils/functions";
import i18next from "../i18n.js";
import { withSnackbar } from "notistack";
import AntSwitch from "../components/antSwitch";
import { FormControl, Typography } from "@material-ui/core";
import Autocomplete from "../components/autocompleteWithLabelAnimation";
import ButtonFull from "../components/buttonFull";
import ButtonOutlined from "../components/buttonOutlined";
import { actualizarOtrosDatos, getOtrosDatos } from "../api/candidato";
import HeartIcon from "../assets/icons/heartIcon";
import DeleteChip from "../assets/icons/delete_chip";
import { getPlace } from "../api/maestro";
import InputDate from "../components/datePickerWithLabel";
import InputWithLabel from "../components/inputWithLabelAnimation";
import useMaestros from "../Hooks/useMaestros";

const permisos_de_conduccion = getPermisosConduccion(i18next.languages[0]);
const rangos_salarios = getSalaryRange(i18next.languages[0]);
const tipos_salario = getTiposSalario(i18next.languages[0]);
const tipos_cambio_residencia = getTiposCambioResidencia(i18next.languages[0]);
const tipos_desplazamientos = getTiposDesplazamientos(i18next.languages[0]);
const tipos_Contratos = getContractTypes(i18next.languages[0]);

const useStyles = makeStyles((theme) => ({
  principal_container: {
    gridArea: "body_container",
    display: "grid",
    gridTemplateColumns: "30% 65%",
    columnGap: "20px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "auto",
    },
  },
  col2: {
    marginTop: "100px",
    padding: 20,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "60px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },
  data_sections_container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "50px",
    [theme.breakpoints.down("sm")]: {
      rowGap: "30px",
    },
  },
  secondRow: {
    width: "100%",
    marginTop: "10px"
  },
  data_section_icon_and_title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  label: {
    fontSize: "14px"
  },
  data_section_title_principal: {
    fontSize: 22,
    fontWeight: 900,
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  text_switch_grid_style: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  text_switch_style: {
    fontSize: 14,
  },
  text_section_title_style: {
    fontSize: 16,
    fontWeight: 900,
    gridColumnStart: 1,
    gridColumnEnd: 3,
    [theme.breakpoints.down("sm")]: {
      gridColumnStart: "unset",
      gridColumnEnd: "unset",
    },
  },
  general_grid: {
    display: "grid",
    gridTemplateColumns: ".5fr .5fr",
    columnGap: "24px",
    rowGap: "30px",
    alignItems: "start",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      rowGap: "30px",
    },
  },
  preferences_header_grid: {
    overflow: "hidden",
  },
  preferences_grid: {
    display: "grid",
    gridTemplateColumns: ".5fr .5fr",
    columnGap: "24px",
    alignItems: "end",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      rowGap: "30px",
    },
    marginTop: 30,
  },
  preferences_grid_2: {
    display: "grid",
    gridTemplateColumns: "2.05fr 1fr",
    columnGap: "24px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      marginTop: 30,
    },
    marginTop: 24,
  },
  action_buttons_grid: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: ".25fr .5fr .25fr",
    gridTemplateAreas: `'cancel . save'`,
    columnGap: "24px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      rowGap: "20px",
      gridTemplateAreas: `
            'save'
            'cancel'`,
    },
  },
  customChip: {
    fontSize: "0.8125rem",
    display: "inline-flex",
    height: "32px",
    boxSizing: "border-box",
    padding: 0,
    alignItems: "center",
    borderRadius: "16px",
    margin: "10px 5px 0px 5px",
    backgroundColor: "#EFEFEF",
    maxWidth: "100%",
  },
  nameChip: {
    paddingLeft: 12,
    paddingRight: 12,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  iconGeneral: {
    margin: "0 5px 0 -3px",
    width: 22,
    display: "flex",
    cursor: "pointer",
  },
}));

function OtrosDatos(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const maestros = useMaestros();

  const { state } = props;

  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [nombre, setNombre] = useState("");
  const [primerApellido, setPrimerApellido] = useState("");
  const [segundoApellido, setSegundoApellido] = useState("");
  const [correoElectronico, setCorreoElectronico] = useState("");
  const [prefijo, setPrefijo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [daysNeeded, setDaysNeeded] = useState(null);
  const [idFotografia, setIdFotografia] = useState(null);
  const [fechaModificacion, setFechaModificacion] = useState(null);

  const [otrosDatos, setOtrosDatos] = useState(null);
  const [permiteFirmaDigital, setPermiteFirmaDigital] = useState(false);
  const [permiteGeolocalizacion, setPermiteGeolocalizacion] = useState(false);
  const [trabajaActualmente, setTrabajaActualmente] = useState(false);
  const [cobraPrestacionParo, setCobraPrestacionParo] = useState(false);
  const [workPermit, setWorkPermit] = useState(false);
  const [criminalRecord, setCriminalRecord] = useState(false);
  const [workPermitDate, setWorkPermitDate] = useState(null);
  const [tiposCarnetConducir, setTiposCarnetConducir] = useState([]);
  const [tieneVehiculoPropio, setTieneVehiculoPropio] = useState(false);
  const [aceptaDiasAislados, setAceptaDiasAislados] = useState(false);
  const [tipoDisponibilidadViajar, setTipoDisponibilidadViajar] =
    useState(null);
  const [
    tipoDisponibilidadCambioResidencia,
    setTipoDisponibilidadCambioResidencia,
  ] = useState(null);
  const [salarioMinimo, setSalarioMinimo] = useState(null);
  const [salarioDeseado, setSalarioDeseado] = useState(null);
  const [tipoSalario, setTipoSalario] = useState(null);
  const [tiposHorario, setTiposHorario] = useState([]);
  const [employments, setEmployments] = useState([]);
  const [employmentsOptions, setEmploymentsOptions] = useState(null);
  const [tiposContrato, setTiposContrato] = useState([]);
  const [tipoHorarioOptions, setTipoHorarioOptions] = useState([]);

  useEffect(() => {
    handleGetMaestro(tipoHorarioOptions,setTipoHorarioOptions,maestros.getTipoHorario());
  }, [tipoHorarioOptions]);

  useEffect(() => {
    if (state && state.personalData) {
      handlePersonalDataAction(state.personalData);
    }
  }, [state.personalData]);

  useEffect(() => {
    if (!otrosDatos) {
      handleGetPlaces(returnLangId(i18next.languages[0]));
    } else {
      handleSetOtrosDatos();
    }
  }, [otrosDatos]);

  async function handleGetOtrosDatos() {
    setLoading(true);
    const response = await getOtrosDatos(returnLangId(i18next.languages[0]));
    if (response.status === 200) {
      const result = await response.json();
      setLoading(false);
      setOtrosDatos(result);
    } else {
      setLoading(false);
      props.enqueueSnackbar(t("other_data.error_getting_data"), {
        variant: "error",
      });
      setOtrosDatos(null);
    }
  }

  function handleSetOtrosDatos() {
    setPermiteFirmaDigital(otrosDatos.permiteFirmaDigital);
    setPermiteGeolocalizacion(otrosDatos.permiteGeolocalizacion);
    setTrabajaActualmente(otrosDatos.trabajaActualmente ?? false);
    setCobraPrestacionParo(otrosDatos.cobraPrestacionParo ?? false);
    setCriminalRecord(otrosDatos.tieneCertificadoPenales ?? false);
    setWorkPermit(otrosDatos.tienePermisoTrabajo ?? false);
    setWorkPermitDate(otrosDatos.fechaFinPermisoTrabajo ?? null);
    setTieneVehiculoPropio(otrosDatos.tieneVehiculoPropio ?? false);
    setAceptaDiasAislados(otrosDatos.aceptaDiasAislados ?? false);
    setDaysNeeded(otrosDatos.diasIncorporacion ?? null);
    setTipoDisponibilidadViajar(
      otrosDatos.tipoDisponibilidadViajar
        ? tipos_desplazamientos.find(
            (desplazamiento) =>
              Number(desplazamiento.id) === otrosDatos.tipoDisponibilidadViajar
          )
        : null
    );
    setTipoDisponibilidadCambioResidencia(
      otrosDatos.tipoDisponibilidadCambioResidencia
        ? tipos_cambio_residencia.find(
            (cambio) =>
              Number(cambio.id) ===
              otrosDatos.tipoDisponibilidadCambioResidencia
          )
        : null
    );
    setSalarioMinimo(
      otrosDatos.salarioMinimo
        ? rangos_salarios.find(
            (rango) => Number(rango.id) === otrosDatos.salarioMinimo
          )
        : null
    );
    setSalarioDeseado(
      otrosDatos.salarioDeseado
        ? rangos_salarios.find(
            (rango) => Number(rango.id) === otrosDatos.salarioDeseado
          )
        : null
    );
    setTipoSalario(
      otrosDatos.tipoSalario
        ? tipos_salario.find(
            (tipo) => Number(tipo.id) === otrosDatos.tipoSalario
          )
        : null
    );
    setTiposCarnetConducir(
      otrosDatos.tiposCarnetConducir
        ? permisos_de_conduccion.filter((permiso) =>
            otrosDatos.tiposCarnetConducir.includes(Number(permiso.id))
          )
        : []
    );
    setTiposHorario(
      otrosDatos.tiposHorario
        ? tipoHorarioOptions.filter((jornada) =>
            otrosDatos.tiposHorario.includes(Number(jornada.id))
          )
        : []
    );
    setTiposContrato(
      otrosDatos.tiposContrato
        ? tipos_Contratos.filter((contract) =>
            otrosDatos.tiposContrato.includes(Number(contract.id))
          )
        : []
    );
    if (otrosDatos.puestosDeseados && employmentsOptions) {
      const data = [];
      otrosDatos.puestosDeseados.map((p) => {
        const employ = employmentsOptions.find((e) => e.id === p.idPuesto);
        data.push({
          idPuesto: p.idPuesto,
          nombre: employ.nombre,
          esPrincipal: p.esPrincipal,
        });
      });
      setEmployments(data);
    }
  }

  function validateSalary() {
    return ((salarioMinimo || salarioDeseado) && tipoSalario) || (!salarioMinimo && !salarioDeseado && !tipoSalario);
  }

  async function save() {
    if (employments && employments.length > 0 && validateSalary()) {
      await handleSaveOtrosDatos();
    } else if (!employments || employments.length === 0) {
      props.enqueueSnackbar(t("other_data.error_employments"), {
        variant: "error",
      });
    } else if (!validateSalary()) {
      props.enqueueSnackbar(t("other_data.error_salary"), {
        variant: "error",
      });
    } else {
      props.enqueueSnackbar(t("other_data.error_expired_expiration_date"), {
        variant: "error",
      });
    }
  }

  async function handleSaveOtrosDatos() {
    if ((await updateOtrosDatos()) === true) {
      props.history.push("/curriculum");
    }
  }

  async function updateOtrosDatos() {
    setLoading(true);
    var data = {
      permiteFirmaDigital: permiteFirmaDigital,
      permiteGeolocalizacion: permiteGeolocalizacion,
      trabajaActualmente: trabajaActualmente,
      cobraPrestacionParo: cobraPrestacionParo,
      tieneCertificadoPenales: criminalRecord,
      tienePermisoTrabajo: workPermit,
      fechaFinPermisoTrabajo: workPermitDate,
      tieneVehiculoPropio: tieneVehiculoPropio,
      aceptaDiasAislados: aceptaDiasAislados,
      diasIncorporacion: daysNeeded !== null ? Number(daysNeeded) : null,
      tipoDisponibilidadViajar: tipoDisponibilidadViajar
        ? Number(tipoDisponibilidadViajar.id)
        : null,
      tipoDisponibilidadCambioResidencia: tipoDisponibilidadCambioResidencia
        ? Number(tipoDisponibilidadCambioResidencia.id)
        : null,
      salarioMinimo: salarioMinimo ? Number(salarioMinimo.id) : null,
      salarioDeseado: salarioDeseado ? Number(salarioDeseado.id) : null,
      tipoSalario: tipoSalario ? Number(tipoSalario.id) : null,
      tiposCarnetConducir:
        tiposCarnetConducir.length > 0
          ? tiposCarnetConducir.map((carnet) => Number(carnet.id))
          : null,
      tiposHorario:
        tiposHorario.length > 0
          ? tiposHorario.map((jornada) => Number(jornada.id))
          : null,
      puestosDeseados: employments ? employments : null,
      tiposContrato:
        tiposContrato.length > 0
          ? tiposContrato.map((contract) => Number(contract.id))
          : null,
    };
    const response = await actualizarOtrosDatos(
      data,
      returnLangId(i18next.languages[0])
    );
    if (response.status !== 200) {
      var result = await response.json();
      props.enqueueSnackbar(
        result.errorMessage
          ? result.errorMessage
          : t("other_data.error_saving_data"),
        {
          variant: "error",
        }
      );
    }
    setLoading(false);
    return response.status === 200;
  }

  async function handleGetPlaces(languageId) {
    setLoading(true);
    const response = await getPlace(languageId);
    if (response.status === 200) {
      const result = await response.json();
      setEmploymentsOptions(result.puestos);
      handleGetOtrosDatos();
    } else {
      switch (response.status) {
        case 404:
          props.enqueueSnackbar(t("work_experience.get_places_not_found"), {
            variant: "error",
          });
          break;
        default:
          props.enqueueSnackbar(t("work_experience.get_place_error"), {
            variant: "error",
          });
          break;
      }
    }
  }

  function handleDeleteEmployment(data) {
    if (!data.esPrincipal) {
      setEmployments(
        employments.filter((element) => element.idPuesto !== data.idPuesto)
      );
    } else if (employments.length > 1) {
      let newsEmployments = employments.filter(
        (element) => element.idPuesto !== data.idPuesto
      );
      newsEmployments[0].esPrincipal = true;
      setEmployments(newsEmployments);
    } else {
      setEmployments([]);
    }
  }

  function handleDefaultEmployment(data, bool) {
    let values = employments.slice();
    const flag = employments.find((element) => element.esPrincipal === true);
    if (flag && flag.length !== 0) {
      for (let i = 0; i < values.length; i++) {
        if (employments[i].idPuesto === data.idPuesto) {
          values[i].esPrincipal = bool;
        }
        if (employments[i].idPuesto === flag.idPuesto) {
          values[i].esPrincipal = false;
        }
      }
    } else {
      for (let i = 0; i < values.length; i++) {
        if (employments[i].idPuesto === data.idPuesto) {
          values[i].esPrincipal = bool;
        }
      }
    }
    setEmployments([...values]);
  }

  function handleSetEmployments(value) {
    const someDefatult = employments.find(
      (element) => element.esPrincipal === true
    );
    if (
      value &&
      !employments.find(
        (element) => parseInt(element.idPuesto) === parseInt(value.id)
      )
    ) {
      if (!someDefatult) {
        setEmployments([
          ...employments,
          { idPuesto: value.id, nombre: value.nombre, esPrincipal: true },
        ]);
      } else {
        setEmployments([
          ...employments,
          { idPuesto: value.id, nombre: value.nombre, esPrincipal: false },
        ]);
      }
    }
  }

  function handlePersonalDataAction(data) {
    setNombre(data.nombre);
    setPrimerApellido(data.primerApellido);
    setSegundoApellido(data.segundoApellido);
    setCorreoElectronico(data.correoElectronico);
    setPrefijo(data.prefijo);
    setTelefono(data.telefono);
    setIdFotografia(data.idFotografia);
    setFechaModificacion(data.fechaModificacion);
  }

  function renderPermissionData() {
    return (
      <div className={classes.general_grid}>
        <div className={classes.text_switch_grid_style}>
          <div className={classes.text_switch_style}>
            {t("other_data.digital_signature")}
          </div>
          <AntSwitch
            checked={permiteFirmaDigital}
            setter={() => setPermiteFirmaDigital(!permiteFirmaDigital)}
          />
        </div>
        <div className={classes.text_switch_grid_style}>
          <div className={classes.text_switch_style}>
            {t("other_data.geolocalization")}
          </div>
          <AntSwitch
            checked={permiteGeolocalizacion}
            setter={() => setPermiteGeolocalizacion(!permiteGeolocalizacion)}
          />
        </div>
      </div>
    );
  }

  function renderCurrentSituation() {
    return (
      <div className={classes.general_grid}>
        <div className={classes.text_section_title_style}>
          {t("other_data.current_status")}
        </div>
        <div className={classes.text_switch_grid_style}>
          <div className={classes.text_switch_style}>
            {t("other_data.currently_working")}
          </div>
          <AntSwitch
            checked={trabajaActualmente}
            setter={() => setTrabajaActualmente(!trabajaActualmente)}
          />
        </div>
        <div className={classes.text_switch_grid_style}>
          <div className={classes.text_switch_style}>
            {t("other_data.paid_unemployment")}
          </div>
          <AntSwitch
            checked={cobraPrestacionParo}
            setter={() => setCobraPrestacionParo(!cobraPrestacionParo)}
          />
        </div>
        <div className={classes.double_row}>
          <div className={classes.text_switch_grid_style}>
            <div className={classes.text_switch_style}>
              {t("other_data.work_permit_simple")}
            </div>
            <AntSwitch
              checked={workPermit}
              setter={() => {
                setWorkPermit(!workPermit);
                setWorkPermitDate(null);
              }}
            />
          </div>
          {workPermit && <>
            <div className={classes.secondRow}>
                              <Typography className={classes.label}>
                                          {t("other_data.date_valid")}
                                          </Typography>
                                          <InputDate
                                            value={workPermitDate}
                                            setter={setWorkPermitDate}
                                            canHideIcon={false}
                                          />
                            </div>
          </>}
        </div>
        <div className={classes.text_switch_grid_style}>
          <div className={classes.text_switch_style}>
            {t("other_data.criminal_record")}
          </div>
          <AntSwitch
            checked={criminalRecord}
            setter={() => setCriminalRecord(!criminalRecord)}
          />
        </div>
      </div>
    );
  }

  function renderDrivingLicenses() {
    return (
      <div className={classes.general_grid}>
        <div className={classes.text_section_title_style}>
          {t("other_data.driving_license")}
        </div>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            value={tiposCarnetConducir}
            setter={setTiposCarnetConducir}
            options={permisos_de_conduccion}
            label={
              tiposCarnetConducir && tiposCarnetConducir.length > 0
                ? null
                : t("other_data.type")
            }
            multiple={true}
            freeSolo={false}
            getOption={(x, v) => optionEqualityString(x, v)}
            getLabel={(x) => (typeof x === "object" && x !== null ? x.name : x)}
            useDefaultIcon={true}
            moveLabel={true}
          />
        </FormControl>
        <div className={classes.text_switch_grid_style} style={{alignSelf: "flex-end"}}>
          <div className={classes.text_switch_style}>
            {t("other_data.own_vehicle")}
          </div>
          <AntSwitch
            checked={tieneVehiculoPropio}
            setter={() => setTieneVehiculoPropio(!tieneVehiculoPropio)}
          />
        </div>
      </div>
    );
  }

  function renderAvailability() {
    return (
      <div className={classes.general_grid}>
        <div className={classes.text_section_title_style}>
          {t("other_data.availability")}
        </div>
        <div className={classes.text_switch_grid_style}>
          <div className={classes.text_switch_style}>
            {t("other_data.work_isolated_days")}
          </div>
          <AntSwitch
            checked={aceptaDiasAislados}
            setter={() => setAceptaDiasAislados(!aceptaDiasAislados)}
          />
        </div>
        {!matchesMobile && <div />}
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            value={tipoDisponibilidadCambioResidencia}
            setter={setTipoDisponibilidadCambioResidencia}
            options={tipos_cambio_residencia}
            label={t("other_data.residence_change_available")}
            multiple={false}
            freeSolo={false}
            getOption={(x, v) => optionEquality(x, v)}
            getLabel={(x) =>
              typeof x === "object" && x !== null
                ? x.name
                : x
                ? tipos_cambio_residencia.find(
                    (s) => Number(s.id) === Number(x)
                  )?.name ?? ""
                : ""
            }
            useDefaultIcon={true}
            moveLabel={true}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            value={tipoDisponibilidadViajar}
            setter={setTipoDisponibilidadViajar}
            options={tipos_desplazamientos}
            label={t("other_data.travel_available")}
            multiple={false}
            freeSolo={false}
            getOption={(x, v) => optionEquality(x, v)}
            getLabel={(x) =>
              typeof x === "object" && x !== null
                ? x.name
                : x
                ? tipos_desplazamientos.find((s) => Number(s.id) === Number(x))
                    ?.name ?? ""
                : ""
            }
            useDefaultIcon={true}
            moveLabel={true}
          />
        </FormControl>
        <InputWithLabel
                fullWidth
                customClass={classes.input}
                label={t("other_data.days_needed")}
                value={daysNeeded}
                setter={(e) => setDaysNeeded(e.target.value)}
                moveLabel={true}
                maxLength={3}
                type={"number"}
              />
      </div>
    );
  }

  function handleSetSalarioMinimo(salario) {
    setSalarioDeseado(null);
    setSalarioMinimo(salario);
  }

  function renderWorkPreferences() {
    return (
      <div className={classes.preferences_header_grid}>
        <div className={classes.text_section_title_style}>
          {t("other_data.preferences")}
        </div>
        <div className={classes.preferences_grid}>
        <FormControl variant="outlined" fullWidth>
            <Autocomplete
              value={tipoSalario}
              setter={setTipoSalario}
              options={tipos_salario}
              label={t("other_data.wage_type")}
              multiple={false}
              freeSolo={false}
              getOption={(x, v) => optionEquality(x, v)}
              getLabel={(x) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? tipos_salario.find((s) => Number(s.id) === Number(x))
                      ?.name ?? ""
                  : ""
              }
              useDefaultIcon={true}
              moveLabel={true}
              fullWidth={true}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              value={salarioMinimo}
              setter={handleSetSalarioMinimo}
              options={rangos_salarios}
              label={t("other_data.minimum_wage")}
              multiple={false}
              freeSolo={false}
              getOption={(x, v) => optionEquality(x, v)}
              getLabel={(x) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? rangos_salarios.find((s) => Number(s.id) === Number(x))
                      ?.name ?? ""
                  : ""
              }
              useDefaultIcon={true}
              moveLabel={true}
              escapeBiggerThan={true}
              fullWidth={true}
            />
          </FormControl>
          
          </div>
          <div className={classes.preferences_grid}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              value={salarioDeseado}
              setter={setSalarioDeseado}
              options={rangos_salarios.filter((x) =>
                salarioMinimo
                  ? Number(x.order) >= Number(salarioMinimo.order)
                  : true
              )}
              label={t("other_data.desired_wage")}
              multiple={false}
              freeSolo={false}
              getOption={(x, v) => optionEquality(x, v)}
              getLabel={(x) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? rangos_salarios.find((s) => Number(s.id) === Number(x))
                      ?.name ?? ""
                  : ""
              }
              useDefaultIcon={true}
              moveLabel={true}
              escapeBiggerThan={true}
              fullWidth={true}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              value={tiposHorario}
              setter={setTiposHorario}
              options={tipoHorarioOptions.sort(
                (a, b) => parseInt(a.orden) - parseInt(b.orden)
              )}
              label={t("other_data.workday_type")}
              multiple={true}
              freeSolo={false}
              getOption={(x, v) => optionEqualityString(x, v)}
              getLabel={(x) =>
                typeof x === "object" && x !== null ? x.name : x
              }
              useDefaultIcon={true}
              moveLabel={true}
            />
          </FormControl>
          </div>
          <div className={classes.preferences_grid}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              value={tiposContrato}
              setter={setTiposContrato}
              options={tipos_Contratos.sort(
                (a, b) => parseInt(a.order) - parseInt(b.order)
              )}
              label={t("other_data.type_contract")}
              multiple={true}
              freeSolo={false}
              getOption={(x, v) => optionEqualityString(x, v)}
              getLabel={(x) =>
                typeof x === "object" && x !== null ? x.name : x
              }
              useDefaultIcon={true}
              moveLabel={true}
            />
          </FormControl>
        </div>
        <div className={classes.preferences_grid_2}>
          <Autocomplete
            options={
              employmentsOptions
                ? employmentsOptions.filter(
                    (o) => !employments.find((e) => e.idPuesto === o.id)
                  )
                : []
            }
            value={employments}
            label={t("basicData.employments")}
            setter={handleSetEmployments}
            moveLabel={true}
            maxLength={100}
            getLabel={(option) => option.nombre}
            getOption={(x, v) => optionEquality(x, v)}
            disableClearable={true}
            customClassWrapper={classes.auto}
            disabled={!employmentsOptions || employments?.length === 3}
            wrong={!employments || employments.length < 1}
          />
        </div>
        <div className={classes.chip}>
          {employments.map((data) => {
            return (
              <div
                className={classes.customChip}
                key={`${data.nombre}, ${data.id}`}
              >
                <div className={classes.nameChip}>{data.nombre}</div>
                <div className={classes.iconGeneral}>
                  {data.esPrincipal ? (
                    <HeartIcon title={t("icons_tooltips.favorite")} fill />
                  ) : (
                    <HeartIcon
                      title={t("icons_tooltips.favorite_do")}
                      fill={false}
                      onClick={() => handleDefaultEmployment(data, true)}
                    />
                  )}
                </div>
                <div className={classes.iconGeneral}>
                  <DeleteChip
                    title={t("icons_tooltips.delete")}
                    onClick={() => handleDeleteEmployment(data)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function renderActionButtons() {
    return (
      <div className={classes.action_buttons_grid}>
        <div style={{ gridArea: "cancel", width: "100%" }}>
          <ButtonOutlined
            size="100%"
            text={t("common.cancel")}
            onClick={() => {
              props.history.goBack();
            }}
          />
        </div>
        <div style={{ gridArea: "save", width: "100%" }}>
          <ButtonFull
            size="100%"
            text={t("common.save")}
            onClick={() => {
              save();
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <Layout history={props.history} loading={loading}>
      <div className={classes.principal_container}>
        {!matchesMobile ? (
          <TuCurriculumHeader
            nombre={nombre}
            primerApellido={primerApellido}
            segundoApellido={segundoApellido}
            correoElectronico={correoElectronico}
            prefijo={prefijo}
            telefono={telefono}
            fechaModificacion={fechaModificacion}
          />
        ) : (
          <div />
        )}
        <div className={classes.col2}>
          <div className={classes.data_sections_container}>
            <div className={classes.data_section_icon_and_title}>
              <OtherData />
              <div className={classes.data_section_title_principal}>
                {t("other_data.title")}
              </div>
            </div>
            {renderPermissionData()}
            {renderCurrentSituation()}
            {renderDrivingLicenses()}
            {renderAvailability()}
            {renderWorkPreferences()}
            {renderActionButtons()}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default withSnackbar(OtrosDatos);
