import React from "react";
import { useTranslation } from "react-i18next";

function Location(props) {
  const { t } = useTranslation();
  const { title = t("icons_tooltips.location")} = props;

  return(
    <svg width="14px" height="17px" viewBox="0 0 14 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Recursos" transform="translate(-336.000000, -1787.000000)" fill="#000000" fillRule="nonzero">
                <g id="pin-3" transform="translate(336.000000, 1787.000000)">
                    <path d="M7,0 C3.1,0 0,3.1 0,7 C0,8.9 0.7,10.7 2.1,12 C2.2,12.1 6.2,15.7 6.3,15.8 C6.7,16.1 7.3,16.1 7.6,15.8 C7.7,15.7 11.8,12.1 11.8,12 C13.2,10.7 13.9,8.9 13.9,7 C14,3.1 10.9,0 7,0 Z M7,9 C5.9,9 5,8.1 5,7 C5,5.9 5.9,5 7,5 C8.1,5 9,5.9 9,7 C9,8.1 8.1,9 7,9 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </svg>
  );
}

export default Location;