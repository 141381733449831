import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  buttonFull: (props) => ({
    width: props.size,
    minHeight: 36,
    maxHeight: 36,
    borderRadius: 4,
  }),
  buttonFull_label: {
    textTransform: "none",
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "16px",
    [theme.breakpoints.up("md")]: {
      fontWeight: "18px",
    },
  },
}));

function ButtonFull(props) {
  const { text, disabled = false, onClick, customClass = "" } = props;
  const classes = useStyles(props);
  return (
    <Button
      classes={{
        root: classes.buttonFull + " " + customClass,
        label: classes.buttonFull_label,
      }}
      disabled={disabled}
      variant="contained"
      color="primary"
      disableElevation={true}
      onClick={onClick}
    >
      {text}
    </Button>
  );
}

export default ButtonFull;
