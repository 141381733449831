import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  svg_syle: {
    cursor: "pointer",
  },
}));

function Eye(props) {
  const { t } = useTranslation();
  const { title = t("icons_tooltips.eye"), color = "#000000", onClick = () => null } = props;
  const classes = useStyles();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="16px"
      height="12px"
      viewBox="0 0 16 12"
      version="1.1"
      className={classes.svg_syle}
      onClick={onClick}
    >
      <title>{title}</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Recursos"
          transform="translate(-261.000000, -1789.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g id="Group" transform="translate(261.000000, 1789.000000)">
            <path
              d="M8.00012749,12 C12.7071275,12 15.7441275,6.716 15.8711275,6.492 C16.0421275,6.188 16.0431275,5.816 15.8721275,5.512 C15.7461275,5.287 12.7311275,0 8.00012749,0 C3.24512749,0 0.251127489,5.289 0.126127489,5.514 C-0.0428725111,5.817 -0.0418725111,6.186 0.128127489,6.489 C0.254127489,6.713 3.26912749,12 8.00012749,12 Z M8.00012749,2 C10.8391275,2 13.0361275,4.835 13.8181275,6 C13.0341275,7.166 10.8371275,10 8.00012749,10 C5.15912749,10 2.96212749,7.162 2.18112749,5.999 C2.95812749,4.835 5.14612749,2 8.00012749,2 Z"
              id="Shape"
            />
            <circle id="Oval" cx="8.00012749" cy="6" r="2" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Eye;
