import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Facebook from "../assets/icons/footer_facebook";
import Instagram from "../assets/icons/footer_instagram";
import Linkedin from "../assets/icons/footer_linkedin";
import Youtube from "../assets/icons/footer_youtube";

const useStyles = makeStyles((theme) => ({
  footer_container: {
    display: "grid",
    width: "100%",
    gridTemplateRows: "1fr .25fr",
    backgroundColor: "black",
    gridArea: "footer",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      gridTemplateColumns: "1fr",
    },
  },
  footer_init: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1fr 1fr 1fr",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr 1fr 1fr",
      padding: "20px 0 0 0",
    },
    padding: "20px 0 10px 0",
  },
  footer_final: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1fr 1fr",
    padding: "9px 40px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr 1fr",
      display: "block",
      padding: "0 20px",
    },
  },
  part1: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  part2: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  footer_text: {
    fontSize: 12,
    lineHeight: "18px",
    color: "white",
    padding: 0,
    "& + &": {
      "&:before": {
        display: "inline-block",
        content: "''",
        height: 10,
        width: 1,
        backgroundColor: "white",
        margin: "0 5px",
      },
    },
  },
  text: {
    fontSize: 12,
    lineHeight: "18px",
    color: "white",
  },
  logo: {
    display: "grid",
    justifyContent: "end",
    alignItems: "center",
    marginRight: "5%",
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "initial",
      margin: "0 0px 0px 20px",
    },
  },
  contact: {
    padding: "0 10%",
    display: "grid",
    alignContent: "end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "initial",
      margin: "0 0px 0px 20px",
      padding: 0,
    },
  },
  socialMedia: {
    display: "grid",
    width: "40%",
    marginLeft: "20%",
    gridTemplateColumns: "0.25fr 0.25fr 0.25fr 0.25fr auto",
    padding: "20px 0 10px 0",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      display: "flex",
      margin: "0 0px 0px 20px",
      width: "50%",
    },
  },
  socialIcon: {
    cursor: "pointer",
  },
}));

function Footer(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.footer_container}>
      <div className={classes.footer_init}>
        <div className={classes.logo}>
          <img alt="Nortempo" src="/images/nortempo_logo_white.svg" />
        </div>
        <div className={classes.contact}>
          <Typography variant="body1" className={classes.text}>
            {t("footer.number")}
          </Typography>
          <Typography variant="body1" className={classes.text}>
            {t("footer.address")}
          </Typography>
        </div>
        <div className={classes.socialMedia}>
          <Linkedin
            title={t(`icons_tooltips.linkedin`)}
            className={classes.socialIcon}
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/nortempo/",
                "_blank"
              )
            }
          />
          <Facebook
            title={t(`icons_tooltips.facebook`)}
            className={classes.socialIcon}
            onClick={() =>
              window.open("https://www.facebook.com/nortempo", "_blank")
            }
          />
          <Instagram
            title={t(`icons_tooltips.instagram`)}
            className={classes.socialIcon}
            onClick={() =>
              window.open("https://www.instagram.com/nortempo/", "_blank")
            }
          />
          <Youtube
            title={t(`icons_tooltips.youtube`)}
            className={classes.socialIcon}
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UCwEQv4DPLoj159TL_u1hr0w",
                "_blank"
              )
            }
          />
        </div>
      </div>
      <div className={classes.footer_final}>
        <div className={classes.part1}>
          <Typography
            variant="body1"
            className={`${classes.footer_text} ${classes.socialIcon}`}
            onClick={() =>
              window.open("https://nortempo.com/aviso-legal/", "_blank")
            }
          >
            {t("footer.footer_text_1")}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.footer_text} ${classes.socialIcon}`}
            onClick={() =>
              window.open(
                "https://nortempo.com/politica-de-privacidad/",
                "_blank"
              )
            }
          >
            {t("footer.footer_text_2")}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.footer_text} ${classes.socialIcon}`}
            onClick={() =>
              window.open("https://nortempo.com/condiciones-de-uso/", "_blank")
            }
          >
            {t("footer.footer_text_3")}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.footer_text} ${classes.socialIcon}`}
            onClick={() =>
              window.open("https://nortempo.com/derechos-y-deberes-personas-evaluadas/", "_blank")
            }
          >
            {t("footer.footer_text_5")}
          </Typography>
        </div>
        <div className={classes.part2}>
          <Typography variant="body1" className={classes.footer_text}>
            {t("footer.footer_text_4")}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default Footer;
