import React from "react";

function SearchIcon() {
    return(
        <svg width="59px" height="59px" viewBox="0 0 59 59" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                <g id="Recursos" transform="translate(-433.000000, -1482.000000)" stroke="#DA291C" strokeWidth="4">
                    <g id="zoom" transform="translate(435.000000, 1484.000000)">
                        <line x1="54" y1="54" x2="37.556" y2="37.556" id="Path"></line>
                        <circle id="Oval" cx="22" cy="22" r="22"></circle>
                    </g>
                </g>
            </g>
        </svg>
  );
}

export default SearchIcon;