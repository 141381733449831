import React from "react";
import { useTranslation } from "react-i18next";

function Folder(props) {
  const { t } = useTranslation();
  const { title = t("icons_tooltips.documents_space") } = props;
  return (
    <svg
      width="64px"
      height="58px"
      viewBox="0 0 64 58"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      >
        <g
          id="Recursos"
          transform="translate(-643.000000, -1482.000000)"
          stroke="#DA291C"
          strokeWidth="4"
        >
          <g id="folder-user" transform="translate(645.000000, 1484.000000)">
            <path
              d="M8,54 L6,54 C2.6862915,54 0,51.3137085 0,48 L0,0 L22,0 L28,10 L60,10 L60,48 C60,51.3137085 57.3137085,54 54,54 L52,54"
              id="Path"
            ></path>
            <circle id="Oval" cx="30" cy="28" r="6"></circle>
            <path
              d="M30,40 C22.2680135,40 16,46.2680135 16,54 L44,54 C44,46.2680135 37.7319865,40 30,40 L30,40 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Folder;
