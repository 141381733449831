import React from "react";
import { useTranslation } from "react-i18next";

function CandidaturaMenu(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.candidaturas")} = props;
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
            <title>{title}</title>
            <g id="Portal-de-empleo---móvil" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Recursos" transform="translate(-189.000000, -924.000000)">
                    <g id="icon-menu-tus-candidaturas" transform="translate(189.000000, 924.000000)">
                        <rect id="Rectangle-Copy-12" x="0" y="0" width="20" height="20"/>
                        <g id="Group-11" transform="translate(2.000000, 3.000000)" stroke="#DA291C" strokeLinecap="square">
                            <polyline id="Path" points="5.33333333 2.12765957 5.33333333 -2.71338507e-13 10.6666667 -2.71338507e-13 10.6666667 2.12765957"/>
                            <path d="M6.13333333,10.6382979 L0,10.6382979 L0,3.19148936 C0,2.60398936 0.4776,2.12765957 1.06666667,2.12765957 L14.9333333,2.12765957 C15.5224,2.12765957 16,2.60398936 16,3.19148936 L16,10.6382979 L9.86666667,10.6382979" id="Path"/>
                            <path d="M14.9333333,11.9680851 L14.9333333,13.8297872 C14.9333333,14.4172872 14.4557333,14.893617 13.8666667,14.893617 L2.13333333,14.893617 C1.54426667,14.893617 1.06666667,14.4172872 1.06666667,13.8297872 L1.06666667,11.9680851" id="Path"/>
                            <rect id="Rectangle" x="6.13333333" y="9.30851064" width="3.73333333" height="2.65957447"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
  );
}

export default CandidaturaMenu;