import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
    svg_style: {
        height: 60,
        width: 64,
        [theme.breakpoints.down("sm")]: {            
            height: 30,
            width: 32,
        },
      },
      svg_style_no_shrink: {
        height: 60,
        width: 64,
      },
}));

function CurriculumIcon(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { title = t("icons_tooltips.curriculum"), color = "#000000", noShrink = false} = props;

    return(
        <svg className={noShrink ? classes.svg_style_no_shrink : classes.svg_style} viewBox="0 0 56 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Recursos" transform="translate(-315.000000, -1172.000000)" stroke={color} strokeWidth="4">
                    <g id="file-text" transform="translate(317.000000, 1174.000000)">
                        <polyline id="Path" points="36 0 36 16 52 16"></polyline>
                        <polygon id="Path" points="36 0 0 0 0 60 52 60 52 16"></polygon>
                        <line x1="12" y1="42" x2="40" y2="42" id="Path"></line>
                        <line x1="12" y1="30" x2="40" y2="30" id="Path"></line>
                        <line x1="12" y1="18" x2="24" y2="18" id="Path"></line>
                    </g>
                </g>
            </g>
        </svg>
  );
}

export default CurriculumIcon;