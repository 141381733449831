import React, { useEffect } from "react";
import CalendarIcon from "../assets/icons/calendar";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
import Wrapper from "./inputWrapper";
import { validateDate } from "../utils/functions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const overrideStyles = createMuiTheme({
  palette: {
    primary: { main: "#FC030B" },
    secondary: { main: "#FFFFFF" },
    text: { primary: "#000000" },
  },
  typography: {
    fontFamily: ["Nunito Sans", "sans-serif"].join(","),
  },
  breakpoints: {
    values: {
      md: 980,
    },
  },
  overrides: {
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiFormHelperText: {
      root: {
        display: "none",
      },
    },
    MuiIconButton: {
      root: {
        padding: "6px",
      },
    },
    MuiInputBase: {
      root: {
        paddingLeft: "12px",
        "&:before": {
          display: "none",
        },
        "&:after": {
          display: "none",
        },
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: 0,
      },
    },
  },
});

function DatePickerWithLabel(props) {
  const {
    placeholder = "dd/mm/yyyy",
    value = null,
    setter = () => {
      return true;
    },
    label = "",
    disabled = false,
    minDate = null,
    maxDate = null,
    customClass = "",
    required = false,
    views = ["date"],
    canHideIcon = false,
    border = false,
    wrong = false,
    onBlur,
    onChange = () => {
      return true;
    },
    debug = false,
    yearOnly = false,
  } = props;

  const theme = useTheme();
  const matchesUp = useMediaQuery(theme.breakpoints.up("1370"));
  const matchesDown = useMediaQuery(theme.breakpoints.down("980"));
  const { t } = useTranslation();

  const renderIcon = canHideIcon ? matchesUp || matchesDown : true;

  function handleSetDate(date) {
    let newDate;
    if (date) {
      newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    } else {
      newDate = date;
    }
    setter(newDate);
    onChange(newDate);
  }

  useEffect(() => {
    if (debug) {
      console.log("VALUE: ", value);
    }
  }, [value]);

  function renderDatePicker() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <ThemeProvider theme={overrideStyles}>
          <KeyboardDatePicker
            autoOk={true}
            disabled={disabled}
            placeholder={placeholder}
            variant="dialog"
            value={value}
            format={yearOnly ? "yyyy" : "dd/MM/yyyy"}
            readOnly={false}
            keyboardIcon={
              renderIcon ? (
                <CalendarIcon title={t(`icons_tooltips.select_date`)} />
              ) : null
            }
            {...(minDate ? { minDate: minDate } : {})}
            {...(maxDate ? { maxDate: maxDate } : {})}
            onChange={(date) => {
              handleSetDate(date);
            }}
            onBlur={onBlur}
            views={views}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }

  return (
    <Wrapper
      label={label}
      customClass={customClass}
      disabled={disabled}
      wrong={
        wrong
          ? wrong
          : value && !validateDate(minDate, maxDate, value) && value !== ""
      }
      required={required}
      border={border}
      padding="none"
    >
      {renderDatePicker()}
    </Wrapper>
  );
}

export default DatePickerWithLabel;
