import React from "react";
import { useTranslation } from "react-i18next";

function PerfilDigitalPortfolio(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.portfolio")} = props;

    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Recursos" transform="translate(-286.000000, -1858.000000)">
                    <g id="ico_design_projects" transform="translate(286.000000, 1858.000000)">
                        <polygon id="Path" fill="#DA291C" points="24 1.96220357e-05 -4.08562073e-14 1.96220356e-05 -4.08562073e-14 24.0000196 24 24.0000196"></polygon>
                        <g id="board-51" transform="translate(4.000000, 4.000000)" fill="#FFFFFF" fillRule="nonzero">
                            <path d="M15,10 L15,1 C15,0.4 14.6,0 14,0 L2,0 C1.4,0 1,0.4 1,1 L1,10 L0,10 L0,12 L4,12 L2.2,14.4 C1.9,14.8 2,15.5 2.4,15.8 C2.6,15.9 2.8,16 3,16 C3.3,16 3.6,15.9 3.8,15.6 L6.5,12 L7,12 L7,14 L9,14 L9,12 L9.5,12 L12.2,15.6 C12.4,15.9 12.7,16 13,16 C13.2,16 13.4,15.9 13.6,15.8 C14,15.5 14.1,14.8 13.8,14.4 L12,12 L16,12 L16,10 L15,10 Z M3,2 L13,2 L13,10 L3,10 L3,2 Z" id="Shape"></path>
                            <rect id="Rectangle" x="5" y="4" width="6" height="1"></rect>
                            <rect id="Rectangle" x="5" y="7" width="6" height="1"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default PerfilDigitalPortfolio;