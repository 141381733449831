
import {sendHeader,dataHeader,responseCheck,dataHeaderWithoutAuthorization} from "../utils/functions";

const baseUrl = window._env_.BASE_URL_CANDIDATOS;

sendHeader().then(dato=>dataHeader.Authorization=`Bearer ${dato}`);

export async function searchOffers(puesto, provincia, idioma) {
  return fetch(
    `${baseUrl}/api/oferta/buscar?palabrasClave=${puesto}&provincia=${provincia}&idioma=${idioma}`,
    {
      method: "GET",
      headers: dataHeaderWithoutAuthorization
    }
  ).then((response) => {
    return response;
  });
}

export async function getOffer(offerId, idioma) {
  return fetch(`${baseUrl}/api/oferta/${offerId}?&idioma=${idioma}`, {
    method: "GET",
    headers: dataHeaderWithoutAuthorization
  }).then((response) => {
    return response;
  });
}

export async function getOfferPreview(offerId, idioma) {
  return fetch(`${baseUrl}/api/oferta/${offerId}/preview?&idioma=${idioma}`, {
    method: "GET",
    headers: dataHeaderWithoutAuthorization
  }).then((response) => {
    return response;
  });
}

export async function getKQ(offerId, idioma) {
 
  return fetch(
    `${baseUrl}/api/oferta/${offerId}/killerquestions?&idioma=${idioma}`,
    {
      method: "GET",
      headers: dataHeader
    }
  ).then((response) => {
    return response;
  });
}

export async function enrollOffer(offerId, idioma, data, tryNumber = 0) {

  return fetch(
    `${baseUrl}/api/candidatura/oferta/${offerId}?idioma=${idioma}`,
    {
      method: "POST",
      headers: dataHeader,
      body: JSON.stringify(data),
    }
  ).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => enrollOffer(offerId, idioma, data,tryNumerIncremented))
  );
}

export async function isCandidateEnrollInOffer(
  languageId,
  offerId,
  tryNumber = 0
) {
  
  return fetch(
    `${baseUrl}/api/candidatura/oferta/${offerId}?idioma=${languageId}`,
    {
      method: "GET",
      headers: dataHeader
    }
  ).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => isCandidateEnrollInOffer(languageId, offerId, tryNumerIncremented))
  
  );
}

export async function getCompetences(offerId, idioma) {
  

  return fetch(
    `${baseUrl}/api/oferta/${offerId}/competencias?&idioma=${idioma}`,
    {
      method: "GET",
      headers: dataHeader
    }
  ).then((response) => {
    return response;
  });
}
