import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const a4WidthMm = 210;
const a4HeightMm = 297;

const pxToMm = (px) => {
  return Math.floor(px / document.getElementById("myMm").offsetHeight);
};

const mmToPx = (mm) => {
  return document.getElementById("myMm").offsetHeight * mm;
};

const printToConsole = (msg) => {
  //console.info(msg);
};

/*
 * Devuelve un array con los DIV correspondientes a cada página
 * El conjunto de elementos de cada página se calcula de forma dinámica
 * en función del tamaño en pixels del elemento y del tamaño en mm de la página del PDF (A4)
 */
const createPages = () => {
  const offsetRootReact = document.getElementById("root").offsetHeight;
  const columnToInteract = document.getElementById("right_col_template");
  const pages = [];
  const paddingH = 48;
  const paddingV = 24;

  const a4HeightPx = mmToPx(a4HeightMm) - paddingV * 2; //padding superior e inferior del DIV de la página
  let heightLimit = a4HeightPx;
  printToConsole("Limite inicial " + heightLimit);
  let currentPage = document.createElement("div");
  currentPage.style.padding = `${paddingV}px ${paddingH}px`;
  currentPage.classList.add("a4_page_container");
  let col_right = document.createElement("div");
  col_right.classList.add("a4_right_column");

  let auxHeight = 0;

  if (pages.length === 0) {
    currentPage.appendChild(
      document.getElementById("element_left_col").cloneNode(true)
    );
  }
  currentPage.appendChild(col_right);

  //se recorren los elementos del root para incorporarlos a las páginas
  columnToInteract.childNodes.forEach((node) => {
    printToConsole("Procesando " + node.id);
    printToConsole(
      "Posición: " +
        (node.offsetTop - offsetRootReact) +
        ". px: " +
        node.offsetHeight +
        ". mm: " +
        pxToMm(node.offsetHeight)
    );
    printToConsole("Límite actual: " + heightLimit);

    //para cada nodo se comprueba si entra en la página actual en función de su posición y su tamaño
    if (
      node.offsetTop - offsetRootReact + node.offsetHeight > heightLimit &&
      node.id !== "cv_footer"
    ) {
      printToConsole("El elemento no coge en la página actual");
      pages.push(currentPage);
      document.body.appendChild(currentPage);

      //se crea una nueva página
      currentPage = document.createElement("div");
      currentPage.style.padding = `${paddingV}px ${paddingH}px`;
      currentPage.classList.add("a4_page_container");
      col_right = document.createElement("div");
      col_right.classList.add("a4_right_column");
      currentPage.appendChild(col_right);
      //se actualiza el límite
      heightLimit += a4HeightPx - node.offsetHeight;
      auxHeight = 0;
      printToConsole("Actualizamos el límite a " + heightLimit);
    } else {
      auxHeight = node.offsetTop - offsetRootReact + node.offsetHeight;
    }
    printToConsole("Añado " + node.id + " a página " + pages.length);
    if (node.id === "cv_footer") {
      let footer = node.cloneNode(true);
      footer.style.marginTop = "auto";
      col_right.appendChild(footer);
    } else {
      col_right.appendChild(node.cloneNode(true));
    }
  });

  printToConsole("/**********************************/");
  pages.push(currentPage);
  document.body.appendChild(currentPage);
  return pages;
};

/*
 * Añade las páginas del array pages a la instancia de PDF
 * Maneja de forma recursiva el uso de la Promise devuelta por la función html2canvas
 */

function addPageRecursively(pdf, pages, currentPageIndex, nameDoc, setter) {
  const page = pages[currentPageIndex];

  /****************** */
  const a4HeightPx = page.offsetHeight; //Alto en px de la página a añadir
  const a4WidthPx = mmToPx(a4WidthMm) - 48; //Ancho en px de la página a añadir

  return html2canvas(page, {
    scale: 4,
    logging: false,
    allowTaint: true,
    scrollY: -window.scrollY,
  }).then(function (canvas) {
    pdf.addImage(
      canvas.toDataURL("image/jpeg"),
      "JPEG",
      0,
      0,
      pxToMm(a4WidthPx),
      pxToMm(a4HeightPx),
      currentPageIndex,
      "SLOW"
    );
    document.body.removeChild(page);
    if (currentPageIndex < pages.length - 1) {
      pdf.addPage();
      //invoca de forma recursiva a esta misma función para procesar la siguiente página
      return addPageRecursively(
        pdf,
        pages,
        currentPageIndex + 1,
        nameDoc,
        setter
      );
    } else {
      setter(false);
      return pdf.save(`${nameDoc}.pdf`);
    }
  });
}

/*
 * Función principal. Genera los DIV que representan las páginas
 * y llama al método addPageRecursively para crear el PDF
 */
export function print(nameDoc, setter) {
  const pages = createPages();
  const pdf = new jsPDF("p", "mm", "a4");
  addPageRecursively(pdf, pages, 0, nameDoc, setter);
}
