import * as types from "../actions/actionTypes";

const initialState = {};

export default function webCandidate(state, action) {
  if (typeof state === "undefined") {
    return false;
  }
  let tempState = { ...state };
  switch (action.type) {
    case types.SET_SEARCH_OFFERS_SNAPSHOT:
      return { ...state, soSnapshot: action.payload };
    case types.CLEAR_SEARCH_OFFERS_SNAPSHOT:
      delete tempState.soSnapshot;
      return tempState;
    case types.SET_LOGIN_ACTION:
      return { ...state, loginAction: action.payload };
    case types.CLEAR_LOGIN_ACTION:
      delete tempState.loginAction;
      return tempState;
    case types.SET_FILTERS_TO_SAVE_ACTION:
      return { ...state, filtersAction: action.payload };
    case types.CLEAR_LOGIN_ACTION:
      delete tempState.filtersAction;
      return tempState;
    case types.SET_SUCCESFUL_SEARCH_SAVE:
      return { ...state, succesfulSave: action.payload };
    case types.CLEAR_SUCCESFUL_SEARCH_SAVE:
      delete tempState.succesfulSave;
      return tempState;
    case types.SET_CANDIDATURAS_SNAPSHOT:
      return { ...state, cSnapshot: action.payload };
    case types.CLEAR_CANDIDATURAS_SNAPSHOT:
      delete tempState.cSnapshot;
      return tempState;
    case types.CLEAR_ALL:
      return initialState;
    case types.SET_SAVED_SEARCH_ACTION:
      return { ...state, savedSearch: action.payload };
    case types.CLEAR_SAVED_SEARCH_ACTION:
      delete tempState.savedSearch;
      return tempState;
    case types.SET_PERSONAL_DATA_ACTION:
      return { ...state, personalData: action.payload };
    case types.CLEAR_PERSONAL_DATA_ACTION:
      delete tempState.personalData;
      return tempState;
    case types.SET_CANDIDATE_WORK_EXPERIENCE:
      return { ...state, candidateWorkExperience: action.payload };
    case types.CLEAR_CANDIDATE_WORK_EXPERIENCE:
      delete tempState.candidateWorkExperience;
    case types.SET_FORMATION_TO_EDIT_ACTION:
      return { ...state, formationData: action.payload };
    case types.CLEAR_FORMATION_TO_EDIT_ACTION:
      delete tempState.formationData;
      return tempState;
    case types.SET_LANGUAGE_TO_EDIT_ACTION:
      return { ...state, languageData: action.payload };
    case types.CLEAR_LANGUAGE_TO_EDIT_ACTION:
      delete tempState.languageData;
      return tempState;
      case types.SET_CANDIDATE_ID_PHOTOGRAPHY:
        return { ...state, idPhotography: action.payload };
      case types.CLEAR_CANDIDATE_ID_PHOTOGRAPHY:
        delete tempState.idPhotography;
        return tempState;
    default:
      return initialState;
  }
}
