import React from "react";
import { useTranslation } from "react-i18next";

function DownloadDocument(props) {
  const { t } = useTranslation();
  const { title = t("icons_tooltips.download")} = props;
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>{title}</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Recursos" transform="translate(-708.000000, -1787.000000)" fill="#DA291C" fillRule="nonzero">
                <g id="Group-7" transform="translate(708.000000, 1787.000000)">
                    <path d="M8,12 C8.3,12 8.5,11.9 8.7,11.7 L14.4,6 L13,4.6 L9,8.6 L9,0 L7,0 L7,8.6 L3,4.6 L1.6,6 L7.3,11.7 C7.5,11.9 7.7,12 8,12 Z" id="Path"></path>
                    <path d="M14,14 L2,14 L2,11 L0,11 L0,15 C0,15.6 0.4,16 1,16 L15,16 C15.6,16 16,15.6 16,15 L16,11 L14,11 L14,14 Z" id="Path"></path>
                </g>
            </g>
        </g>
    </svg>
  );
}

export default DownloadDocument;
