import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  svg_syle: {
    height: 60,
    width: 64,
    [theme.breakpoints.down("sm")]: {
      height: 30,
      width: 32,
    },
  },
  svg_style_no_shrink: {
    height: 60,
    width: 64,
  },
}));

function CandidaturasIcon(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { title = t("icons_tooltips.candidaturas"), color = "#000000", noShrink = false} = props;

  return (
    <svg
      className={noShrink ? classes.svg_style_no_shrink : classes.svg_syle}
      viewBox="0 0 64 60"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
            <title>{title}</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Recursos"
          transform="translate(-127.000000, -1174.000000)"
          stroke={color}
          strokeWidth="4"
        >
          <g id="briefcase-24" transform="translate(129.000000, 1176.000000)">
            <polyline id="Path" points="20 10 20 0 40 0 40 10"></polyline>
            <polyline
              id="Path"
              points="22 42 0 42 0 10 60 10 60 42 38 42"
            ></polyline>
            <polyline id="Path" points="58 48 58 56 2 56 2 48"></polyline>
            <rect id="Rectangle" x="22" y="36" width="16" height="12"></rect>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CandidaturasIcon;
