import { connect } from "react-redux";
import Idiomas from "../../Views/idiomas";
import { bindActionCreators } from "redux";
import { clearLanguageToEditAction } from "../actions/webCandidate";

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      clearLanguageToEditAction: bindActionCreators(clearLanguageToEditAction, dispatch)
    }
  };
}

function mapStateToProps(state) {
  return {
    state: state.webCandidate
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Idiomas);
