import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Formacion from "../../Views/formacion";
import { clearFormationToEditAction } from "../actions/webCandidate";

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      clearFormationToEditAction: bindActionCreators(clearFormationToEditAction, dispatch)
    }
  };
}

function mapStateToProps(state) {
  return {
    state: state.webCandidate
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Formacion);
