import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  FormControl,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import InputWithLabel from "./inputWithLabelAnimation";
import AutocompleteWithLabel from "./autocompleteWithLabelAnimation";
import { EXTRANJERO, TODA_ESPANHA } from "../constants";

const useStyles = makeStyles((theme) => ({
  autocomplete_input: {
    padding: "0!important",
  },
  input: {
    width: "100%",
  },
  no_margin: {
    margin: "0px",
  },
  search_button: {
    width: "100%",
    color: "#FFFFFF",
    height: "36px",
    textTransform: "none",
    fontWeight: "bold",
  },
  search_separator: {
    marginTop: "15px",
    fontSize: "22px",
    lineHeight: "30px",
    fontWeight: "900",
    width: "100%",
    marginBottom: (props) => (props.displayInline ? "0" : "16px"),
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "21px",
    },
  },
  searcher: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  formContainer: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: ".35fr .35fr .30fr",
    columnGap: "24px",
    justifyContent: "end",
    alignItems: "end",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridAutoRows: "min-content",
      rowGap: "24px",
    },
  },
  formContainerColumn: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "12px",
    justifyContent: "end",
    alignItems: "end",
  },
}));

function OffersSearcher(props) {
  const provincias = require("../assets/dataLists/es/provincias.json");
  const {
    displayInline
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);

  const [puestoBusqueda, setPuestoBusqueda] = useState("");
  const [provinciaBusqueda, setProvinciaBusqueda] = useState({
    id: TODA_ESPANHA,
    name: t("offers.allProvinces"),
  });

  const handleSearchOffers = () => {
    props.history.push(
      `/search_offers/${provinciaBusqueda.id}/${puestoBusqueda}`
    );
  };

  const handleKey = (key) => {
    if (key === "Enter") {
      handleSearchOffers(puestoBusqueda, provinciaBusqueda.id);
    }
  };

  return (
    <div className={classes.searcher}>
      <Typography
        className={classes.search_separator}
        style={props.whiteTitle ? { color: "white" } : {}}
      >
        {t("offers.newSearch")}
      </Typography>
      <div
        className={
          displayInline ? classes.formContainer : classes.formContainerColumn
        }
      >
        <InputWithLabel
          fullWidth={!displayInline}
          customClassWrapper={classes.input}
          label={t("offers.jobSearch")}
          value={puestoBusqueda}
          setter={(e) => setPuestoBusqueda(e.target.value)}
          moveLabel={true}
          onKeyDown={(e) => handleKey(e ? e.key : null)}
          whiteLabel={props.whiteLabel}
        />
        <FormControl
          variant="outlined"
          fullWidth={!displayInline}
          className={classes.input}
        >
          <AutocompleteWithLabel
            options={[
              { id: EXTRANJERO, name: t("offers.abroad") },
              { id: TODA_ESPANHA, name: t("offers.allProvinces") },
              ...provincias.filter((p) => p.id_country === "724"),
            ]}
            getLabel={(option) => option.name}
            customClass={{
              input: classes.inputRoot_textField,
              root: classes.inputRoot_textField,
            }}
            value={provinciaBusqueda}
            setter={setProvinciaBusqueda}
            label={t("offers.province")}
            moveLabel={true}
            whiteLabel={props.whiteLabel}
          />
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          style={
            props.whiteTitle
              ? {
                  backgroundColor: "#000000",
                  color: "white",
                }
              : {}
          }
          className={classes.search_button}
          onClick={() =>
            handleSearchOffers(puestoBusqueda, provinciaBusqueda?.id)
          }
        >
          {t("offers.search")}
        </Button>
      </div>
    </div>
  );
}

export default withRouter(OffersSearcher);
