import * as types from "./actionTypes";

export function setSearchOfferSnapshot(data) {
  return function (dispatch) {
    dispatch({ type: types.SET_SEARCH_OFFERS_SNAPSHOT, payload: data });
  };
}

export function clearSearchOfferSnapshot() {
  return function (dispatch) {
    dispatch({ type: types.CLEAR_SEARCH_OFFERS_SNAPSHOT });
  };
}

export function setLoginAction(data) {
  return function (dispatch) {
    dispatch({ type: types.SET_LOGIN_ACTION, payload: data });
  };
}

export function clearLoginAction() {
  return function (dispatch) {
    dispatch({ type: types.CLEAR_LOGIN_ACTION });
  };
}

export function setFiltersToSaveAction(data) {
  return function (dispatch) {
    dispatch({ type: types.SET_FILTERS_TO_SAVE_ACTION, payload: data });
  };
}

export function clearFiltersToSaveAction() {
  return function (dispatch) {
    dispatch({ type: types.CLEAR_FILTERS_TO_SAVE_ACTION });
  };
}

export function setCandidaturasSnapshot(data) {
  return function (dispatch) {
    dispatch({ type: types.SET_CANDIDATURAS_SNAPSHOT, payload: data });
  };
}

export function clearCandidaturasSnapshot() {
  return function (dispatch) {
    dispatch({ type: types.CLEAR_CANDIDATURAS_SNAPSHOT });
  };
}

export function setSuccesfulSearchSave(data) {
  return function (dispatch) {
    dispatch({ type: types.SET_SUCCESFUL_SEARCH_SAVE, payload: data });
  };
}

export function clearSuccesfulSearchSave() {
  return function (dispatch) {
    dispatch({ type: types.CLEAR_SUCCESFUL_SEARCH_SAVE });
  };
}

export function setSavedSearchAction(data) {
  return function (dispatch) {
    dispatch({ type: types.SET_SAVED_SEARCH_ACTION, payload: data });
  };
}

export function clearSavedSearchAction() {
  return function (dispatch) {
    dispatch({ type: types.CLEAR_SAVED_SEARCH_ACTION });
  };
}

export function setPersonalDataAction(data) {
  return function (dispatch) {
    dispatch({ type: types.SET_PERSONAL_DATA_ACTION, payload: data });
  };
}

export function clearPersonalDataAction() {
  return function (dispatch) {
    dispatch({ type: types.CLEAR_PERSONAL_DATA_ACTION });
  };
}

export function setFormationToEditAction(data) {
  return function (dispatch) {
    dispatch({ type: types.SET_FORMATION_TO_EDIT_ACTION, payload: data });
  };
}

export function clearFormationToEditAction() {
  return function (dispatch) {
    dispatch({ type: types.CLEAR_FORMATION_TO_EDIT_ACTION });
  };
}

export function setLanguageToEditAction(data) {
  return function (dispatch) {
    dispatch({ type: types.SET_LANGUAGE_TO_EDIT_ACTION, payload: data });
  };
}

export function clearLanguageToEditAction() {
  return function (dispatch) {
    dispatch({ type: types.CLEAR_LANGUAGE_TO_EDIT_ACTION });
  };
}

export function clearAll() {
  return function (dispatch) {
    dispatch({ type: types.CLEAR_ALL });
  };
}

export function setCandidateWorkExperience(data) {
  return function (dispatch) {
    dispatch({ type: types.SET_CANDIDATE_WORK_EXPERIENCE, payload: data });
  };
}

export function clearCandidateWorkExperience() {
  return function (dispatch) {
    dispatch({ type: types.CLEAR_CANDIDATE_WORK_EXPERIENCE });
  };
}

export function setCandidateIdPhotography(data) {
  return function (dispatch) {
    dispatch({ type: types.SET_CANDIDATE_ID_PHOTOGRAPHY, payload: data });
  };
}

export function clearCandidateIdPhotography() {
  return function (dispatch) {
    dispatch({ type: types.CLEAR_CANDIDATE_ID_PHOTOGRAPHY });
  };
}