import { combineReducers } from "redux";
import webCandidate from "./webCandidate"
import maestros from "./maestrosReducer"

const rootReducer = combineReducers({
 webCandidate,
 maestros
});

export default rootReducer;
