import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import ButtonOutlined from "../components/buttonOutlined";
import { withSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AntSwitch from "./antSwitch";
import ButtonFull from "./buttonFull";
import DatePickerWithLabel from "./datePickerWithLabel";
import Header from "./header";
import InputWithLabel from "./inputWithLabelAnimation";
import { formatDateFront, optionEquality } from "../utils/functions";
import Trash from "../assets/icons/trash";
import AutocompleteWithLabel from "./autocompleteWithLabelAnimation";
import { getEtts, getPlace, getSectors } from "../api/maestro";
import { addExpEtts } from "../api/candidato";
import { CURRICULUM_URL } from "../constants";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: window.innerHeight,
    background: "#FFFFFF",
    [theme.breakpoints.up("md")]: {
      height: "100vh",
    },
    display: "grid",
    gridTemplateColumns: "minmax(0, 1fr)",
    gridTemplateRows: "90px minmax(0, auto)",
    rowGap: "0px",
    gridTemplateAreas: "'header' 'body'",
  },
  body: {
    gridArea: "body",
    display: "grid",
    overflow: "auto",
    gridTemplateColumns:
      "minMax(0,1fr) clamp(1100px, 1200px, 1300px) minMax(0, 1fr)",
    gridTemplateAreas: "'leftMarg content rightMarg'",
    backgroundImage: "url(images/fondo1920x1080.jpg)",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateRows: "auto",
      gridTemplateColumns: "0% minMax(0, 1fr) 0%",
      backgroundImage: "none",
      backgroundAttachment: "none",
      backgroundPosition: "none",
      backgroundRepeat: "none",
      backgroundSize: "none",
    },
  },
  body_container: {
    display: "grid",
    gridArea: "content",
    gridTemplateRows: "auto auto minmax(0, 1fr)",
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
    },
  },
  form_container: {
    display: "grid",
    marginTop: "16px",
    width: "100%",
    height: "auto",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridTemplateRows:
      "minmax(36px, auto) minmax(36px, auto) minmax(36px, auto)",
    gridColumnGap: "25px",
    gridRowGap: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      margin: "0px",
    },
    alignItems: "flex-end",
  },
  field: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  table_container_not_all_etts: {
    width: "100%",
    marginTop: "24px",
    height: "400px",
  },
  table_container_all_etts: {
    width: "100%",
    marginTop: "24px",
  },
  antSwitchDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  buttonAdd: {
    display: "flex",
    justifyContent: "end",
  },
  title: {
    gridColumn: "1/5",
  },
  row: {
    backgroundColor: "rgba(255,255,255,0.8)",
  },
  date: {
    width: "100%",
  },
  buttonWithEtts: {
    display: "grid",
    justifyContent: "end",
    gridColumnStart: 1,
    gridColumnEnd: 5,
  },
  buttonNoEtts: {
    display: "grid",
    justifyContent: "end",
    gridColumnStart: 1,
    gridColumnEnd: 5,
  },
  buttonCancel: {
    marginRight: "12px",
  },
}));

function EttInfo(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [newEtt, setNewEtt] = useState({ trabajoActual: true });
  const [listEtts, setListEtts] = useState([]);
  const [fieldsWithErrors, setFieldsWithErrors] = useState([]);
  const [employmentsOptions, setEmploymentsOptions] = useState(null);
  const [sectorOptions, setSectorOptions] = useState(null);
  const [ettsOptions, setEttsOptions] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedEtt, setSelectedEtt] = useState(null);
  const [dataNoSaved, setDataNoSaved] = useState(false);

  const tableCellsHeader = [
    {
      text: t("ett.ett"),
      size: "15&%",
    },
    {
      text: t("ett.sector"),
      size: "15%",
    },
    {
      text: t("ett.company"),
      size: "15%",
    },
    {
      text: t("ett.job"),
      size: "20%",
    },
    {
      text: t("ett.startDate"),
      size: "15%",
    },
    {
      text: t("ett.endDate"),
      size: "15%",
    },
    {
      text: "",
      size: "5%",
    },
  ];

  async function handleGetEmployments() {
    const response = await getPlace(88);
    if (response.status === 200) {
      const result = await response.json();
      setEmploymentsOptions(result.puestos);
    } else {
      setEmploymentsOptions([]);
      switch (response.status) {
        case 404:
          props.enqueueSnackbar(t("work_experience.get_places_not_found"), {
            variant: "error",
          });
          break;
        default:
          props.enqueueSnackbar(t("work_experience.get_place_error"), {
            variant: "error",
          });
          break;
      }
    }
  }

  async function handleGetSectors() {
    const response = await getSectors(88);
    if (response.status === 200) {
      const result = await response.json();
      setSectorOptions(result.sectores);
    } else {
      setSectorOptions([]);
      switch (response.status) {
        case 404:
          props.enqueueSnackbar(t("work_experience.get_sectors_not_found"), {
            variant: "error",
          });
          break;
        default:
          props.enqueueSnackbar(t("work_experience.get_sectors_error"), {
            variant: "error",
          });
          break;
      }
    }
  }

  async function handleGetEtts() {
    const response = await getEtts();
    if (response.status === 200) {
      const result = await response.json();
      setEttsOptions(result);
    } else {
      setEttsOptions([]);
      switch (response.status) {
        case 500:
          props.enqueueSnackbar(t("work_experience.get_sectors_not_found"), {
            variant: "error",
          });
          break;
        default:
          props.enqueueSnackbar(t("work_experience.get_sectors_not_found"), {
            variant: "warning",
          });
          break;
      }
    }
  }

  useEffect(() => {
    setNewEtt({ trabajoActual: true });
    setSelectedJob(null);
    setSelectedSector(null);
    setSelectedEtt(null);
  }, [listEtts]);

  useEffect(() => {
    handleGetEmployments();
    handleGetSectors();
    handleGetEtts();
  }, []);

  function handleSetEtt(ett) {
    let newEttTemp = { ...newEtt };
    newEttTemp.ett = ett;
    setNewEtt(newEttTemp);
  }

  function handleSetCompany(company) {
    let newEttTemp = { ...newEtt };
    newEttTemp.empresa = company;
    setNewEtt(newEttTemp);
  }

  function handleSetJob(job) {
    if (job) {
      let newEttTemp = { ...newEtt };
      newEttTemp.idPuesto = job.id;
      setNewEtt(newEttTemp);
      setSelectedJob(job);
    }
  }

  function handleSetSector(sector) {
    if (sector) {
      let newEttTemp = { ...newEtt };
      newEttTemp.idSector = sector.id;
      setNewEtt(newEttTemp);
      setSelectedSector(sector);
    }
  }

  function handleSetEtt(ett) {
    if (ett) {
      let newEttTemp = { ...newEtt };
      newEttTemp.idEtt = ett.id;
      setNewEtt(newEttTemp);
      setSelectedEtt(ett);
    }
  }

  function handleSetStartDate(startDate) {
    let newEttTemp = { ...newEtt };
    newEttTemp.fechaInicio = startDate;
    setNewEtt(newEttTemp);
  }

  function handleSetIsWorking(isWorking) {
    let newEttTemp = { ...newEtt };
    newEttTemp.trabajoActual = isWorking;
    if (isWorking === true) {
      newEttTemp.fechaFin = null;
      removeErroFromList("fechaFin");
    }
    setNewEtt(newEttTemp);
  }

  function handleSetEndDate(endDate) {
    let newEttTemp = { ...newEtt };
    newEttTemp.fechaFin = endDate;
    setNewEtt(newEttTemp);
  }

  function handleClickAddEtt() {
    if (getValidationErrors().length === 0) {
      let listEttsTemp = [...listEtts];
      listEttsTemp.push(newEtt);
      setListEtts(listEttsTemp);
    }
  }

  async function handleAddExpEtts() {
    const response = await addExpEtts(listEtts);
    if (response.status === 200) {
      props.history.push(CURRICULUM_URL);
    } else {
      props.enqueueSnackbar(t("ett.error"), {
        variant: "error",
      });
    }
  }

  const getValidationErrors = () => {
    const errors = [];
    if (!newEtt?.idEtt || newEtt?.idEtt === "") errors.push("ett");
    if (!newEtt?.empresa || newEtt?.empresa === "") errors.push("empresa");
    if (!newEtt?.idPuesto || newEtt?.idPuesto === "") errors.push("puesto");
    if (!newEtt?.idSector || newEtt?.idSector === "") errors.push("sector");
    if (!newEtt?.fechaInicio || isNaN(newEtt.fechaInicio)) errors.push("fechaInicio");
    if (!newEtt?.trabajoActual && (!newEtt?.fechaFin || isNaN(newEtt.fechaFin))) errors.push("fechaFin");
    setFieldsWithErrors(errors);

    return errors;
  };

  function removeErroFromList(field) {
    if (fieldsWithErrors.length > 0) {
      const newErrorList = fieldsWithErrors.filter((e) => e !== field);
      setFieldsWithErrors(newErrorList);
    }
  }

  function handleDeleteEtt(index) {
    let listTemp = [...listEtts];
    listTemp.splice(index, 1);
    setListEtts(listTemp);
  }

  function handleGetNameSector(idSector) {
    let sector = sectorOptions.find((s) => s.id === idSector);
    return sector.nombre;
  }

  function handleGetNameJob(idJob) {
    let job = employmentsOptions.find((e) => e.id === idJob);
    return job.nombre;
  }

  function handleGetNameEtt(idEtt) {
    let ett = ettsOptions.find((e) => e.id === idEtt);
    return ett.nombre;
  }

  function hasDataNoSaved() {
    if (selectedJob || selectedSector || selectedEtt) {
      setDataNoSaved(true);
      return true;
    } else {
      setDataNoSaved(false);
      return false;
    }
  }

  function getContinueButtonClass() {
    return listEtts.length > 0 ? classes.buttonWithEtts : classes.buttonNoEtts;
  }

  function getContinueButtonText() {
    return listEtts.length > 0 ? t("common.save") : t("common.continue");
  }

  function getTableContainerClass() {
    return listEtts?.length === 10
      ? classes.table_container_all_etts
      : classes.table_container_not_all_etts;
  }

  return (
    <div className={classes.container}>
      <Header menuItems={false}></Header>
      <div className={classes.body}>
        <div className={classes.body_container}>
          {listEtts.length !== 10 ? (
            <div className={classes.form_container}>
              <div className={getContinueButtonClass()}>
                <ButtonFull
                  size="120px"
                  text={getContinueButtonText()}
                  onClick={() => {
                    let result = hasDataNoSaved();
                    if (result === false) {
                      if (listEtts.length > 0) handleAddExpEtts();
                      else props.history.push(CURRICULUM_URL);
                    }
                  }}
                />
              </div>
              <div className={classes.title}>
                <Typography style={{ fontSize: "14px" }}>
                  {t("ett.info_title")}
                </Typography>
              </div>
              <AutocompleteWithLabel
                options={ettsOptions}
                value={selectedEtt}
                label={t("ett.ett")}
                setter={(e) => {
                  handleSetEtt(e);
                  removeErroFromList("ett");
                }}
                getLabel={(option) => option.nombre}
                getOption={(x, v) => optionEquality(x, v)}
                wrong={fieldsWithErrors.includes("ett")}
                customClassWrapper={classes.field}
              />
              <AutocompleteWithLabel
                options={sectorOptions}
                value={selectedSector}
                label={t("ett.sector")}
                setter={(e) => {
                  handleSetSector(e);
                  removeErroFromList("sector");
                }}
                getLabel={(option) => option.nombre}
                getOption={(x, v) => optionEquality(x, v)}
                wrong={fieldsWithErrors.includes("sector")}
                customClassWrapper={classes.field}
              />
              <InputWithLabel
                wrong={fieldsWithErrors.includes("empresa")}
                value={newEtt?.empresa}
                label={t("ett.company")}
                setter={(e) => {
                  handleSetCompany(e.target.value);
                  removeErroFromList("empresa");
                }}
                customClassWrapper={classes.field}
              />
              <AutocompleteWithLabel
                options={employmentsOptions}
                value={selectedJob}
                label={t("ett.job")}
                setter={(e) => {
                  handleSetJob(e);
                  removeErroFromList("puesto");
                }}
                getLabel={(option) => option.nombre}
                getOption={(x, v) => optionEquality(x, v)}
                wrong={fieldsWithErrors.includes("puesto")}
                customClassWrapper={classes.field}
              />
              <DatePickerWithLabel
                wrong={fieldsWithErrors.includes("fechaInicio")}
                label={t("ett.startDate")}
                value={newEtt?.fechaInicio}
                setter={(e) => {
                  handleSetStartDate(e);
                  removeErroFromList("fechaInicio");
                }}
                maxDate={new Date()}
                customClass={classes.date}
              />
              <div className={classes.antSwitchDiv}>
                <Typography>{t("ett.isWorking")}</Typography>
                <AntSwitch
                  checked={newEtt?.trabajoActual}
                  setter={() => handleSetIsWorking(!newEtt?.trabajoActual)}
                />
              </div>
              <DatePickerWithLabel
                wrong={fieldsWithErrors.includes("fechaFin")}
                label={t("ett.endDate")}
                value={newEtt?.fechaFin}
                setter={(e) => {
                  handleSetEndDate(e);
                  removeErroFromList("fechaFin");
                }}
                disabled={newEtt?.trabajoActual}
                maxDate={new Date()}
                customClass={classes.date}
              />
              <div className={classes.buttonAdd}>
                <ButtonOutlined
                  onClick={handleClickAddEtt}
                  text={t("icons_tooltips.addEtt")}
                  sizeIcon={"32px"}
                />
              </div>
            </div>
          ) : null}
          {listEtts.length > 0 ? (
            <TableContainer className={getTableContainerClass()}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow className={classes.row}>
                    {tableCellsHeader.map((tch) => {
                      return (
                        <TableCell
                          style={{ width: tch.size, fontWeight: "bold" }}
                        >
                          {tch.text}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listEtts?.map((ett, index) => {
                    return (
                      <TableRow className={classes.row}>
                        <TableCell>{handleGetNameEtt(ett.idEtt)}</TableCell>
                        <TableCell>
                          {handleGetNameSector(ett.idSector)}
                        </TableCell>
                        <TableCell>{ett.empresa}</TableCell>
                        <TableCell>{handleGetNameJob(ett.idPuesto)}</TableCell>
                        <TableCell>
                          {formatDateFront(ett.fechaInicio)}
                        </TableCell>
                        <TableCell>
                          {ett.fechaFin ? formatDateFront(ett.fechaFin) : "-"}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleDeleteEtt(index);
                            }}
                          >
                            <Trash title={t("icons_tooltips.delete")} />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </div>
        <Dialog open={dataNoSaved} onClose={() => setDataNoSaved(false)}>
          <DialogContent>
            <Typography>
              ¿Quieres continuar sin guardar los datos de tu experiencia?
            </Typography>
          </DialogContent>
          <DialogActions>
            <div
              style={{
                display: "flex",
                width: "100%",
                padding: "8px",
                justifyContent: "end",
              }}
            >
              <ButtonOutlined
                text={t("alertDialog.cancel")}
                onClick={() => setDataNoSaved(false)}
                customClass={classes.buttonCancel}
              />
              <ButtonFull
                text={t("alertDialog.continue")}
                onClick={() => {
                  if (listEtts.length > 0) handleAddExpEtts();
                  else props.history.push(CURRICULUM_URL);
                }}
              />
            </div>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default withSnackbar(EttInfo);
