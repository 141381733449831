import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setCandidateIdPhotography,
  clearCandidateIdPhotography} from "../actions/webCandidate"
import ProfilePicture from "../../components/profilePicture";

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setCandidateIdPhotography: bindActionCreators(setCandidateIdPhotography,dispatch),
      clearCandidateIdPhotography: bindActionCreators(clearCandidateIdPhotography, dispatch)

    }
  };
}

function mapStateToProps(state) {
  return {
    state: state.webCandidate
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePicture);
