import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  svg_syle: {
    cursor: "pointer",
    width: 12,
    height: 12,
  },
}));

function DownwardMore(props) {
  const { t } = useTranslation();
  const { title = t("icons_tooltips.expand"), color = "#DA291C" } = props;
  const classes = useStyles();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="14px"
      height="7px"
      viewBox="0 0 14 7"
      version="1.1"
      className={classes.svg_style}
    >
            <title>{title}</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Recursos"
          transform="translate(-152.000000, -1792.000000)"
          stroke={color}
        >
          <g id="priority-low" transform="translate(153.000000, 1792.000000)">
            <polyline id="Path" points="0 0.5 6 6.5 12 0.5" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default DownwardMore;
