import React from "react";
import { useTranslation } from "react-i18next";

function PerfilDigitalTwitter(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.twitter")} = props;

    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Recursos" transform="translate(-214.000000, -1858.000000)">
                    <g id="ico_twitter" transform="translate(214.000000, 1858.000000)">
                        <polygon id="Path" fill="#DA291C" points="24 1.96220359e-05 -4.08562073e-14 1.96220358e-05 -4.08562073e-14 24.0000196 24 24.0000196"></polygon>
                        <path d="M21,6.72501962 C20.3371804,7.03760706 19.6279119,7.24025519 18.9,7.32501962 C19.6614728,6.85409406 20.2425814,6.14091532 20.55,5.30001962 C19.8369956,5.7405552 19.0487856,6.04566872 18.225,6.20001962 C17.5357947,5.43536976 16.5544137,4.99920045 15.525,5.00001847 C13.4967234,5.00332301 11.8533034,6.64674305 11.85,8.67501962 C11.8341339,8.95221421 11.8594081,9.2302303 11.925,9.50001847 C8.93079388,9.36825044 6.14051683,7.94575625 4.275,5.60001962 C3.9201417,6.16011304 3.73758801,6.81209052 3.75,7.47501962 C3.76325812,8.70837444 4.3796295,9.85706656 5.4,10.5500196 C4.81815723,10.5619146 4.24524093,10.4056647 3.75,10.1000196 L3.75,10.1750196 C3.73438473,11.921589 4.96222028,13.4327712 6.675,13.7750196 C6.35789804,13.867171 6.03013698,13.9175958 5.7,13.9250196 C5.47240846,13.9372425 5.24435745,13.9119035 5.025,13.8500196 C5.47502092,15.3761227 6.88402489,16.4175604 8.475,16.4000196 C7.1658762,17.416027 5.55712087,17.9698608 3.9,17.9750196 C3.59805241,17.987353 3.29574141,17.9621604 3,17.9000196 C4.68033615,18.9728736 6.63137974,19.5451797 8.625,19.5500196 C11.417547,19.5756348 14.1031718,18.4776154 16.0778838,16.5029034 C18.0525957,14.5281914 19.1506151,11.8425666 19.125,9.05001962 L19.125,8.60001962 C19.8402497,8.07230306 20.4722834,7.44026932 21,6.72501962 Z" id="Path" fill="#FFFFFF"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default PerfilDigitalTwitter;