import React from "react";
import { useTranslation } from "react-i18next";

function PerfilDigitalOtro(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.other")} = props;

    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Recursos" transform="translate(-394.000000, -1858.000000)">
                    <g id="ico_other" transform="translate(394.000000, 1858.000000)">
                        <polygon id="Path-Copy-3" fill="#DA291C" points="24 1.96220357e-05 -4.08562073e-14 1.96220356e-05 -4.08562073e-14 24.0000196 24 24.0000196"></polygon>
                        <g id="link" transform="translate(3.000000, 4.000000)" fill="#FFFFFF" fillRule="nonzero">
                            <path d="M5.5,16 C4.3,16 3.2,15.5 2.3,14.7 C0.5,12.9 0.5,10.1 2.3,8.3 L3,7.6 L4.4,9 L3.7,9.7 C2.7,10.7 2.7,12.3 3.7,13.3 C4.7,14.3 6.3,14.3 7.3,13.3 L10.3,10.3 C11.3,9.3 11.3,7.7 10.3,6.7 L9.6,6 L11,4.6 L11.7,5.3 C13.5,7.1 13.5,9.9 11.7,11.7 L8.7,14.7 C7.9,15.5 6.7,16 5.5,16 Z" id="Path"></path>
                            <path d="M7,11.4 L6.3,10.7 C4.5,8.9 4.5,6.1 6.3,4.3 L9.3,1.3 C10.2,0.4 11.3,8.8817842e-16 12.5,8.8817842e-16 C13.7,8.8817842e-16 14.8,0.5 15.7,1.3 C17.5,3.1 17.5,5.9 15.7,7.7 L15,8.4 L13.6,7 L14.3,6.3 C15.3,5.3 15.3,3.7 14.3,2.7 C13.3,1.7 11.7,1.7 10.7,2.7 L7.7,5.7 C6.7,6.7 6.7,8.3 7.7,9.3 L8.4,10 L7,11.4 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default PerfilDigitalOtro;