import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import CurriculumIcon from "../assets/icons/curriculumIcon";
import ProfilePicture from "../redux/containers/profilePicture";

const useStyles = makeStyles((theme) => ({
  principal_container: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  summary_icon_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  title_text: {
    fontSize: 36,
    fontWeight: 900,
    marginBottom: "30px",
  },
  personal_data_inner_sections: {
    fontSize: 16,
    fontWeight: 600,
    color: "rgba(0,0,0,0.87)",
    margin: "5px",
    wordBreak: "break-all",
    overflowWrap: "break-word",
  },
  personal_data_section_container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px",
  },
  personal_data_section_negative_margin: {
    display: "flex",
    flexDirection: "column",
    margin: "-5px",
  },
  fullname: {
    color: "rgba(0,0,0,0.87)",
    fontSize: 18,
    fontWeight: 900,
    margin: "5px",
    wordBreak: "break-word",
  },
  candidate_last_update: {
    display: "inline-block",
    color: "rgba(0,0,0,0.87)",
    fontSize: 12,
    fontWeight: 500,
    margin: "5px",
  },
  email: (props) => ({
    overflow: "hidden",
    whiteSpace: props.email < 95 ? "break-spaces" : "nowrap",
    textOverflow: "ellipsis",
    width: props.email < 95 ? "auto" : "290px",
  }),
}));

function TuCurriculumHeader(props) {
  const {
    nombre,
    primerApellido,
    segundoApellido,
    correoElectronico,
    prefijo,
    telefono,
    fechaModificacion,
    isPhotoEditable = false,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles({ email: correoElectronico?.length });

  function datosPersonalesSection() {
    return (
      <div className={classes.personal_data_section_container}>
        <div className={classes.personal_data_section_negative_margin}>
          {nombre !== null &&
            primerApellido !== null &&
            segundoApellido !== null && (
              <div className={classes.fullname}>
                {`${nombre} ${primerApellido} ${segundoApellido}`}
              </div>
            )}

          {fechaModificacion !== null && (
            <div className={classes.candidate_last_update}>
              {`${t("candidateDashboard.last_update")} ${fechaModificacion}`}
            </div>
          )}
          <div className={classes.personal_data_inner_sections}>
            {correoElectronico !== null && (
              <div className={classes.email}>{correoElectronico}</div>
            )}
            {prefijo !== null && telefono !== null && (
              <div>{`+${prefijo} ${telefono}`}</div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.principal_container}>
      <div className={classes.summary_icon_container}>
        <CurriculumIcon
          title={t("icons_tooltips.curriculum")}
          color="#DA291C"
          noShrink={true}
        />
      </div>
      <div className={classes.title_text}>{t("curriculum.title")}</div>
      <ProfilePicture
        isEditAvailable={isPhotoEditable}
        propsMargin="0 0 30px 0"
      />
      {datosPersonalesSection()}
    </div>
  );
}

export default TuCurriculumHeader;
