export const SET_SEARCH_OFFERS_SNAPSHOT = "SET_SEARCH_OFFERS_SNAPSHOT";
export const CLEAR_SEARCH_OFFERS_SNAPSHOT = "CLEAR_SEARCH_OFFERS_SNAPSHOT";
export const SET_LOGIN_ACTION = "SET_LOGIN_ACTION";
export const CLEAR_LOGIN_ACTION = "CLEAR_LOGIN_ACTION";
export const SET_FILTERS_TO_SAVE_ACTION = "SET_FILTERS_TO_SAVE_ACTION";
export const CLEAR_FILTERS_TO_SAVE_ACTION = "CLEAR_FILTERS_TO_SAVE_ACTION";
export const SET_CANDIDATURAS_SNAPSHOT = "SET_CANDIDATURAS_SNAPSHOT";
export const CLEAR_CANDIDATURAS_SNAPSHOT = "CLEAR_CANDIDATURAS_SNAPSHOT";
export const SET_SUCCESFUL_SEARCH_SAVE = "SET_SUCCESFUL_SEARCH_SAVE";
export const CLEAR_SUCCESFUL_SEARCH_SAVE = "CLEAR_SUCCESFUL_SEARCH_SAVE";
export const SET_SAVED_SEARCH_ACTION = "SET_SAVED_SEARCH_ACTION";
export const CLEAR_SAVED_SEARCH_ACTION = "CLEAR_SAVED_SEARCH_ACTION";
export const SET_PERSONAL_DATA_ACTION = "SET_PERSONAL_DATA_ACTION";
export const CLEAR_PERSONAL_DATA_ACTION = "CLEAR_PERSONAL_DATA_ACTION";
export const SET_FORMATION_TO_EDIT_ACTION = "SET_FORMATION_TO_EDIT_ACTION";
export const CLEAR_FORMATION_TO_EDIT_ACTION = "CLEAR_FORMATION_TO_EDIT_ACTION";
export const SET_LANGUAGE_TO_EDIT_ACTION = "SET_LANGUAGE_TO_EDIT_ACTION";
export const CLEAR_LANGUAGE_TO_EDIT_ACTION = "CLEAR_LANGUAGE_TO_EDIT_ACTION";
export const CLEAR_ALL = "CLEAR_ALL";
export const CLEAR_CANDIDATE_WORK_EXPERIENCE = "CLEAR_CANDIDATE_WORK_EXPERIENCE";
export const SET_CANDIDATE_WORK_EXPERIENCE = "SET_CANDIDATE_WORK_EXPERIENCE";
export const CLEAR_CANDIDATE_ID_PHOTOGRAPHY = "CLEAR_CANDIDATE_ID_PHOTOGRAPHY";
export const SET_CANDIDATE_ID_PHOTOGRAPHY = "SET_CANDIDATE_ID_PHOTOGRAPHY";
export const SET_PAISES = "SET_PAISES";
export const SET_LOCALIDADES = "SET_LOCALIDADES";
export const SET_TIPO_IDENTIFICACION = "SET_TIPO_IDENTIFICACION";
export const SET_TIPO_HORARIO = "SET_TIPO_HORARIO";

