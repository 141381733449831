import { connect } from "react-redux";
import { mapPrefixes } from "../../utils/functions";
import datosContratacion from "../../Views/datosContratacion";
import { setTipoIdentificacion } from "../actions/maestroAction";
import { bindActionCreators } from "redux";

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setTipoIdentificacion: bindActionCreators(setTipoIdentificacion, dispatch),
    }
  };
}

function mapStateToProps(state) {
  return {
    countries: mapPrefixes(state?.maestros.paises),
    locations: state?.maestros.localidades,
    identificationTypes: state?.maestros.tipoIdentificacion,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(datosContratacion);
