import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setLocalidades, setPaises,setTipoHorario, setTipoIdentificacion} from "../actions/maestroAction";
import MaestrosContext from "../../contexts/maestrosContext";

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setPaises: bindActionCreators(setPaises, dispatch),
      setLocalidades: bindActionCreators(setLocalidades, dispatch),
      setTipoHorario: bindActionCreators(setTipoHorario, dispatch),
      setTipoIdentificacion: bindActionCreators(setTipoIdentificacion, dispatch),
    },
  };
}

function mapStateToProps(state) {
  return {
    state: {
        paises: state?.maestros?.paises,
        localidades: state?.maestros?.localidades,
        tipoHorario: state?.maestros?.tipoHorario,
        tipoIdentificacion: state?.maestros?.tipoIdentificacion
    }
  };
}

function MaestrosContainer(props) {
  return <MaestrosContext {...props} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(MaestrosContainer);
