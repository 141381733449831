import React from "react";
import { useTranslation } from "react-i18next";

function Instagram(props) {
    const { t } = useTranslation();
    const { title=t(`icons_tooltips.instagram`), onClick, className } = props;
    return (
        <svg onClick={onClick} className={className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>Instagram</title>
            <g id="Page-1" stroke="none" stroke-width="1" fill="white" fill-rule="evenodd">
                <g id="Dashboard-v1" transform="translate(-1146.000000, -1234.000000)">
                    <path d="M1170,1234 L1170,1258 L1146,1258 L1146,1234 L1170,1234 Z M1162,1238 L1154,1238 C1151.83691,1238.1073 1150.1073,1239.83685 1150,1242 L1150,1250 C1150.1073,1252.16315 1151.83691,1253.8927 1154,1254 L1162,1254 C1164.16309,1253.8927 1165.8927,1252.16315 1166,1250 L1166,1242 C1165.8927,1239.83685 1164.16309,1238.1073 1162,1238 Z M1162,1240 C1163.05212,1240.11731 1163.88269,1240.94788 1164,1242 L1164,1250 C1163.88269,1251.05212 1163.05212,1251.88269 1162,1252 L1154,1252 C1152.94189,1251.89508 1152.10486,1251.05811 1152,1250 L1152,1242 C1152.11731,1240.94788 1152.94788,1240.11731 1154,1240 L1162,1240 Z M1158,1242 C1155.79089,1242 1154,1243.79083 1154,1246 C1154,1248.20917 1155.79089,1250 1158,1250 C1160.20911,1250 1162,1248.20917 1162,1246 C1162,1243.79083 1160.20911,1242 1158,1242 Z M1158,1244 C1159.10461,1244 1160,1244.89545 1160,1246 C1160,1247.10455 1159.10461,1248 1158,1248 C1156.89539,1248 1156,1247.10455 1156,1246 C1156,1244.89545 1156.89539,1244 1158,1244 Z M1162.145,1240.932 C1161.61481,1240.932 1161.185,1241.36181 1161.185,1241.892 C1161.185,1242.42219 1161.61481,1242.852 1162.145,1242.852 C1162.67519,1242.852 1163.105,1242.42219 1163.105,1241.892 C1163.105,1241.36181 1162.67519,1240.932 1162.145,1240.932 Z" id="path-1"></path>
                </g>
            </g>
        </svg>
    );
}

export default Instagram;
