import React from "react";
import { useTranslation } from "react-i18next";

function MoveUp(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.upload_document")} = props;

  return (
    <svg width="14px" height="16px" viewBox="0 0 14 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Tus-documentos---2" transform="translate(-410.000000, -418.000000)" fill={props.fill ? props.fill : "#DA291C"} fillRule="nonzero">
                <g id="Group" transform="translate(182.000000, 415.000000)">
                    <g id="move-up" transform="translate(228.000000, 3.000000)">
                        <path d="M6,3.4 L6,12 L8,12 L8,3.4 L12,7.4 L13.4,6 L7.7,0.3 C7.3,-0.1 6.7,-0.1 6.3,0.3 L0.6,6 L2,7.4 L6,3.4 Z" id="Path"></path>
                        <rect id="Rectangle" x="0" y="14" width="14" height="2"></rect>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  );
}

export default MoveUp;
