import React from "react";
import { useTranslation } from "react-i18next";

function PerfilDigitalBlog(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.blog")} = props;

    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Recursos" transform="translate(-358.000000, -1858.000000)">
                    <g id="ico_blog" transform="translate(358.000000, 1858.000000)">
                        <polygon id="Path-Copy-2" fill="#DA291C" points="24 1.96220357e-05 -4.08562073e-14 1.96220356e-05 -4.08562073e-14 24.0000196 24 24.0000196"></polygon>
                        <g id="blog" transform="translate(5.000000, 5.000000)" fill="#FFFFFF" fillRule="nonzero">
                            <rect id="Rectangle" x="0" y="8" width="14" height="2"></rect>
                            <rect id="Rectangle-Copy-5" x="0" y="4" width="14" height="2"></rect>
                            <rect id="Rectangle-Copy-6" x="0" y="0" width="14" height="2"></rect>
                            <rect id="Rectangle" x="0" y="12" width="10" height="2"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default PerfilDigitalBlog;