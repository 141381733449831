import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
    svg_syle: {
        height: 60,
        width: 64,
        [theme.breakpoints.down("sm")]: {            
            height: 30,
            width: 32,
        },
      },
}));

function ContratacionIcon(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { title = t("icons_tooltips.hire_data")} = props;

    return(
        <svg className={classes.svg_syle} viewBox="0 0 61 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Recursos" transform="translate(-495.000000, -1171.000000)" stroke="#000000" strokeWidth="4">
                    <g id="sign" transform="translate(495.000000, 1173.000000)">
                        <line x1="0" y1="60" x2="60" y2="60" id="Path"></line>
                        <path d="M8,48 L8,48 C5.238,45.238 5.238,40.762 8,38 L43.928,2.072 C46.69,-0.69 51.166,-0.69 53.928,2.072 L53.928,2.072 C56.69,4.834 56.69,9.31 53.928,12.072 L18,48 C15.238,50.762 10.762,50.762 8,48 Z" id="Path"></path>
                        <line x1="8" y1="48" x2="4" y2="52" id="Path"></line>
                        <polyline id="Path" points="46 38 58 26 54 22"></polyline>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default ContratacionIcon;