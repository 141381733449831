import React from "react";
import { useTranslation } from "react-i18next";

function YourExperience(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.your_experiences")} = props;

    return (
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="39-Tu-CV-sin-cubrir" transform="translate(-548.000000, -226.000000)" fill="#000000" fillRule="nonzero">
                    <g id="briefcase-26" transform="translate(548.000000, 226.000000)">
                        <path d="M15,13 L10,13 C10,13.6 9.6,14 9,14 L7,14 C6.4,14 6,13.6 6,13 L1,13 L1,15 C1,15.6 1.4,16 2,16 L14,16 C14.6,16 15,15.6 15,15 L15,13 Z" id="Path"></path>
                        <path d="M15,4 L12,4 L12,1 C12,0.4 11.6,0 11,0 L5,0 C4.4,0 4,0.4 4,1 L4,4 L1,4 C0.4,4 0,4.4 0,5 L0,11 C0,11.6 0.4,12 1,12 L6,12 L6,11 C6,10.4 6.4,10 7,10 L9,10 C9.6,10 10,10.4 10,11 L10,12 L15,12 C15.6,12 16,11.6 16,11 L16,5 C16,4.4 15.6,4 15,4 Z M10,4 L6,4 L6,2 L10,2 L10,4 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default YourExperience;