import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  custom_style: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

function DatosContracionIcon(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { title = t("icons_tooltips.documents") } = props;
  return (
    <svg
      className={classes.custom_style}
      width="55px"
      height="55px"
      viewBox="0 0 55 55"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      >
        <g
          id="Recursos"
          transform="translate(-745.000000, -1483.000000)"
          stroke="#DA291C"
          strokeWidth="4"
        >
          <g id="sign" transform="translate(747.000000, 1485.000000)">
            <line x1="0" y1="51" x2="51" y2="51" id="Path"></line>
            <path
              d="M6.77896387,41.2210361 L6.77896387,41.2210361 C4.40701204,38.8490843 4.40701204,35.0051826 6.77896387,32.6332308 L37.6332308,1.77896387 C40.0051826,-0.592987957 43.8490843,-0.592987957 46.2210361,1.77896387 L46.2210361,1.77896387 C48.592988,4.1509157 48.592988,7.99481736 46.2210361,10.3667692 L15.3667692,41.2210361 C12.9948174,43.592988 9.1509157,43.592988 6.77896387,41.2210361 Z"
              id="Path"
            ></path>
            <line x1="7" y1="41" x2="4" y2="44" id="Path"></line>
            <polyline id="Path" points="39 33 49 22.5 45.6666667 19"></polyline>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default DatosContracionIcon;
