import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { 
  setFormationToEditAction,
  setLanguageToEditAction,
  setPersonalDataAction,
  setCandidateWorkExperience,
  clearLanguageToEditAction, 
  clearFormationToEditAction, 
  setCandidateIdPhotography,
  clearCandidateIdPhotography} from "../actions/webCandidate"
import Curriculum from "../../Views/curriculum";
import { setTipoIdentificacion } from "../actions/maestroAction";

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setPersonalDataAction: bindActionCreators(setPersonalDataAction, dispatch),
      setFormationToEditAction: bindActionCreators(setFormationToEditAction, dispatch),
      setLanguageToEditAction: bindActionCreators(setLanguageToEditAction, dispatch),
      setCandidateWorkExperience: bindActionCreators(setCandidateWorkExperience, dispatch),
      clearLanguageToEditAction: bindActionCreators(clearLanguageToEditAction, dispatch),
      clearFormationToEditAction: bindActionCreators(clearFormationToEditAction, dispatch),
      setCandidateIdPhotography: bindActionCreators(setCandidateIdPhotography,dispatch),
      clearCandidateIdPhotography: bindActionCreators(clearCandidateIdPhotography, dispatch),
      setTipoIdentificacion: bindActionCreators(setTipoIdentificacion, dispatch),
    }
  };
}

function mapStateToProps(state) {
  return {
    state: state.webCandidate,
    locations:state?.maestros.localidades,
    tipo_identificacion:state?.maestros.tipoIdentificacion,
    countries:state?.maestros.paises
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Curriculum);
