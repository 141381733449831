import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { obtenerAlerta } from "../api/candidato";
import i18next from "../i18n.js";
import { getProvincias, isForeingCountry, returnLangId } from "../utils/functions";
import { withSnackbar } from "notistack";

function RedireccionAlerta(props) {
  const { actions } = props;
  const { t } = useTranslation();
  const [alerta, setAlerta] = useState(null)
  const query = window.location.search

  const dataListProvincias = getProvincias(i18next.languages[0]);

  useEffect(() => {
    if(!alerta){
      handleGetAlerta()
    }else{
      handleLoadAlerta()
    }
  }, [alerta]);

  async function handleGetAlerta(){
    let idAlerta = query.split('=').length >= 2 ? Number(query.split('=')[1]) : 0
    let response = await obtenerAlerta(idAlerta, returnLangId(i18next.languages[0]))
    if (response.status === 200 || response.status === 201) {
      const result = await response.json();
      setAlerta(result)
    } else {
      var result = await response.json();
      props.enqueueSnackbar(result.errorMessage ? result.errorMessage : t("common.alert_error"), {
          variant: "error",
          }
        );
      }
  }
  
  function handleLoadAlerta(){
    const data = {
      palabrasClave: alerta.palabrasClave,
      provincias: alerta.provincias,
      localidades: alerta.localidades,
      sectores: alerta.sectores,
      puestos: alerta.puestos,
      tiposJornada: alerta.tiposJornada,
      tiposContrato: alerta.tiposContrato,
      ciudades:alerta.ciudades,
    }
    actions.setSavedSearchAction(data);
    props.history.push(`search_offers/${alerta.provincias.length > 1 ? isForeingCountry(dataListProvincias,alerta.provincias)  : alerta.provincias}/${alerta.palabrasClave ? alerta.palabrasClave : ""}`)
  }

  return (
    <div/>
  );
}

export default withSnackbar(RedireccionAlerta);
