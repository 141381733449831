import React from "react";
import { useTranslation } from "react-i18next";

function PersonalData(props) {
    const { t } = useTranslation();
    const { color, title = t("icons_tooltips.personal_data")} = props;

    return (
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="39-Tu-CV-sin-cubrir" transform="translate(-184.000000, -466.000000)" fill="#000000" fillRule="nonzero">
                    <g id="Group-Copy" transform="translate(184.000000, 466.000000)">
                        <circle fill={color ? color : ""} id="Oval" cx="8" cy="4" r="4"></circle>
                        <path fill={color ? color : ""} d="M16,13.2005167 C16.0033678,12.4187396 15.5482998,11.7075074 14.836984,11.3828521 C12.6810924,10.4449101 10.3509975,9.97400112 8,10.0011071 C5.64900248,9.97400112 3.3189076,10.4449101 1.16301604,11.3828521 C0.451700241,11.7075074 -0.00336781562,12.4187396 0,13.2005167 L0,16 L16,16 L16,13.2005167 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default PersonalData;