import { UserManager } from "oidc-client";

const clientId = window._env_.OIDC_CLIENT_ID;
const redirect = window._env_.OIDC_REDIRECT_URI;
const scope = window._env_.OIDC_SCOPE;
const authority = window._env_.OIDC_AUTHORITY;
const issuer = window._env_.OIDC_ISSUER;
const auth_endpoint = window._env_.OIDC_AUTH_ENDPOINT;
const token_endpoint = window._env_.OIDC_TOKEN_ENDPOINT;
const jwks = window._env_.OIDC_JWS_URI;
const redirect_logout_uri = window._env_.OIDC_REDIRECT_LOGOUT_URI;

export class AuthService {
  constructor() {
    const settings = {
      // This is  the metadata endpoint
      authority: authority,

      // Turn off calls to user info since CORS will block it
      loadUserInfo: true,

      // The URL where the Web UI receives the login result
      redirect_uri: redirect,

      // The no longer recommended implicit flow must be used if CORS is disabled
      // If you want to use impicit flow use id_token instead of code for the return type.
      response_type: "code",

      // Other OAuth settings
      client_id: clientId,

      // openid is required, remove https://nortempob2cdev.onmicrosoft.com/test/Read if access_token is not required.
      scope: scope,
      // Supply these details explicitly. Directly copied from azure ad b2c policy metadata endpoint.
      metadata: {
        issuer: issuer,
        authorization_endpoint: auth_endpoint,
        token_endpoint: token_endpoint,
        jwks_uri: jwks,
        end_session_endpoint: redirect_logout_uri,
      },
    };
    this.userManager = new UserManager(settings);
  }

  getUser() {
    return this.userManager.getUser();
  }

  login() {
    return this.userManager.signinRedirect();
  }

  renewToken() {
    return this.userManager.signinSilent();
  }

  logout() {
    sessionStorage.removeItem("persist:root");
    return this.userManager.signoutRedirect();
  }

  removeUser() {
    return this.userManager.removeUser();
  }
}
