import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../components/loading";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  Box,
  Checkbox,
  Button,
  FormControl,
  Typography,
} from "@material-ui/core";
import AutocompleteWithLabel from "../components/autocompleteWithLabelAnimation";
import { saveBasicData, getBasicData } from "../api/candidato";
import Header from "../components/header";
import { withSnackbar } from "notistack";
import Snackbar from "../components/customSnackBar";
import i18next from "../i18n.js";
import {
  validatePhone,
  validateEmail,
  validateDocument,
  validateDate,
  getProvincias,
  getProvinceOptions,
  handleProvince,
  handleGetMaestro,
} from "../utils/functions";
import { AuthService } from "../auth/AuthService";
import jwt_decode from "jwt-decode";
import InputWithLabel from "../components/inputWithLabelAnimation";
import { optionEquality, returnLangId } from "../utils/functions";
import HeartIcon from "../assets/icons/heartIcon";
import DeleteChip from "../assets/icons/delete_chip";
import { getPlace } from "../api/maestro";
import DatePickerWithLabel from "../components/datePickerWithLabel";
import { ETT_URL, CURRICULUM_URL, SPAIN } from "../constants";
import useMaestros from "../Hooks/useMaestros";

const auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: window.innerHeight,
    background: "#FFFFFF",
    [theme.breakpoints.up("md")]: {
      height: "100vh",
    },
    display: "grid",
    gridTemplateColumns: "minmax(0, 1fr)",
    gridTemplateRows: "90px minmax(0, auto)",
    rowGap: "30px",
    gridTemplateAreas: "'header' 'body'",
  },
  body: {
    gridArea: "body",
    display: "grid",
    overflow: "auto",
    gridTemplateColumns:
      "minMax(0,1fr) clamp(1200px, 1300px, 1400px) minMax(0, 1fr)",
    gridTemplateAreas: "'leftMarg content rightMarg'",
    backgroundImage: "url(images/fondo1920x1080.jpg)",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateRows: "auto",
      gridTemplateColumns: "0% minMax(0, 1fr) 0%",
      backgroundImage: "none",
      backgroundAttachment: "none",
      backgroundPosition: "none",
      backgroundRepeat: "none",
      backgroundSize: "none",
    },
  },
  body_container: {
    display: "grid",
    gridArea: "content",
    gridTemplateAreas: "'titleArea filled' 'formArea filled' 'filled2 filled'",
    gridTemplateRows: "auto auto minmax(0, 1fr)",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto",
    },
  },
  title_container: {
    gridArea: "titleArea",
    marginBottom: "32px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
    },
  },
  form_container: {
    display: "grid",
    gridArea: "formArea",
    width: "100%",
    height: "auto",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridTemplateRows:
      "minmax(36px, auto) minmax(36px, auto) minmax(36px, auto)",
    gridColumnGap: "25px",
    gridRowGap: "25px",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection:"column",
      padding: "20px",
      alignItems: "initial",
    },
  },
 
  autocomplete_input_container: {
    width: "50%",
  },


  input_not_full_width: {
    width: "50%",
    marginLeft: "10px",
  },
  input: {
    width: (props) => (props.displayInline ? "35%" : "100%"),
  },

  no_margin: {
    margin: "0px",
  },
  save_button: {
    color: "#FFFFFF",
    fontWeight: "900",
    height: "36px",
    textTransform: "none",
    width: "75%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
      marginBottom: "30px",
      fontSize: "16px",
    },
    marginBottom: "35px",
  },
  save_button_fullWidth: {
    width: "100% !important",
  },
  double_input_container: {
    display: "flex",
    alignItems: "flex-end",
  },
  auto: {
    marginTop: "auto",
  },
  customChip: {
    fontSize: "0.8125rem",
    display: "inline-flex",
    height: "32px",
    boxSizing: "border-box",
    padding: 0,
    alignItems: "center",
    borderRadius: "16px",
    margin: "10px 5px 0px 5px",
    backgroundColor: "#EFEFEF",
    maxWidth: "98%",
  },
  nameChip: {
    paddingLeft: 12,
    paddingRight: 12,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  iconGeneral: {
    margin: "0 5px 0 -3px",
    width: 22,
    display: "flex",
    cursor: "pointer",
  },
  chip: {
    flexDirection:"column"
  }
}));

const provincias = getProvincias(i18next.languages[0]);

const identificationTypeNIF = "1";

function BasicData(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const maestros = useMaestros();
  const { paises, phonePrefixes, localidades } = props;
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const minDate = new Date().setFullYear(Number(new Date().getFullYear()) - 90);
  const maxDate = new Date();

  const [loading, setLoading] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [surname2, setSurname2] = useState("");
  const [identificationTypes,setIdentificationTypes] = useState([]);
  const [identificationType, setIdentificationType] = useState({
      "id": "1",
      "name":"NIF",
      "orden":"1"
    });
  const [identityDoc, setIdentityDoc] = useState("");
  const [phonePrefix, setPhonePrefix] = useState({
    "id": "724",
    "name": "España",
    "prefix": "34"
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [notificationsEmail, setNotificationsEmail] = useState("");
  const [fieldsWithErrors, setFieldsWithErrors] = useState([]);
  const [employments, setEmployments] = useState([]);
  const [employmentsOptions, setEmploymentsOptions] = useState(null);
  const [sendComunications, setSendComunications] = useState(true);
  const [isWorkInEtt, setIsWorkInEtt] = useState(false);
  const [fechaNacimiento, setFechaNacimiento] = useState(null);
  const [pais, setPais] = useState(null);
  const [provincia, setProvincia] = useState(null);
  const [localidad, setLocalidad] = useState(null);
  const [city, setCity] = useState("");
  const [errorBasicData, setErrorBasicData] = useState(false);

  function removeErrorFromList(field) {
    if (fieldsWithErrors.length > 0) {
      const newErrorsList = fieldsWithErrors.filter((error) => error !== field);
      setFieldsWithErrors(newErrorsList);
    }
  }

  useEffect(() => {
    handleGetMaestro(identificationTypes,setIdentificationTypes,maestros.getTipoIdentificacion());
  }, [identificationTypes]);

  useEffect(() => {
    setLoading(true);
    handleGetPlaces(returnLangId(i18next.languages[0]));
  }, []);

  useEffect(() => {
    if (employmentsOptions && identificationTypes) {
      handleGetDatosBasicos();
    }
  }, [employmentsOptions, identificationTypes]);

  useEffect(() => {
    if(fieldsWithErrors.includes("provincia")){
      setFieldsWithErrors(fieldsWithErrors.filter((item) => item !== "provincia"));
      }
  }, [pais]);

  async function handleGetDatosBasicos() {
    const response = await getBasicData(returnLangId(i18next.languages[0]));
    if (response.status === 200) {
      const result = await response.json();
      if (result.tieneDatosBasicos) {
        history.push(CURRICULUM_URL);
      }
      setName(result.nombre);
      setSurname(result.primerApellido);
      setSurname2(result.segundoApellido);
      if (result.tipoIdentificacion) {
        setIdentificationType(
          identificationTypes.find(
            (type) => parseInt(type.orden) === result.tipoIdentificacion
          )
        );
      }
      setIdentityDoc(result.numeroIdentificacion);
      if (result.telefono?.prefijo) {
        setPhonePrefix(
          phonePrefixes.find(
            (prefix) => prefix.prefix === result.telefono.prefijo.trim()
          )
        );
      }
      if (result.telefono?.numero) {
        setPhoneNumber(parseInt(result.telefono.numero.trim()));
      }
      if (!result.correoElectronico) {
        getDefaultMail();
      } else {
        setNotificationsEmail(result.correoElectronico);
      }
      if (
        result.puestosDeseados &&
        employmentsOptions &&
        employmentsOptions.length > 0
      ) {
        const data = [];
        result.puestosDeseados.map((p) => {
          const employ = employmentsOptions.find((e) => e.id === p.idPuesto);
          data.push({
            idPuesto: p.idPuesto,
            nombre: employ.nombre,
            esPrincipal: p.esPrincipal,
          });
        });
        setEmployments(data);
      }
      setFechaNacimiento(result.fechaNacimiento);
      setPais(
        result.idPais && paises.find((pais) => Number(pais.id) === Number(result.idPais))
          ? result.idPais
          : null
      );
      setProvincia(
        result.idProvincia
          ? provincias.find(
            (provincia) => Number(provincia.id) === Number(result.idProvincia)
          )?.id
          : null
      );
      setLocalidad(
        result.idLocalidad
          ? localidades.find(
            (localidad) => Number(localidad.id) === Number(result.idLocalidad)
          )
          : null
      );
    } else {
      setErrorBasicData(true);
      props.enqueueSnackbar(t("basicData.error_get_data"), {
        variant: "error",
      });
    }
    setLoading(false);
  }

  async function getDefaultMail() {
    const user = await auth.getUser();
    const token = await jwt_decode(user.access_token);
    if (token) {
      setNotificationsEmail(token.preferred_username);
    }
  }

  const getValidationErrors = () => {
    const errors = [];
    if (!name || name === "") {
      errors.push("name");
    }
    if (!surname || surname === "") {
      errors.push("surname");
    }
    if (identificationType?.id === identificationTypeNIF && surname2 === "") {
      errors.push("surname2");
    }
    if (
      !identityDoc ||
      !validateDocument(identityDoc, identificationType?.name)
    ) {
      errors.push("identityDoc");
    }
    if(!identificationType){
      errors.push("identificationTypeError");
    }
    if (!validatePhone(phoneNumber)) {
      errors.push("phoneNumber");
    }
    if (!validateEmail(notificationsEmail)) {
      errors.push("notificationsEmail");
    }
    if (employments?.length < 1) {
      errors.push("employments");
    }
    if (!fechaNacimiento || !validateDate(minDate, maxDate, fechaNacimiento) ) {
      errors.push("fechaNacimiento");
    }
    if (!pais) {
      errors.push("pais");
    }
    if (pais && !provincia) {
      errors.push("provincia");
    }
    if (pais !== SPAIN && !city) {
      errors.push("ciudad");
    }
    if (pais === SPAIN && !localidad) {
      errors.push("localidad");
    }

    setFieldsWithErrors(errors);

    return errors;
  };

  async function handleSaveData(haveEttExperience = false) {
    if (getValidationErrors().length === 0) {
      setLoading(true);
      let data = {
        nombre: name,
        primerApellido: surname,
        segundoApellido: surname2,
        tipoIdentificacion: Number(identificationType.id),
        numeroIdentificacion: identityDoc.toUpperCase(),
        telefono: {
          prefijo: phonePrefix.prefix,
          numero: phoneNumber.toString(),
        },
        correoElectronico: notificationsEmail,
        puestosDeseados: employments,
        fechaNacimiento: fechaNacimiento,
        idPais: Number(pais),
        idProvincia: Number(provincia),
        idLocalidad: Number(localidad) ? Number(localidad): null ,
        ciudad: city,
        recibirInformacion: sendComunications,
      };
      const response = await saveBasicData(data);
 
      if (response.status === 200) {
        setLoading(false);
        window.gtag("event", "conversion", {
          id_type: identificationType.id,
          id_number: identityDoc.toUpperCase(),
          send_to: window._env_.GA_MEASUREMENT_ID,
        });
        setSnackbarText(t("basicData.save_success"));
        setIsSnackBarOpen(true);
        if (haveEttExperience) history.push(ETT_URL);
        else history.push(CURRICULUM_URL);
      } else {
        switch (response.status) {
          case 400:
            props.enqueueSnackbar(
              t("basicData.save_error_incorrect_entry_data"),
              {
                variant: "error",
              }
            );
            break;
          case 404:
            props.enqueueSnackbar(
              t("basicData.save_error_candidate_not_found"),
              {
                variant: "error",
              }
            );
            break;
          case 409:
            props.enqueueSnackbar(t("basicData.save_error_conflict"), {
              variant: "error",
            });
            break;
          default:
            props.enqueueSnackbar(t("basicData.error"), {
              variant: "error",
            });
            break;
        }
      }
      setLoading(false);
    }
  }

  async function handleGetPlaces(languageId) {
    const response = await getPlace(languageId);
    if (response.status === 200) {
      const result = await response.json();
      setEmploymentsOptions(result.puestos);
    } else {
      setEmploymentsOptions([]);
      switch (response.status) {
        case 404:
          props.enqueueSnackbar(t("work_experience.get_places_not_found"), {
            variant: "error",
          });
          break;
        default:
          props.enqueueSnackbar(t("work_experience.get_place_error"), {
            variant: "error",
          });
          break;
      }
    }
  }

  function handleDeleteEmployment(data) {
    if (!data.esPrincipal) {
      setEmployments(
        employments.filter((element) => element.idPuesto !== data.idPuesto)
      );
    } else if (employments.length > 1) {
      let newsEmployments = employments.filter(
        (element) => element.idPuesto !== data.idPuesto
      );
      newsEmployments[0].esPrincipal = true;
      setEmployments(newsEmployments);
    } else {
      setEmployments([]);
    }
  }

  function handleDefaultEmployment(data, bool) {
    let values = employments.slice();
    const flag = employments.find((element) => element.esPrincipal === true);
    if (flag && flag.length !== 0) {
      for (let i = 0; i < values.length; i++) {
        if (employments[i].idPuesto === data.idPuesto) {
          values[i].esPrincipal = bool;
        }
        if (employments[i].idPuesto === flag.idPuesto) {
          values[i].esPrincipal = false;
        }
      }
    } else {
      for (let i = 0; i < values.length; i++) {
        if (employments[i].idPuesto === data.idPuesto) {
          values[i].esPrincipal = bool;
        }
      }
    }
    setEmployments([...values]);
  }

  function handleSetEmployments(value) {
    removeErrorFromList("employments");
    const someDefatult = employments.find(
      (element) => element.esPrincipal === true
    );
    if (
      value &&
      !employments.find(
        (element) => parseInt(element.idPuesto) === parseInt(value.id)
      )
    ) {
      if (!someDefatult) {
        setEmployments([
          ...employments,
          { idPuesto: value.id, nombre: value.nombre, esPrincipal: true },
        ]);
      } else {
        setEmployments([
          ...employments,
          { idPuesto: value.id, nombre: value.nombre, esPrincipal: false },
        ]);
      }
    }
  }

  function handleSetPais(value) {
    setPais(value);
    if (value !== SPAIN) {
      const provincia = provincias.find(
        (prov) => prov.id_country === value?.toString()
      );
      setProvincia(Number(provincia?.id));
      setLocalidad(
        provincia
          ? Number(
            localidades.find((loc) => loc.id_province === provincia.id).id
          )
          : null
      );
      setCity("");
    } else {
      setProvincia(null);
      setLocalidad(null);
    }
  }

  function renderForm() {
    return (
      <div className={classes.form_container}>
        <InputWithLabel
          wrong={fieldsWithErrors.includes("name")}
          customClass={classes.input}
          label={t("basicData.name")}
          value={name}
          setter={(e) => {
            setName(e.target.value);
            removeErrorFromList("name");
          }}
          moveLabel={true}
          maxLength={100}
        />
        <InputWithLabel
          wrong={fieldsWithErrors.includes("surname")}
          customClass={classes.input}
          label={t("basicData.surname")}
          value={surname}
          setter={(e) => {
            setSurname(e.target.value);
            removeErrorFromList("surname");
          }}
          moveLabel={true}
          maxLength={100}
        />
        <InputWithLabel
          wrong={fieldsWithErrors.includes("surname2")}
          customClass={classes.input}
          name="surname2"
          label={t("basicData.surname2")}
          value={surname2}
          setter={(e) => {
            setSurname2(e.target.value);
            removeErrorFromList("surname2");
          }}
          moveLabel={true}
          maxLength={100}
        />
        <div className={classes.double_input_container}>
          <FormControl
            variant="outlined"
            className={classes.autocomplete_input_container}
          >
            <AutocompleteWithLabel
              id="autocomplete-identification-types"
              options={identificationTypes}
              getLabel={(option) => option.name}
              getOption={(x, v) => optionEquality(x, v)}
              disableClearable={true}
              value={identificationType}
              setter={setIdentificationType}
              customClassWrapper={classes.auto}
              wrong={fieldsWithErrors.includes("identificationTypeError")}
            />
          </FormControl>
          <InputWithLabel
            wrong={fieldsWithErrors.includes("identityDoc")}
            customClassWrapper={classes.input_not_full_width}
            label={t("basicData.identityDoc")}
            value={identityDoc}
            setter={(e) => {
              setIdentityDoc(e.target.value.toLocaleUpperCase());
              removeErrorFromList("identityDoc");
            }}
            moveLabel={true}
            maxLength={20}
          />
        </div>
        <div className={classes.double_input_container}>
          <FormControl
            variant="outlined"
            className={classes.autocomplete_input_container}
          >
            <AutocompleteWithLabel
              id="autocomplete-phone-prefixes"
              options={phonePrefixes}
              getLabel={(x) =>
                typeof x === "object" && x !== null
                  ? `${x.name} +${x.prefix}`
                  : x
              }
              disableClearable={true}
              value={phonePrefix}
              setter={setPhonePrefix}
              customClassWrapper={classes.auto}
            />
          </FormControl>
          <InputWithLabel
            wrong={fieldsWithErrors.includes("phoneNumber")}
            customClassWrapper={classes.input_not_full_width}
            label={t("basicData.phoneNumber")}
            value={phoneNumber}
            setter={(e) => {
              setPhoneNumber(e.target.value);
              removeErrorFromList("phoneNumber");
            }}
            moveLabel={true}
            maxLength={10}
            type="number"
          />
        </div>
        <InputWithLabel
          wrong={fieldsWithErrors.includes("notificationsEmail")}
          customClass={classes.input}
          label={t("basicData.email")}
          value={notificationsEmail}
          setter={(e) => {
            setNotificationsEmail(e.target.value);
            removeErrorFromList("notificationsEmail");
          }}
          moveLabel={true}
          maxLength={100}
        />
        <DatePickerWithLabel
          customClass={classes.date_picker_class}
          label={t("edit_personal_data.birthdate")}
          value={fechaNacimiento}
          setter={(value) => {
            setFechaNacimiento(value);
          }}
          minDate={minDate}
          maxDate={maxDate}
          wrong={fieldsWithErrors.includes("fechaNacimiento")}
        />
        <AutocompleteWithLabel
          id={"pais_autocomplete"}
          autoComplete={"new-password"}
          useDefaultIcon={true}
          value={pais}
          setter={(value) => {
            handleSetPais(value ? Number(value.id) : null);
          }}
          label={t("edit_personal_data.country_placeholder")}
          options={paises}
          getOption={(x, v) => optionEquality(x, v)}
          getLabel={(x) =>
            typeof x === "object" && x !== null
              ? x.name
              : x
                ? paises.find((n) => Number(n.id) === Number(x))?.name ?? ""
                : ""
          }
          moveLabel={true}
          wrong={fieldsWithErrors.includes("pais")}
        />
        <div className={classes.double_input_container}>
          <FormControl
            variant="outlined"
            className={classes.autocomplete_input_container}
          >
            <AutocompleteWithLabel
              id={"provincia_autocomplete"}
              autoComplete={"new-password"}
              useDefaultIcon={true}
              disabled={!pais}
              value={pais ? provincia : ""}
              setter={(value) => {
                setProvincia(value ? Number(value.id) : null);
                setLocalidad(null);
              }}
              label={pais === SPAIN || !pais? t("edit_personal_data.province_placeholder") : t("edit_personal_data.region")}
              options={getProvinceOptions(provincias,pais,paises)}
              getOption={(x, v) => optionEquality(x, v)}
              getLabel={(x) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? handleProvince(provincias,paises,x, pais)
                  : ""
              }
              moveLabel={true}
              customClassWrapper={classes.auto}
              wrong={fieldsWithErrors.includes("provincia")}
            />
          </FormControl>
          {pais === SPAIN || !pais ?
            <AutocompleteWithLabel
              autoComplete={"new-password"}
              useDefaultIcon={true}
              disabled={!pais || !provincia || pais !== SPAIN}
              value={pais && provincia ? localidad : null}
              setter={(value) => {
                setLocalidad(value ? Number(value.id) : null);
              }}
              label={t("edit_personal_data.city_placeholder")}
              options={localidades?.filter(
                (loc) => Number(loc.id_province) === Number(provincia)
              )}
              getOption={(x, v) => optionEquality(x, v)}
              getLabel={(x) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                    ? localidades.find((n) => Number(n.id) === Number(x))?.name ??
                    ""
                    : ""
              }
              moveLabel={true}
              customClassWrapper={classes.input_not_full_width}
              wrong={fieldsWithErrors.includes("localidad")}
            />
            :
            <InputWithLabel
              customClassWrapper={classes.input_not_full_width}
              label={t("edit_personal_data.city_abroad")}
              value={city}
              setter={(e) => {
                setCity(e.target.value);
              }}
              moveLabel={true}
              wrong={fieldsWithErrors.includes("ciudad")}
            />
          }
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", gridColumn: "1/4" }}
        >
          <div style={{ width: "300px" }}>
            <AutocompleteWithLabel
              options={
                employmentsOptions
                  ? employmentsOptions.filter(
                    (o) => !employments.find((e) => e.idPuesto === o.id)
                  )
                  : []
              }
              value={employments}
              label={t("basicData.employments")}
              setter={handleSetEmployments}
              moveLabel={true}
              maxLength={100}
              getLabel={(option) => option.nombre}
              getOption={(x, v) => optionEquality(x, v)}
              disableClearable={true}
              customClassWrapper={classes.auto}
              disabled={!employmentsOptions || employments?.length === 3}
              wrong={
                fieldsWithErrors.includes("employments") &&
                employments?.length < 1
              }
            />
          </div>
          <div className={classes.chip}>
            {employments.map((data) => {
              return (
                <div
                  className={classes.customChip}
                  key={`${data.nombre}, ${data.id}`}
                >
                  <div className={classes.nameChip}>{data.nombre}</div>
                  <div className={classes.iconGeneral}>
                    {data.esPrincipal ? (
                      <HeartIcon title={t("icons_tooltips.favorite")} fill />
                    ) : (
                      <HeartIcon
                        title={t("icons_tooltips.favorite")}
                        fill={false}
                        onClick={() => handleDefaultEmployment(data, true)}
                      />
                    )}
                  </div>
                  <div className={classes.iconGeneral}>
                    <DeleteChip
                      title={t("icons_tooltips.delete")}
                      onClick={() => handleDeleteEmployment(data)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          style={{ display: "flex", gridColumn: "1/4", alignItems: "center" }}
        >
          <Checkbox
            checked={sendComunications}
            onChange={(e) => setSendComunications(e.target.checked)}
            color="primary"
            style={{ paddingLeft: 0 }}
          />
          <Typography variant="body1">{t("basicData.checkInfo")}</Typography>
        </div>
        <div
          style={{ display: "flex", gridColumn: "1/4", alignItems: "center" }}
        >
          <Checkbox
            checked={isWorkInEtt}
            onChange={(e) => setIsWorkInEtt(e.target.checked)}
            color="primary"
            style={{ paddingLeft: 0 }}
          />
          <Typography variant="body1">{t("basicData.checkEtt")}</Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          className={`${classes.save_button} ${
            matchesMobile ? classes.save_button_fullWidth : ""
          }`}
          fullWidth={matchesMobile}
          onClick={() =>
            isWorkInEtt ? handleSaveData(true) : handleSaveData()
          }
        >
          {t("basicData.save")}
        </Button>
      </div>
    );
  }

  function tittle() {
    return (
      <div>
        <Box
          display="block"
          fontSize={36}
          lineHeight={"46px"}
          fontWeight="900"
          m={1}
          className={classes.no_margin}
        >
          {t("basicData.thanks")}
        </Box>
      </div>
    );
  }

  function tittle_text() {
    return (
      <div>
        <Box
          display="inline-block"
          fontSize={16}
          lineHeight={"24px"}
          m={1}
          className={classes.no_margin}
        >
          {t("basicData.intro")}
        </Box>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Snackbar
        text={snackbarText}
        isSnackBarOpen={isSnackBarOpen}
        handleIsSnackBarOpen={setIsSnackBarOpen}
      />
      <Header menuItems={false} />
      <div className={classes.body}>
        <div className={classes.body_container}>
          {!loading ? (
            !errorBasicData && (
              <>
                <div className={classes.title_container}>
                  {tittle()}
                  {tittle_text()}
                </div>
                {renderForm()}
              </>
            )
          ) : (
            <Loading height={64} width={64} />
          )}
        </div>
      </div>
    </div>
  );
}

export default withSnackbar(BasicData);
