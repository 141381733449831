import { withSnackbar } from "notistack";
import React, { useEffect, useMemo,createContext } from "react";
import { getDataMaestros } from "../utils/functions";
import { getLocalidades, getPaises } from "../api/maestro";


export const MaestroContext = createContext({});

function MaestrosProvider(props) {
    const { actions, state, enqueueSnackbar} = props;
    const paises = state?.paises?.length;
    const localidades = state?.localidades?.length;
    const tipoHorario = state?.tipoHorario;
    const tipoIdentificacion = state?.tipoIdentificacion;

    useEffect(() => {
        if (!paises) {
            getDataMaestros(getPaises, actions.setPaises, enqueueSnackbar);
        }
    }, [paises]);

    useEffect(() => {
        if (!localidades) {
            getDataMaestros(getLocalidades, actions.setLocalidades, enqueueSnackbar);
        }
    }, [localidades]);

    const value = useMemo(() => {
        return { paises, 
                 localidades, 
                 tipoHorario,
                 tipoIdentificacion,
                 actions,
                 enqueueSnackbar 
                };
    }, [tipoHorario, paises, localidades,tipoIdentificacion]);

    return (
        <MaestroContext.Provider value={value}>
           {props.children}
        </MaestroContext.Provider>
    );
}

export default withSnackbar(MaestrosProvider)