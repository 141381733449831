import * as types from "./actionTypes";

export function setPaises(data) {
    return function (dispatch) {
      dispatch({ type: types.SET_PAISES, payload: data });
    };
  }

export function setLocalidades(data) {
  return function (dispatch) {
      dispatch({ type: types.SET_LOCALIDADES, payload: data });
    };
  }
  
  export function setTipoIdentificacion(data) {
    return function (dispatch) {
        dispatch({ type: types.SET_TIPO_IDENTIFICACION, payload: data });
      };
  }

  export function setTipoHorario(data) {
    return function (dispatch) {
        dispatch({ type: types.SET_TIPO_HORARIO, payload: data });
      };
  }