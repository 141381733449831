import React from "react";
import { useTranslation } from "react-i18next";

function Sign(props) {
  const { t } = useTranslation();
  const { title = t("icons_tooltips.contract_type")} = props;

  return(
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" >
      <title>{title}</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Recursos" transform="translate(-371.000000, -1787.000000)" fill="#000000" fillRule="nonzero">
            <g id="sign" transform="translate(371.000000, 1787.000000)">
                <rect id="Rectangle" x="0" y="14" width="16" height="2"></rect>
                <path d="M10.5,5.9 L11.3,6.7 L8.3,9.7 L9.7,11.1 L13.4,7.4 C13.8,7 13.8,6.4 13.4,6 L11.9,4.5 L12.3,4.1 C13.2,3.2 13.2,1.6 12.3,0.7 C11.4,-0.2 9.8,-0.2 8.9,0.7 L1,8.6 L1,12 L4.4,12 L10.5,5.9 Z" id="Path"></path>
            </g>
        </g>
    </g>
  </svg>
  );
}

export default Sign;