import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../components/basicLayout";
import Snackbar from "../components/customSnackBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import TuCurriculumHeader from "../components/tuCurriculumHeader";
import { useTranslation } from "react-i18next";
import Add from "../assets/icons/cAdd";
import AddToCVActionButtons from "../components/addToCVActionButtons";
import YourLanguages from "../assets/icons/yourLanguages";
import Autocomplete from "../components/autocompleteWithLabelAnimation";
import i18next from "../i18n.js";
import { withSnackbar } from "notistack";
import { getIdiomasList, getNivelesIdioma, optionEquality, returnLangId } from "../utils/functions";
import { FormControl, Chip, Typography } from "@material-ui/core";
import AntSwitch from "../components/antSwitch";
import DatePickerWithLabel from "../components/datePickerWithLabel";
import AlertDialog from "../components/alertDialog";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { addLanguage, updateLanguage, deleteLanguage } from "../api/candidato";
import { getCertificateLanguages } from "../api/maestro";
import { MAX_CHAR_COMMENTS } from "../constants";
import InputWithLabel from "../components/inputWithLabelAnimation";

const idiomas_list=getIdiomasList(i18next.languages[0]);
const niveles_idioma=getNivelesIdioma(i18next.languages[0]);

const useStyles = makeStyles((theme) => ({
  principal_container: {
    gridArea: "body_container",
    display: "grid",
    gridTemplateColumns: "30% 65%",
    columnGap: "20px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "auto",
    },
  },
  col2: {
    marginTop: "100px",
    padding: 20,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "60px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },
  data_sections_container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "50px",
  },
  data_section_icon_and_title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    marginBottom: "20px",
  },
  data_section_title_principal: {
    fontSize: 22,
    fontWeight: 900,
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  render_basic_data_style: {
    display: "flex",
    flexDirection: "column",
  },
  basic_data_grid: {
    display: "grid",
    gridTemplateColumns: ".5fr .5fr",
    columnGap: "26px",
    rowGap: "20px",
    alignItems: "end",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      rowGap: "20px",
    },
  },
  certifications_container: {
    display: "flex",
    flexDirection: "column",
  },
  certifications_grid: {
    display: "grid",
    gridTemplateColumns: ".5fr .5fr",
    gridTemplateAreas: `
            'switch .'
            'certificate date'`,
    columnGap: "26px",
    rowGap: "20px",
    alignItems: "end",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      rowGap: "20px",
      gridTemplateAreas: "unset",
    },
  },
  text_switch: {
    gridArea: "switch",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      gridArea: "unset",
    },
  },
  add_certification_row: {
    margin: "auto 0 auto auto",
    cursor: "pointer",
  },
  delete_row: {
    display: "grid",
    gridTemplateColumns: ".5fr .5fr",
    columnGap: "25px",
    gridTemplateAreas: `'. delete'`,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  delete_link: {
    justifySelf: "center",
    fontSize: 14,
    color: "#DA291C",
    gridArea: "delete",
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      opacity: "0.8",
    },
  },
  chip: {
    backgroundColor: "#EFEFEF",
    marginTop: "10px",
    maxWidth: "100%",
  },
  certificate: {
    gridArea: "certificate",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      gridArea: "unset",
    },
  },
  certificate_date: {
    gridArea: "date",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      gridArea: "unset",
    },
    display: "flex",
  },
  date_picker_class: {
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "94%",
    },
  },
  counter_chars_description:{
    fontSize: 12,
    color: "#D8D8D8",
  },
  comments: {
    marginTop: 20
  }
}));

function Idiomas(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { actions, state } = props;

  const [loading, setLoading] = useState(false);
  const [snackbarText, setSnackbarText] = useState(null);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [nombre, setNombre] = useState("");
  const [primerApellido, setPrimerApellido] = useState("");
  const [segundoApellido, setSegundoApellido] = useState("");
  const [correoElectronico, setCorreoElectronico] = useState("");
  const [prefijo, setPrefijo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [idFotografia, setIdFotografia] = useState(null);
  const [fechaModificacion, setFechaModificacion] = useState(null);

  const [idIdioma, setIdIdioma] = useState(null);
  const [idNivelHablado, setIdNivelHablado] = useState(null);
  const [idNivelEscrito, setIdNivelEscrito] = useState(null);
  const [idNivelLeido, setIdNivelLeido] = useState(null);
  const [tieneCertificacion, setTieneCertificacion] = useState(false);
  const [certificados, setCertificados] = useState([]);
  const [comentarios, setComentarios] = useState("");

  const [newCertificados, setNewCertificados] = useState(null);
  const [newCertificado, setNewCertificado] = useState(null);
  const [newCertificadoDate, setNewCertificadoDate] = useState(null);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [certificadosError, setCertificadosError] = useState(null);

  const minDate = new Date().setFullYear(Number(new Date().getFullYear()) - 90);
  const [saveWrong, setSaveWrong] = useState(false);

  useEffect(() => {
    if (state && state.personalData) {
      handlePersonalDataAction(state.personalData);
    }
  }, [state.personalData]);

  useEffect(() => {
    if (state && state.languageData) {
      handleEditMode(state.languageData);
    }
  }, [state.languageData]);

  useEffect(() => {
    if (newCertificados && newCertificados.length < 1) {
      setTieneCertificacion(false);
    }
  }, [newCertificados]);

  useEffect(() => {
    if (idIdioma) {
      handleGetCertificados(idIdioma);
      setCertificados([]);
    }
  }, [idIdioma]);

  async function handleGetCertificados(idIdioma) {
    const response = await getCertificateLanguages("", "", idIdioma);
    if (response.status === 200) {
      let result = await response.json();
      setCertificados(
        result.certificadosIdioma.map((cert) => ({
          id: cert.id,
          idIdioma: cert.idIdioma,
          name: cert.nombre,
        }))
      );
    } else {
      if (response.status !== 404) {
        setCertificadosError(t("add_language.get_certificates_error"));
      }
    }
  }

  async function addNewLanguage(isGoBack) {
    var data = setDataToSave();
    setLoading(true);
    const response = await addLanguage(
      data,
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      setSnackbarText(t("add_language.language_added"));
      setIsSnackBarOpen(true);
      if (isGoBack) {
        props.history.goBack();
      } else {
        emptyForm();
        setLoading(false);
      }
    } else {
      var result = await response.json();
      setLoading(false);
      props.enqueueSnackbar(
        result.errorMessage
          ? result.errorMessage
          : t("add_language.adding_error"),
        {
          variant: "error",
        }
      );
    }
  }

  async function editLanguage(isGoBack) {
    var data = setDataToSave();
    setLoading(true);
    const response = await updateLanguage(
      data,
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      setSnackbarText(t("add_language.language_updated"));
      setIsSnackBarOpen(true);
      actions.clearLanguageToEditAction();
      if (isGoBack) {
        props.history.goBack();
      } else {
        emptyForm();
        setLoading(false);
      }
    } else {
      var result = await response.json();
      setLoading(false);
      props.enqueueSnackbar(
        result.errorMessage
          ? result.errorMessage
          : t("add_language.update_error"),
        {
          variant: "error",
        }
      );
    }
  }

  async function deleteLang() {
    setLoading(true);
    const response = await deleteLanguage(
      idIdioma,
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      props.history.goBack();
    } else {
      setLoading(false);
      props.enqueueSnackbar(t("add_formation.delete_error"), {
        variant: "error",
      });
    }
  }

  const setDataToSave = () => {
    let certificadosToSave;
    if (canAddValidCertificado()) {
      var isObject = typeof newCertificado === "object";
      const certificado = {
        idIdioma: idIdioma,
        nombreCertificacion: isObject ? newCertificado.name : newCertificado,
        idCertificacion: isObject ? newCertificado.id : 0,
        año: newCertificadoDate.getFullYear().toString(),
      };
      certificadosToSave = [...newCertificados, certificado];
    } else {
      certificadosToSave = [...newCertificados];
    }

    var data = {
      idIdioma: idIdioma,
      idNivelHablado: idNivelHablado,
      idNivelEscrito: idNivelEscrito,
      idNivelLeido: idNivelLeido,
      tieneCertificacion: tieneCertificacion,
      certificados: certificadosToSave,
      comentarios: comentarios
    };
    return data;
  };

  function handlePersonalDataAction(data) {
    setNombre(data.nombre);
    setPrimerApellido(data.primerApellido);
    setSegundoApellido(data.segundoApellido);
    setCorreoElectronico(data.correoElectronico);
    setPrefijo(data.prefijo);
    setTelefono(data.telefono);
    setIdFotografia(data.idFotografia);
    setFechaModificacion(data.fechaModificacion);
  }

  function handleEditMode(idioma) {
    setIsEditMode(true);
    setIdIdioma(idioma.idIdioma);
    setIdNivelEscrito(idioma.idNivelEscrito);
    setIdNivelHablado(idioma.idNivelHablado);
    setIdNivelLeido(idioma.idNivelLeido);
    setTieneCertificacion(idioma.tieneCertificacion);
    setNewCertificados(idioma.certificados);
    setComentarios(idioma?.comentarios);
  }

  function canAddValidCertificado() {
    var isValid = true;
    if (
      !idIdioma ||
      !newCertificado ||
      !newCertificadoDate ||
      (newCertificadoDate &&
        newCertificadoDate.getFullYear() > new Date().getFullYear()) ||
      (newCertificadoDate && newCertificadoDate.getFullYear() < 1951) ||
      (newCertificadoDate && isNaN(newCertificadoDate.getTime())) ||
      newCertificados.find((x) => x.nombreCertificacion === newCertificado.name)
    ) {
      return false;
    }
    return true;
  }

  function validateAddCertificado() {
    var isValid = true;
    if (!idIdioma) {
      isValid = false;
      props.enqueueSnackbar(t("add_language.select_language_first"), {
        variant: "error",
      });
    }
    if (!newCertificado) {
      isValid = false;
      props.enqueueSnackbar(t("add_language.select_certificate_first"), {
        variant: "error",
      });
    }
    if (!newCertificadoDate) {
      isValid = false;
      props.enqueueSnackbar(t("add_language.select_date_first"), {
        variant: "error",
      });
    }
    if (
      (newCertificadoDate &&
        newCertificadoDate.getFullYear() > new Date().getFullYear()) ||
      (newCertificadoDate && newCertificadoDate.getFullYear() < 1951)
    ) {
      isValid = false;
      props.enqueueSnackbar(t("add_language.invalid_date_certificate"), {
        variant: "error",
      });
    }
    if (newCertificadoDate && isNaN(newCertificadoDate.getTime())) {
      isValid = false;
      props.enqueueSnackbar(t("add_language.invalid_date_certificate"), {
        variant: "error",
      });
    }
    if (
      newCertificados.find((x) => x.nombreCertificacion === newCertificado.name)
    ) {
      isValid = false;
      props.enqueueSnackbar(t("add_language.existing_certificate_error"), {
        variant: "error",
      });
    }
    if (isValid) addCertificado();
  }

  function validateNewLanguage(isGoBack) {
    if (!idIdioma || !idNivelEscrito || !idNivelLeido || !idNivelHablado) {
      setSaveWrong(true);
      props.enqueueSnackbar(t("common.data_error"), {
        variant: "error",
      });
    } else if (
      tieneCertificacion &&
      newCertificados.length === 0 &&
      (!newCertificado || !newCertificadoDate)
    ) {
      setSaveWrong(true);
      props.enqueueSnackbar(t("add_language.certificate_empty"), {
        variant: "error",
      });
    } else {
      isEditMode ? editLanguage(isGoBack) : addNewLanguage(isGoBack);
    }
  }

  function addCertificado() {
    var isObject = typeof newCertificado === "object";
    var data = {
      idIdioma: idIdioma,
      nombreCertificacion: isObject ? newCertificado.name : newCertificado,
      idCertificacion: isObject ? newCertificado.id : 0,
      año: newCertificadoDate.getFullYear().toString(),
    };
    setNewCertificados([...newCertificados, data]);
    setNewCertificado(null);
    setNewCertificadoDate(null);
  }

  function deleteCertificado(cert) {
    var auxList = [...newCertificados];
    var index = newCertificados.indexOf(cert);
    if (index !== -1) {
      auxList.splice(index, 1);
      setNewCertificados(auxList);
    }
  }

  function emptyForm() {
    setIsEditMode(false);
    setIdIdioma(null);
    setIdNivelHablado(null);
    setIdNivelLeido(null);
    setIdNivelEscrito(null);
    setNewCertificadoDate(null);
    setNewCertificado(null);
    setNewCertificados([]);
    setTieneCertificacion(false);
    setComentarios("");
  }

  function handleChangeIdioma(value) {
    setIdIdioma(value ? Number(value.id) : null);
    setIdNivelHablado(null);
    setIdNivelLeido(null);
    setIdNivelEscrito(null);
    setNewCertificadoDate(null);
    setNewCertificado(null);
    setNewCertificados([]);
    setComentarios("");
  }

  function renderBasicInfo() {
    return (
      <div className={classes.render_basic_data_style}>
        <div className={classes.data_section_icon_and_title}>
          <YourLanguages />
          <div className={classes.data_section_title_principal}>
            {isEditMode
              ? t("add_language.title_edit")
              : t("add_language.title")}
          </div>
        </div>
        <div className={classes.basic_data_grid}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              useDefaultIcon={true}
              disabled={isEditMode}
              value={idIdioma}
              setter={handleChangeIdioma}
              options={idiomas_list}
              label={t("add_language.language")}
              multiple={false}
              freeSolo={false}
              getOption={(x, v) => optionEquality(x, v)}
              getLabel={(x) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? idiomas_list.find((s) => Number(s.id) === Number(x))
                      ?.name ?? ""
                  : ""
              }
              moveLabel={true}
              wrong={saveWrong && !idIdioma}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              useDefaultIcon={true}
              value={idNivelEscrito}
              setter={(value) =>
                setIdNivelEscrito(value ? Number(value.id) : null)
              }
              options={niveles_idioma}
              label={t("add_language.write_level")}
              multiple={false}
              freeSolo={false}
              getOption={(x, v) => optionEquality(x, v)}
              getLabel={(x) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? niveles_idioma.find((s) => Number(s.id) === Number(x))
                      ?.name ?? ""
                  : ""
              }
              moveLabel={true}
              wrong={saveWrong && !idNivelEscrito}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              useDefaultIcon={true}
              value={idNivelLeido}
              setter={(value) =>
                setIdNivelLeido(value ? Number(value.id) : null)
              }
              options={niveles_idioma}
              label={t("add_language.read_level")}
              multiple={false}
              freeSolo={false}
              getOption={(x, v) => optionEquality(x, v)}
              getLabel={(x) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? niveles_idioma.find((s) => Number(s.id) === Number(x))
                      ?.name ?? ""
                  : ""
              }
              moveLabel={true}
              wrong={saveWrong && !idNivelLeido}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              useDefaultIcon={true}
              value={idNivelHablado}
              setter={(value) =>
                setIdNivelHablado(value ? Number(value.id) : null)
              }
              options={niveles_idioma}
              label={t("add_language.talk_level")}
              multiple={false}
              freeSolo={false}
              getOption={(x, v) => optionEquality(x, v)}
              getLabel={(x) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? niveles_idioma.find((s) => Number(s.id) === Number(x))
                      ?.name ?? ""
                  : ""
              }
              moveLabel={true}
              wrong={saveWrong && !idNivelHablado}
            />
          </FormControl>
        </div>
        <div className={classes.comments}>
         <InputWithLabel
           multiline={true}
           rows={3}
           fullWidth
           label={t("add_language.comments")}
           placeholder={t("add_language.comments_placeholder")}
           value={comentarios}
           setter={(e)=>setComentarios(e.target.value)}
           moveLabel={true}
           minLength={0}
           maxLength={MAX_CHAR_COMMENTS}
         />
         <Typography className={classes.counter_chars_description}>
           {`${comentarios?.length ?? 0}/${MAX_CHAR_COMMENTS}`}
         </Typography>
       </div>
      </div>
    );
  }

  const handleCertificadosError = () => {
    if (certificadosError) {
      props.enqueueSnackbar(certificadosError, {
        variant: "error",
      });
    }
  };

  function renderCertificationData() {
    return (
      <div className={classes.certifications_container}>
        <div className={classes.certifications_grid}>
          <div className={classes.text_switch}>
            <div>{t("add_language.got_certification")}</div>
            <AntSwitch
              checked={tieneCertificacion}
              disabled={!idIdioma}
              setter={() => setTieneCertificacion(!tieneCertificacion)}
            />
          </div>
          {tieneCertificacion && (
            <div className={classes.certificate}>
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  useDefaultIcon={true}
                  error={tieneCertificacion && !newCertificado}
                  disabled={!tieneCertificacion}
                  value={newCertificado}
                  setter={setNewCertificado}
                  options={
                    idIdioma
                      ? certificados.filter(
                          (cert) => cert.idIdioma === idIdioma
                        )
                      : []
                  }
                  label={t("add_language.certification")}
                  multiple={false}
                  freeSolo={true}
                  getOption={(x, v) => optionEquality(x, v)}
                  getLabel={(x) => x.name || x}
                  onOpen={handleCertificadosError}
                  maxLength={50}
                  moveLabel={true}
                />
              </FormControl>
            </div>
          )}
          {tieneCertificacion && (
            <div className={classes.certificate_date}>
              <DatePickerWithLabel
                views={["year"]}
                yearOnly={true}
                disabled={!tieneCertificacion}
                customClass={classes.date_picker_class}
                placeholder={t("add_language.certification_date")}
                value={newCertificadoDate}
                setter={(value) => {
                  setNewCertificadoDate(value);
                }}
                minDate={minDate}
                maxDate={new Date()}
                debug={false}
              />
              <div
                className={classes.add_certification_row}
                onClick={() => validateAddCertificado()}
              >
                <Add title={t("icons_tooltips.add")} />
              </div>
            </div>
          )}
        </div>
        {tieneCertificacion && (
          <div className={classes.chips_container}>
            {newCertificados.map((cert, index) => (
              <Chip
                key={index}
                label={`${cert?.nombreCertificacion}, ${cert?.año}`}
                deleteIcon={
                  <HighlightOffIcon title={t("icons_tooltips.remove")} />
                }
                onDelete={() => deleteCertificado(cert)}
                color="secondary"
                classes={{
                  root: classes.chip,
                }}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  function renderDeleteLink() {
    return (
      <div className={classes.delete_row}>
        <div
          onClick={() => setIsDialogOpen(true)}
          className={classes.delete_link}
        >
          {t("add_language.delete")}
        </div>
      </div>
    );
  }

  return (
    <Layout history={props.history} loading={loading}>
      <Snackbar
        text={snackbarText}
        isSnackBarOpen={isSnackBarOpen}
        handleIsSnackBarOpen={setIsSnackBarOpen}
      />
      <div className={classes.principal_container}>
        {!matchesMobile ? (
          <TuCurriculumHeader
            nombre={nombre}
            primerApellido={primerApellido}
            segundoApellido={segundoApellido}
            correoElectronico={correoElectronico}
            prefijo={prefijo}
            telefono={telefono}
            fechaModificacion={fechaModificacion}
          />
        ) : (
          <div />
        )}
        <div className={classes.col2}>
          <div className={classes.data_sections_container}>
            {renderBasicInfo()}
            {renderCertificationData()}
          </div>
          <AddToCVActionButtons
            saveAndActionText={t("add_language.save_and_add_other")}
            onSaveClick={() => validateNewLanguage(true)}
            onSaveAndActionClick={() => validateNewLanguage(false)}
            onCancelClick={() => {
              props.history.goBack();
            }}
            isUpdate={isEditMode}
          />
          {isEditMode && renderDeleteLink()}
        </div>
      </div>
      <AlertDialog
        title={t("add_language.delete_confirm")}
        open={isDialogOpen}
        handleClose={() => {
          setIsDialogOpen(false);
        }}
        handleAccept={() => {
          deleteLang();
          setIsDialogOpen(false);
        }}
      />
    </Layout>
  );
}

export default withSnackbar(Idiomas);
