//import "react-app-polyfill/ie11"; //comment before upload to dev
import React from "react";
import ReactDOM from "react-dom";
import Root from "./root";
import smoothscroll from "smoothscroll-polyfill";
import "./i18n";

smoothscroll.polyfill();

ReactDOM.render(<Root />, document.getElementById("root"));
