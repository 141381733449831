import React from "react";
import { perfilesDigitalesEnum } from "../utils/enums";
import format from "date-fns/format";
import PerfilDigitalBlog from "../assets/icons/perfilDigitalBlog";
import PerfilDigitalFacebook from "../assets/icons/perfilDigitalFacebook";
import PerfilDigitalInstagram from "../assets/icons/perfilDigitalInstagram";
import PerfilDigitalTwitter from "../assets/icons/perfilDigitalTwitter";
import PerfilDigitalLinkedin from "../assets/icons/perfilDigitalLinkedin";
import PerfilDigitalOtro from "../assets/icons/perfilDigitalOtro";
import PerfilDigitalPortfolio from "../assets/icons/perfilDigitalPortfolio";
import { apiKey } from "../config";
import { AuthService } from "../auth/AuthService";
import { MAX_TRIES } from "../api/constants";
import { SPAIN } from "../constants";

const auth = new AuthService();

export function formatDate(date) {
  //returns string with proper format for input type date "yyyy-mm-dd"
  let month = Number(date.getMonth()) + 1;
  if (month < 10) {
    month = "0" + month;
  }
  return `${date.getFullYear()}-${month}-${date.getDate()}`;
}

export function formatDateFront(date) {
  let month = Number(date.getMonth() + 1);
  if (month < 10)
    month = "0" + month;
  let day = Number(date.getDate());
  if (date < 10)
    day = "0" + day;

  return `${day}/${month}/${date.getFullYear()}`;
}

export function formatToLocalDate(date) {
  //returns string with proper format for input type date "dd/mm/yyyy"
  let month = Number(date.getMonth()) + 1;
  if (month < 10) {
    month = "0" + month;
  }
  return `${format(date, "dd/MM/yyyy")}`;
}

export function validateEmail(mail) {
  // eslint-disable-next-line
  if (mail && mail.length <= 100) {
    return /^[\w\-\.]+@([\w\-]+\.)+[\w\-]{2,4}$/.test(
      mail
    );
  }
  return false;
}

export function validatePhone(phone) {
  return phone && phone.toString().length <= 10;
}

export function validateInternationalPhone(value) {
  const pattern_telefonoInternacional = /^[+]?\d{0,20}$/;
  return value ? pattern_telefonoInternacional.test(value): true;
}

export function validateDocument(doc, type = "NIF") {
  const DNI_REGEX = /^(\d{8})([A-Za-z])$/;
  const NIE_REGEX = /^[XYZxyz]\d{7}[A-Za-z]$/;

  if (doc.lenth > 20) {
    return false;
  }

  const validDNI = function (nif = doc) {
    let dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
    let letter = dni_letters.charAt(parseInt(nif, 10) % 23);
    return letter === nif.charAt(8).toUpperCase();
  };

  const validNIE = function () {
    // Change the initial letter for the corresponding number and validate as DNI
    let nie_prefix = doc.charAt(0).toUpperCase();
    // eslint-disable-next-line
    switch (nie_prefix) {
      case "X":
        nie_prefix = 0;
        break;
      case "Y":
        nie_prefix = 1;
        break;
      case "Z":
        nie_prefix = 2;
        break;
    }

    return validDNI(nie_prefix + doc.substr(1));
  };

  switch (type) {
    case "NIF":
      return DNI_REGEX.test(doc) && validDNI();
    case "NIE":
      return NIE_REGEX.test(doc) && validNIE();
    default:
      return true;
  }
}

export function getLabelForSelectComponent(field, nameProperty) {
  return field[nameProperty] || "";
}

export function validateFile(file, size = 20) {
  const fileSize = file?.size / 1024 / 1024;
  return fileSize <= size;
}

export function validateDate(minDate = null, maxDate = null, value) {
  if (minDate && maxDate) {
    return (
      new Date(value).setHours(0, 0, 0, 0) >=
      new Date(minDate).setHours(0, 0, 0, 0) &&
      new Date(value).setHours(0, 0, 0, 0) <=
      new Date(maxDate).setHours(0, 0, 0, 0)
    );
  } else if (minDate) {
    return (
      new Date(value).setHours(0, 0, 0, 0) >=
      new Date(minDate).setHours(0, 0, 0, 0)
    );
  } else if (maxDate) {
    return (
      new Date(value).setHours(0, 0, 0, 0) <=
      new Date(maxDate).setHours(0, 0, 0, 0)
    );
  } else {
    return true;
  }
}

export function returnLangId(lang) {
  switch (lang) {
    case "es":
      return 88;
    case "en":
      return 21;
    case "pt":
      return 74;
    default:
      return 88;
  }
}

export function base64ToArrayBuffer(base64) {
  let binaryString = window.atob(base64);
  let binaryLen = binaryString.length;
  let bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    let ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export function returnMimeData(value) {
  const dividedName = value.split(".");
  const type = dividedName[dividedName.length - 1];
  switch (type) {
    case "pdf":
      return "application/pdf";
    case "doc":
    case "docx":
      return "application/msword";
    case "jpeg":
    case "jpg":
      return "image/jpeg";
    case "png":
      return "image/png";
    default:
      return "";
  }
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function formatDateInput(date) {
  const d = date.split("T")[0];
  return d;
}
export function renderSwitchDigitalProfile(tipoPerfilDigital) {
  switch (tipoPerfilDigital) {
    case perfilesDigitalesEnum.LINKEDIN:
      return <PerfilDigitalLinkedin />;
    case perfilesDigitalesEnum.FACEBOOK:
      return <PerfilDigitalFacebook />;
    case perfilesDigitalesEnum.INSTAGRAM:
      return <PerfilDigitalInstagram />;
    case perfilesDigitalesEnum.TWITTER:
      return <PerfilDigitalTwitter />;
    case perfilesDigitalesEnum.BLOG:
      return <PerfilDigitalBlog />;
    case perfilesDigitalesEnum.PORTFOLIO:
      return <PerfilDigitalPortfolio />;
    default:
      return <PerfilDigitalOtro />;
  }
}

export function optionEqualityString(x, v) {
  if (x.id) {
    return x.id === v.id;
  } else {
    return x === v;
  }
}

export function optionEquality(x, v) {
  if (x.id) {
    return x.id === v.id || Number(x.id) === Number(v);
  } else {
    return x === v;
  }
}

export function validateSocialSecurityNumber(ss) {
  if (ss) {
    const doc = ss.trim();
    let variable = parseInt(String(doc).slice(0, 2));
    let numberAffiliate = parseInt(String(doc).slice(2, -2));
    let controlDigits = parseInt(String(doc).slice(-2));
    let result;
    if (numberAffiliate < 10000000) {
      result = numberAffiliate + variable * 10000000;
    } else {
      result = parseInt(`${variable}${numberAffiliate}`);
    }
    let mod = result % 97;
    return doc.length === 12 && mod === parseInt(controlDigits);
  } else {
    return true;
  }
}

export function validatePostalCode(postalCode, country) {
  if (country && Number(country) === 724) {
    return (
      postalCode &&
      postalCode.toString().length === 5 &&
      Number(postalCode) > 1000 &&
      Number(postalCode) <= 52999
    );
  } else {
    return (
      postalCode &&
      postalCode.toString().length > 1 &&
      postalCode.toString().length <= 11
    );
  }
}

export const getYear = (stringDate) => {
  let formattedDate = new Date(stringDate);
  return formattedDate ? formattedDate.getFullYear() : "";
};

export function normalizeString(item) {
  return item?.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f\s]/g, "");
}

export function sortArrayAlphabeticallyByField(array, field) {
  if (!array) return null;

  let result;
  result = array.sort(function (a, b) {
    if (a[field].toString().toLowerCase() < b[field].toString().toLowerCase())
      return -1;
    if (a[field].toString().toLowerCase() > b[field].toString().toLowerCase())
      return 1;
    return 0;
  });
  return result;
}

 export async function sendHeader() {
  const token =  await auth.getUser();
  return token?.access_token
}

export const dataHeader =  {
  "Ocp-Apim-Subscription-Key": apiKey,
  "Content-Type": "application/json",
  "Authorization":" "
}

export const dataHeaderWithoutAuthorization = {
  "Ocp-Apim-Subscription-Key": apiKey,
  "Content-Type": "application/json"
}

export const responseCheck = (respuesta, numero, funcion) => {
  if (respuesta.status === 401 && numero < MAX_TRIES) {
    return auth.renewToken().then(() => {
      return funcion(++numero);
    });
  } else {
    return respuesta;
  }
};


export const getProvincias = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_provincias.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_provincias.json");
    default:
      return require("../assets/dataLists/es/provincias.json");
  }
}


export const getNationalityOptions = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_nacionalidad.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_nacionalidad.json");
    default:
      return require("../assets/dataLists/es/nacionalidad.json");
  }
}

export const getDiscapacityOptions = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_discapacidades.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_discapacidades.json");
    default:
      return require("../assets/dataLists/es/discapacidades.json");
  }
}

export const getNivelesEstudio = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_niveles.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_niveles.json");
    default:
      return require("../assets/dataLists/es/niveles.json");
  }
}

export const getAreasEstudio = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_areas.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_areas.json");
    default:
      return require("../assets/dataLists/es/areas.json");
  }
}

export const getIdiomasList = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_idiomas.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_idiomas.json");
    default:
      return require("../assets/dataLists/es/idiomas.json");
  }
}

export const getNivelesIdioma = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_nivelesIdioma.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_nivelesIdioma.json");
    default:
      return require("../assets/dataLists/es/nivelesIdioma.json");
  }
}

export const getPermisosConduccion = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_permisoConducir.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_permisoConducir.json");
    default:
      return require("../assets/dataLists/es/permisoConducir.json");
  }
}

export const getTiposSalario = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_tipos_salario.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_tipos_salario.json");
    default:
      return require("../assets/dataLists/es/tipos_salario.json");
  }
}

export const getTiposCambioResidencia = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_tipoCambio.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_tipoCambio.json");
    default:
      return require("../assets/dataLists/es/tipoCambio.json");
  }
}

export const getTiposDesplazamientos = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_tipoDesplazamiento.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_tipoDesplazamiento.json");
    default:
      return require("../assets/dataLists/es/tipoDesplazamiento.json");
  }
}

export const getPermitTypeOptions = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_permisoTrabajo.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_permisoTrabajo.json");
    default:
      return require("../assets/dataLists/es/permisoTrabajo.json");
  }
}

export const getGenderOptions = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_sexo.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_sexo.json");
    default:
      return require("../assets/dataLists/es/sexo.json");
  }
}

export const getTiposVia = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_tipoVia.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_tipoVia.json");
    default:
      return require("../assets/dataLists/es/tipoVia.json");
  }
}

export const getTiposPerfilDigital = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_tipoPerfilDigital.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_tipoPerfilDigital.json");
    default:
      return require("../assets/dataLists/es/tipoPerfilDigital.json");
  }
}

export const getDocTypes = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_tipoDocumento.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_tipoDocumento.json");
    default:
      return require("../assets/dataLists/es/tipoDocumento.json");
  }
}

export const getContractTypes = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_tipoContrato.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_tipoContrato.json");
    default:
      return require("../assets/dataLists/es/tipoContrato.json");
  }
}

export const getFrecuenciasNotificacion = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_frecuenciaNotificacionAlerta.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_frecuenciaNotificacionAlerta.json");
    default:
      return require("../assets/dataLists/es/frecuenciaNotificacionAlerta.json");
  }
}

export const getTimeFilters = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_tiempo_candidatura.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_tiempo_candidatura.json");
    default:
      return require("../assets/dataLists/es/tiempo_candidatura.json");
  }
}

export const getStatusFilters = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_estado_candidatura.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_estado_candidatura.json");
    default:
      return require("../assets/dataLists/es/estado_candidatura.json");
  }
}

export const getSalaryRange = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_rangoSalarial.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_rangoSalarial.json");
    default:
      return require("../assets/dataLists/es/rangoSalarial.json");
  }
}

export const getSalaryType = (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_tipoSalario.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_tipoSalario.json");
    default:
      return require("../assets/dataLists/es/tipoSalario.json");
  }
}

export const getMinimumExperience= (language) => {
  switch (language) {
    case "en":
      return require("../assets/dataLists/en/EN_experienciaMinima.json");
    case "pt":
      return require("../assets/dataLists/pt/PT_experienciaMinima.json");
    default:
      return require("../assets/dataLists/es/experienciaMinima.json");
  }
}

export function handleProvince(dataListProv, dataListCountry, idProvincie, idPais) {
  if (dataListProv.find((n) => Number(n.id) === Number(idProvincie))?.name && Number(idProvincie).name !== "" && idProvincie !== idPais) {
    return dataListProv.find((n) => Number(n.id) === Number(idProvincie))?.name;
  } else {
    return dataListCountry.find((n) => Number(n.id) === Number(idPais))?.name ?? "";
  }
}

export const getProvinceOptions = (provincias,idPais,dataLiscountry)=> {
  const provinces = provincias.filter(
    (prov) => Number(prov.id_country) === idPais);

  if (provinces.length === 1 && provinces[0]?.name === '') {
    return dataLiscountry.filter((n) => Number(n.id) === Number(idPais))
  } else {
    return provinces;
  }
}

export function isForeingCountry(dataListProvincies, alertProvinces) {
  let isForeing = dataListProvincies.find((prov) => alertProvinces.includes(Number(prov.id)) && prov.id_country != SPAIN) ? -1 : 0;
  return isForeing;
}

export const filterSalaryRangeOptions = (DataListsalarios, minimumSalary) => {
  return DataListsalarios.filter((s) => minimumSalary ? Number(s.order) >= Number(minimumSalary.order || minimumSalary.orden) 
                                                      : DataListsalarios)
};

export async function handleErrorResponse(
  enqueueSnackbar,
  response,
  message = "Error inesperado"
) {
  if (response.status < 500) {
    try {
      const errorResult = await response.json();
      enqueueSnackbar(errorResult.errorMessage ?? message, {
        variant: "warning",
      });
    } catch (error) {
      enqueueSnackbar(message, {
        variant: "warning",
      });
    }
  } else {
    enqueueSnackbar(message, {
      variant: "error",
    });
  }
}

export function mapPrefixes(paises) {
  return paises?.map((p) => {
    p.name = p.nombre;
    p.prefix = p.prefijo;
    return p;
  });
}

export function formatMaestro(maestro) {
  return maestro.map((item) => {
    Object.keys(item).forEach((key) => {
      switch (key) {
        case "nombre":
          item.name = item.nombre;
          break;
        case "idProvincia":
          item.id_province = item.idProvincia;
          break;
        case "codigoIne":
          item.codigo_ine = item.codigoIne;
          break;
        default:
          break;
      }
    })
    return item;
  })
}

export async function getDataMaestros(requestFunction,setterAction,snackbar){
    requestFunction().then((response) => {
      if (response.status === 200) {
          response.json().then((result) => {
            setterAction(formatMaestro(result));
          });
      } else {
        setterAction([]);
      }
  });
}

export async function handleGetMaestro(maestro,setter,getMaestro) {
  !maestro.length && setter(await getMaestro);
}