import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import i18n from "../i18n";
import idiomasDisponibles from "../assets/dataLists/idiomasDisponibles.json";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header_language: {
    display: "flex",
    justifyContent: "flex-start",
  },

  header_language_text: {
    display: "flex",
    justifyContent: "space-between",
  },
  header_language_text_field: {
    display: "flex",
    justifyContent: "space-between",
    color: "#FC030B",
  },
  header_language_link: {
    marginLeft: "2px",
    cursor: "pointer",

    "&:hover": {
      color: "rgb(176, 2, 7)",
    },
  },
  header_language_link_active: {
    marginLeft: "2px",
    cursor: "pointer",
    fontWeight: 800,

    color: "rgb(176, 2, 7)",

    "&:hover": {
      color: "#FC030B",
    },
  },
  header_separator: {
    marginLeft: "5px",
    marginRight: "5px",
  },
}));

const changeLanguage = (lng) => {
  switch (lng) {
    case idiomasDisponibles[0].name:
      i18n.changeLanguage(idiomasDisponibles[0].id);
      break;
    case idiomasDisponibles[1].name:
      i18n.changeLanguage(idiomasDisponibles[1].id);
      break;
    case idiomasDisponibles[2].name:
      i18n.changeLanguage(idiomasDisponibles[2].id);
      break;
    default:
      i18n.changeLanguage(i18n.getFixedT());
  }
};
function LanguageSwitch(props) {
  const classes = useStyles();

  function renderLanguages() {
    return idiomasDisponibles.map((idioma, index) => {
      if (index === 0) {
        return (
          <div className={classes.header_language_text_field} key={index}>
            {" "}
            <Typography
              onClick={(e) => changeLanguage(idioma.name)}
              className={
                i18n.language.toUpperCase().slice(0, 2) ===
                idioma.id.toUpperCase().slice(0, 2)
                  ? classes.header_language_link_active
                  : classes.header_language_link
              }
            >
              {idioma.id.toUpperCase().slice(0, 2)}
            </Typography>
          </div>
        );
      } else {
        return (
          <div className={classes.header_language_text_field} key={index}>
            <Typography className={classes.header_separator}>|</Typography>{" "}
            <Typography
              onClick={(e) => changeLanguage(idioma.name)}
              className={
                i18n.language.toUpperCase().slice(0, 2) ===
                idioma.id.toUpperCase().slice(0, 2)
                  ? classes.header_language_link_active
                  : classes.header_language_link
              }
            >
              {idioma.id.toUpperCase().slice(0, 2)}
            </Typography>
          </div>
        );
      }
    });
  }
  return (
    <div className={classes.header_language}>
      <div className={classes.header_language_text}>{renderLanguages()}</div>
    </div>
  );
}

export default LanguageSwitch;
