import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import i18next from "../i18n.js";
import { getBasicData } from "../api/candidato";
import { returnLangId } from "../utils/functions";

function AuthWrapper(props) {
  const { forceLogin, children, authService, ifNotLogged = false} = props;
  const [isLogged, setIsLogged] = useState(false);
  const [handlignData, setHandlingData] = useState(true);
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    if (forceLogin) {
      authService.getUser().then((user) => {
        if (user) {
          setIsLogged(true);
        } else {
          sessionStorage.setItem(
            "origin:login",
            `${window.location.pathname}${window.location.search}`
          );
          authService
            .login()
            .catch((error_login) => console.log("ERROR: ", error_login));
        }
      });
    } else {
      authService.getUser().then((user) => {
        if (user) {
          setIsLogged(true);
        } else {
          setIsLogged(false);
        }
      });
    }
  }, [forceLogin, authService]);

  useEffect(() => {
    handleHandling();
  }, [window.location.pathname, isLogged]);

  async function handleHandling() {
    if (!ifNotLogged) {
      if (forceLogin) {
        if (isLogged) {
          checkBasicData();
        }
      } else {
        if (await authService.getUser()) {
          checkBasicData();
        } else {
          setHandlingData(false);
        }
      }
    } else {
      setHandlingData(false);
    }
  }

  async function checkBasicData() {
    const data = sessionStorage.getItem("user:basic_data");
    const token = await authService.getUser();
    const decoded = jwt_decode(token.access_token);
    if (!data || data !== decoded.preferred_username) {
      handleGetDatosContacto();
    } else if (data && data === decoded.preferred_username) {
      setHasData(true);
      setHandlingData(false);
    } else {
      setHandlingData(false);
    }
  }

  async function handleGetDatosContacto() {
    const response = await getBasicData(returnLangId(i18next.languages[0]));
    if (response.status === 200) {
      const result = await response.json();
      if (result.tieneDatosBasicos) {
        setHasData(true);
        setHandlingData(false);
      } else {
        setHandlingData(false);
      }
    } else {
      setHandlingData(false);
    }
  }
  if (handlignData) {
    return <div />;
  } else {
    if (forceLogin) {
      if (isLogged) {
        if (ifNotLogged) {
          window.location.pathname = "/candidate_dashboard";
          return <div />;
        }
        if(window.location.pathname === "/ett")
        {
          return React.cloneElement(children, {
            userNotAuthenticated: false
          });
        }
        if (hasData || window.location.pathname === "/basic_data") {
          return React.cloneElement(children, {
            userNotAuthenticated: true,
            auth: authService,
            setLogged: setIsLogged,
          });
        } else {
          window.location.pathname = "/basic_data";
          return <div />;
        }
      } else {
        return <div />;
      }
    } else {
      if (isLogged) {
        if (ifNotLogged) {
          window.location.pathname = "/candidate_dashboard";
          return <div />;
        }
        if (hasData || window.location.pathname === "/basic_data") {
          return React.cloneElement(children, { userNotAuthenticated: false });
        } else {
          window.location.pathname = "/basic_data";
          return <div />;
        }
      } else {
        if (!ifNotLogged && window.location.pathname === "/logout_delete") {
          window.location.pathname = "/candidate_dashboard";
          return <div />;
        } else {
          return React.cloneElement(children, {
            userNotAuthenticated: true,
            auth: authService,
            setLogged: setIsLogged,
          });
        }
      }
    }
  }
}

export default AuthWrapper;
