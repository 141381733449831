import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonOutlined from "../components/buttonOutlined";
import ButtonFull from "../components/buttonFull";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    action_buttons_grid: {
      display: "grid",
      gridTemplateColumns: ".25fr .25fr .5fr",
      columnGap: "25px",
      rowGap: "24px",
      gridTemplateAreas: `
        'cancel . save'
        '. . save_and_attach'`,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateAreas: `
          'save'
          'save_and_attach'
          'cancel'`,
        rowGap: "20px",
      },
    },
}));

function AddToCVActionButtons(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { saveAndActionText, onSaveClick, onSaveAndActionClick, onCancelClick, isUpdate } = props

    return (
        <div className={classes.action_buttons_grid}>
            <div
                style={{ gridArea: "cancel" }}>
                <ButtonOutlined
                    size="100%"
                    text={t("common.cancel")}
                    onClick={onCancelClick}
                />
            </div>
            <div
                style={{ gridArea: "save" }}>
                <ButtonFull
                    size="100%"
                    text={ isUpdate ? t("common.update") : t("common.save")}
                    onClick={onSaveClick}
                />
            </div>
            <div
                style={{ gridArea: "save_and_attach" }}>
                <ButtonOutlined
                    size="100%"
                    text={saveAndActionText}
                    onClick={onSaveAndActionClick}
                />
            </div>
        </div>
    );
}

export default AddToCVActionButtons;