import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../components/loading";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Typography, Box, Link, Icon } from "@material-ui/core";
import {
  getSavedSearches,
  updateSavedSearch,
  deleteSavedSearch,
} from "../api/candidato";
import PriorityLow from "../assets/icons/priorityLow";
import Zoom from "../assets/icons/zoom";
import Layout from "../components/basicLayout";
import Snackbar from "../components/customSnackBar";
import { withSnackbar } from "notistack";
import OffersSearcher from "../components/offersSearcher";
import AntSwitch from "../components/antSwitch";
import i18next from "../i18n.js";
import AlertDialog from "../components/alertDialog";
import { getFrecuenciasNotificacion } from "../utils/functions";

const useStyles = makeStyles((theme) => ({
  body_container: {
    display: "grid",
    gridArea: "body_container",
    gridTemplateAreas: "'bodyLeft separator bodyRight'",
    gridTemplateColumns: "3fr 1fr 8fr",
    gridTemplateRows: "1fr",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto auto auto",
      gridTemplateAreas: "'topFilters' 'bodyRight' 'bodyLeft'",
    },
  },
  body_left_col: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gridArea: "bodyLeft",
  },
  body_right_col: {
    display: "flex",
    gridArea: "bodyRight",
  },
  body_right_col_container: {
    width: "100%",
    padding: "20px",
  },
  list: {
    paddingLeft: "0px",
    marginTop: "80px",
    "list-style-type": "none",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-16px",
    },
  },
  list_with_border: {
    borderBottom: "1px solid #DDDDDD",
    paddingBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "32px",
      marginBottom: 0,
    },
  },
  list_item: {
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  rotate_icon: {
    "& svg": {
      transform: "rotate(180deg)",
    },
  },
  saved_search_delete_link: {
    textDecoration: "underline",
    display: "inline-flex",
    cursor: "pointer",
  },
  saved_search_frecuency: {
    float: "right",
    margin: 0,
    marginRight: "8px",
  },
  saved_search_title: {
    margin: 0,
    padding: 0,
    fontWeight: 900,
    fontSize: 22,
    lineHeight: "22px",
    [theme.breakpoints.down("sm")]: {
      wordBreak: "break-word",
    },
  },
  searcher: {
    padding: "10px 20px 40px 20px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
    },
  },
  seeMoreButton_container: {
    "&:hover, &:focus": {
      cursor: "pointer",
    },
    paddingBottom: "32px",
    paddingTop: "4px",
    borderBottom: "1px solid #DDDDDD",
  },
  seeMoreButton_icon: {
    display: "inline-block",
    margin: "0 12px",
    height: "24px",
    [theme.breakpoints.down("sm")]: {
      float: "right",
    },
  },
  seeMoreButton_text: {
    display: "inline-block",
    textDecoration: "none",
    "&:hover, &:link, &:active, &:visited": {
      textDecoration: "none",
    },
    fontSize: "14px",
    lineHeight: "28px",
  },
  switch: {
    float: "right",
  },
  title: {
    fontSize: "36px",
    lineHeight: "46px",
    fontWeight: 900,
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      fontSize: "24px",
      lineHeight: "30px",
    },
  },
  title_container: {
    display: "grid",
    gridTemplateColumns: "minMax(0, 1fr) auto",
    columnGap: 12,
    gridTemplateRows: "auto",
    marginBottom: "20px",
  },
  zoom_icon: {
    marginBottom: "15px",
    padding: "10px",
    "& svg": {
      height: "54px",
      width: "54px",
    },
  },
}));

function SavedSearches(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(true);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [savedSearches, setSavedSearches] = useState([]);
  const [savedSearchesStates, setSavedSearchesStates] = useState([]);
  const [
    areAllSavedSearchesVisibles,
    setAreAllSavedSearchesVisibles,
  ] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchToDelete, setSearchToDelete] = useState(null);

  const frecuenciasNotificacion=getFrecuenciasNotificacion(i18next.languages[0]);

  useEffect(() => {
    handleGetSavedSearches();
  }, []);

  async function handleGetSavedSearches() {
    setLoading(true);

    const response = await getSavedSearches();

    if (response.status === 200) {
      const result = await response.json();
      setSavedSearches(result);
      setSavedSearchesStates(
        result.map((search) => ({
          idAlerta: search.idAlerta,
          activa: search.activa,
          isUpdating: false,
        }))
      );
    } else {
      props.enqueueSnackbar(t("offers.error"), {
        variant: "error",
      });
      setSavedSearches([]);
    }

    setLoading(false);
  }

  async function handleSearchStateChange(search) {
    let newStates = savedSearchesStates.filter(
      (searchWithState) => searchWithState.idAlerta !== search.idAlerta
    );
    setSavedSearchesStates([
      ...newStates,
      { idAlerta: search.idAlerta, activa: !search.activa, isUpdating: true },
    ]);

    const response = await updateSavedSearch(search.idAlerta, !search.activa);

    if (response.status === 200) {
      let newStates = savedSearchesStates.filter(
        (searchWithState) => searchWithState.idAlerta !== search.idAlerta
      );
      setSavedSearchesStates([
        ...newStates,
        {
          idAlerta: search.idAlerta,
          activa: !search.activa,
          isUpdating: false,
        },
      ]);
    } else {
      switch (response.status) {
        case 400:
          props.enqueueSnackbar(
            t("saved_searches.error_bad_request_update_search"),
            {
              variant: "error",
            }
          );
          break;
        case 404:
          props.enqueueSnackbar(
            t("saved_searches.error_not_found_update_search"),
            {
              variant: "warning",
            }
          );
          break;
        default:
          props.enqueueSnackbar(t("saved_searches.error"), {
            variant: "error",
          });
      }
      handleGetSavedSearches();
    }
  }

  async function handleDeleteSearch(search) {
    setLoading(true);
    const response = await deleteSavedSearch(search.idAlerta);

    if (response.status === 200) {
      handleGetSavedSearches();
      setSnackbarText(
        t("saved_searches.search_deleted", {
          search: `"${search.nombre}"`,
        })
      );
      setIsSnackBarOpen(true);
    } else {
      setLoading(false);
      switch (response.status) {
        case 400:
          props.enqueueSnackbar(
            t("saved_searches.error_bad_request_delete_search"),
            {
              variant: "error",
            }
          );
          break;
        case 404:
          props.enqueueSnackbar(
            t("saved_searches.error_not_found_delete_search"),
            {
              variant: "warning",
            }
          );
          break;
        default:
          props.enqueueSnackbar(t("saved_searches.error"), {
            variant: "error",
          });
      }
    }
    setSearchToDelete(null);
  }

  function noSearches() {
    return (
      <Typography component="div">
        <Box display="inline-block" fontSize={30} fontWeight="900" m={1}>
          {t("offers.noOffers")}
        </Box>
      </Typography>
    );
  }

  function searches() {
    return (
      <ul
        className={`${classes.list} ${
          savedSearches.length <= 20 ? classes.list_with_border : ""
        }`}
      >
        {savedSearches
          .slice(0, areAllSavedSearchesVisibles ? savedSearches.length : 20)
          .map((search, index) => (
            <li key={index}>
              <div className={classes.list_item}>
                <div className={classes.title_container}>
                  <Typography
                    variant="body1"
                    className={classes.saved_search_title}
                  >
                    {search.nombre}
                  </Typography>
                  {
                    <AntSwitch
                      disabled={savedSearchesStates.some(
                        (search) => search.isUpdating
                      )}
                      checked={
                        savedSearchesStates.find(
                          (searchWithState) =>
                            searchWithState.idAlerta === search.idAlerta
                        )?.activa
                      }
                      setter={() =>
                        handleSearchStateChange(
                          savedSearchesStates.find(
                            (searchWithState) =>
                              searchWithState.idAlerta === search.idAlerta
                          )
                        )
                      }
                    />
                  }
                </div>

                <Typography component="div">
                  <Box
                    className={classes.saved_search_delete_link}
                    fontSize={14}
                    lineHeight="21px"
                  >
                    <Link
                      onClick={() => {
                        setSearchToDelete(search);
                        setIsDialogOpen(true);
                      }}
                    >
                      {t("saved_searches.delete")}
                    </Link>
                  </Box>
                  <Box
                    display="inline-block"
                    className={classes.saved_search_frecuency}
                    m={1}
                  >
                    {frecuenciasNotificacion.find(
                      (frec) =>
                        frec.id === search.idFrecuenciaNotificacionAlerta
                    )?.name ?? ""}
                  </Box>
                </Typography>
              </div>
            </li>
          ))}
      </ul>
    );
  }

  function seeMoreButton(arrowVisibilty, iconFloatingRight, handleClick) {
    return (
      <div className={classes.seeMoreButton_container} onClick={handleClick}>
        <Typography className={classes.seeMoreButton_text}>
          <Link>
            {arrowVisibilty ? t("offers.seeLess") : t("offers.seeMore")}
          </Link>
        </Typography>
        <Typography
          className={`${classes.seeMoreButton_icon} ${
            arrowVisibilty ? classes.rotate_icon : ""
          }`}
        >
          <Icon color="primary">
            <PriorityLow title={t("icons_tooltips.expand")}/>
          </Icon>
        </Typography>
      </div>
    );
  }

  function tittle() {
    return (
      <Typography className={classes.title}>
        {t("saved_searches.your_searches")}
      </Typography>
    );
  }

  function tittle_icon() {
    return (
      <Typography
        className={classes.zoom_icon}
        align={matchesMobile ? "center" : "left"}
      >
        <Icon color="primary">
          <Zoom />
        </Icon>
      </Typography>
    );
  }

  return (
    <Layout history={props.history} loading={loading}>
      <Snackbar
        text={snackbarText}
        isSnackBarOpen={isSnackBarOpen}
        handleIsSnackBarOpen={setIsSnackBarOpen}
      />
      <div className={classes.body_container}>
        {matchesMobile ? (
          <div>
            {tittle_icon()}
            {tittle()}
          </div>
        ) : null}
        <div className={classes.body_left_col}>
          {!matchesMobile ? (
            <div>
              {tittle_icon()}
              {tittle()}
            </div>
          ) : null}
          <div className={classes.searcher}>
            <OffersSearcher />
          </div>
        </div>
        <div className={classes.body_right_col}>
          <div className={classes.body_right_col_container}>
            {savedSearches.length === 0 ? noSearches() : searches()}
            {savedSearches.length <= 20
              ? null
              : seeMoreButton(areAllSavedSearchesVisibles, true, () =>
                  setAreAllSavedSearchesVisibles(!areAllSavedSearchesVisibles)
                )}
          </div>
        </div>
      </div>
      <AlertDialog
        title={t("saved_searches.delete_confirm_message")}
        open={isDialogOpen}
        handleClose={() => {
          setSearchToDelete(null);
          setIsDialogOpen(false);
        }}
        handleAccept={() => {
          handleDeleteSearch(searchToDelete);
          setIsDialogOpen(false);
        }}
      />
    </Layout>
  );
}

export default withSnackbar(SavedSearches);
