import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { FormControl, Typography } from "@material-ui/core";
import {
  addWorkExperience,
  getWorkExperience,
  deleteWorkExperience,
  updateWorkExperience,
} from "../api/candidato";
import {
  getDirectManger,
  getEtts,
  getFunction,
  getKnowledge,
  getLevelExperiencie,
  getPlace,
  getSectors,
  getStaffInCharge,
} from "../api/maestro";
import { withSnackbar } from "notistack";
import Snackbar from "../components/customSnackBar";
import i18next from "../i18n.js";
import {
  returnLangId,
  validateDate,
  optionEquality,
  getLabelForSelectComponent,
  getSalaryRange,
  getTiposSalario,
  filterSalaryRangeOptions
} from "../utils/functions";
import YourExperience from "../assets/icons/yourExperience";
import AntSwitch from "../components/antSwitch";
import InputDate from "../components/datePickerWithLabel";
import AddToCVActionButtons from "../components/addToCVActionButtons";
import AlertDialog from "../components/alertDialog";
import Layout from "../components/basicLayout";
import AutocompleteWithLabel from "../components/autocompleteWithLabelAnimation";
import TuCurriculumHeader from "../components/tuCurriculumHeader";
import InputWithLabel from "../components/inputWithLabelAnimation";
import { EMPLOYEE, 
         INTERMEDIATE, 
         INTERN, 
         MAX_CHAR_DESCRIPTION, 
         SPECIALIST 
        } from "../constants";
import AutocompleteWithChips from "../redux/containers/autocompleteWithChips";

const useStyles = makeStyles((theme) => ({
  principal_container: {
    gridArea: "body_container",
    display: "grid",
    gridTemplateColumns: "30% 65%",
    columnGap: "20px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "auto",
    },
  },
  body_container: {
    marginTop: 100,
    display: "grid",
    gridTemplateAreas: "'titleArea' 'formArea' 'filled2'",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto minmax(0, 1fr)",
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto 1fr",
      gridTemplateAreas:
        "'titleArea' \
                          'bodyContainer'",
    },
  },
  title_container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
    },
  },
  form_container: {
    display: "grid",
    gridArea: "formArea",
    width: "100%",
    height: "auto",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateAreas:
      '"sector ett" \
                        "puesto empresa" \
                        "tipoSalario empty9"\
                        "salarioMin salarioMax"\
                        "nivel personalCargo"\
                        "responsableDirecto ."\
                        "funciones empty2" \
                        "descripPuesto descripPuesto"\
                        "trabajaAqui empty3" \
                        "fechaInicio fechaFin" \
                        "conocimientos empty4" \
                        "conocimientosChips empty5" \
                        "tengoReferencias empty6" \
                        "references references" \
                        "addReference empty8" \
                        "referencesList referencesList" \
                        "botonera botonera" \
                        "emptyDelete delete"',
    gridColumnGap: "25px",
    gridRowGap: "25px",
    [theme.breakpoints.down("sm")]: {
      gridArea: "bodyContainer",
      display: "grid",
      gridTemplateAreas:
        "'sector' \
                        'ett' \
                        'puesto' \
                        'empresa' \
                        'tipoSalario' \
                        'salarioMin' \
                        'salarioMax' \
                        'nivel' \
                        'personalCargo' \
                        'responsableDirecto' \
                        'funciones' \
                        'descripPuesto'\
                        'trabajaAqui' \
                        'fechaInicio' \
                        'fechaFin' \
                        'conocimientos' \
                        'conocimientosChips' \
                        'tengoReferencias' \
                        'references' \
                        'addReference' \
                        'referencesList' \
                        'botonera' \
                        'delete'",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto",
      gridColumnGap: "0px",
    },
    alignItems: "end",
  },
  autocomplete_input_container: {
    width: "100%",
    backgroundColor: "#FFFFFF",
  },
  input: {
    width: (props) => (props.displayInline ? "35%" : "100%"),
  },
  switch_with_label: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "& p": {
      fontSize: "14px",
      lineHeight: "18px",
    },
  },
  title: {
    fontSize: "22px",
    lineHeight: "30px",
    fontWeight: 900,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "25px",
    },
  },
  form_grid_sector: {
    gridArea: "sector",
  },
  form_grid_ett: {
    gridArea: "ett",
  },
  form_grid_puesto: {
    gridArea: "puesto",
  },
  form_grid_nivel: {
    gridArea: "nivel",
  },
  form_grid_descripcion_Puesto: {
    gridArea: "descripPuesto",
  },
  form_grid_personalCargo: {
    gridArea: "personalCargo",
  },
  form_grid_responsableDirecto: {
    gridArea: "responsableDirecto",
  },
  form_grid_salarioMin: {
    gridArea: "salarioMin",
  },
  form_grid_salarioMax: {
    gridArea: "salarioMax",
  },
  form_grid_tipoSalario: {
    gridArea: "tipoSalario",
  },
  form_grid_empresa: {
    gridArea: "empresa",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
  form_grid_funciones: {
    gridArea: "funciones",
    display: "grid",
    gridTemplateColumns: "minmax(0, 1fr)",
  },
  form_grid_trabaja_aqui: {
    gridArea: "trabajaAqui",
  },
  form_grid_fecha_inicio: {
    gridArea: "fechaInicio",
  },
  form_grid_fecha_fin: {
    gridArea: "fechaFin",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
  form_grid_conocimientos: {
    gridArea: "conocimientos",
    display: "grid",
    gridTemplateColumns: "minmax(0, 1fr)",
  },
  form_grid_tengo_referencias: {
    gridArea: "tengoReferencias",
  },
  form_grid_referencias_container: {
    gridArea: "references",
    display: "grid",
    gridTemplateAreas: '"reference" \
                        "addReference"',
    gridRowGap: "20px",
  },
  reference_grid: {
    display: "grid",
    gridArea: "reference",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateAreas: '"nombre ." \
                        "telefono email"',
    gridColumnGap: "25px",
    gridRowGap: "25px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridTemplateAreas:
        '"nombre" \
         "telefono" \
         "email"',
    },
    alignItems: "end",
  },
  add_delete_grid: {
    display: "grid",
    gridArea: "reference",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateAreas: '"add delete"',
    gridColumnGap: "25px",
    gridRowGap: "20px",
    marginBottom: "20px",
  },
  form_grid_nombre: {
    gridArea: "nombre",
  },
  form_grid_telefono: {
    gridArea: "telefono",
    display: "grid",
    gridTemplateColumns: ".4fr .6fr",
    columnGap: "10px",
  },
  form_grid_email: {
    gridArea: "email",
  },
  form_grid_add: {
    gridArea: "add",
  },
  form_grid_delete: {
    gridArea: "delete",
  },
  form_grid_botonera: {
    gridArea: "botonera",
  },
  add_another_reference_link: {
    fontSize: 14,
    color: theme.palette.primary.main,
    cursor: "pointer",
    textDecoration: "underline",
    width: "180px",
  },
  delete: {
    gridArea: "delete",
    color: theme.palette.primary.main,
    textAlign: "right",
    textDecoration: "underline",
    display: "flex",
    cursor: "pointer",
  },
  delete_center: {
    justifyContent: "center",
  },
  delete_right: {
    justifyContent: "flex-end",
  },
  label: {
    fontSize: "14px",
    lineHeight: "18px",
    marginBottom: "10px",
  },
  space_functions:{
    marginTop: -20
  },
  counter_chars_description:{
    fontSize: 12,
    color: "#D8D8D8",
  }
}));

function WorkExperience(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [nombre, setNombre] = useState("");
  const [primerApellido, setPrimerApellido] = useState("");
  const [segundoApellido, setSegundoApellido] = useState("");
  const [correoElectronico, setCorreoElectronico] = useState("");
  const [prefijo, setPrefijo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [idFotografia, setIdFotografia] = useState(null);
  const [fechaModificacion, setFechaModificacion] = useState(null);

  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [allFunctions, setAllFunctions] = useState([]);
  const [allKnowledges, setAllKnowledges] = useState([]);
  const [allPlaces, setAllPlaces] = useState([]);
  const [allSectors, setAllSectors] = useState([]);
  const [allEtts, setAllEtts] = useState([]);
  const [allLevels, setAllLevels] = useState([]);
  const [allStaff, setAllStaff] = useState([]);
  const [allManagers, setAllManagers] = useState([]);

  const [sector, setSector] = useState(null);
  const [ett, setEtt] = useState(null);
  const [isEtt, setIsEtt] = useState(false);
  const [job, setJob] = useState(null);
  const [company, setCompany] = useState("");
  const [functions, setFunctions] = useState([]);
  const [isWorkingHere, setIsWorkingHere] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [knowledges, setKnowledges] = useState([]);
  const [references, setReferences] = useState([]);
  const [hasReferences, setHasReferences] = useState(false);
  const [referenceName, setReferenceName] = useState("");
  const [referencePhonePrefix, setReferencePhonePrefix] = useState({
    "id": "724",
    "name": "España",
    "prefix": "34"
  });
  const [level, setlevel] = useState(null);
  const [referencePhoneNumber, setReferencePhoneNumber] = useState("");
  const [referenceEmail, setReferenceEmail] = useState("");
  const [addNewExperience, setAddNewExperience] = useState(false);
  const [minSalary,setMinSalary] = useState(null);
  const [maxSalary,setMaxSalary] = useState(null);
  const [typeSalary,setTypeSalary] = useState(null);
  const [staffInCharge, setStaffInCharge] = useState(null);
  const [directManger, setDirectManger] = useState(null); 
  const [descripcionPuesto,setDescripcionPuesto] = useState(""); 

  const [knowledgeError, setKnowledgeError] = useState(null);
  const [functionsError, setFunctionsError] = useState(null);
  const [saveWrong, setSaveWrong] = useState(false);
  const [saveWrongReferences, setSaveWrongReferences] = useState(false);
  const [levelsError, setLevelsError] = useState(null);
  const [staffError, setStaffError] = useState(null);
  const [managerError, setManagerError] = useState(null);

  const { state, phonePrefixes} = props;

  const rangos_salarios = getSalaryRange(i18next.languages[0]);
  const tipos_salarios = getTiposSalario(i18next.languages[0]);

  useEffect(() => {
    handleGetFunctions();
    handleGetKnowledges();
    handleGetEtts();
    handleGetPlaces(returnLangId(i18next.languages[0]));
    handleGetSectors(returnLangId(i18next.languages[0]));
    handleGetLevels();
    handleGetDirectManagers();
    handleGetStaff();
  }, []);

  useEffect(() => {
    if (state && state.personalData) {
      handlePersonalDataAction(state.personalData);
    }
  }, [state.personalData]);
  
  useEffect(() => {
    handleGetExperience(Number(props.match.params.experienceId));
  }, [props.match.params.experienceId]);

  function handlePersonalDataAction(data) {
    setNombre(data.nombre);
    setPrimerApellido(data.primerApellido);
    setSegundoApellido(data.segundoApellido);
    setCorreoElectronico(data.correoElectronico);
    setPrefijo(data.prefijo);
    setTelefono(data.telefono);
    setIdFotografia(data.idFotografia);
    setFechaModificacion(data.fechaModificacion);
  }

  async function handleGetExperience(experienceId) {
    setLoading(true);
    if (experienceId) {
      let workExperience = state?.candidateWorkExperience?.find(
        (exp) => exp.idExperienciaLaboral === experienceId
      );

      if (!workExperience) {
        const response = await getWorkExperience(
          returnLangId(i18next.languages[0])
        );

        if (response.status === 200) {
          const result = await response.json();

          workExperience = result.find(
            (exp) => exp.idExperienciaLaboral === experienceId
          );
        } else {
          switch (response.status) {
            case 404:
              props.enqueueSnackbar(
                t("work_experience.get_work_experience_not_found"),
                {
                  variant: "error",
                }
              );
              break;
            default:
              props.enqueueSnackbar(t("work_experience.get_functions_error"), {
                variant: "error",
              });
              break;
          }
        }
      }

      if (workExperience) {
        fillWorkExperienceData(workExperience);
      }
    }
    setLoading(false);
  }

  const returnLabel = useCallback((x) => {
    return x.nombre || x.name || "";
  }, []);

  async function handleGetFunctions() {
    setLoading(true);
    const response = await getFunction();
    if (response.status === 200) {
      const result = await response.json();
      setAllFunctions(result.funciones);
    } else {
      if (response.status !== 404) {
        setFunctionsError(t("work_experience.get_functions_error"));
      }
    }
    setLoading(false);
  }

  async function handleGetEtts() {
    setLoading(true);
    const response = await getEtts();
    if (response.status === 200) {
      const result = await response.json();
      setAllEtts(result);
    } else {
      if (response.status !== 404) {
        setFunctionsError(t("work_experience.get_functions_error"));
      }
    }
    setLoading(false);
  }

  async function handleGetKnowledges() {
    setLoading(true);
    const response = await getKnowledge();
    if (response.status === 200) {
      const result = await response.json();
      setAllKnowledges(result.conocimientos);
    } else {
      if (response.status !== 404) {
        setKnowledgeError(t("work_experience.get_knowledge_error"));
      }
    }
    setLoading(false);
  }

  function getTypeOfLevel(){
    return level && 
           level?.nombre !== INTERN && 
           level?.nombre !== EMPLOYEE;
  }

  async function handleGetLevels(){
    setLoading(true);
    const response = await getLevelExperiencie();
    if (response.status === 200) {
      const result = await response.json();
      setAllLevels(result.niveles);
    } else {
      setLevelsError(t("work_experience.get_level_error"));
    }
    setLoading(false);
  }

  async function handleGetStaff() {
    setLoading(true);
    const response = await getStaffInCharge();
    if (response.status === 200) {
      const result = await response.json();
      setAllStaff(result.personalACargo);
    } else {
      setStaffError(t("work_experience.get_staff_error"));
    }
    setLoading(false);
  }

  async function handleGetDirectManagers() {
    setLoading(true);
    const response = await getDirectManger();
    if (response.status === 200) {
      const result = await response.json();
      setAllManagers(result.nivelesResponsable);
    } else {
      setManagerError(t("work_experience.get_manager_error"));
    }
    setLoading(false);
  }

  async function handleGetPlaces(languageId) {
    setLoading(true);

    const response = await getPlace(languageId);

    if (response.status === 200) {
      const result = await response.json();
      setAllPlaces(result.puestos);
    } else {
      switch (response.status) {
        case 404:
          props.enqueueSnackbar(t("work_experience.get_places_not_found"), {
            variant: "error",
          });
          break;
        default:
          props.enqueueSnackbar(t("work_experience.get_place_error"), {
            variant: "error",
          });
          break;
      }
    }
    setLoading(false);
  }

  async function handleGetSectors(languageId) {
    setLoading(true);

    const response = await getSectors(languageId);

    if (response.status === 200) {
      const result = await response.json();
      setAllSectors(result.sectores);
    } else {
      switch (response.status) {
        case 404:
          props.enqueueSnackbar(t("work_experience.get_sectors_not_found"), {
            variant: "error",
          });
          break;
        default:
          props.enqueueSnackbar(t("work_experience.get_sectors_error"), {
            variant: "error",
          });
          break;
      }
    }
    setLoading(false);
  }

  const minDate = new Date().setFullYear(
    Number(new Date().getFullYear()) - 1000
  );

  const validateTypeSalary = () => {
    return (minSalary || maxSalary) && !typeSalary;
  };

  const validateStaff_Manager = () => {
    let noExistStaff = !staffInCharge && level?.nombre != SPECIALIST;
    let noExitsManager = !directManger && level?.nombre == SPECIALIST;
    let isIntermediate = (!staffInCharge || !directManger) && level?.nombre == INTERMEDIATE;

    return getTypeOfLevel() && noExistStaff || noExitsManager || isIntermediate;
  };

  const getValidationErrors = (isReferenceValidation = false) => {
    if (!isReferenceValidation) {
      if (
        !sector ||
        !job ||
        job.id === 0 ||
        !company ||
        company.replace(/\s/g, "") === "" ||
        !startDate ||
        (startDate && isNaN(new Date(startDate).getTime())) ||
        (!isWorkingHere && !endDate) ||
        (!isWorkingHere && endDate && isNaN(new Date(endDate).getTime())) ||
        (!isWorkingHere && new Date(startDate) > new Date(endDate)) ||
        startDate > new Date() ||
        (!isWorkingHere && endDate > new Date()) ||
        validateTypeSalary() ||
        validateStaff_Manager()
      ) {
        setSaveWrong(true);
        if (hasReferences && references.length < 1) {
          setSaveWrongReferences(true);
        }
        return true;
      }
    } else if (
      hasReferences &&
      (referenceName.replace(/\s/g, "") === "" ||
        referencePhoneNumber.replace(/\s/g, "") === "") &&
      (referenceName.replace(/\s/g, "") === "" ||
        referenceEmail.replace(/\s/g, "") === "")
    ) {
      setSaveWrongReferences(true);
      return true;
    }
    setSaveWrongReferences(false);
    return false;
  };

  const fillWorkExperienceData = (workExperience) => {
    workExperience.sector.id = workExperience.sector.idSector;
    delete workExperience.sector.idSector;
    setSector(workExperience.sector);
    setJob(workExperience.puesto);
    setlevel(workExperience.nivel);
    setIsEtt(workExperience.esEtt);
    setEtt(workExperience.ett);
    setCompany(workExperience.empresa);
    setMinSalary(workExperience.salarioMinimo);
    setMaxSalary(workExperience.salarioMaximo);
    setTypeSalary(workExperience.tipoSalario);
    setStaffInCharge(workExperience.personalACargo);
    setDirectManger(workExperience.nivelResponsable);
    setDescripcionPuesto(workExperience.descripcionPuesto);
    workExperience.funciones = workExperience.funciones.map((f) => {
      f.id = f.idFuncion;
      delete f.idFuncion;
      return f;
    });
    setFunctions(workExperience.funciones);
    setIsWorkingHere(workExperience.trabajoActual);
    setStartDate(
      workExperience.fechaInicio ? workExperience.fechaInicio : null
    );
    if (
      workExperience.fechaFin?.split("T")[0].split("-")[0] ??
      false !== "0001"
    ) {
      setEndDate(
        workExperience.fechaFin ? workExperience.fechaFin : null
      );
    }
    workExperience.conocimientos = workExperience.conocimientos.map((c) => {
      c.id = c.idConocimiento;
      delete c.idConocimiento;
      return c;
    });
    setKnowledges(workExperience.conocimientos);
    setHasReferences(workExperience.referencias.length > 0);
    setReferences(workExperience.referencias);
  };

  const clearFields = (onlyReferences = false) => {
    setReferenceName("");
    setReferencePhonePrefix(
      {
        "id": "724",
        "name": "España",
        "prefix": "34"
      }
    );
    setReferencePhoneNumber("");
    setReferenceEmail("");
    if (!onlyReferences) {
      setSector(null);
      setJob(null);
      setlevel(null);
      setMaxSalary(null);
      setMinSalary(null);
      setTypeSalary(null);
      setCompany("");
      setFunctions([]);
      setIsWorkingHere(false);
      setStartDate(null);
      setEndDate(null);
      setKnowledges([]);
      setReferences([]);
      setHasReferences(false);
      setStaffInCharge(null);
      setDirectManger(null);
      setDescripcionPuesto("");
    }
  };

  const addReference = (fromText = false) => {
    if (fromText) {
      setAddNewExperience(true);
    }
    const errors = getValidationErrors(true);
    if (!errors) {
      const newReference = {
        nombre: referenceName,
        telefono:
          referencePhoneNumber && referencePhoneNumber.trim() !== ""
            ? {
                prefijo: referencePhonePrefix.prefix,
                numero: referencePhoneNumber,
              }
            : null,
        correoElectronico: referenceEmail.trim() !== "" ? referenceEmail : null,
      };
      const updatedReferences = [...references, newReference];
      setReferences(updatedReferences);
      clearFields(true);
      return updatedReferences;
    } else {
      return references;
    }
  };

  async function handleDeleteWorkExperience() {
    setLoading(true);
    const response = await deleteWorkExperience(
      returnLangId(i18next.languages[0]),
      props.match.params.experienceId
    );
    if (response.status === 200) {
      props.history.push("/curriculum");
    } else {
      switch (response.status) {
        case 400:
          props.enqueueSnackbar(
            t("work_experience.delete_error_incorrect_entry_data"),
            {
              variant: "error",
            }
          );
          break;
        case 404:
          props.enqueueSnackbar(
            t("work_experience.delete_error_candidate_not_found"),
            {
              variant: "error",
            }
          );
          break;
        default:
          props.enqueueSnackbar(t("work_experience.error"), {
            variant: "error",
          });
          break;
      }
    }
  }

  async function handleSaveData(addMore = false) {
    if (!getValidationErrors()) {
      setLoading(true);

      let newReferences;
      if (hasReferences) {
        if (referenceName.trim() !== "") {
          newReferences = addReference();
        } else {
          newReferences = references;
        }
      }
      let data = {
        idSector: Number(sector?.id),
        idPuesto: Number(job?.id),
        idEtt: ett ? ett.id : null,
        empresa: company,
        funciones: functions.map((fun) =>
          fun.id
            ? { idFuncion: fun.id, nombre: fun.nombre }
            : { idFuncion: 0, nombre: fun.nombre }
        ),
        idNivel: Number(level?.id),
        conocimientos: knowledges.map((k) =>
          k.id
            ? { idConocimiento: k.id, nombre: k.nombre }
            : { idConocimiento: 0, nombre: k.nombre}
        ),
        referencias: hasReferences ? newReferences : null,
        fechaInicio: startDate,
        fechaFin: isWorkingHere ? null : endDate,
        trabajoActual: isWorkingHere,
        idSalarioMinimo: Number(minSalary?.id),
        idSalarioMaximo: Number(maxSalary?.id),
        idTipoSalario: Number(typeSalary?.id),
        idPersonalACargo: Number(staffInCharge?.id),
        idNivelResponsable: Number(directManger?.id),
        descripcionPuesto: descripcionPuesto
      };

      let response;

      if (props.match.params.experienceId) {
        response = await updateWorkExperience(
          returnLangId(i18next.languages[0]),
          props.match.params.experienceId,
          data
        );
      } else {
        response = await addWorkExperience(
          returnLangId(i18next.languages[0]),
          data
        );
      }

      if (response.status === 200) {
        if (addMore) {
          setSnackbarText(t("work_experience.save_success"));
          setIsSnackBarOpen(true);

          clearFields();
          if (props.match.params.experienceId) {
            props.history.push("/work_experience");
          }

          setLoading(false);
        } else {
          props.history.push("/curriculum");
        }
      } else {
        switch (response.status) {
          case 400:
            props.enqueueSnackbar(
              t("work_experience.save_error_incorrect_entry_data"),
              {
                variant: "error",
              }
            );
            break;
          case 404:
            props.enqueueSnackbar(
              t("work_experience.save_error_candidate_not_found"),
              {
                variant: "error",
              }
            );
            break;
          default:
            props.enqueueSnackbar(t("work_experience.error"), {
              variant: "error",
            });
            break;
        }
        setLoading(false);
      }
    } else {
      props.enqueueSnackbar(t("common.data_error"), {
        variant: "error",
      });
    }
  }

  function handleDelete(index) {
    var referencesAux = [...references];
    if (index !== -1) {
      referencesAux.splice(index, 1);
      setReferences(referencesAux);
      if (referencesAux.length === 0) {
        setHasReferences(false);
      }
    }
  }

  function referenceForm(values = {}, key, readOnly = false) {
    return (
      <>
        <div className={classes.reference_grid} key={key}>
          <div className={classes.form_grid_nombre}>
            <InputWithLabel
              fullWidth
              wrong={
                saveWrongReferences &&
                hasReferences &&
                referenceName === "" &&
                !readOnly
              }
              customClass={classes.input}
              label={t("work_experience.name")}
              value={values?.nombre}
              setter={(e) => setReferenceName(e.target.value)}
              moveLabel={true}
              disabled={readOnly}
              maxLength={250}
            />
          </div>
          <div className={`${classes.form_grid_telefono}`}>
            <FormControl variant="outlined">
              <AutocompleteWithLabel
                disabled={readOnly}
                disableClearable={true}
                value={values ? values.telefono?.prefijo : referencePhonePrefix}
                setter={(value) => {
                  setReferencePhonePrefix(value ? value.prefix.trim() : null);
                }}
                options={phonePrefixes}
                getOption={(x, v) => optionEquality(x, v)}
                getLabel={(x) =>
                  typeof x === "object" && x !== null
                    ? `+${x.prefix}`
                    : x
                    ? `+${
                        phonePrefixes.find(
                          (pr) => pr.prefix.trim() === x.trim()
                        )
                          ? phonePrefixes.find(
                              (pr) => pr.prefix.trim() === x.trim()
                            )?.prefix ?? ""
                          : ""
                      }`
                    : `+${x}`
                }
                useDefaultIcon={true}
                moveLabel={true}
              />
            </FormControl>
            <InputWithLabel
              fullWidth
              wrong={
                saveWrongReferences &&
                hasReferences &&
                referenceEmail.replace(/\s/g, "") === "" &&
                referencePhoneNumber.replace(/\s/g, "") === "" &&
                !readOnly
              }
              customClass={classes.input}
              label={t("work_experience.phoneNumber")}
              value={values?.telefono?.numero}
              setter={(e) => setReferencePhoneNumber(e.target.value)}
              moveLabel={true}
              disabled={readOnly}
              maxLength={10}
              type={"number"}
            />
          </div>
          <div className={classes.form_grid_email}>
            <InputWithLabel
              fullWidth
              wrong={
                saveWrongReferences &&
                hasReferences &&
                referenceEmail.replace(/\s/g, "") === "" &&
                referencePhoneNumber.replace(/\s/g, "") === "" &&
                !readOnly
              }
              customClass={classes.input}
              label={t("work_experience.email")}
              value={values?.correoElectronico}
              setter={(e) => setReferenceEmail(e.target.value)}
              moveLabel={true}
              disabled={readOnly}
              maxLength={100}
            />
          </div>
        </div>
        <div className={classes.add_delete_grid}>
          {(!readOnly ||
            (references.length === key + 1 && !addNewExperience)) && (
            <div className={`${classes.form_grid_add}`}>
              <Typography
                className={classes.add_another_reference_link}
                onClick={() => addReference(true)}
              >
                {t("work_experience.add_another_reference")}
              </Typography>
            </div>
          )}
          {readOnly && (
            <div
              className={`${classes.delete} ${classes.form_grid_delete} ${classes.delete_right}`}
              onClick={() => handleDelete(key)}
            >
              {t("common.delete")}
            </div>
          )}
        </div>
      </>
    );
  }

  function referencesSection() {
    return (
      <div className={classes.form_grid_referencias_container}>
        <div>
          {references.map((r, index) =>
            referenceForm(
              {
                nombre: r.nombre,
                telefono: r.telefono?.prefijo
                  ? {
                      prefijo: phonePrefixes.find(
                        (pref) =>
                          pref.prefix.trim() === r.telefono.prefijo.trim()
                      ),
                      numero: r.telefono.numero.trim(),
                    }
                  : { prefijo: null, numero: "" },
                correoElectronico: r.correoElectronico,
              },
              index,
              true
            )
          )}
          {references.length < 1 || addNewExperience
            ? referenceForm({
                nombre: referenceName,
                telefono: {
                  prefijo: referencePhonePrefix,
                  numero: referencePhoneNumber,
                },
                correoElectronico: referenceEmail,
              })
            : null}
        </div>
      </div>
    );
  }

  function handleSetCompany(value) {
    var regex = new RegExp(
      /^[\wÀ-ÿ\u00f1\u00d1]+(\s*[\wÀ-ÿ\u00f1\u00d1$&+,:;=?@#|'<>.^*()%!-]*)*[\wÀ-ÿ\u00f1\u00d1]*$/
    );
    if (value.trim() === "") {
      setCompany("");
    } else {
      if (regex.test(value)) {
        setCompany(value);
      }
    }
  }

  const handleErrors = (error) => {
    if (error) {
      props.enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  function renderForm() {
    return (
      <div className={classes.form_container}>
        <div className={classes.form_grid_sector}>
          <FormControl
            variant="outlined"
            className={`${classes.autocomplete_input_container}`}
          >
            <AutocompleteWithLabel
              value={sector}
              setter={setSector}
              options={allSectors}
              label={t("work_experience.sector")}
              getOption={(x, v) => optionEquality(x, v)}
              getLabel={(x) => getLabelForSelectComponent(x, "nombre")}
              wrong={saveWrong && !sector}
              useDefaultIcon={true}
              moveLabel={true}
            />
          </FormControl>
        </div>
        {
          isEtt ? (
            <div className={classes.form_grid_ett}>
              <FormControl
                variant="outlined"
                className={`${classes.autocomplete_input_container}`}
              >
                <AutocompleteWithLabel
                  value={ett}
                  setter={setEtt}
                  options={allEtts}
                  label={t("work_experience.ett")}
                  multiple={false}
                  freeSolo={false}
                  getOption={(x, v) => optionEquality(x, v)}
                  getLabel={(x) => getLabelForSelectComponent(x, "nombre")}
                  useDefaultIcon={true}
                  moveLabel={true}
                />
              </FormControl>
            </div>
          ) : null
        }
        <div className={classes.form_grid_puesto}>
          <FormControl
            variant="outlined"
            className={`${classes.autocomplete_input_container}`}
          >
            <AutocompleteWithLabel
              value={job}
              setter={setJob}
              options={allPlaces}
              label={t("work_experience.job")}
              multiple={false}
              freeSolo={false}
              getOption={(x, v) => optionEquality(x, v)}
              getLabel={(x) => getLabelForSelectComponent(x, "nombre")}
              wrong={saveWrong && (!job || job.id === 0)}
              useDefaultIcon={true}
              moveLabel={true}
            />
          </FormControl>
          {matchesMobile ? (
            <div className={classes.form_grid_empresa}>
              <InputWithLabel
                fullWidth
                wrong={
                  saveWrong && (!company || company.replace(/\s/g, "") === "")
                }
                customClass={classes.input}
                label={t("work_experience.company")}
                value={company}
                setter={(e) => handleSetCompany(e.target.value)}
                moveLabel={true}
                maxLength={100}
              />
            </div>
          ) : null}
        </div>
        {!matchesMobile ? (
          <div className={classes.form_grid_empresa}>
            <InputWithLabel
              fullWidth
              wrong={
                saveWrong && (!company || company.replace(/\s/g, "") === "")
              }
              customClass={classes.input}
              label={t("work_experience.company")}
              value={company}
              setter={(e) => handleSetCompany(e.target.value)}
              moveLabel={true}
              maxLength={100}
            />
          </div>
        ) : null}
        <div className={classes.form_grid_nivel}>
          <FormControl
            variant="outlined"
            className={`${classes.autocomplete_input_container}`}
          >
            <AutocompleteWithLabel
              value={level}
              setter={(e)=>{
                            setlevel(e);
                            setDirectManger(null);
                            setStaffInCharge(null);
                      }}
              options={allLevels}
              label={t("work_experience.level")}
              getOption={(x, v) => optionEquality(x, v)}
              getLabel={(x) => getLabelForSelectComponent(x, "nombre")}
              useDefaultIcon={true}
              moveLabel={true}
              onOpen={(e)=>handleErrors(levelsError)}
            />
          </FormControl>
          </div>
        {getTypeOfLevel() &&
          <>
            <div className={`${level?.nombre == INTERMEDIATE ?
              classes.form_grid_responsableDirecto :
              classes.form_grid_personalCargo}`}>
              {(level?.nombre == SPECIALIST || level?.nombre == INTERMEDIATE) &&
                <FormControl
                  variant="outlined"
                  className={`${classes.autocomplete_input_container}`}
                >
                  <AutocompleteWithLabel
                    value={directManger}
                    setter={setDirectManger}
                    options={allManagers}
                    label={t("work_experience.levelDirectSuperior")}
                    getOption={(x, v) => optionEquality(x, v)}
                    getLabel={(x) => getLabelForSelectComponent(x, "nombre")}
                    wrong={saveWrong && validateStaff_Manager()}
                    useDefaultIcon={true}
                    moveLabel={true}
                    onOpen={(e)=>handleErrors(managerError)}
                  />
                </FormControl>
              }
            </div>
            {level?.nombre != SPECIALIST &&
              <div className={`${classes.form_grid_personalCargo}`}>
                <FormControl
                  variant="outlined"
                  className={`${classes.autocomplete_input_container}`}
                >
                  <AutocompleteWithLabel
                    value={staffInCharge}
                    setter={setStaffInCharge}
                    options={allStaff}
                    label={t("work_experience.staffInCharge")}
                    getOption={(x, v) => optionEquality(x, v)}
                    getLabel={(x) => getLabelForSelectComponent(x, "nombre")}
                    wrong={saveWrong && validateStaff_Manager()}
                    useDefaultIcon={true}
                    moveLabel={true}
                    onOpen={(e)=>handleErrors(staffError)}
                  />
                </FormControl>
              </div>
            }
          </>
        }
        <div className={`${classes.form_grid_funciones} ${(level?.nombre != INTERMEDIATE)? classes.space_functions:""}`}>
          <AutocompleteWithChips
            options={allFunctions ? allFunctions : []}
            fieldName={"functions"}
            values={functions}
            label={t("work_experience.functions")}
            setter={setFunctions}
            setterOptions={setAllFunctions}
            onOpen={(e)=>handleErrors(functionsError)}
            moveLabel={true}
            maxLength={250}
          />
        </div>
        <div className={classes.form_grid_tipoSalario}>
          <AutocompleteWithLabel
             value={typeSalary}
             setter={setTypeSalary}
             options={tipos_salarios}
             label={t("work_experience.type_Salary")}
             getOption={(x, v) => optionEquality(x, v)}
             getLabel={returnLabel}
             wrong={saveWrong && validateTypeSalary()}
             useDefaultIcon={true}
             moveLabel={true}
          />
        </div> 
        <FormControl
            variant="outlined"
            className={`${classes.form_grid_salarioMin}`}
          >
            <AutocompleteWithLabel
              value={minSalary}
              setter={(value) => {
                setMinSalary(value);
                setMaxSalary(null);
              }}
              options={rangos_salarios}
              label={t("work_experience.minSalary")}
              getOption={(x, v) => optionEquality(x, v)}
              getLabel={returnLabel}
              useDefaultIcon={true}
              moveLabel={true}
            />
          </FormControl>
          <FormControl
            variant="outlined"
            className={`${classes.form_grid_salarioMax}`}
          >
            <AutocompleteWithLabel
              value={maxSalary}
              setter={setMaxSalary}
              options={filterSalaryRangeOptions(rangos_salarios, minSalary)}
              label={t("work_experience.maxSalary")}
              getOption={(x, v) => optionEquality(x, v)}
              getLabel={returnLabel}
              useDefaultIcon={true}
              moveLabel={true}
            />
          </FormControl>
         
        <div className={classes.form_grid_descripcion_Puesto}>
        <InputWithLabel
          multiline={true}
          rows={6}
          fullWidth
          label={t("work_experience.jobDescription")}
          value={descripcionPuesto}
          setter={(e) => setDescripcionPuesto(e.target.value)}
          moveLabel={true}
          minLength={0}
          maxLength={4000}
        />
        <Typography className={classes.counter_chars_description}>
          {`${descripcionPuesto?.length ?? 0}/${MAX_CHAR_DESCRIPTION} (${t("work_experience.maxChar")} ${MAX_CHAR_DESCRIPTION} ${t("work_experience.char")})`}
        </Typography>
        </div>
        <div
          className={`${classes.switch_with_label} ${classes.form_grid_trabaja_aqui}`}
        >
          <Typography>{t("work_experience.is_working")}</Typography>
          <AntSwitch
            disabled={false}
            checked={isWorkingHere}
            setter={() => setIsWorkingHere(!isWorkingHere)}
          />
        </div>
        <div className={classes.form_grid_fecha_inicio}>
          <div>
            <Typography className={classes.label}>
              {t("work_experience.startDate")}
            </Typography>
            <InputDate
              value={startDate}
              setter={setStartDate}
              minDate={minDate}
              maxDate={new Date()}
              canHideIcon={false}
              wrong={saveWrong && !startDate}
            />
          </div>
          {matchesMobile ? (
            <div className={classes.form_grid_fecha_fin}>
              {!isWorkingHere ? (
                <div>
                  <Typography className={classes.label}>
                    {t("work_experience.endDate")}
                  </Typography>
                  <InputDate
                    value={endDate}
                    setter={setEndDate}
                    canHideIcon={false}
                    minDate={
                      startDate !== "" && validateDate(minDate, null, startDate)
                        ? startDate
                        : minDate
                    }
                    maxDate={new Date()}
                    wrong={saveWrong && !isWorkingHere && !endDate}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        {!matchesMobile ? (
          <div className={classes.form_grid_fecha_fin}>
            {!isWorkingHere ? (
              <div>
                <Typography className={classes.label}>
                  {t("work_experience.endDate")}
                </Typography>
                <InputDate
                  value={endDate}
                  setter={setEndDate}
                  canHideIcon={false}
                  minDate={
                    startDate !== "" && validateDate(minDate, null, startDate)
                      ? startDate
                      : minDate
                  }
                  maxDate={new Date()}
                  wrong={saveWrong && !isWorkingHere && !endDate}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        <div className={classes.form_grid_conocimientos}>
          <Typography className={classes.label}>
            {t("work_experience.knowledges")}
          </Typography>
          <AutocompleteWithChips
            options={allKnowledges}
            values={knowledges ? knowledges : []}
            placeholder={t("work_experience.name")}
            setter={setKnowledges}
            setterOptions={setAllKnowledges}
            onOpen={(e)=>handleErrors(knowledgeError)}
            maxLength={150}
          />
        </div>
        <div
          className={`${classes.switch_with_label} ${classes.form_grid_tengo_referencias}`}
        >
          <Typography>{t("work_experience.has_references")}</Typography>
          <AntSwitch
            disabled={false}
            checked={hasReferences}
            setter={() => setHasReferences(!hasReferences)}
          />
        </div>
        {hasReferences ? referencesSection() : null}
        <div className={classes.form_grid_botonera}>
          <AddToCVActionButtons
            saveAndActionText={
              props.match.params.experienceId
                ? t("work_experience.update_and_add")
                : t("work_experience.save_and_add")
            }
            onSaveClick={() => handleSaveData()}
            onSaveAndActionClick={() => {
              handleSaveData(true);
            }}
            onCancelClick={() => {
              props.history.push("/curriculum");
            }}
            isUpdate={props.match.params.experienceId}
          />
        </div>
        {props.match.params.experienceId ? (
          <div
            className={`${classes.delete_center} ${classes.delete}`}
            onClick={() => setIsDialogOpen(true)}
          >
            {t("common.delete")}
          </div>
        ) : null}
      </div>
    );
  }

  function tittle() {
    return (
      <div className={classes.title_container}>
        <YourExperience />
        <div style={{ marginLeft: "12px" }}>
          <Typography className={classes.title}>
            {t("work_experience.add_experience")}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <Layout history={props.history} loading={loading}>
      <Snackbar
        text={snackbarText}
        isSnackBarOpen={isSnackBarOpen}
        handleIsSnackBarOpen={setIsSnackBarOpen}
      />
      <div className={classes.principal_container}>
        {!matchesMobile ? (
          <TuCurriculumHeader
            nombre={nombre}
            primerApellido={primerApellido}
            segundoApellido={segundoApellido}
            correoElectronico={correoElectronico}
            prefijo={prefijo}
            telefono={telefono}
            fechaModificacion={fechaModificacion}
          />
        ) : (
          <div />
        )}
        <div className={classes.body_container}>
          {tittle()}
          {renderForm()}
        </div>
      </div>
      <AlertDialog
        title={t("work_experience.delete_confirm_message")}
        open={isDialogOpen}
        handleClose={() => {
          setIsDialogOpen(false);
        }}
        handleAccept={() => {
          handleDeleteWorkExperience();
          setIsDialogOpen(false);
        }}
      />
    </Layout>
  );
}

export default withSnackbar(WorkExperience);
