import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const LocationListener = () => {
  const location = useLocation();
  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: location.pathname,
      page_location: location.pathname,
      page_path: location.pathname + location.search,
      send_to: window._env_.GA_MEASUREMENT_ID,
    });
  }, [location]);
  return null;
};

export default LocationListener;
