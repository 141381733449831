import { connect } from "react-redux";
import CandidateDashboard from "../../Views/candidateDashboard";
import { bindActionCreators } from "redux";
import { 
  setSavedSearchAction, 
  clearSavedSearchAction 
} from "../actions/webCandidate"

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setSavedSearchAction: bindActionCreators(setSavedSearchAction, dispatch),
      clearSavedSearchAction: bindActionCreators(clearSavedSearchAction, dispatch),
    }
  };
}

function mapStateToProps(state) {
  return {
    state: state.webCandidate
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDashboard);
