import React from "react";
import { useTranslation } from "react-i18next";

function Facebook(props) {
    const { t } = useTranslation();
    const { title=t(`icons_tooltips.faccebook`), onClick, className } = props;
    return (
        <svg onClick={onClick} className={className}  width="24px" height="24px" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>Facebook</title>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Dashboard-v1" transform="translate(-1103.000000, -1234.000000)" fill="#FFFFFF">
                <path d="M1127,1234 L1127,1258 L1118.736,1258 L1118.736,1249 L1122.176,1249 L1123.176,1245 L1118.736,1245 L1118.736,1242.771 C1118.736,1241.723 1119.036,1241.008 1120.536,1241.008 L1120.536,1241.008 L1122.459,1241.008 L1122.459,1237.768 C1121.52897,1237.66934 1120.59424,1237.6216 1119.659,1237.62483 C1116.893,1237.62483 1115,1239.313 1115,1242.413 L1115,1242.413 L1115,1245 L1112,1245 L1112,1249 L1115,1249 L1115,1258 L1103,1258 L1103,1234 L1127,1234 Z" id="path-1"></path>
                    <use id="ico_facebook" fill="#FFFFFF" href="#path-1"></use>
                </g>
            </g>
        </svg>
    );
}

export default Facebook;
