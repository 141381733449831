import React from "react";
import { useTranslation } from "react-i18next";

function PerfilDigitalFacebook(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.facebook")} = props;

    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <defs>
                <rect id="path-1" x="0" y="0" width="24" height="24"></rect>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Recursos" transform="translate(-178.000000, -1858.000000)">
                    <g id="ico_facebook" transform="translate(178.000000, 1858.000000)">
                        <mask id="mask-2" fill="white">
                            <use href="#path-1"></use>
                        </mask>
                        <use id="Rectangle" fill="#DA291C" href="#path-1"></use>
                        <path d="M12,24 L12,15 L9,15 L9,11 L12,11 L12,8.413 C12,5.313 13.893,3.62483028 16.659,3.62483028 C17.5942421,3.62159828 18.5289706,3.6693362 19.459,3.768 L19.459,7.008 L17.536,7.008 C16.036,7.008 15.736,7.723 15.736,8.771 L15.736,11 L20.176,11 L19.176,15 L15.736,15 L15.736,24 L12,24 Z" id="Path" fill="#FFFFFF" mask="url(#mask-2)"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default PerfilDigitalFacebook;