import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  snackBar: {
    backgroundColor: "#FFFFFF",
    color: "#000000",
  },
  snackBar_alert: {
    color: theme.palette.primary.main,
  },
  snackBar_icon: {
    display: "none",
  },
}));

function CustomSnackbar(props) {
  const classes = useStyles(props);
  const { text, isSnackBarOpen, handleIsSnackBarOpen, indeed = false } = props;

  const Alert = (props) => (
    <MuiAlert elevation={6} variant="filled" {...props} />
  );

  return (
    <Snackbar
      open={isSnackBarOpen}
      autoHideDuration={5000}
      onClose={() => {
        handleIsSnackBarOpen(false);
      }}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        severity="success"
        onClose={() => {
          handleIsSnackBarOpen(false);
        }}
        classes={{
          filledSuccess: classes.snackBar,
          icon: classes.snackBar_icon,
          action: classes.snackBar_alert,
        }}
      >
        {text}
        {indeed && isSnackBarOpen ? (
          <img
            height={1}
            width={1}
            border={0}
            src={"//conv.indeed.com/pagead/conv/4033635675136975/?script=0"}
          />
        ) : null}
      </Alert>
    </Snackbar>
  );
}

export default CustomSnackbar;
