import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import ButtonFull from "./buttonFull";

const styles = (theme) => ({
  error_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px 6px",
  },
  error_text: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 24,
  },
});

class ErrorBoundaryWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorText: "" };
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.children.key) {
      if (
        this.props.children.key !== nextProps.children.key &&
        this.state.hasError
      ) {
        this.setState({ hasError: false, errorText: "" });
        return true;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  componentDidCatch(error, info) {
    if (this.props.errorAction) {
      this.props.errorAction();
    }
    this.setState({ hasError: true, errorText: this.props.errorText });
  }

  handleGoBack() {
    this.props.history.goBack();
    this.setState({ hasError: false, errorText: "" });
  }

  render() {
    const { classes, t } = this.props;
    if (this.state.hasError) {
      return (
        <div className={classes.error_container}>
          <Typography variant="body1" className={classes.error_text}>
            {this.state.errorText}
          </Typography>
          {this.props.showButton ? (
            <ButtonFull
              text={t("common.return")}
              size="300px"
              onClick={() => this.handleGoBack()}
            />
          ) : null}
        </div>
      );
    }
    return this.props.children;
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(ErrorBoundaryWrapper)
);
