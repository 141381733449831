import { connect } from "react-redux";
import SearchOffers from "../../Views/searchOffers";
import { bindActionCreators } from "redux";
import {setSearchOfferSnapshot, clearSearchOfferSnapshot, setFiltersToSaveAction, clearFiltersToSaveAction, clearSuccesfulSearchSave, clearSavedSearchAction } from "../actions/webCandidate"

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setSnapshot: bindActionCreators(setSearchOfferSnapshot, dispatch),
      clearSnapshot: bindActionCreators(clearSearchOfferSnapshot, dispatch),
      setFiltersToSaveAction: bindActionCreators(setFiltersToSaveAction, dispatch),
      clearFiltersToSaveAction: bindActionCreators(clearFiltersToSaveAction, dispatch),
      clearSuccesfulSave: bindActionCreators(clearSuccesfulSearchSave, dispatch),
      clearSavedSearchAction: bindActionCreators(clearSavedSearchAction, dispatch)
    }
  };
}

function mapStateToProps(state) {
  return {
    state: state.webCandidate,
    paises: state.maestros.paises,
    localidades: state?.maestros?.localidades
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchOffers);
