import React from "react";
import { useTranslation } from "react-i18next";

function PerfilDigitalInstagram(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.instagram")} = props;

    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Recursos" transform="translate(-250.000000, -1858.000000)">
                    <g id="ico_instagram" transform="translate(250.000000, 1858.000000)">
                        <polygon id="Path" fill="#DA291C" points="24 1.96220357e-05 -4.08562073e-14 1.96220356e-05 -4.08562073e-14 24.0000196 24 24.0000196"></polygon>
                        <g id="logo-instagram" transform="translate(4.000000, 4.000000)" fill="#FFFFFF" fillRule="nonzero">
                            <circle id="Oval" cx="12.145" cy="3.892" r="1"></circle>
                            <path d="M8,12 C5.794,12 4,10.206 4,8 C4,5.794 5.794,4 8,4 C10.206,4 12,5.794 12,8 C12,10.206 10.206,12 8,12 Z M8,6 C6.897,6 6,6.897 6,8 C6,9.103 6.897,10 8,10 C9.103,10 10,9.103 10,8 C10,6.897 9.103,6 8,6 Z" id="Shape"></path>
                            <path d="M12,16 L4,16 C1.944,16 0,14.056 0,12 L0,4 C0,1.944 1.944,0 4,0 L12,0 C14.056,0 16,1.944 16,4 L16,12 C16,14.056 14.056,16 12,16 Z M4,2 C3.065,2 2,3.065 2,4 L2,12 C2,12.953 3.047,14 4,14 L12,14 C12.935,14 14,12.935 14,12 L14,4 C14,3.065 12.935,2 12,2 L4,2 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default PerfilDigitalInstagram;