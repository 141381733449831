import { connect } from "react-redux";
import AutocompleteWithChips from "../../components/autocompleteWithChips";

function mapDispatchToProps(dispatch) {
  return {
    actions: {
    }
  };
}

function mapStateToProps(state) {
  return {
    paises: state?.maestros.paises
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteWithChips);
