import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useMemo,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CandidaturasIcon from "../assets/icons/candidaturasIcon";
import Eye from "../assets/icons/eye";
import OffersSearcher from "../components/offersSearcher";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import i18next from "../i18n.js";
import ShowMoreIcon from "../assets/icons/downwardMore";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getTimeFilters, getStatusFilters, returnLangId } from "../utils/functions";
import { useTheme } from "@material-ui/core/styles";
import Clear from "@material-ui/icons/ClearRounded";
import Done from "@material-ui/icons/DoneRounded";
import Loading from "../components/loading";
import Layout from "../components/basicLayout";
import { useTranslation } from "react-i18next";
import { withSnackbar } from "notistack";
import { getCandidaturas } from "../api/candidato";

const timeFilters=getTimeFilters(i18next.languages[0]);
const statusFilters=getStatusFilters(i18next.languages[0]);

const useStyles = makeStyles((theme) => ({
  body_info_container: {
    gridArea: "body_container",
    display: "grid",
    gridTemplateColumns: "30% minMax(0,1fr)",
    columnGap: 135,
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateRows: "auto",
      gridTemplateColumns: "1fr",
    },
  },
  body_info_left_col: {
    display: "grid",
    gridTemplateRows: "315px auto",
    rowGap: "33px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateRows: "minMax(0, 1fr)",
      padding: "0 20px",
    },
  },
  summary_container: {
    display: "grid",
    rowGap: "20px",
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  summary: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  summary_icon_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  summary_title: {
    fontSize: 36,
    lineHeight: "46px",
    fontWeight: 900,
    marginTop: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      lineHeight: "30px",
      marginTop: 35,
    },
  },
  summary_title_empty: {
    fontSize: 36,
    lineHeight: "46px",
    fontWeight: 900,
    marginTop: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      lineHeight: "30px",
      marginTop: 35,
      marginLeft: 20,
    },
    width: "50%",
  },
  candidaturas_list_container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      paddingBottom: 40,
    },
  },
  candidatura_container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 20px",
    "& + &": {
      marginTop: 25,
    },
  },
  candidatura_title_container: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 22,
  },
  candidatura_title_text: {
    fontSize: 22,
    lineHeight: "30px",
    fontWeight: 900,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "21px",
    },
  },
  dots_container: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    backgroundColor: "transparent",
    "&:before": {
      content: "''",
      width: "var(--black-width)",
      height: "1px",
      backgroundColor: "black",
      position: "absolute",
      left: 0,
    },
    "&:after": {
      content: "''",
      width: "var(--gray-width)",
      height: "1px",
      backgroundColor: "#DDDDDD",
      position: "absolute",
      right: 0,
    },
  },
  dot: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: "var(--dot-color)",
    flexShrink: 0,
    zIndex: 100,
    transition: "background-color 0.3s ease, border 0.3s ease",
    cursor: "pointer",
    border: "1px solid var(--dot-border-color)",
  },
  dot_empty: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: "var(--dot-color)",
    flexShrink: 0,
    zIndex: 100,
    transition: "background-color 0.3s ease, border 0.3s ease",
    border: "1px solid var(--dot-border-color)",
  },
  dot_offer_closed: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: "#DDDDDD",
    flexShrink: 0,
    zIndex: 100,
    transition: "background-color 0.3s ease, border 0.3s ease",
    cursor: "pointer",
    border: "1px solid #DDDDDD",
  },
  dot_hover: {
    "&:hover": {
      backgroundColor: "white",
      border: "1px solid black",
    },
    "&:hover $dot_icon": {
      color: "black",
    },
  },
  dot_icon: {
    height: 16,
    width: 16,
    padding: 0,
    color: "white",
    transition: "color 0.3s ease, border 0.3s ease",
  },
  candidatura_footer_container: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1fr auto",
    marginTop: 18,
  },
 
  candidatura_footer_text: {
    fontSize: 14,
    lineHeight: "21px",
    whiteSpace: "pre-line",
  },
  full_two_columns: {
    gridColumnStart: "unset",
    [theme.breakpoints.down("sm")]: {
      gridColumnStart: "span 2",
    },
  },
  candidatura_footer_text_date: {
    fontSize: 14,
    lineHeight: "21px",
    flexShrink: 0,
  },
  show_more: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-start",
    cursor: "pointer",
    marginTop: 20,
  },
  text_show_more: {
    fontSize: 14,
    lineHeight: "28px",
    color: "#DA291C",
    textAlign: "left",
    padding: 0,
    margin: 0,
    marginRight: 12,
  },
  filters_container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FBFBFB",
    padding: "4px 20px",
    marginBottom: 28,
    [theme.breakpoints.down("sm")]: {
      marginTop: 14,
      marginBottom: 21,
    },
  },
  filters_time_container: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  search_container: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
      paddingBottom: 40,
    },
  },
}));

function Candidaturas(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { state, actions } = props;

  const [containerSize, setContainerSize] = useState(null);
  const [percentDot, setPercentDot] = useState(null);
  const [activeState, setActiveState] = useState(null);
  const [candidaturesList, setCandidaturesList] = useState(null);
  const [candidatureListToShow, setCandidatureListToShow] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [loadingCandidatures, setLoadingCandidatures] = useState(false);

  const [statusFilter, setStatusFilter] = useState(0);
  const [timeFilter, setTimeFilter] = useState(1);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuType, setMenuType] = useState(null);

  const oldActive = useRef(null);
  const currentTime = useRef(1);

  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (state) {
      if (state.cSnapshot) {
        const s = state.cSnapshot;
        setActiveState(s.activeState);
        setCandidaturesList(s.candidaturesList);
        setCandidatureListToShow(s.candidatureListToShow);
        setShowMore(s.showMore);
        setStatusFilter(s.statusFilter);
        setTimeFilter(s.timeFilter);
        actions.clearSnapshot();
      } else {
        if (!loadingCandidatures) {
          handleGetCandidaturas();
        }
      }
    }
  }, [state]);

  useEffect(() => {
    if (containerSize) {
      setPercentDot((24 / containerSize) * 100);
    }
  }, [containerSize]);

  useEffect(() => {
    if (currentTime.current !== 0) {
      if (currentTime.current < timeFilter) {
        handleGetCandidaturas();
        currentTime.current = timeFilter;
      } else if (timeFilter === 0) {
        handleGetCandidaturas();
        currentTime.current = timeFilter;
      }
    }
  }, [timeFilter]);

  const candidaturesListContainer = useRef(null);

  useMemo(() => {
    if (candidaturesList) {
      const filtered = filterCandidaturesByStatus(filterCandidaturesByTime());
      let actives = [];
      filtered.forEach((c) => {
        actives.push(c.listaEstadosCandidatura.length - 1);
      });
      setActiveState(actives);
      setCandidatureListToShow(filtered);
      oldActive.current = actives;
    }
  }, [candidaturesList, timeFilter, statusFilter]);

  function updateSize() {
    setContainerSize(candidaturesListContainer.current.offsetWidth);
  }

  useLayoutEffect(() => {
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  async function handleGetCandidaturas() {
    setLoadingCandidatures(true);
    const response = await getCandidaturas(
      returnLangId(i18next.languages[0]),
      timeFilter,
      0
    );
    if (response.status === 200) {
      const result = await response.json();
      let arr = result.slice(0);
      arr.forEach((c, index, a) => {
        let newListado = [...c.listaEstadosCandidatura];
        if (newListado[1] && Number(newListado[1].idEstado) === 3) {
          newListado.splice(1, 0, {
            ...newListado[1],
            idEstado: 2,
            esActual: false,
          });
        }
        a[index] = { ...c, listaEstadosCandidatura: newListado };
      });
      setCandidaturesList(arr.sort(orderCandidaturesFromService));
      setLoadingCandidatures(false);
    } else {
      props.enqueueSnackbar(t("candidaturas.error"), {
        variant: "error",
      });
      setLoadingCandidatures(false);
    }
  }

  function orderCandidaturesFromService(a, b) {
    if (a.fechaInscripcion > b.fechaInscripcion) {
      return -1;
    }
    if (a.fechaInscripcion < b.fechaInscripcion) {
      return 1;
    }
    return 0;
  }

  function filterCandidaturesByTime() {
    if (timeFilter !== 0) {
      return candidaturesList.filter((c) => {
        let months;
        const d2 = new Date();
        const d1 = new Date(c.fechaInscripcion);
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        switch (timeFilter) {
          case 1:
            return months <= 1;
          case 2:
            return months <= 3;
          case 3:
            return months <= 6;
          case 4:
            return months <= 12;
        }
      });
    } else {
      return candidaturesList;
    }
  }

  function filterCandidaturesByStatus(list) {
    if (statusFilter !== 0) {
      return list.filter((l) => {
        return (
          Number(
            l.listaEstadosCandidatura[l.listaEstadosCandidatura.length - 1]
              .idEstado
          ) === Number(statusFilter)
        );
      });
    } else {
      return list;
    }
  }

  function formatDate(date) {
    const d = date.split("T")[0].split("-");
    return `${d[2]}-${d[1]}-${d[0]}`;
  }

  function emptyDot() {
    return (
      <div
        className={classes.dot_empty}
        style={{
          "--dot-color": "white",
          "--dot-border-color": "#DDDDDD",
        }}
      />
    );
  }

  function handleDotClick(index1, index2) {
    let aux = activeState.slice();
    aux[index1] = index2;
    setActiveState(aux.slice());
    oldActive.current = aux.slice();
  }

  function handleDotEnter(index1, index2) {
    if (!matchesMobile) {
      let aux = activeState.slice();
      aux[index1] = index2;
      setActiveState(aux.slice());
    }
  }

  function handleDotLeave() {
    if (!matchesMobile) {
      setActiveState(oldActive.current);
    }
  }

  function handleEyeClick(oferta) {
    const snapshot = {
      activeState: activeState,
      candidaturesList: candidaturesList,
      candidatureListToShow: candidatureListToShow,
      showMore: showMore,
      statusFilter: statusFilter,
      timeFilter: timeFilter,
    };
    actions.setSnapshot(snapshot);
    props.history.push(`/offer/${oferta}`);
  }

  function renderCandidaturas() {
    let elements;
    if (candidaturesList && candidaturesList.length > 0) {
      let listToRender = [];
      if (showMore) {
        listToRender = candidatureListToShow;
      } else {
        listToRender = candidatureListToShow.slice(0, 20);
      }
      elements = listToRender.map((c, index) => {
        let blackWidth;
        let grayWidth;
        if (
          c.listaEstadosCandidatura.length !== 0 &&
          c.listaEstadosCandidatura.length < 4
        ) {
          blackWidth = 31.25 * (c.listaEstadosCandidatura.length - 1);
        } else {
          blackWidth = 25 * c.listaEstadosCandidatura.length;
        }

        grayWidth = 100 - blackWidth;

        if (!c.pedidoAbierto) {
          return (
            <div className={classes.candidatura_container} key={index}>
              <div className={classes.candidatura_title_container}>
                <Typography
                  variant="body1"
                  className={classes.candidatura_title_text}
                >
                  {c.titulo}
                </Typography>
                <Eye
                  title={t("icons_tooltips.eye")}
                  onClick={() => handleEyeClick(c.idOferta)}
                />
              </div>
              <div
                className={classes.dots_container}
                style={{
                  "--black-width": "0%",
                  "--gray-width": "100%",
                }}
              >
                {c.listaEstadosCandidatura[0] ? (
                  <div
                    className={`${classes.dot_offer_closed} ${
                      matchesMobile ? "" : classes.dot_hover
                    }`}
                    onClick={() => handleDotClick(index, 0)}
                    onMouseEnter={() => handleDotEnter(index, 0)}
                    onMouseLeave={handleDotLeave}
                  >
                    {Number(c.listaEstadosCandidatura[0].idEstado) === 5 ? (
                      <div title={t("icons_tooltips.discarded")}>
                        <Clear color="secondary" className={classes.dot_icon} />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  emptyDot()
                )}
                {c.listaEstadosCandidatura[1] ? (
                  <div
                    className={`${classes.dot_offer_closed} ${
                      matchesMobile ? "" : classes.dot_hover
                    }`}
                    onClick={() => handleDotClick(index, 1)}
                    onMouseEnter={() => handleDotEnter(index, 1)}
                    onMouseLeave={handleDotLeave}
                  >
                    {Number(c.listaEstadosCandidatura[1].idEstado) === 5 ? (
                      <div title={t("icons_tooltips.discarded")}>
                        <Clear color="secondary" className={classes.dot_icon} />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  emptyDot()
                )}
                {c.listaEstadosCandidatura[2] ? (
                  <div
                    className={`${classes.dot_offer_closed} ${
                      matchesMobile ? "" : classes.dot_hover
                    }`}
                    onClick={() => handleDotClick(index, 2)}
                    onMouseEnter={() => handleDotEnter(index, 2)}
                    onMouseLeave={handleDotLeave}
                  >
                    {Number(c.listaEstadosCandidatura[2].idEstado) === 5 ? (
                      <div title={t("icons_tooltips.discarded")}>
                        <Clear color="secondary" className={classes.dot_icon} />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  emptyDot()
                )}
                {c.listaEstadosCandidatura[3] ? (
                  <div
                    className={`${classes.dot_offer_closed} ${
                      matchesMobile ? "" : classes.dot_hover
                    }`}
                    onClick={() => handleDotClick(index, 3)}
                    onMouseEnter={() => handleDotEnter(index, 3)}
                    onMouseLeave={handleDotLeave}
                  >
                    {Number(c.listaEstadosCandidatura[3].idEstado) !== 5 ? (
                      <div title={t("icons_tooltips.done")}>
                        <Done color="secondary" className={classes.dot_icon} />
                      </div>
                    ) : (
                      <div title={t("icons_tooltips.discarded")}>
                        <Clear color="secondary" className={classes.dot_icon} />
                      </div>
                    )}
                  </div>
                ) : (
                  emptyDot()
                )}
              </div>
              <div className={classes.candidatura_footer_container}>
                <Typography
                  variant="body1"
                  className={classes.candidatura_footer_text}
                >
                  {activeState
                    ? statusFilters.find(
                        (s) =>
                          Number(s.id) ===
                          Number(
                            c.listaEstadosCandidatura[activeState[index]]
                              .idEstado
                          )
                      )?.name ?? ""
                    : ""}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.candidatura_footer_text_date}
                >
                  {activeState
                    ? formatDate(
                        c.listaEstadosCandidatura[activeState[index]]
                          .fechaModificacion
                      )
                    : ""}
                </Typography>
                <Typography
                  variant="body1"
                  className={
                    classes.candidatura_footer_text +
                    " " +
                    classes.full_two_columns
                  }
                >
                  {c.listaEstadosCandidatura[3] &&
                  c.listaEstadosCandidatura[3].idEstado === 4
                    ? t("candidaturas.selected")
                    : !c.pedidoAbierto && !c.listaEstadosCandidatura[3]
                    ? t("candidaturas.rejected")
                    : null}
                </Typography>
              </div>
            </div>
          );
        } else {
          return (
            <div className={classes.candidatura_container} key={index}>
              <div className={classes.candidatura_title_container}>
                <Typography
                  variant="body1"
                  className={classes.candidatura_title_text}
                >
                  {c.titulo}
                </Typography>
                <Eye
                  title={t("icons_tooltips.eye")}
                  onClick={() => handleEyeClick(c.idOferta)}
                />
              </div>
              <div
                className={classes.dots_container}
                style={{
                  "--black-width":
                    c.listaEstadosCandidatura.length !== 0 &&
                    c.listaEstadosCandidatura.length < 4
                      ? `${
                          blackWidth +
                          Math.max(
                            Math.min(2, Math.max(0, percentDot)),
                            Math.min(0, percentDot)
                          )
                        }%`
                      : `${blackWidth}%`,
                  "--gray-width":
                    c.listaEstadosCandidatura.length !== 0 &&
                    c.listaEstadosCandidatura.length < 4
                      ? `${
                          grayWidth -
                          Math.max(
                            Math.min(2, Math.max(0, percentDot)),
                            Math.min(0, percentDot)
                          )
                        }%`
                      : `${grayWidth}%`,
                }}
              >
                {c.listaEstadosCandidatura[0] ? (
                  <div
                    onClick={() => handleDotClick(index, 0)}
                    onMouseEnter={() => handleDotEnter(index, 0)}
                    onMouseLeave={handleDotLeave}
                    className={`${classes.dot} ${
                      matchesMobile ? "" : classes.dot_hover
                    }`}
                    style={{
                      "--dot-color":
                        oldActive.current[index] === 0 ? "#DA291C" : "black",
                      "--dot-border-color": "transparent",
                    }}
                  >
                    {Number(c.listaEstadosCandidatura[0].idEstado) === 5 ? (
                      <div title={t("icons_tooltips.discarded")}>
                        <Clear color="secondary" className={classes.dot_icon} />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  emptyDot()
                )}
                {c.listaEstadosCandidatura[1] ? (
                  <div
                    onClick={() => handleDotClick(index, 1)}
                    onMouseEnter={() => handleDotEnter(index, 1)}
                    onMouseLeave={handleDotLeave}
                    className={`${classes.dot} ${
                      matchesMobile ? "" : classes.dot_hover
                    }`}
                    style={{
                      "--dot-color":
                        oldActive.current[index] === 1 ? "#DA291C" : "black",
                      "--dot-border-color": "transparent",
                    }}
                  >
                    {Number(c.listaEstadosCandidatura[1].idEstado) === 5 ? (
                      <div title={t("icons_tooltips.discarded")}>
                        <Clear color="secondary" className={classes.dot_icon} />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  emptyDot()
                )}
                {c.listaEstadosCandidatura[2] ? (
                  <div
                    onClick={() => handleDotClick(index, 2)}
                    onMouseEnter={() => handleDotEnter(index, 2)}
                    onMouseLeave={handleDotLeave}
                    className={`${classes.dot} ${
                      matchesMobile ? "" : classes.dot_hover
                    }`}
                    style={{
                      "--dot-color":
                        oldActive.current[index] === 2 ? "#DA291C" : "black",
                      "--dot-border-color": "transparent",
                    }}
                  >
                    {Number(c.listaEstadosCandidatura[2].idEstado) === 5 ? (
                      <div title={t("icons_tooltips.discarded")}>
                        <Clear color="secondary" className={classes.dot_icon} />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  emptyDot()
                )}
                {c.listaEstadosCandidatura[3] ? (
                  <div
                    onClick={() => handleDotClick(index, 3)}
                    onMouseEnter={() => handleDotEnter(index, 3)}
                    onMouseLeave={handleDotLeave}
                    className={`${classes.dot} ${
                      matchesMobile ? "" : classes.dot_hover
                    }`}
                    style={{
                      "--dot-color":
                        oldActive.current[index] === 3 ? "#DA291C" : "black",
                      "--dot-border-color": "transparent",
                    }}
                  >
                    {Number(c.listaEstadosCandidatura[3].idEstado) !== 5 ? (
                      <div title={t("icons_tooltips.done")}>
                        <Done color="secondary" className={classes.dot_icon} />
                      </div>
                    ) : (
                      <div title={t("icons_tooltips.discarded")}>
                        <Clear color="secondary" className={classes.dot_icon} />
                      </div>
                    )}
                  </div>
                ) : (
                  emptyDot()
                )}
              </div>
              <div className={classes.candidatura_footer_container}>
                <Typography
                  variant="body1"
                  className={classes.candidatura_footer_text}
                >
                  {activeState
                    ? statusFilters.find(
                        (s) =>
                          Number(s.id) ===
                          Number(
                            c.listaEstadosCandidatura[activeState[index]]
                              .idEstado
                          )
                      )?.name_large ?? ""
                    : ""}
                </Typography>

                <Typography
                  variant="body1"
                  className={classes.candidatura_footer_text_date}
                >
                  {activeState
                    ? formatDate(
                        c.listaEstadosCandidatura[activeState[index]]
                          .fechaModificacion
                      )
                    : ""}
                </Typography>
                <Typography
                  variant="body1"
                  className={
                    classes.candidatura_footer_text +
                    " " +
                    classes.full_two_columns
                  }
                >
                  {c.listaEstadosCandidatura[3] &&
                  c.listaEstadosCandidatura[3].idEstado === 4
                    ? t("candidaturas.selected")
                    : null}
                </Typography>
              </div>
            </div>
          );
        }
      });
      if (elements.length === 0) {
        return (
          <div>
            <Typography variant="body1" className={classes.summary_title_empty}>
              {`${t("candidaturas.title_no_find")} ${
                statusFilters.find((s) => parseInt(s.id) === statusFilter)?.name
              }`}
            </Typography>
          </div>
        );
      }
      return elements;
    } else {
      return (
        <div>
          <Typography variant="body1" className={classes.summary_title_empty}>
            {t("candidaturas.title_empty")}
          </Typography>
        </div>
      );
    }
  }

  function renderMenuItemsTime() {
    let elements = [];
    timeFilters.forEach((t) =>
      elements.push(
        <MenuItem onClick={() => handleMenuItemClick("time", Number(t.id))}>
          {t.name}
        </MenuItem>
      )
    );
    return elements;
  }

  function renderMenuItemsStatus() {
    let elements = [
      <MenuItem onClick={() => handleMenuItemClick("status", 0)}>
        {t("candidaturas.see_all")}
      </MenuItem>,
    ];
    statusFilters.forEach((s) =>
      elements.push(
        <MenuItem onClick={() => handleMenuItemClick("status", Number(s.id))}>
          {s.name}
        </MenuItem>
      )
    );
    return elements;
  }

  function renderMenuItems() {
    if (menuType === "time") {
      return renderMenuItemsTime();
    } else if (menuType === "status") {
      return renderMenuItemsStatus();
    }
  }

  function handleMenuClick(event, type) {
    setMenuType(type);
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setMenuType(null);
    setAnchorEl(null);
  }

  function handleMenuItemClick(type, value) {
    if (type === "time") {
      setTimeFilter(value);
    } else if (type === "status") {
      setStatusFilter(value);
    }
    handleMenuClose();
  }

  return (
    <Layout history={props.history}>
      <div className={classes.body_info_container}>
        <div className={classes.body_info_left_col}>
          <div className={classes.summary_container}>
            <div className={classes.summary}>
              <div className={classes.summary_icon_container}>
                <CandidaturasIcon
                  title={t("icons_tooltips.candidaturas_space")}
                  color="#DA291C"
                  noShrink={true}
                />
              </div>

              <Typography variant="body1" className={classes.summary_title}>
                {t("candidaturas.title")}
              </Typography>
            </div>
            {matchesMobile ? null : (
              <OffersSearcher/>
            )}
          </div>
        </div>
        <div
          className={classes.candidaturas_list_container}
          ref={candidaturesListContainer}
        >
          <div className={classes.filters_container}>
            <div
              className={classes.filters_time_container}
              onClick={(event) => handleMenuClick(event, "time")}
            >
              <Typography variant="body1" className={classes.text_show_more}>
                {timeFilter && timeFilter !== 0
                  ? timeFilters.find((t) => Number(t.id) === timeFilter)
                      ?.name ?? ""
                  : t("candidaturas.see_all")}
              </Typography>
              <ShowMoreIcon title={t("icons_tooltips.expand")} />
            </div>
            <div
              className={classes.filters_time_container}
              onClick={(event) => handleMenuClick(event, "status")}
            >
              <Typography variant="body1" className={classes.text_show_more}>
                {statusFilter && statusFilter !== 0
                  ? statusFilters.find((t) => Number(t.id) === statusFilter)
                      ?.name ?? ""
                  : t("candidaturas.see_all")}
              </Typography>
              <ShowMoreIcon title={t("icons_tooltips.expand")} />
            </div>
          </div>
          {loadingCandidatures ? (
            <Loading height={64} width={64} />
          ) : candidatureListToShow && activeState && containerSize ? (
            renderCandidaturas()
          ) : null}
          {candidatureListToShow &&
          candidatureListToShow.length > 20 &&
          !showMore ? (
            <div
              className={classes.show_more}
              onClick={() => setShowMore(true)}
            >
              <Typography variant="body1" className={classes.text_show_more}>
                {t("candidaturas.see_more")}
              </Typography>
              <ShowMoreIcon title={t("icons_tooltips.expand")} />
            </div>
          ) : null}
        </div>
        {matchesMobile ? (
          <div className={classes.search_container}>
            {" "}
            <OffersSearcher/>
            {" "}
          </div>
        ) : null}
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {Boolean(anchorEl) && renderMenuItems()}
      </Menu>
    </Layout>
  );
}

export default withSnackbar(Candidaturas);
