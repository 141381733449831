import * as types from "../actions/actionTypes";

export default function questions1Reducer(state, action) {
  if (typeof state === "undefined") {
    return false;
  }
  let tempState = { ...state };
  switch (action.type) {
    case types.SET_PAISES:
      tempState = {
        ...state,
        paises: action.payload,
      };
      return { ...tempState };
    case types.SET_LOCALIDADES:
      tempState = {
        ...state,
        localidades: action.payload,
      };
      return { ...tempState };
      case types.SET_TIPO_IDENTIFICACION:
        tempState = {
          ...state,
          tipoIdentificacion: action.payload,
        };
        return { ...tempState };
      case types.SET_TIPO_HORARIO:
        tempState = {
          ...state,
          tipoHorario: action.payload,
        };
         return { ...tempState };
    default:
      return state;
  }
}