import React from "react";
import { useTranslation } from "react-i18next";

function HiredataMenuIcon(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.hire_data")} = props;

    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
            <title>{title}</title>
            <g id="Portal-de-empleo---móvil" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Recursos" transform="translate(-100.000000, -924.000000)">
                    <g id="icon-menu-tus-datos" transform="translate(100.000000, 924.000000)">
                        <rect id="Rectangle-Copy-9" x="0" y="0" width="20" height="20"/>
                        <g id="Group-8" transform="translate(2.000000, 2.000000)">
                            <g id="sign"/>
                            <line x1="0.5" y1="15.5" x2="15.5" y2="15.5" id="Path" stroke="#DA291C" strokeLinecap="round" strokeLinejoin="round"/>
                            <polygon id="Path" stroke="#DA291C" strokeLinecap="round" strokeLinejoin="round" points="1.5 12.5 2.5 8.5 10.5 0.5 13.5 3.5 5.5 11.5"/>
                            <polyline id="Path" stroke="#DA291C" strokeLinecap="round" strokeLinejoin="round" points="12.5 4.5 14.5 6.5 10.5 10.5"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
  )
}

export default HiredataMenuIcon;