import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setCandidateIdPhotography,
  clearCandidateIdPhotography} from "../actions/webCandidate"
import EditarDatosPersonales from "../../Views/editarDatosPersonales";
import { mapPrefixes } from "../../utils/functions";
import { setTipoIdentificacion } from "../actions/maestroAction";

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setCandidateIdPhotography: bindActionCreators(setCandidateIdPhotography,dispatch),
      clearCandidateIdPhotography: bindActionCreators(clearCandidateIdPhotography, dispatch),
      setTipoIdentificacion: bindActionCreators(setTipoIdentificacion, dispatch),
    }
  };
}

function mapStateToProps(state) {
  return {
    state: state.webCandidate,
    paises: state.maestros.paises,
    prefijos: mapPrefixes(state.maestros.paises),
    localidades: state?.maestros.localidades,
    identificationType: state?.maestros.tipoIdentificacion
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditarDatosPersonales);
