import { connect } from "react-redux";
import SaveSearch from "../../Views/saveSearch";
import { bindActionCreators } from "redux";
import { setSuccesfulSearchSave } from "../actions/webCandidate"

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setSuccesfulSave: bindActionCreators(setSuccesfulSearchSave, dispatch)
    }
  };
}

function mapStateToProps(state) {
  return {
    state: state.webCandidate
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveSearch);
