import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { formatToLocalDate, getDiscapacityOptions, getGenderOptions, getTiposVia, getNationalityOptions, getNivelesEstudio, getPermitTypeOptions, getProvincias, handleGetMaestro } from "../utils/functions";
import { getDatosContratacion } from "../api/candidato";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import DatosContratacionIcon from "../assets/icons/datosContratacionIcon";
import ButtonFull from "../components/buttonFull";
import ButtonOutlined from "../components/buttonOutlined";
import { contactaAsesorUrl } from "../config";
import i18next from "../i18n.js";
import Layout from "../components/basicLayout";
import useMaestros from "../Hooks/useMaestros";

const levels = getNivelesEstudio(i18next.languages[0]);
const permitTypeOptions = getPermitTypeOptions(i18next.languages[0]);
const nationalityOptions = getNationalityOptions(i18next.languages[0]);
const genderOptions = getGenderOptions(i18next.languages[0]);
const discapacityOptions = getDiscapacityOptions(i18next.languages[0]);
const provinces = getProvincias(i18next.languages[0]);
const tiposVia = getTiposVia(i18next.languages[0]);

const useStyles = makeStyles((theme) => ({
  principal_container: {
    gridArea: "body_container",
    display: "grid",
    gridTemplateColumns: "30% 65%",
    columnGap: "20px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "auto",
    },
  },
  col1: {
    padding: 20,
    display: "grid",
    height: "fit-content",
  },
  col2: {
    padding: 20,
    marginTop: 120,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "25px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  title_text: {
    fontSize: 36,
    fontWeight: 900,
    lineHeight: 1.3,
    marginBottom: "30px",
  },
  desc_text: {
    fontSize: 14,
  },
  data_section_title: {
    fontSize: 22,
    fontWeight: 900,
  },
  data_info: {
    display: "flex",
    flexDirection: "column",
  },
  data_info_direccion: {
    display: "flex",
    flexDirection: "column",
    "grid-column-start": "1",
    "grid-column-end": "3",
  },
  data_title: {
    fontSize: 14,
    fontWeight: 900,
  },
  data_value: {
    fontSize: 14,
  },
  data_grid: {
    display: "grid",
    gridTemplateColumns: "minMax(0, 1fr) minMax(0, 1fr) minMax(0, 1fr)",
    rowGap: "24px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  other_data_grid: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    rowGap: "24px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  separator_line: {
    height: 1,
    backgroundColor: "#EFEFEF",
    "grid-column-start": "1",
    "grid-column-end": "4",
  },
  buttons_container: {
    marginTop: "60px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      marginBottom: "0px",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  custom_button_size: {
    width: 251,
    "& + &": {
      marginLeft: "24px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "& + &": {
        marginTop: "24px",
      },
    },
  },
  icon_style: {
    display: "flex",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

function DatosContratacion(props) {
  const { countries, locations } = props;
  const { t } = useTranslation();
  const maestros = useMaestros();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [datosContratacion, setDatosContratacion] = useState(null);
  const [nombre, setNombre] = useState(null);
  const [primerApellido, setPrimerApellido] = useState(null);
  const [segundoApellido, setSegundoApellido] = useState(null);
  const [tipoIdentificacion, setTipoIdentificacion] = useState(null);
  const [numIdentificacion, setNumIdentificacion] = useState(null);
  const [numSeguridadSocial, setNumSeguridadSocial] = useState(null);
  const [nacionalidad, setNacionalidad] = useState(null);
  const [fechaNacimiento, setFechaNacimiento] = useState(null);
  const [sexo, setSexo] = useState(null);
  const [telefono, setTelefono] = useState(null);
  const [otroTelefono, setOtroTelefono] = useState(null);
  const [nivelEstudios, setNivelEstudios] = useState(null);
  const [correoElectronicoNotificaciones, setCorreoElectronicoNotificaciones] =
    useState(null);
  const [discapacidad, setDiscapacidad] = useState(null);
  const [permiteFirmaElectronica, setPermiteFirmaElectronica] = useState(null);
  const [tieneCertificadoPenales, setTieneCertificadoPenales] = useState(null);
  const [pais, setPais] = useState("");
  const [provincia, setProvincia] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [codigoPostal, setCodigoPostal] = useState(null);
  const [tipoVia, setTipoVia] = useState(null);
  const [nombreVia, setNombreVia] = useState(null);
  const [numVia, setNumVia] = useState(null);
  const [bloque, setBloque] = useState(null);
  const [piso, setPiso] = useState(null);
  const [puerta, setPuerta] = useState(null);
  const [IBAN, setIBAN] = useState(null);
  const [permisoTrabajo, setPermisoTrabajo] = useState("");
  const [fechaValidezPermisoTrabajo, setFechaValidezPermisoTrabajo] = useState(null);
  const [tipoIdentificacionOptions, setTipoIdentificacionOptions] = useState([]);

  useEffect(() => {
    if (!datosContratacion) {
      setLoading(true);
      handleDatosContratacion();
    } else {
      handleSetDatosContratacion();
    }
  }, [datosContratacion]);

  useEffect(() => {
    handleGetMaestro(tipoIdentificacionOptions,setTipoIdentificacionOptions,maestros.getTipoIdentificacion());
  }, [tipoIdentificacionOptions]);

  async function handleDatosContratacion() {
    const response = await getDatosContratacion();
    if (response.status === 200) {
      const result = await response.json();
      setDatosContratacion(result);
    } else {
      props.enqueueSnackbar(t("candidateDashboard.error"), {
        variant: "error",
      });
      setDatosContratacion(null);
    }
  }

  function FormatTipoVia(tipoVia) {
    return tipoVia
      ? tipoVia.charAt(0) + tipoVia.slice(1).toLowerCase().trim()
      : null;
  }

  function formatPhoneNumber(number) {
    return number.replace(/(\d{3})/g, "$1 ");
  }

  async function handleSetDatosContratacion() {
    setNombre(datosContratacion.nombre ? datosContratacion.nombre.trim() : "-");
    setPrimerApellido(
      datosContratacion.primerApellido
        ? datosContratacion.primerApellido.trim()
        : "-"
    );
    setSegundoApellido(
      datosContratacion.segundoApellido
        ? datosContratacion.segundoApellido.trim()
        : "-"
    );
    setTipoIdentificacion(
      tipoIdentificacionOptions.find(
        (identificationType) =>
          identificationType.id ===
          datosContratacion.tipoIdentificacion?.toString()
      )?.name ?? t("datosContratacion.DNI")
    );
    setNumIdentificacion(
      datosContratacion.numIdentificacion
        ? datosContratacion.numIdentificacion.trim()
        : "-"
    );
    setNumSeguridadSocial(
      datosContratacion.numSeguridadSocial
        ? datosContratacion.numSeguridadSocial.trim()
        : "-"
    );
    setNacionalidad(
      datosContratacion.nacionalidad
        ? nationalityOptions
            .find(
              (element) => Number(element.id) === datosContratacion.nacionalidad
            )
            ?.name.trim() ?? ""
        : "-"
    );
    setFechaNacimiento(
      datosContratacion.fechaNacimiento
        ? formatToLocalDate(new Date(datosContratacion.fechaNacimiento))
        : "-"
    );
    setSexo(
      datosContratacion.sexo
        ? genderOptions
            .find((element) => Number(element.id) === datosContratacion.sexo)
            ?.name.trim() ?? ""
        : "-"
    );
    setTelefono(
      datosContratacion.telefono
        ? datosContratacion.telefono.prefijo
          ? `+${datosContratacion.telefono.prefijo.trim()}  ${
              datosContratacion.telefono.numero
                ? formatPhoneNumber(datosContratacion.telefono.numero.trim())
                : ""
            }`
          : datosContratacion.telefono.telefono
          ? formatPhoneNumber(datosContratacion.telefono.telefono.trim())
          : ""
        : "-"
    );
    setOtroTelefono(
      datosContratacion.otroTelefono
        ? datosContratacion.otroTelefono.prefijo
          ? `+${datosContratacion.otroTelefono.prefijo.trim()}  ${
              datosContratacion.otroTelefono.numero
                ? formatPhoneNumber(
                    datosContratacion.otroTelefono.numero.trim()
                  )
                : ""
            }`
          : datosContratacion.otroTelefono.telefono
          ? formatPhoneNumber(datosContratacion.otroTelefono.telefono.trim())
          : ""
        : "-"
    );
    setNivelEstudios(
      datosContratacion.nivelEstudios
        ? levels
            .find(
              (element) =>
                Number(element.id) === datosContratacion.nivelEstudios.id
            )
            ?.name.trim() ?? ""
        : "-"
    );
    setCorreoElectronicoNotificaciones(
      datosContratacion.correoElectronicoNotificaciones
        ? datosContratacion.correoElectronicoNotificaciones.trim()
        : null
    );
    setDiscapacidad(
      datosContratacion.discapacidad && datosContratacion.discapacidad !== 1
        ? discapacityOptions
            .find((element) => element.id === datosContratacion.discapacidad)
            ?.name.trim() ?? ""
        : null
    );
    setPermiteFirmaElectronica(
      datosContratacion.permiteFirmaElectronica
        ? t("common.yes")
        : t("common.no")
    );
    setTieneCertificadoPenales(
      datosContratacion.tieneCertificadoPenales ? t("common.yes") : null
    );
    setPais(
      datosContratacion.pais
        ? countries
            .find((element) => Number(element.id) === datosContratacion.pais)
            ?.name.trim() ?? ""
        : "-"
    );
    setProvincia(
      datosContratacion.provincia
        ? provinces
            .find(
              (element) => Number(element.id) === datosContratacion.provincia
            )
            ?.name.trim() ?? ""
        : "-"
    );
    setLocalidad(
      datosContratacion.localidad
        ? locations
            .find(
              (element) => Number(element.id) === datosContratacion.localidad
            )
            ?.name.trim() ?? ""
        : "-"
    );
    setCodigoPostal(
      datosContratacion.codigoPostal
        ? datosContratacion.codigoPostal.trim()
        : "-"
    );
    setTipoVia(
      FormatTipoVia(
        tiposVia.find((tipoVia) => tipoVia.id === datosContratacion.tipoVia)
          ?.name
      ) ?? ""
    );
    setNombreVia(
      datosContratacion.nombreVia ? datosContratacion.nombreVia.trim() : "-"
    );
    setNumVia(datosContratacion.numVia ? datosContratacion.numVia.trim() : "");
    setBloque(
      datosContratacion.bloque ? datosContratacion.bloque.trim() : null
    );
    setPiso(datosContratacion.piso ? datosContratacion.piso.trim() : null);
    setPuerta(
      datosContratacion.puerta ? datosContratacion.puerta.trim() : null
    );
    setIBAN(datosContratacion.iban ? datosContratacion.iban.trim() : "-");
    setPermisoTrabajo(
      datosContratacion.tipoPermisoTrabajo
        ? permitTypeOptions
            .find(
              (element) => element.id === datosContratacion.tipoPermisoTrabajo
            )
            ?.name.trim() ?? ""
        : "-"
    );
    setFechaValidezPermisoTrabajo(
      datosContratacion.fechaValidezPermisoTrabajo
        ? formatToLocalDate(
            new Date(datosContratacion.fechaValidezPermisoTrabajo)
          )
        : null
    );
    setLoading(false);
  }

  function datosContratacionHeader() {
    return (
      <div className={classes.col1}>
        <div className={classes.icon_style}>
          <DatosContratacionIcon title={t("icons_tooltips.hire_data_space")} />
        </div>
        <div className={classes.title_text}>{t("datosContratacion.title")}</div>
        <div className={classes.desc_text}>
          {t("datosContratacion.description")}
        </div>
      </div>
    );
  }

  function dataInfo(title, value, direccion) {
    return (
      <div
        className={direccion ? classes.data_info_direccion : classes.data_info}
      >
        <div className={classes.data_title}>{title}</div>
        <div className={classes.data_value}>{value}</div>
      </div>
    );
  }

  function actionButtons() {
    return (
      <div className={classes.buttons_container}>
        <ButtonFull
          text={t("datosContratacion.buttonAdjuntaDocumentacion")}
          customClass={classes.custom_button_size}
          onClick={() => {
            props.history.push("my_documents");
          }}
        />
        <ButtonOutlined
          text={t("datosContratacion.buttonContactaAsesor")}
          customClass={classes.custom_button_size}
          onClick={() => {
            window.open(contactaAsesorUrl);
          }}
        />
      </div>
    );
  }

  function showData() {
    return (
      <div className={classes.col2}>
        <div className={classes.data_section_title}>
          {t("datosContratacion.datosPersonalesSection")}
        </div>
        <div className={classes.data_grid}>
          {dataInfo(t("datosContratacion.nombre"), nombre, false)}
          {dataInfo(
            t("datosContratacion.primerApellido"),
            primerApellido,
            false
          )}
          {dataInfo(
            t("datosContratacion.segundoApellido"),
            segundoApellido,
            false
          )}
          {dataInfo(t("datosContratacion.genero"), sexo, false)}
          {dataInfo(t("datosContratacion.nacionalidad"), nacionalidad, false)}
          {dataInfo(
            t("datosContratacion.fechaNacimiento"),
            fechaNacimiento,
            false
          )}
          {dataInfo(tipoIdentificacion, numIdentificacion, false)}
          {dataInfo(
            t("datosContratacion.nSeguridadSocial"),
            numSeguridadSocial,
            false
          )}
          <div className={classes.separator_line} />
        </div>
        <div className={classes.data_section_title}>
          {t("datosContratacion.contactoSection")}
        </div>
        <div className={classes.data_grid}>
          {dataInfo(t("datosContratacion.telefonoPrincipal"), telefono, false)}
          {dataInfo(
            t("datosContratacion.telefonoSecundario"),
            otroTelefono,
            false
          )}
          {dataInfo(
            t("datosContratacion.correo"),
            correoElectronicoNotificaciones,
            false
          )}
          <div className={classes.separator_line} />
        </div>
        <div className={classes.data_section_title}>
          {t("datosContratacion.residenciaSection")}
        </div>
        <div className={classes.data_grid}>
          {dataInfo(
            t("datosContratacion.direccion"),
            tipoVia &&
              nombreVia &&
              `${numVia}${tipoVia} ${nombreVia}${
                nombreVia != "-" ? "," : ""
              } ${numVia}${bloque ? ", " + t("datosContratacion.block") : ""}${
                bloque ? " " + bloque : ""
              }${piso || puerta ? "," : ""} ${piso ? +piso + "º" : ""} ${
                puerta ? puerta : ""
              }`,
            true
          )}
          {dataInfo(t("datosContratacion.ciudad"), localidad, false)}
          {dataInfo(t("datosContratacion.provincia"), provincia, false)}
          {dataInfo(t("datosContratacion.codigoPostal"), codigoPostal, false)}
          {dataInfo(t("datosContratacion.pais"), pais, false)}
          <div className={classes.separator_line} />
        </div>
        <div className={classes.data_section_title}>
          {t("datosContratacion.otrosDatosSection")}
        </div>
        <div className={classes.other_data_grid}>
          {dataInfo(t("datosContratacion.nivelEstudios"), nivelEstudios, false)}
          {dataInfo(t("datosContratacion.IBAN"), IBAN, false)}
          {dataInfo(
            t("datosContratacion.firmaElectrónica"),
            permiteFirmaElectronica,
            false
          )}
          {dataInfo(
            t("datosContratacion.permisoTrabajo"),
            `${permisoTrabajo}  ${
              fechaValidezPermisoTrabajo
                ? ", " +
                  t("datosContratacion.validUntil") +
                  fechaValidezPermisoTrabajo
                : ""
            }`,
            false
          )}
          {discapacidad &&
            dataInfo(
              t("datosContratacion.certificadoDiscapacidad"),
              discapacidad,
              false
            )}
          {tieneCertificadoPenales &&
            dataInfo(
              t("datosContratacion.certificadoPenales"),
              tieneCertificadoPenales,
              false
            )}
        </div>
        {actionButtons()}
      </div>
    );
  }

  return (
    <Layout history={props.history} loading={loading}>
      <div className={classes.principal_container}>
        {datosContratacionHeader()}
        {showData()}
      </div>
    </Layout>
  );
}

export default withSnackbar(DatosContratacion);
