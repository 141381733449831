import { connect } from "react-redux";
import basicData from "../../Views/basicData";
import { mapPrefixes } from "../../utils/functions";
import { bindActionCreators } from "redux";
import { setTipoIdentificacion } from "../actions/maestroAction";

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setTipoIdentificacion: bindActionCreators(setTipoIdentificacion, dispatch),
    }
  };
}

function mapStateToProps(state) {
  return {
    paises: state?.maestros.paises,
    phonePrefixes: mapPrefixes(state?.maestros.paises),
    localidades: state?.maestros.localidades,
    tipoIdentificacion: state?.maestros.tipoIdentificacion,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(basicData);
