import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  a: {
    fill: "#fc030b",
  },
  b: {
    fill: "none",
    stroke: "#fc030b",
  },
}));

function HeartIcon(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClick, fill = true, title=t("icons_tooltips.favorite") } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" onClick={onClick} width="18.138" height="16.899" viewBox="0 0 18.138 16.899">
      <title>{title}</title>
      <path className={fill ? classes.a : classes.b} d="M12,20.009l-1.243-1.127C6.341,14.876,3.428,12.232,3.428,9A4.662,4.662,0,0,1,8.141,4.284,5.142,5.142,0,0,1,12,6.071a5.142,5.142,0,0,1,3.856-1.787A4.662,4.662,0,0,1,20.566,9c0,3.235-2.913,5.878-7.327,9.884L12,20.009Z" transform="translate(-2.928 -3.784)" />
    </svg>
  );
}

export default HeartIcon;
