import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import LanguageSwitch from "../components/languageSwitch";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/icons/MenuRounded";
import Clear from "@material-ui/icons/ClearRounded";
import SearchMenu from "../assets/icons/search_menu_icon";
import CandidaturaMenu from "../assets/icons/candidatura_menu_icon";
import Home from "@material-ui/icons/HomeOutlined";
import CvMenu from "../assets/icons/cv_menu_icon";
import HiredMenu from "../assets/icons/hiredata_menu_icon";
import DocumentsMenu from "../assets/icons/documents_menu_icon";
import CloseMenu from "../assets/icons/closesession_menu_item";
import PersonalData from "../assets/icons/personalData";
import PcMonitor from "../assets/icons/pc_monitor";
import { useTheme } from "@material-ui/core/styles";
import { AuthService } from "../auth/AuthService";
const auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  header: {
    gridArea: "header",
    position: "fixed",
    width: "100%",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "1.171875rem",
    paddingRight: "1.171875rem",
    paddingTop: ".78125rem",
    paddingBottom: ".78125rem",
    [theme.breakpoints.up("header1")]: {
      paddingLeft: "1.5625rem",
      paddingRight: "1.5625rem",
    },
    [theme.breakpoints.up("header2")]: {
      paddingLeft: "2.34375rem",
      paddingRight: "2.34375rem",
    },
    [theme.breakpoints.up("header3")]: {
      paddingLeft: "3.125rem",
      paddingRight: "3.125rem",
    },
    zIndex: 900,
  },
  header_icon: {
    width: 24,
    height: 24,
    color: "#5D5A5A",
    marginLeft: 24,
    cursor: "pointer",
  },
  header_logo: {
    width: 198,
    height: 43,
    cursor: "pointer",
  },
  header_logo_mobile: {
    width: 119,
    height: 26,
  },
  header_icon_action_container: {
    float:"left", 
    fontWeight: 700, 
    marginRight: "25px", 
    fontSize: "12px", 
    cursor: "pointer",
  },
  header_icon_action: {
    verticalAlign:"top", 
    marginRight: "5px", 
    display:"inline-block",
  },
  header_icon_text: {
    display: "inline-block",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "600",
  },
  drawer: {
    top: "60px!important",
  },
  paper: {
    top: "60px!important",
    width: "25%",
    padding: "24px 20px",
  },
  backdrop: {
    top: "60px!important",
    backgroundColor: "transparent",
  },
  listItem: {
    marginBottom: 12,
    cursor: "pointer",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "#F5F5F5",
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  listItemText: {
    fontSize: 16,
    fontWeight: 600,
    color: "black",
  },
  menu_lang_container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  menu_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 18,
  },
  menu_item: {
    fontSize: 14,
    lineHeight: "21px",
    color: theme.palette.primary.main,
    cursor: "pointer",
    "& + &": {
      marginLeft: 30,
    },
    [theme.breakpoints.down("sm")]: {
      color: "black",
      fontSize: 16,
      lineHeight: 0,
      marginLeft: 10,
    },
  },
  menu_item_unlogged: {
    fontSize: 14,
    lineHeight: "21px",
    color: theme.palette.primary.main,
    cursor: "pointer",
    "& + &": {
      marginLeft: 30,
    },
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      color: "black",
      fontSize: 16,
      lineHeight: 0,
      marginLeft: 10,
      fontWeight: 400
    },
  },
  menu_item_nl: {
    fontSize: 14,
    lineHeight: "21px",
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontWeight: 900,
    textTransform: "uppercase",
    "& + &": {
      marginLeft: 30,
    },
    [theme.breakpoints.down("sm")]: {
      color: "black",
      fontSize: 16,
      lineHeight: "21px",
      marginLeft: 10,
    },
  },
  mobile_menu_container: {
    display: "flex",
    flexDirection: "column",
    height: "var(--height)",
    width: "100%",
    position: "absolute",
    left: 0,
    top: "100%",
    transition: "height .3s",
    overflow: "hidden",
    backgroundColor: "white",
    boxShadow: "0 10px 6px -6px #777",
    zIndex: 1000,
  },
  mobile_menu_item_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: 20,
    paddingTop: 6,
    paddingBottom: 6,
    height: "32px",
  },
}));

function Header(props) {
  const vinculacionLaboral = localStorage.getItem("vinculacionLaboral");
  const classes = useStyles();
  const {
    history,
    menuItems = true,
    isLogged = true,
    setPadding = null,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [openMenu, setOpenMenu] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (setPadding) {
      setPadding(ref.current.offsetHeight);
    }
  }, []);

  function returnMenuItems() {
    if (matchesMobile) {
      if (isLogged) {
        return (
          <>
            {openMenu ? (
              <div title={t("icons_tooltips.clear")}><Clear color="primary" onClick={() => setOpenMenu(false)} /></div>
            ) : (
              <div title={t("icons_tooltips.menu")}><Menu color="primary" onClick={() => setOpenMenu(true)} /></div>
            )}
            <div
              className={classes.mobile_menu_container}
              style={{ "--height": openMenu ? "240px" : 0 }}
            >
              <div
                className={classes.mobile_menu_item_container}
                onClick={() => history.push("/candidate_dashboard")}
              >
                <div title={t("icons_tooltips.home")}><Home style={{ fill: "#DA291C" }} /></div>
                <Typography variant="body1" className={classes.menu_item}>
                  {t("header.menu_item_0")}
                </Typography>
              </div>
              <div
                className={classes.mobile_menu_item_container}
                onClick={() => props.history.push("/search_offers/0")}
              >
                <SearchMenu title={t("icons_tooltips.search")}/>
                <Typography variant="body1" className={classes.menu_item}>
                  {t("header.menu_item_6")}
                </Typography>
              </div>
              <div
                className={classes.mobile_menu_item_container}
                onClick={() => history.push("/candidaturas")}
              >
                <CandidaturaMenu title={t("icons_tooltips.candidaturas")}/>
                <Typography variant="body1" className={classes.menu_item}>
                  {t("header.menu_item_1")}
                </Typography>
              </div>
              <div
                className={classes.mobile_menu_item_container}
                onClick={() => history.push("/curriculum")}
              >
                <CvMenu title={t("icons_tooltips.curriculum")}/>
                <Typography variant="body1" className={classes.menu_item}>
                  {t("header.menu_item_2")}
                </Typography>
              </div>
              {
                vinculacionLaboral === "true" ? (
                  <div
                    className={classes.mobile_menu_item_container}
                    onClick={() => history.push("/datos_contratacion")}
                  >
                    <HiredMenu title={t("icons_tooltips.hire_data")}/>
                    <Typography variant="body1" className={classes.menu_item}>
                      {t("header.menu_item_3")}
                    </Typography>
                  </div>
                ) : null
              }
              <div
                className={classes.mobile_menu_item_container}
                onClick={() => history.push("/my_documents")}
              >
                <DocumentsMenu title={t("icons_tooltips.documents")}/>
                <Typography variant="body1" className={classes.menu_item}>
                  {t("header.menu_item_4")}
                </Typography>
              </div>
              <div
                className={classes.mobile_menu_item_container}
                onClick={() => auth.logout()}
              >
                <CloseMenu title={t("icons_tooltips.close_menu")}/>
                <Typography variant="body1" className={classes.menu_item}>
                  {t("header.menu_item_5")}
                </Typography>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            {openMenu ? (
              <div title={t("icons_tooltips.clear")}><Clear color="primary" onClick={() => setOpenMenu(false)} /></div>
            ) : (
              <div title={t("icons_tooltips.menu")}><Menu color="primary" onClick={() => setOpenMenu(true)} /></div>
            )}
            <div
              className={classes.mobile_menu_container}
              style={{ "--height": openMenu ? "170px" : 0 }}
            >
              <div
                className={classes.mobile_menu_item_container}
                onClick={() => window.open("https://nortempo.com/empleo/")}
              >
                <Typography variant="body1" className={classes.menu_item_unlogged}>
                  {t("header.menu_item_1_nl")}
                </Typography>
              </div>
              <div
                className={classes.mobile_menu_item_container}
                onClick={() => window.open("https://nortempo.com/empresas/")}
              >
                <Typography variant="body1" className={classes.menu_item_unlogged}>
                  {t("header.menu_item_2_nl")}
                </Typography>
              </div>
              <div
                className={classes.mobile_menu_item_container}
                onClick={() => window.open("https://nortempo.com/nosotros/")}
              >
                <Typography variant="body1" className={classes.menu_item_unlogged}>
                  {t("header.menu_item_3_nl")}
                </Typography>
              </div>
              <div
                className={classes.mobile_menu_item_container}
                onClick={() => window.open("https://nortempo.com/blog/")}
              >
                <Typography variant="body1" className={classes.menu_item_unlogged}>
                  {t("header.menu_item_4_nl")}
                </Typography>
              </div>
              <div
                className={classes.mobile_menu_item_container}
                onClick={() =>
                  window.open(
                    "https://nortempo.com/contacto/#formulario-candidatos"
                  )
                }
              >
                <Typography variant="body1" className={classes.menu_item_unlogged}>
                  {t("header.menu_item_5_nl")}
                </Typography>
              </div>
            </div>
          </>
        );
      }
    } else {
      if (isLogged) {
        return (
          <div className={classes.menu_lang_container}>
            {
              //<LanguageSwitch />
              //Se quita temporalmente el selector de idioma
            }
            <div className={classes.menu_container}>
              <Typography
                variant="body1"
                className={classes.menu_item}
                onClick={() => history.push("/candidate_dashboard")}
              >
                {t("header.menu_item_0")}
              </Typography>
              <Typography
                variant="body1"
                className={classes.menu_item}
                onClick={() => history.push("/candidaturas")}
              >
                {t("header.menu_item_1")}
              </Typography>
              <Typography
                variant="body1"
                className={classes.menu_item}
                onClick={() => history.push("/curriculum")}
              >
                {t("header.menu_item_2")}
              </Typography>
              {
                vinculacionLaboral === "true" ? (
                  <Typography
                    variant="body1"
                    className={classes.menu_item}
                    onClick={() => history.push("/datos_contratacion")}
                  >
                    {t("header.menu_item_3")}
                  </Typography>
                ) : null
              }
              <Typography
                variant="body1"
                className={classes.menu_item}
                onClick={() => history.push("/my_documents")}
              >
                {t("header.menu_item_4")}
              </Typography>
              <Typography
                variant="body1"
                className={classes.menu_item}
                onClick={() => auth.logout()}
              >
                {t("header.menu_item_5")}
              </Typography>
            </div>
          </div>
        );
      } else {
        return (
          <div className={classes.menu_lang_container}>
            <div>
              <div className={classes.header_icon_action_container} onClick={() =>
                props.history.push("/")
              }>
                <div className={classes.header_icon_action}>
                  <PersonalData color = "#DA291C" title={t("icons_tooltips.personal_data")}/>
                </div>
                <div className={classes.header_icon_text}>
                  {t("header.icon_1_nl")}
                </div>
              </div>
              <div className={classes.header_icon_action_container} onClick={() =>
                  window.open(
                    "https://nortempo.com/"
                  )}>
                <div className={classes.header_icon_action}>
                   <PcMonitor title={t("icons_tooltips.virtual_office")}/>
                </div>
                <div className={classes.header_icon_text}>
                  {t("header.icon_2_nl")}
                </div>
              </div>
              {
                //<LanguageSwitch />
                //Se quita temporalmente el selector de idioma
              }
            </div>
            
            <div className={classes.menu_container}>
              <Typography
                variant="body1"
                className={classes.menu_item_unlogged}
                onClick={() => window.open("https://nortempo.com/empleo/")}
              >
                {t("header.menu_item_1_nl")}
              </Typography>
              <Typography
                variant="body1"
                className={classes.menu_item_unlogged}
                onClick={() => window.open("https://nortempo.com/empresas/")}
              >
                {t("header.menu_item_2_nl")}
              </Typography>
              <Typography
                variant="body1"
                className={classes.menu_item_unlogged}
                onClick={() => window.open("https://nortempo.com/nosotros/")}
              >
                {t("header.menu_item_3_nl")}
              </Typography>
              <Typography
                variant="body1"
                className={classes.menu_item_unlogged}
                onClick={() => window.open("https://nortempo.com/blog/")}
              >
                {t("header.menu_item_4_nl")}
              </Typography>
              <Typography
                variant="body1"
                className={classes.menu_item_unlogged}
                onClick={() =>
                  window.open(
                    "https://nortempo.com/contacto/#formulario-candidatos"
                  )
                }
              >
                {t("header.menu_item_5_nl")}
              </Typography>
            </div>
          </div>
        );
      }
    }
  }

  function returnLogout() {
    return (
      <div className={classes.menu_lang_container}>
        <Typography
          variant="body1"
          className={classes.menu_item}
          onClick={() => auth.logout()}
        >
          {t("header.menu_item_5")}
        </Typography>
      </div>
    );
  }

  function returnImage() {
    if (matchesMobile) {
      if (openMenu) {
        return null; //<LanguageSwitch />; //Se quita temporalmente el selector de idioma
      } else {
        return (
          <img
            alt="Nortempo"
            src={isLogged ? "/images/nortempo_logo.svg" : "/images/nortempo_logo_mueves_mundo.svg"}
            className={
              matchesMobile
                ? `${classes.header_logo_mobile}`
                : `${classes.header_logo}`
            }
            onClick={() =>
              menuItems && isLogged
                ? props.history.push("/candidate_dashboard")
                : null
            }
          />
        );
      }
    } else {
      return (
        <img
          alt="Nortempo"
          src={isLogged ? "/images/nortempo_logo.svg" : "/images/nortempo_logo_mueves_mundo.svg"}
          style = {{cursor: menuItems && isLogged ? "pointer" : "default"}}
          className={
            matchesMobile
              ? `${classes.header_logo_mobile}`
              : `${classes.header_logo}`
          }
          onClick={() =>
            menuItems && isLogged
              ? props.history.push("/candidate_dashboard")
              : null
          }
        />
      );
    }
  }

  return (
    <div className={classes.header} ref={ref}>
      {returnImage()}
      {menuItems ? returnMenuItems() : returnLogout()}
    </div>
  );
}

export default Header;
