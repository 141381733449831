import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import { AuthService } from "../auth/AuthService";

const auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  header_logo_mobile: {
    width: 119,
    height: 26,
  },
  header_logo: {
    width: 198,
    height: 43,
  },
  layout_container: {
    width: "100%",
    height: "100vh",
    minHeight: "100vh",
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
    gridTemplateAreas: "'header' 'body'",
    backgroundImage: "url(images/fondo1920x1080.jpg)",
    backgroundPosition: "center",
  },
  layout_body: {
    gridArea: "body",
    display: "grid",
    overflow: "auto",
    gridTemplateColumns: "minMax(0, 1fr) minMax(0, 1fr)",
    gridTemplateAreas: "'. body_container .'",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      gridTemplateRows: "auto",
      gridTemplateColumns: "minMax(0, 1fr) 0%",
      marginTop: "20px",
    },
  },
  header: {
    gridArea: "header",
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    padding: "18px 20px",
  },
  first_grid: {
    width: "100%",
    height: "100%",
    paddingLeft: "20%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "15%",
    },
  },
  tittle: {
    width: "280px",
    fontWeight: "700",
    fontSize: "25px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "15px",
      fontSize: "20px",
    },
  },
  body: {
    width: "280px",
  },
}));

function Logout(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.layout_container}>
      <div className={classes.header}>
        <img
          alt="Nortempo"
          src="/images/nortempo_logo.svg"
          className={
            matchesMobile
              ? `${classes.header_logo_mobile}`
              : `${classes.header_logo}`
          }
        />
      </div>
      <div className={classes.layout_body}>
        <div className={classes.first_grid}>
          <div className={classes.tittle}>{t("delete_candidate.title")}</div>
          <div className={classes.body}>{t("delete_candidate.body")}</div>
        </div>
      </div>
    </div>
  );
}

export default withSnackbar(Logout);
