import React from "react";
import { useTranslation } from "react-i18next";

function Youtube(props) {
    const { t } = useTranslation();
    const { title=t(`icons_tooltips.youtube`), onClick, className } = props;
    return (
        <svg onClick={onClick} className={className}  width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>Youtube</title>
            <g id="Page-1" stroke="none" stroke-width="1" fill="white" fill-rule="evenodd">
                <g id="Dashboard-v1" transform="translate(-1234.000000, -1234.000000)">
                    <path d="M1258,1234 L1258,1258 L1234,1258 L1234,1234 L1258,1234 Z M1246.30567,1240.00072 L1245.69433,1240.00072 C1244.74955,1240.0042 1241.81285,1240.02452 1239.69995,1240.14301 C1239.03674,1240.17175 1238.40259,1240.42334 1237.90002,1240.85699 C1237.51538,1241.35944 1237.25806,1241.94751 1237.15002,1242.57098 C1237.04187,1243.19446 1237,1243.92902 1237,1245.35699 L1237.0009,1246.82836 C1237.01333,1248.12696 1237.15002,1249.42902 1237.15002,1249.42902 C1237.22107,1250.06299 1237.48254,1250.66058 1237.90002,1251.14301 C1238.43848,1251.61536 1239.13379,1251.87 1239.84998,1251.85699 C1241.02355,1251.97476 1244.43513,1251.99555 1245.60267,1251.99921 L1245.98794,1252 L1245.98794,1252 L1246,1252 C1246,1252 1246.00306,1252 1246.00907,1252 L1246.21516,1251.99938 C1246.79741,1251.99676 1248.47508,1251.98313 1250.179,1251.91228 L1250.66563,1251.89044 C1251.23203,1251.86305 1251.79004,1251.82881 1252.30005,1251.78601 C1252.96326,1251.75726 1253.59741,1251.50568 1254.09998,1251.07202 C1254.48462,1250.56952 1254.74194,1249.98145 1254.84998,1249.35797 C1254.95813,1248.73456 1255,1248 1255,1246.57098 L1255,1246.57098 L1255,1245.28601 C1255,1243.92902 1254.84998,1242.57098 1254.84998,1242.57098 C1254.77893,1241.93701 1254.51746,1241.33942 1254.09998,1240.85699 C1253.63647,1240.36255 1252.97644,1240.10071 1252.30005,1240.14301 C1250.18715,1240.02452 1247.25045,1240.0042 1246.30567,1240.00072 Z M1244.125,1243.42902 L1249,1245.85699 L1244.125,1248.21399 L1244.125,1243.42902 Z" id="path-1"></path>
                </g>
            </g>
        </svg>
    );
}

export default Youtube;
