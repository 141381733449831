
import {sendHeader,dataHeader,responseCheck} from "../utils/functions";

const baseUrl = window._env_.BASE_URL_CANDIDATOS;

sendHeader().then(dato=>dataHeader.Authorization=`Bearer ${dato}`);

export async function getCandidaturasRecientes(idioma, tryNumber = 0) {
  
  return fetch(
    `${baseUrl}/api/candidatura/candidaturasSimple?idioma=${idioma}`,
    {
      method: "GET",
      headers: dataHeader
    }
  ).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => getCandidaturasRecientes(idioma,tryNumerIncremented))
  );
}
