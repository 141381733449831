import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import createStore from "./redux/configureStore";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import SearchOffers from "./redux/containers/searchOffers";
import ErrorBoundaryWrapper from "./components/errorBoundaryWrapper";
import { useTranslation } from "react-i18next";
import CandidateDashboard from "./redux/containers/candidateDashboard";
import { SnackbarProvider } from "notistack";
import Clear from "@material-ui/icons/Clear";
import Candidaturas from "./redux/containers/candidaturas";
import Curriculum from "./redux/containers/curriculum";
import SaveSearch from "./redux/containers/saveSearch";
import { AuthService } from "./auth/AuthService";
import AuthWrapper from "./components/authWrapper";
import SavedSearches from "./Views/savedSearches";
import EditarDatosPersonales from "./redux/containers/editarDatosPersonales";
import MyDocuments from "./redux/containers/myDocuments";
import BasicData from "./redux/containers/basicData";
import WorkExperience from "./redux/containers/workExperienceCV";
import Formacion from "./redux/containers/formacion";
import Idiomas from "./redux/containers/idiomas";
import OtrosDatos from "./redux/containers/otrosDatos";
import RedireccionAlerta from "./redux/containers/redireccionAlerta";
import LogoutDelete from "./Views/logout_delete";
import CvGenerator from "./Views/cvGenerator";
import LocationListener from "./utils/locationListener";
import EttInfo from "./components/ettInfo";
import MaestrosContainer from "./redux/containers/maestrosContext";
import DatosContratacion from "./redux/containers/datosContratacion";
import OfferDetails from "./redux/containers/offerDetails";

const { store } = createStore();

const theme = createMuiTheme({
  palette: {
    primary: { main: "#DA291C" },
    secondary: { main: "#FFFFFF" },
    text: { primary: "#000000" },
  },
  typography: {
    fontFamily: ["Mulish", "sans-serif"].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      header1: 660,
      header2: 920,
      header3: 1180,
    },
  },
});

function Root() {
  const { t } = useTranslation();
  const classes = createMuiTheme();

  const auth = new AuthService();

  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  useEffect(() => {
    var meta = document.createElement("meta");
    meta.name = "viewport";
    meta.content =
      "width=device-width,height=" + window.innerHeight + ", initial-scale=1.0";
    document.getElementsByTagName("head")[0].appendChild(meta);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Provider store={store}>
          <SnackbarProvider
            maxSnack={3}
            preventDuplicate
            hideIconVariant
            dense={false}
            ref={notistackRef}
            autoHideDuration={6000}
            action={(key) => (
              <div title={t(`icons_tooltips.clear`)}>
                <Clear
                  color="secondary"
                  style={{ cursor: "pointer" }}
                  onClick={onClickDismiss(key)}
                />
              </div>
            )}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            classes={{
              containerRoot: classes.container,
              variantError: classes.error,
            }}
          >
            <MaestrosContainer>
              <Router>
                <LocationListener />
                <Switch>
                  <Redirect exact from="/" to="/candidate_dashboard" />             
                  <Route
                    path="/logout"
                    component={() => {
                      window.location.href = "https://nortempo.com/";
                      return null;
                    }}
                  />
                  <Route
                    path="/logout_delete"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper
                          forceLogin={false}
                          authService={auth}
                          ifNotLogged={true}
                        >
                          <LogoutDelete {...props} />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/login"
                    component={() => {
                      auth.login();
                      return null;
                    }}
                  />
                  <Route
                    path="/reset_password"
                    component={() => {
                      window.location.href =
                        window._env_.OIDC_REDIRECT_URI_AFTER_RESET;
                      return null;
                    }}
                  />
                  <Route
                    path="/search_offers/:provinciaBusqueda/:puestoBusqueda?"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper forceLogin={false} authService={auth}>
                          <SearchOffers {...props} />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/cv"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper forceLogin={false} authService={auth}>
                          <CvGenerator {...props} />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/offer/:offerId/:preview?"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper forceLogin={false} authService={auth}>
                          <OfferDetails
                            {...props}
                            authenticationNotNeeded={true}
                          />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/candidate_dashboard"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper forceLogin={true} authService={auth}>
                          <CandidateDashboard {...props} />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/logout"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper forceLogin={false} authService={auth}>
                          <div />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/candidaturas"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        {/* { RouteWithAuth(<CandidateDashboard {...props} />) } */}
                        <AuthWrapper forceLogin={true} authService={auth}>
                          <Candidaturas {...props} />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/curriculum"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper forceLogin={true} authService={auth}>
                          <Curriculum {...props} />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/datos_contratacion"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper forceLogin={true} authService={auth}>
                          <DatosContratacion {...props} />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/save_search"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper forceLogin={true} authService={auth}>
                          <SaveSearch {...props} />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/saved_searches"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper forceLogin={true} authService={auth}>
                          <SavedSearches {...props} />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/my_documents"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper forceLogin={true} authService={auth}>
                          <MyDocuments {...props} />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/ett"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper forceLogin={true} authService={auth}>
                          <EttInfo {...props}/>
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/basic_data"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper forceLogin={true} authService={auth}>
                          <BasicData />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/formacion"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper forceLogin={true} authService={auth}>
                          <Formacion {...props} />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/edit_personal_data"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper forceLogin={true} authService={auth}>
                          <EditarDatosPersonales {...props} />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/idioma"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper forceLogin={true} authService={auth}>
                          <Idiomas {...props} />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/work_experience/:experienceId?"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper forceLogin={true} authService={auth}>
                          <WorkExperience {...props} />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/otros_datos"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper forceLogin={true} authService={auth}>
                          <OtrosDatos {...props} />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                  <Route
                    path="/alerta"
                    render={(props) => (
                      <ErrorBoundaryWrapper
                        {...props}
                        errorText={t(`render_errors.render_order`)}
                        showButton={true}
                      >
                        <AuthWrapper forceLogin={true} authService={auth}>
                          <RedireccionAlerta {...props} />
                        </AuthWrapper>
                      </ErrorBoundaryWrapper>
                    )}
                  />
                </Switch>
              </Router>
            </MaestrosContainer>
          </SnackbarProvider>
        </Provider>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default Root;
