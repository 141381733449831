import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../components/basicLayout";
import Snackbar from "../components/customSnackBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import TuCurriculumHeader from "../components/tuCurriculumHeader";
import Loading from "../components/loading";
import { useTranslation } from "react-i18next";
import AddToCVActionButtons from "../components/addToCVActionButtons";
import YourFormation from "../assets/icons/yourFormation";
import Autocomplete from "../components/autocompleteWithLabelAnimation";
import i18next from "../i18n.js";
import { withSnackbar } from "notistack";
import {
  optionEquality,
  returnLangId,
  formatDateInput,
  formatDate,
  getNivelesEstudio,
  getAreasEstudio,
} from "../utils/functions";
import { FormControl, TextField } from "@material-ui/core";
import AntSwitch from "../components/antSwitch";
import DatePickerWithLabel from "../components/datePickerWithLabel";
import { getCertificate } from "../api/maestro";
import {
  addFormation,
  updateFormation,
  deleteFormation,
} from "../api/candidato";
import AlertDialog from "../components/alertDialog";
import InputWithLabel from "../components/inputWithLabelAnimation";

const niveles_de_estudio=getNivelesEstudio(i18next.languages[0]);
const areas_estudio=getAreasEstudio(i18next.languages[0]);

const useStyles = makeStyles((theme) => ({
  principal_container: {
    gridArea: "body_container",
    display: "grid",
    gridTemplateColumns: "30% 65%",
    columnGap: "20px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "auto",
    },
  },
  col2: {
    marginTop: "100px",
    padding: 20,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "60px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },
  data_sections_container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "40px",
  },
  data_section_icon_and_title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    marginBottom: "20px",
  },
  data_section_title_principal: {
    fontSize: 22,
    fontWeight: 900,
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  render_basic_data_style: {
    display: "flex",
    flexDirection: "column",
  },
  basic_data_grid: {
    display: "grid",
    gridTemplateColumns: ".5fr .5fr",
    columnGap: "26px",
    rowGap: "25px",
    alignItems: "end",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
      rowGap: "20px",
    },
  },
  date_start: {
    gridArea: "date_start",
    [theme.breakpoints.down("sm")]: {
      gridArea: "unset",
    },
  },
  date_end: {
    gridArea: "date_end",
    [theme.breakpoints.down("sm")]: {
      gridArea: "unset",
    },
  },
  dates_studies_grid: {
    display: "grid",
    gridTemplateColumns: ".5fr .5fr",
    columnGap: "26px",
    rowGap: "20px",
    gridTemplateAreas: `
    'is_currently .'
    'date_start date_end'`,
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateAreas: "unset",
      rowGap: "20px",
    },
  },
  text_switch: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  delete_row: {
    display: "grid",
    gridTemplateColumns: ".5fr .5fr",
    columnGap: "25px",
    gridTemplateAreas: `'. delete'`,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  delete_link: {
    justifySelf: "center",
    fontSize: 14,
    color: "#DA291C",
    gridArea: "delete",
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      opacity: "0.8",
    },
  },
}));

function Formacion(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { actions, state } = props;

  const [loading, setLoading] = useState(false);
  const [snackbarText, setSnackbarText] = useState(null);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [nombre, setNombre] = useState("");
  const [primerApellido, setPrimerApellido] = useState("");
  const [segundoApellido, setSegundoApellido] = useState("");
  const [correoElectronico, setCorreoElectronico] = useState("");
  const [prefijo, setPrefijo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [idFotografia, setIdFotografia] = useState(null);
  const [fechaModificacion, setFechaModificacion] = useState(null);

  const [idNivelEstudios, setIdNivelEstudios] = useState(null);
  const [idAreaEstudio, setIdAreaEstudio] = useState(null);
  const [titulo, setTitulo] = useState(null);
  const [centro, setCentro] = useState(null);
  const [esFormacionActual, setEsFormacionActual] = useState(true);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFin, setFechaFin] = useState(null);
  const [tieneValidez, setTieneValidez] = useState(false);
  const [fechaFinValidez, setFechaFinValidez] = useState(null);

  const [isAreaNeeded, setIsAreaNeeded] = useState(true);
  const [isTitleNeeded, setIsTitleNeeded] = useState(true);
  const [isCertificate, setIsCertificate] = useState(false);

  const [certificados, setCertificados] = useState(null);

  const [isEditMode, setIsEditMode] = useState(false);
  const [idFormacion, setIdFormacion] = useState(null);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [certificadosError, setCertificadosError] = useState(null);

  const minDate = new Date().setFullYear(Number(new Date().getFullYear()) - 90);
  const maxDate = new Date().setFullYear(Number(new Date().getFullYear()) + 30);

  const [saveWrong, setSaveWrong] = useState(false);

  const NIVEL_CERTIFICACIONES = 15;

  useEffect(() => {
    if (state && state.personalData) {
      handlePersonalDataAction(state.personalData);
    }
  }, [state.personalData]);

  useEffect(() => {
    if (state && state.formationData) {
      handleEditMode(state.formationData);
    }
  }, [state.formationData]);

  useEffect(() => {
    if (!certificados) {
      handleGetCertificados();
    }
  }, [certificados]);

  async function handleGetCertificados() {
    setLoading(true);
    const response = await getCertificate();
    if (response.status === 200) {
      let result = await response.json();
      setCertificados(result.certificados);
      setLoading(false);
    } else {
      setLoading(false);
      setCertificados([]);
      if (response.status !== 404) {
        setCertificadosError(t("add_formation.get_certificates_error"));
      }
    }
  }

  function handlePersonalDataAction(data) {
    setNombre(data.nombre);
    setPrimerApellido(data.primerApellido);
    setSegundoApellido(data.segundoApellido);
    setCorreoElectronico(data.correoElectronico);
    setPrefijo(data.prefijo);
    setTelefono(data.telefono);
    setIdFotografia(data.idFotografia);
    setFechaModificacion(data.fechaModificacion);
  }

  function handleSetIdNivelEstudios(value) {
    var idNivelEstudios =
      value && typeof value === "object" ? Number(value.id) : value;
    var nivelEstudios = niveles_de_estudio.find(
      (s) => Number(s.id) === Number(idNivelEstudios)
    );
    if (nivelEstudios) {
      setIsAreaNeeded(nivelEstudios.area === "Si");
      setIsTitleNeeded(nivelEstudios.title === "Si");
    } else {
      setIsAreaNeeded(true);
      setIsTitleNeeded(true);
    }
    setIsCertificate(Number(idNivelEstudios) === 15);
    setTitulo(null);
    setIdAreaEstudio(null);
    setIdNivelEstudios(idNivelEstudios);
  }

  function handleEditMode(formacion) {
    setIsEditMode(true);
    setIdFormacion(formacion.idFormacion);
    setIdNivelEstudios(formacion.idNivelEstudios);
    handleSetIdNivelEstudios(formacion.idNivelEstudios);
    setIdAreaEstudio(formacion.idAreaEstudios);
    setTitulo(
      formacion.idNivelEstudios === NIVEL_CERTIFICACIONES
        ? formacion.certificacion
        : formacion.titulo
    );
    setEsFormacionActual(formacion.esFormacionActual);
    setFechaInicio(
      formacion.fechaInicio ? formacion.fechaInicio : null
    );
    setFechaFin(formacion.fechaFin ? formacion.fechaFin : null);
    setTieneValidez(formacion.tieneFechaValidez);
    setFechaFinValidez(
      formacion.fechaFinValidez
        ? formatDateInput(formacion.fechaFinValidez)
        : null
    );
    setCentro(formacion.centro);
  }

  function validateStartDate() {
    const auxTime = new Date(fechaInicio);
    if (fechaInicio && !isNaN(auxTime.getTime()) && auxTime < new Date()) {
      return true;
    } else {
      return false;
    }
  }

  function validateEndDate() {
    if (fechaInicio && !esFormacionActual) {
      const auxTime = new Date(fechaInicio);
      const auxTime2 = new Date(fechaFin);
      if (
        validateStartDate() &&
        !isNaN(auxTime2.getTime()) &&
        auxTime2 >= auxTime &&
        auxTime2 < new Date()
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  function validateTitle() {
    if (isTitleNeeded) {
      if (titulo) {
        if (typeof titulo === "object") {
          return true;
        } else {
          return titulo.replace(/\s/g, "") !== "";
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  function validateNewFormation(isGoBack) {
    if (
      !idNivelEstudios ||
      idNivelEstudios === "" ||
      !validateTitle() ||
      (isAreaNeeded && (!idAreaEstudio || idAreaEstudio === "")) ||
      !validateStartDate() ||
      !validateEndDate() ||
      (tieneValidez && !fechaFinValidez)
    ) {
      setSaveWrong(true);
      props.enqueueSnackbar(t("common.data_error"), {
        variant: "error",
      });
    } else {
      isEditMode ? updateNewFormation(isGoBack) : addNewFormation(isGoBack);
    }
  }

  function emptyForm() {
    setIdFormacion(null);
    setIsEditMode(false);
    setIdNivelEstudios(null);
    setIdAreaEstudio(null);
    setTitulo("");
    setCentro("");
    setEsFormacionActual(true);
    setFechaInicio(null);
    setFechaFin(null);
    setTieneValidez(null);
    setFechaFinValidez(null);
    setIsAreaNeeded(true);
    setIsTitleNeeded(true);
    setIsCertificate(false);
  }

  const newData = () => {
    var nuevoCertificado = isCertificate
      ? typeof titulo === "object"
        ? { id: titulo.id, nombre: titulo.nombre }
        : { id: 0, nombre: titulo }
      : null;
    return {
      idNivelEstudios: idNivelEstudios,
      idAreaEstudios: isAreaNeeded ? idAreaEstudio : null,
      titulo: isTitleNeeded && !isCertificate ? titulo : "",
      esFormacionActual: esFormacionActual,
      fechaInicio: fechaInicio,
      fechaFin: !esFormacionActual ? fechaFin : null,
      tieneValidez: tieneValidez,
      fechaFinValidez: tieneValidez ? fechaFinValidez : null,
      certificacion: nuevoCertificado,
      centro: centro,
    };
  };

  async function addNewFormation(isGoBack) {
    var data = newData();
    setLoading(true);
    const response = await addFormation(
      data,
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      setSnackbarText(t("add_formation.formation_added"));
      setIsSnackBarOpen(true);
      if (isGoBack) {
        props.history.goBack();
      } else {
        setLoading(false);
        emptyForm();
        setFechaInicio(null);
      }
    } else {
      setLoading(false);
      var result = await response.json();
      props.enqueueSnackbar(
        result.errorMessage
          ? result.errorMessage
          : t("add_formation.adding_error"),
        {
          variant: "error",
        }
      );
    }
  }

  async function updateNewFormation(isGoBack) {
    var data = newData();
    setLoading(true);
    const response = await updateFormation(
      idFormacion,
      data,
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      actions.clearFormationToEditAction();
      setSnackbarText(t("add_formation.formation_added"));
      setIsSnackBarOpen(true);
      if (isGoBack) {
        props.history.goBack();
      } else {
        setLoading(false);
        emptyForm();
        setFechaInicio(null);
      }
    } else {
      setLoading(false);
      var result = await response.json();
      props.enqueueSnackbar(
        result.errorMessage
          ? result.errorMessage
          : t("add_formation.update_error"),
        {
          variant: "error",
        }
      );
    }
  }

  async function deleteForm() {
    setLoading(true);
    const response = await deleteFormation(
      idFormacion,
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      props.history.goBack();
    } else {
      setLoading(false);
      var result = await response.json();
      props.enqueueSnackbar(
        result.errorMessage
          ? result.errorMessage
          : t("add_formation.delete_error"),
        {
          variant: "error",
        }
      );
    }
  }

  const handleCertificadosError = () => {
    if (certificadosError) {
      props.enqueueSnackbar(certificadosError, {
        variant: "error",
      });
    }
  };

  function renderBasicData() {
    return (
      <div className={classes.render_basic_data_style}>
        <div className={classes.data_section_icon_and_title}>
          <YourFormation />
          <div className={classes.data_section_title_principal}>
            {isEditMode
              ? t("add_formation.title_edit")
              : t("add_formation.title")}
          </div>
        </div>
        <div className={classes.basic_data_grid}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              value={idNivelEstudios}
              setter={handleSetIdNivelEstudios}
              options={niveles_de_estudio}
              label={t("add_formation.studies_level")}
              getOption={(x, v) => optionEquality(x, v)}
              useDefaultIcon={true}
              getLabel={(x) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? niveles_de_estudio.find((s) => Number(s.id) === Number(x))
                      ?.name ?? ""
                  : ""
              }
              moveLabel={true}
              wrong={saveWrong && !idNivelEstudios}
            />
          </FormControl>
          {isAreaNeeded && (
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                useDefaultIcon={true}
                value={idAreaEstudio}
                setter={(value) => {
                  setIdAreaEstudio(value ? Number(value.id) : null);
                }}
                options={areas_estudio.filter((a) => Number(a.id) !== 0)}
                label={t("add_formation.studies_area")}
                getOption={(x, v) => optionEquality(x, v)}
                getLabel={(x) =>
                  typeof x === "object" && x !== null
                    ? x.name
                    : x
                    ? areas_estudio.find((s) => Number(s.id) === Number(x))
                        ?.name ?? ""
                    : ""
                }
                moveLabel={true}
                wrong={saveWrong && !idAreaEstudio}
              />
            </FormControl>
          )}
          {isTitleNeeded &&
            (isCertificate ? (
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  useDefaultIcon={true}
                  value={titulo}
                  setter={setTitulo}
                  options={certificados}
                  label={t("add_formation.studies_title")}
                  multiple={false}
                  freeSolo={true}
                  getOption={(x, v) => optionEquality(x, v)}
                  getLabel={(x) => x.nombre || x}
                  onOpen={handleCertificadosError}
                  moveLabel={true}
                  maxLength={250}
                  wrong={
                    saveWrong &&
                    (!titulo ||
                      (!titulo.nombre && titulo.replace(/\s/g, "") === ""))
                  }
                />
              </FormControl>
            ) : (
              <InputWithLabel
                fullWidth
                label={t("add_formation.studies_title")}
                value={titulo}
                setter={(e) => setTitulo(e.target.value)}
                moveLabel={true}
                maxLength={150}
                wrong={
                  saveWrong && (!titulo || titulo.replace(/\s/g, "") === "")
                }
              />
            ))}
          <InputWithLabel
            fullWidth
            label={t("add_formation.studies_center")}
            value={centro}
            setter={(e) => setCentro(e.target.value)}
            moveLabel={true}
            maxLength={150}
            wrong={saveWrong && (!centro || centro.replace(/\s/g, "") === "")}
          />
        </div>
      </div>
    );
  }

  function renderDeleteLink() {
    return (
      <div className={classes.delete_row}>
        <div
          onClick={() => setIsDialogOpen(true)}
          className={classes.delete_link}
        >
          {t("add_formation.delete")}
        </div>
      </div>
    );
  }

  function renderStudiesDates() {
    return (
      <div className={classes.dates_studies_grid}>
        <div className={classes.text_switch}>
          <div>{t("add_formation.currently_studying")}</div>
          <AntSwitch
            checked={esFormacionActual}
            setter={() => setEsFormacionActual(!esFormacionActual)}
          />
        </div>
        <div className={classes.date_start}>
          <DatePickerWithLabel
            customClass={classes.date_picker_class}
            label={t("add_formation.start_date")}
            value={fechaInicio}
            setter={setFechaInicio}
            minDate={minDate}
            maxDate={new Date()}
            wrong={saveWrong && !fechaInicio}
          />
        </div>
        {!esFormacionActual && (
          <div className={classes.date_end}>
            <DatePickerWithLabel
              customClass={classes.date_picker_class}
              label={t("add_formation.end_date")}
              value={fechaFin}
              setter={(value) => setFechaFin(value)}
              minDate={fechaInicio ? fechaInicio : minDate}
              maxDate={new Date()}
              wrong={saveWrong && !fechaFin}
            />
          </div>
        )}
      </div>
    );
  }

  function renderCertificateValidDate() {
    return (
      <div className={classes.basic_data_grid}>
        <div className={classes.text_switch}>
          <div>{t("add_formation.has_validation_date")}</div>
          <AntSwitch
            checked={tieneValidez}
            setter={() => setTieneValidez(!tieneValidez)}
          />
        </div>
        <div style={{ width: "100%" }}>
          <DatePickerWithLabel
            customClass={classes.date_picker_class}
            label={t("add_formation.valid_until")}
            value={tieneValidez ? fechaFinValidez : null}
            setter={(value) => setFechaFinValidez(value)}
            minDate={minDate}
            maxDate={maxDate}
            wrong={tieneValidez && !fechaFinValidez}
          />
        </div>
      </div>
    );
  }

  return (
    <Layout history={props.history} loading={loading}>
      <Snackbar
        text={snackbarText}
        isSnackBarOpen={isSnackBarOpen}
        handleIsSnackBarOpen={setIsSnackBarOpen}
      />
      <div className={classes.principal_container}>
        {!matchesMobile ? (
          <TuCurriculumHeader
            nombre={nombre}
            primerApellido={primerApellido}
            segundoApellido={segundoApellido}
            correoElectronico={correoElectronico}
            prefijo={prefijo}
            telefono={telefono}
            fechaModificacion={fechaModificacion}
          />
        ) : (
          <div />
        )}
        <div className={classes.col2}>
          <div className={classes.data_sections_container}>
            {renderBasicData()}
            {renderStudiesDates()}
            {isCertificate && renderCertificateValidDate()}
          </div>
          <AddToCVActionButtons
            saveAndActionText={t("add_formation.save_and_add")}
            onSaveClick={() => validateNewFormation(true)}
            onSaveAndActionClick={() => validateNewFormation(false)}
            onCancelClick={() => {
              props.history.goBack();
            }}
            isUpdate={isEditMode}
          />
          {isEditMode && renderDeleteLink()}
        </div>
      </div>
      <AlertDialog
        title={t("add_formation.delete_confirm")}
        open={isDialogOpen}
        handleClose={() => {
          setIsDialogOpen(false);
        }}
        handleAccept={() => {
          deleteForm();
          setIsDialogOpen(false);
        }}
      />
    </Layout>
  );
}

export default withSnackbar(Formacion);
