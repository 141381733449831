import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clearCandidateIdPhotography} from "../actions/webCandidate"
import Documents from "../../Views/myDocuments";

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      clearCandidateIdPhotography: bindActionCreators(clearCandidateIdPhotography, dispatch)
    }
  };
}

function mapStateToProps(state) {
  return {
    state: state.webCandidate
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
