import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../components/basicLayout";
import { useTranslation } from "react-i18next";
import Loading from "../components/loading";
import DatePickerWithLabel from "../components/datePickerWithLabel";
import { FormControl, Typography } from "@material-ui/core";
import {
  getDiscapacityOptions,
  getGenderOptions,
  getTiposPerfilDigital,
  getTiposVia,
  getNationalityOptions,
  getProvincias,
  optionEquality,
  getProvinceOptions,
  handleProvince,
  validateInternationalPhone,
  handleGetMaestro,
} from "../utils/functions";
import Add from "../assets/icons/cAdd";
import Trash from "../assets/icons/trash";
import PersonalData from "../assets/icons/personalData";
import { getDatosPersonales, updateDatosPersonales } from "../api/candidato";
import i18next from "../i18n.js";
import { withSnackbar } from "notistack";
import {
  returnLangId,
  capitalize,
  renderSwitchDigitalProfile,
  formatDateInput,
  validateDocument,
  validatePhone,
  validateEmail,
  validateSocialSecurityNumber,
  validatePostalCode,
  formatToLocalDate,
} from "../utils/functions";
import Snackbar from "../components/customSnackBar";
import TuCurriculumHeader from "../components/tuCurriculumHeader";
import AddToCVActionButtons from "../components/addToCVActionButtons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import ProfilePicture from "../redux/containers/profilePicture";
import InputWithLabel from "../components/inputWithLabelAnimation";
import AutocompleteWithLabel from "../components/autocompleteWithLabelAnimation";
import { SPAIN } from "../constants";
import ButtonOutlined from "../components/buttonOutlined";
import useMaestros from "../Hooks/useMaestros";

const nacionalidades = getNationalityOptions(i18next.languages[0]);
const certificadosDiscapacidad = getDiscapacityOptions(i18next.languages[0]);
const provincias = getProvincias(i18next.languages[0]);
const sexos = getGenderOptions(i18next.languages[0]);
const tiposVia = getTiposVia(i18next.languages[0]);
const tiposPerfilDigital = getTiposPerfilDigital(i18next.languages[0]);

const useStyles = makeStyles((theme) => ({
  principal_container: {
    gridArea: "body_container",
    display: "grid",
    gridTemplateColumns: "30% 65%",
    columnGap: "20px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "auto",
    },
  },
  col2: {
    marginTop: "100px",
    padding: 20,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "60px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },
  data_sections_container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "60px",
  },
  data_section_icon_and_title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    marginBottom: "25px",
  },
  data_section_title: {
    fontSize: 18,
    fontWeight: 900,
    marginBottom: "20px",
  },
  data_section_title_principal: {
    fontSize: 22,
    fontWeight: 900,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
    marginLeft: "10px",
  },
  data_section_container: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    [theme.breakpoints.up("md")]: {
      maxWidth: 400,
      minWidth: 280,
      width: "100%",
      marginBottom: "unset",
    },
  },
  input: {
    width: (props) => (props.displayInline ? "35%" : "100%"),
  },
 
  section_selector_input: {
    width: "100%",
    display: "grid",
    columnGap: "10px",
    gridTemplateColumns: ".4fr .6fr",
  },
  address_grid: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: ".20fr .20fr .20fr .20fr .20fr",
    columnGap: "25px",
    [theme.breakpoints.down("sm")]: {
      columnGap: "10px",
    },
  },
  single_perfil_digital: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "& + &": {
      marginTop: 20,
    },
  },
  icon_text_perfil_digital: {
    display: "flex",
    flexDirection: "row",
  },
  fullname_grid: {
    display: "grid",
    gridTemplateColumns: ".5fr .5fr",
    gridTemplateAreas: `
      'name .'
      'firstSurname secondSurname'`,
    columnGap: "25px",
    rowGap: "20px",
    marginBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas: "unset",
      gridTemplateColumns: "1fr",
      rowGap: "20px",
    },
  },
  name: {
    gridArea: "name",
    [theme.breakpoints.down("sm")]: {
      gridArea: "unset",
    },
  },
  firstName: {
    gridArea: "firstSurname",
    [theme.breakpoints.down("sm")]: {
      gridArea: "unset",
    },
  },
  secondName: {
    gridArea: "secondSurname",
    [theme.breakpoints.down("sm")]: {
      gridArea: "unset",
    },
  },
  basic_data_grid: {
    display: "grid",
    gridTemplateColumns: ".5fr .5fr",
    columnGap: "25px",
    rowGap: "20px",
    alignItems: "end",
    marginBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      width: "100%",
    },
  },
  direction_grid: {
    display: "grid",
    gridTemplateColumns: "1fr",
    columnGap: "25px",
    rowGap: "20px",
    alignItems: "end",
    marginBottom: "25px",
  },
  section_30_70: {
    display: "grid",
    gridTemplateColumns: "0.4fr 0.6fr",
    columnGap: "25px",
    alignItems: "end",
    [theme.breakpoints.down("sm")]: {
      columnGap: "10px",
    },
  },
  date_picker_class: {
    width: "100%",
  },
  location_grid: {
    display: "grid",
    gridTemplateColumns: ".333fr .333fr .333fr",
    columnGap: "25px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      rowGap: "20px",
      width: "100%",
    },
  },
  digital_profiles_grid: {
    display: "grid",
    gridTemplateColumns: ".25fr .75fr .35fr",
    columnGap: "25px",
    rowGap: "30px",
    [theme.breakpoints.down("md")]: {
      columnGap: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      rowGap: "20px",
      width: "100%",
    },
  },
  digital_profiles_list_container: {
    display: "flex",
    flexDirection: "column",
  },
  button_add_profiles: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    paddingTop: "15px",
  },
  text_add_profiles: {
    fontSize: "14px",
    marginLeft: '5px'
  },
  placeholder: {
    color: "#B2B2B2",
  },
  icon_text_perfil_digital_texto: {
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    padding: 0,
    margin: 0,
    marginLeft: "13px",
  },
  multiline: {
    marginBottom: "25px",
  },
  formControl: {
    height: "100%",
  },
  marginAuto: {
    marginTop: "auto",
  },
  inputRoot_textField: {
    height: "36px",
    "-webkit-appearance": "none",
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    },
    padding: "0px 6px !important",
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "-moz-appearance": "textfield !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important",
    },
  },
}));

function EditarDatosPersonales(props) {
  const { actions, paises, prefijos, localidades } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const maestros = useMaestros();
  const [loading, setLoading] = useState(false);

  const [datosPersonales, setDatosPersonales] = useState(null);

  const [nombre, setNombre] = useState(null);
  const [nuevoNombre, setNuevoNombre] = useState(null);

  const [primerApellido, setPrimerApellido] = useState(null);
  const [nuevoPrimerApellido, setNuevoPrimerApellido] = useState(null);

  const [segundoApellido, setSegundoApellido] = useState(null);
  const [nuevoSegundoApellido, setNuevoSegundoApellido] = useState(null);

  const [presentacion, setPresentacion] = useState(null);
  const [sexo, setSexo] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState(null);
  const [nacionalidad, setNacionalidad] = useState("");
  const [tipoIdentificacion, setTipoIdentificacion] = useState(null);
  const [identificacion, setIdentificacion] = useState(null);
  const [numeroSeguridadSocial, setNumeroSeguridadSocial] = useState(null);
  const [correoElectronico, setCorreoElectronico] = useState(null);
  const [correoElectronicoNotificacion, setCorreoElectronicoNotificacion] =
    useState(null);

  const [prefijo, setPrefijo] = useState(null);
  const [nuevoPrefijo, setNuevoPrefijo] = useState(null);

  const [telefono, setTelefono] = useState(null);
  const [nuevoTelefono, setNuevoTelefono] = useState(null);

  const [prefijoOtroTelefono, setPrefijoOtroTelefono] = useState(null);
  const [otroTelefono, setOtroTelefono] = useState(null);
  const [tipoVia, setTipoVia] = useState("");
  const [nombreVia, setNombreVia] = useState(null);
  const [numeroVia, setNumeroVia] = useState(null);
  const [bloque, setBloque] = useState(null);
  const [piso, setPiso] = useState(null);
  const [puerta, setPuerta] = useState(null);
  const [pais, setPais] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [codigoPostal, setCodigoPostal] = useState(null);
  const [provincia, setProvincia] = useState("");
  const [discapacidad, setDiscapacidad] = useState(null);
  const [idFotografia, setIdFotografia] = useState(null);
  const [perfilesDigitales, setPerfilesDigitales] = useState([]);
  const [fechaModificacion, setFechaModificacion] = useState(null);
  const [ciudad, setCiudad] = useState("");
  const [telefonoInternacional, setTelefonoInternacional] = useState(null);

  const [newPerfilDigital, setNewPerfilDigital] = useState("");
  const [newUriPerfilDigital, setNewUriPerfilDigital] = useState(null);

  const [snackbarText, setSnackbarText] = useState(null);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);

  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isNuevoNombreValid, setisNuevoNombreValid] = useState(true);
  const [isNuevoPrimerApellidoValid, setIsNuevoPrimerApellidoValid] =
    useState(true);
  const [isFechaDeNacimientoValid, setIsFechaDeNacimientoValid] =
    useState(true);
  const [isTipoIdentificacionValid, setIsTipoIdentificacionValid] =
    useState(true);
  const [isIdentificacionValid, setIsIdentificacionValid] = useState(true);
  const [isNumeroSeguridadSocialValid, setIsNumeroSeguridadSocialValid] =
    useState(true);
  const [
    isCorreoElectronicoNotificacionValid,
    setIsCorreoElectronicoNotificacionValid,
  ] = useState(true);
  const [isNuevoTelefonoValid, setIsNuevoTelefonoValid] = useState(true);
  const [isNuevoPrefijoValid, setIsNuevoPrefijoValid] = useState(true);
  const [isPrefijoOtroTelefonoValid, setIsPrefijoOtroTelefonoValid] =
    useState(true);
  const [isOtroTelefonoValid, setIsOtroTelefonoValid] = useState(true);
  const [isCodigoPostalValid, setIsCodigoPostalValid] = useState(true);
  const [tiposIdentificacion, setTiposIdentificacion] = useState([]);

  const updateIsNuevoNombreValid = (value) => {
    setisNuevoNombreValid(value && value.trim() !== "");
  };

  const updateIsNuevoPrimerApellidoValid = (value) => {
    setIsNuevoPrimerApellidoValid(value && value.trim() !== "");
  };

  const updateIsFechaDeNachimientoValid = (value) => {
    setIsFechaDeNacimientoValid(value && new Date(value) <= new Date());
  };

  const updateIsTipoIdentificacionValid = (value) => {
    setIsTipoIdentificacionValid(value !== null);
  };

  const updateIsIdentificacionValid = (value, tipoId) => {
    setIsIdentificacionValid(
      value &&
        value.trim() !== "" &&
        validateDocument(
          value,
          tiposIdentificacion.find((ti) => Number(ti.id) === Number(tipoId))
            ?.name ?? ""
        )
    );
  };

  const updateIsNumeroSeguridadSocialValid = (value) => {
    setIsNumeroSeguridadSocialValid(
      value ? validateSocialSecurityNumber(value) : true
    );
  };

  const updateIsCorreoElecetronicoNotificacionValid = (value) => {
    setIsCorreoElectronicoNotificacionValid(
      value ? validateEmail(value) : false
    );
  };

  const updateIsNuevoTelefonoValid = (value) => {
    setIsNuevoTelefonoValid(
      value && value.trim() !== "" && validatePhone(value)
    );
  };

  const updateIsOtroTelefonoValid = (value) => {
    setIsOtroTelefonoValid(
      value ? value && value.trim() !== "" && validatePhone(value) : true
    );
  };

  const updateIsCodigoPostalValid = (value) => {
    setIsCodigoPostalValid(value ? validatePostalCode(value, pais) : true);
  };

  useEffect(() => {
    handleGetMaestro(tiposIdentificacion,setTiposIdentificacion,maestros.getTipoIdentificacion());
  }, [tiposIdentificacion]);

  useEffect(() => {
    updateIsCodigoPostalValid(codigoPostal);
  }, [pais, codigoPostal]);

  const minDate = new Date().setFullYear(Number(new Date().getFullYear()) - 90);
  useEffect(() => {
    if (!datosPersonales) {
      handleDatosPersonales();
    } else {
      handleSetDatosPersonales();
    }
  }, [datosPersonales]);

  async function handleDatosPersonales() {
    setLoading(true);
    const response = await getDatosPersonales(
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      setLoading(false);
      const result = await response.json();
      setDatosPersonales(result);
    } else {
      setLoading(false);
      props.enqueueSnackbar(t("edit_personal_data.personal_data_error"), {
        variant: "error",
      });
      setDatosPersonales(null);
    }
  }

  function validateLocalidadOrRegion(){
    return (pais && pais == SPAIN) ? localidad : ciudad;
  }

  function validateActualizarDatosPersonales(goToDocuments) {
    const validateProvince = pais ? provincia : true;
    const validateLocalidadRegion = provincia ? validateLocalidadOrRegion() : true;
    if (
      isNuevoNombreValid &&
      isNuevoPrimerApellidoValid &&
      isFechaDeNacimientoValid &&
      isNumeroSeguridadSocialValid &&
      isTipoIdentificacionValid &&
      isIdentificacionValid &&
      isCorreoElectronicoNotificacionValid &&
      isNuevoTelefonoValid &&
      isOtroTelefonoValid &&
      isPrefijoOtroTelefonoValid &&
      isCodigoPostalValid &&
      validateProvince &&
      validateLocalidadRegion &&
      pais &&
      validateInternationalPhone(telefonoInternacional) &&
      (!nombreVia || (nombreVia.replace(/\s/g, "") !== "" && tipoVia)) &&
      (!tipoVia || (nombreVia && nombreVia.replace(/\s/g, "") !== "")) &&
      (!newUriPerfilDigital ||
        (newUriPerfilDigital.replace(/\s/g, "") !== "" && newPerfilDigital)) &&
      (!newPerfilDigital ||
        (newUriPerfilDigital && newUriPerfilDigital.replace(/\s/g, "") !== ""))
    ) {
      actualizarDatosPersonales(goToDocuments);
    } else {
      props.enqueueSnackbar(t("common.data_error"), {
        variant: "error",
      });
    }
  }

  function validateNuevoPrefijo() {
    if (nuevoPrefijo && !nuevoTelefono) {
      setNuevoPrefijo(null);
      return null;
    }
    if (!nuevoPrefijo && nuevoTelefono) {
      setNuevoPrefijo("34");
      return "34";
    }
    return nuevoPrefijo;
  }

  function validateOtroPrefijo() {
    if (prefijoOtroTelefono && !otroTelefono) {
      setPrefijoOtroTelefono(null);
      return null;
    }
    if (!prefijoOtroTelefono && otroTelefono) {
      setPrefijoOtroTelefono("34");
      return "34";
    }
    return prefijoOtroTelefono;
  }

  async function actualizarDatosPersonales(goToDocuments) {
    const fNacimiento = new Date(fechaNacimiento);

    const data = {
      nombre: nuevoNombre,
      primerApellido: nuevoPrimerApellido,
      segundoApellido: nuevoSegundoApellido,
      presentacion: presentacion && presentacion !== "" ? presentacion : null,
      sexo: sexo ? sexo : 0,
      fechaNacimiento: new Date(
        fNacimiento.getTime() - fNacimiento.getTimezoneOffset() * 60000
      ),
      nacionalidad: nacionalidad && nacionalidad !== "" ? nacionalidad : null,
      tipoIdentificacion: tipoIdentificacion,
      identificacion: identificacion.toUpperCase(),
      numeroSeguridadSocial:
        numeroSeguridadSocial && numeroSeguridadSocial !== ""
          ? numeroSeguridadSocial
          : null,
      correoElectronico: correoElectronico,
      correoElectronicoNotificacion: correoElectronicoNotificacion,
      telefono: { prefijo: validateNuevoPrefijo(), numero: nuevoTelefono },
      otroTelefono: { prefijo: validateOtroPrefijo(), numero: otroTelefono },
      tipoVia: tipoVia && tipoVia !== "" ? tipoVia : null,
      nombreVia: nombreVia && nombreVia !== "" ? nombreVia : null,
      numeroVia: numeroVia && numeroVia !== "" ? numeroVia : null,
      bloque: bloque && bloque !== "" ? bloque : null,
      piso: piso && piso !== "" ? piso : null,
      puerta: puerta && puerta !== "" ? puerta : null,
      localidad: localidad && localidad !== "" ? localidad : null,
      codigoPostal: codigoPostal && codigoPostal !== "" ? codigoPostal : null,
      provincia: provincia && provincia !== "" ? provincia : null,
      pais: pais && pais !== "" ? pais : null,
      ciudad: ciudad && ciudad !== "" ? ciudad : null,
      idDiscapacidad: discapacidad,
      perfilesDigitales:
        perfilesDigitales && perfilesDigitales.length > 0
          ? perfilesDigitales
          : null,
      telefonoInternacional: telefonoInternacional ?? null,
    };
    setLoading(true);
    const response = await updateDatosPersonales(
      data,
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      setNombre(nuevoNombre);
      setPrimerApellido(nuevoPrimerApellido);
      setSegundoApellido(nuevoSegundoApellido);
      setPrefijo(nuevoPrefijo);
      setTelefono(nuevoTelefono);
      setIdentificacion(identificacion.toUpperCase());
      setLoading(false);
      setSnackbarText(t("edit_personal_data.personal_data_saved"));
      setIsSnackBarOpen(true);
      goToDocuments && props.history.push("/my_documents");
    } else {
      setLoading(false);
      const error = await response.json();
      if (response.status === 418) {
        props.enqueueSnackbar(
          t("edit_personal_data.personal_data_save_error_ss"),
          {
            variant: "error",
          }
        );
      } else {
        if (error && error.errorMessage && error.errorMessage.trim() !== "") {
          props.enqueueSnackbar(error.errorMessage, {
            variant: "error",
          });
        } else {
          props.enqueueSnackbar(
            t("edit_personal_data.personal_data_save_error"),
            {
              variant: "error",
            }
          );
        }
      }
    }
  }

  function handleSetDatosPersonales() {
    var nombreData = datosPersonales.nombre
      ? datosPersonales.nombre.trim()
      : "";
    setNombre(nombreData);
    setNuevoNombre(nombreData);
    updateIsNuevoNombreValid(nombreData);
    var primerApellidoData = datosPersonales.primerApellido
      ? datosPersonales.primerApellido.trim()
      : "";
    setPrimerApellido(primerApellidoData);
    setNuevoPrimerApellido(primerApellidoData);
    updateIsNuevoPrimerApellidoValid(primerApellidoData);
    var segundoApellidoData = datosPersonales.segundoApellido
      ? datosPersonales.segundoApellido.trim()
      : "";
    setSegundoApellido(segundoApellidoData);
    setNuevoSegundoApellido(segundoApellidoData);
    setPresentacion(
      datosPersonales.presentacion ? datosPersonales.presentacion.trim() : null
    );
    setSexo(datosPersonales.sexo ? Number(datosPersonales.sexo) : "");
    var fechaNacimientoData = datosPersonales.fechaNacimiento
      ? formatDateInput(datosPersonales.fechaNacimiento.trim())
      : "";
    setFechaNacimiento(fechaNacimientoData);
    updateIsFechaDeNachimientoValid(fechaNacimientoData);
    setNacionalidad(
      datosPersonales.nacionalidad ? Number(datosPersonales.nacionalidad) : ""
    );
    var tipoIdentificacionData = datosPersonales.tipoIdentificacion
      ? Number(datosPersonales.tipoIdentificacion)
      : "";
    setTipoIdentificacion(tipoIdentificacionData);
    updateIsTipoIdentificacionValid(tipoIdentificacionData);
    var identificacionData = datosPersonales.identificacion
      ? datosPersonales.identificacion.trim()
      : null;
    setIdentificacion(identificacionData);
    updateIsIdentificacionValid(identificacionData, tipoIdentificacionData);
    var numeroSeguridadSocialData = datosPersonales.numeroSeguridadSocial
      ? datosPersonales.numeroSeguridadSocial.trim()
      : null;
    setNumeroSeguridadSocial(numeroSeguridadSocialData);
    updateIsNumeroSeguridadSocialValid(numeroSeguridadSocialData);
    setCorreoElectronico(
      datosPersonales.correoElectronico
        ? datosPersonales.correoElectronico.trim()
        : ""
    );
    var correoElectronicoNotificacionData =
      datosPersonales.correoElectronicoNotificacion
        ? datosPersonales.correoElectronicoNotificacion.trim()
        : null;
    setCorreoElectronicoNotificacion(correoElectronicoNotificacionData);
    updateIsCorreoElecetronicoNotificacionValid(
      correoElectronicoNotificacionData
    );
    var telefonoData = datosPersonales.telefono
      ? datosPersonales.telefono.numero
        ? datosPersonales.telefono.numero.trim()
        : ""
      : null;
    setTelefono(telefonoData);
    setNuevoTelefono(telefonoData);
    updateIsNuevoTelefonoValid(telefonoData);
    var prefijoData = datosPersonales.telefono
      ? datosPersonales.telefono.prefijo
        ? datosPersonales.telefono.prefijo.trim()
        : ""
      : null;
    setPrefijo(prefijoData);
    setNuevoPrefijo(prefijoData);
    var otroTelefonoData = datosPersonales.otroTelefono
      ? datosPersonales.otroTelefono.numero
        ? datosPersonales.otroTelefono.numero.trim()
        : ""
      : null;
    setOtroTelefono(otroTelefonoData);
    var otroPrefijoData = datosPersonales.otroTelefono
      ? datosPersonales.otroTelefono.prefijo
        ? datosPersonales.otroTelefono.prefijo.trim()
        : ""
      : null;
    setPrefijoOtroTelefono(otroPrefijoData);
    updateIsOtroTelefonoValid(otroTelefonoData);
    setTipoVia(datosPersonales.tipoVia ? datosPersonales.tipoVia.trim() : "");
    setNombreVia(
      datosPersonales.nombreVia ? datosPersonales.nombreVia.trim() : ""
    );
    setNumeroVia(
      datosPersonales.numeroVia ? datosPersonales.numeroVia.trim() : ""
    );
    setBloque(datosPersonales.bloque ? datosPersonales.bloque.trim() : "");
    setPiso(datosPersonales.piso ? datosPersonales.piso.trim() : "");
    setPuerta(datosPersonales.puerta ? datosPersonales.puerta.trim() : "");
    setPais(
      datosPersonales.pais
        ? datosPersonales.pais
        : parseInt(paises.find((p) => p.id === "724")?.id)
    );
    setProvincia(datosPersonales.provincia ? datosPersonales.provincia : "");
    setLocalidad(datosPersonales.localidad ? datosPersonales.localidad : "");
    var cp = datosPersonales.codigoPostal
      ? datosPersonales.codigoPostal.trim()
      : null;
    setCiudad(datosPersonales.ciudad);
    setCodigoPostal(cp);
    setDiscapacidad(
      datosPersonales.idDiscapacidad ? datosPersonales.idDiscapacidad : 1
    );
    let idFoto = datosPersonales.idFotografia
      ? datosPersonales.idFotografia
      : null;
    setIdFotografia(idFoto);
    actions.setCandidateIdPhotography(datosPersonales.idFotografia);
    setPerfilesDigitales(
      datosPersonales.perfilesDigitales ? datosPersonales.perfilesDigitales : []
    );
    setFechaModificacion(
      datosPersonales.fechaModificacion
        ? formatToLocalDate(new Date(datosPersonales.fechaModificacion))
        : ""
    );
    setTelefonoInternacional(
      datosPersonales.telefonoInternacional 
        ? datosPersonales.telefonoInternacional.trim() 
        : "" 
    );
  }

  function addPerfilDigital() {
    if (
      newUriPerfilDigital.toLowerCase().includes("http://") ||
      newUriPerfilDigital.toLowerCase().includes("https://")
    ) {
      setPerfilesDigitales([
        ...perfilesDigitales,
        { url: newUriPerfilDigital, tipoPerfilDigital: newPerfilDigital },
      ]);
    } else {
      setPerfilesDigitales([
        ...perfilesDigitales,
        {
          url: `https://${newUriPerfilDigital}`,
          tipoPerfilDigital: newPerfilDigital,
        },
      ]);
    }
    setNewPerfilDigital(null);
    setNewUriPerfilDigital("");
  }

  function renderFullName() {
    return (
      <div className={classes.fullname_grid}>
        <InputWithLabel
          fullWidth
          wrong={!isNuevoNombreValid}
          customClass={`${classes.input} ${classes.name}`}
          customClassWrapper={classes.name}
          label={t("edit_personal_data.name")}
          value={nuevoNombre}
          setter={(e) => {
            updateIsNuevoNombreValid(e.target.value);
            setNuevoNombre(e.target.value);
          }}
          moveLabel={true}
          maxLength={100}
        />
        <InputWithLabel
          fullWidth
          wrong={!isNuevoPrimerApellidoValid}
          customClass={`${classes.input} ${classes.firstName}`}
          customClassWrapper={classes.firstName}
          label={t("edit_personal_data.first_surname")}
          value={nuevoPrimerApellido}
          setter={(e) => {
            updateIsNuevoPrimerApellidoValid(e.target.value);
            setNuevoPrimerApellido(e.target.value);
          }}
          moveLabel={true}
          maxLength={100}
        />
        <InputWithLabel
          fullWidth
          customClass={`${classes.input} ${classes.secondName}`}
          customClassWrapper={classes.secondName}
          label={t("edit_personal_data.second_surname")}
          value={nuevoSegundoApellido}
          setter={(e) => {
            setNuevoSegundoApellido(e.target.value);
          }}
          moveLabel={true}
          maxLength={100}
        />
      </div>
    );
  }

  function renderBasicData() {
    return (
      <div className={classes.data_section_container} style={{ marginBottom: 0 }}>
         <div className={classes.basic_data_grid}>
        <FormControl variant="outlined" fullWidth>
          <AutocompleteWithLabel
            autoComplete={"new-password"}
            useDefaultIcon={true}
            value={sexo}
            setter={(value) => {
              setSexo(value ? Number(value.id) : null);
            }}
            options={sexos}
            label={t("edit_personal_data.gender_placeholder")}
            getOption={(x, v) => optionEquality(x, v)}
            getLabel={(x) =>
              typeof x === "object" && x !== null
                ? x.name
                : x
                ? sexos.find((s) => Number(s.id) === Number(x))?.name ?? ""
                : ""
            }
            moveLabel={true}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <AutocompleteWithLabel
            autoComplete={"new-password"}
            useDefaultIcon={true}
            value={nacionalidad}
            setter={(value) => {
              setNacionalidad(value ? Number(value.id) : null);
            }}
            options={nacionalidades}
            label={t("edit_personal_data.nationality_placeholder")}
            getOption={(x, v) => optionEquality(x, v)}
            getLabel={(x) =>
              typeof x === "object" && x !== null
                ? x.name
                : x
                ? nacionalidades.find((n) => Number(n.id) === Number(x))
                    ?.name ?? ""
                : ""
            }
            moveLabel={true}
          />
        </FormControl>
        <DatePickerWithLabel
          customClass={classes.date_picker_class}
          label={t("edit_personal_data.birthdate")}
          value={fechaNacimiento}
          setter={(value) => {
            updateIsFechaDeNachimientoValid(value);
            setFechaNacimiento(value);
          }}
          minDate={minDate}
          maxDate={new Date()}
          wrong={!isFechaDeNacimientoValid}
        />
        <div className={classes.section_selector_input}>
          <FormControl variant="outlined" fullWidth>
            <AutocompleteWithLabel
              id={"tipo_identificacion_autocomplete"}
              autoComplete={"new-password"}
              useDefaultIcon={true}
              wrong={!isTipoIdentificacionValid}
              value={tipoIdentificacion}
              setter={(value) => {
                var tipoId = value ? Number(value.id) : null;
                updateIsTipoIdentificacionValid(tipoId);
                updateIsIdentificacionValid(identificacion, tipoId);
                setTipoIdentificacion(tipoId);
              }}
              label={t("edit_personal_data.identification_type")}
              options={tiposIdentificacion}
              getOption={(x, v) => optionEquality(x, v)}
              getLabel={(x) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? tiposIdentificacion.find((n) => Number(n.id) === Number(x))
                      ?.name ?? ""
                  : ""
              }
              moveLabel={true}
            />
          </FormControl>
          <InputWithLabel
            wrong={!isIdentificacionValid}
            fullWidth
            customClass={classes.input}
            label={t("edit_personal_data.identification_number")}
            value={identificacion}
            setter={(e) => {
              setIdentificacion(e.target.value.toLocaleUpperCase());
              updateIsIdentificacionValid(e.target.value, tipoIdentificacion);
            }}
            moveLabel={true}
            maxLength={20}
          />
        </div>
        </div>
        <div className={classes.basic_data_grid}>
        <InputWithLabel
          wrong={!isNumeroSeguridadSocialValid}
          fullWidth
          type="number"
          name="numeroSeguridadSocial"
          placeholder={t(
            "edit_personal_data.social_security_number_placeholder"
          )}
          label={t("edit_personal_data.social_security_number")}
          value={numeroSeguridadSocial}
          setter={(e) => {
            e.target.value.trim().length < 13 &&
              setNumeroSeguridadSocial(e.target.value);
            updateIsNumeroSeguridadSocialValid(e.target.value);
          }}
          maxLength={12}
        />
        <FormControl variant="outlined" fullWidth>
          <AutocompleteWithLabel
            autoComplete={"new-password"}
            useDefaultIcon={true}
            value={discapacidad}
            setter={(value) => {
              setDiscapacidad(value ? Number(value.id) : null);
            }}
            options={certificadosDiscapacidad}
            getOption={(x, v) => optionEquality(x, v)}
            getLabel={(x) =>
              typeof x === "object" && x !== null
                ? x.name
                : x
                  ? certificadosDiscapacidad.find(
                    (n) => Number(n.id) === Number(x)
                  )?.name ?? ""
                  : ""
            }
            label={t("edit_personal_data.disabilty_certificate")}
          />
        </FormControl>
        </div>
      </div>
    );
  }

  function renderDatosPersonales() {
    return (
      <div className={classes.data_section_container}>
        <div className={classes.data_section_icon_and_title}>
          <PersonalData />
          <div className={classes.data_section_title_principal}>
            {t("edit_personal_data.personal_data")}
          </div>
        </div>
        {matchesMobile && (
          <ProfilePicture isEditAvailable={true} propsMargin="0 0 20px 0" />
        )}
        {renderFullName()}
        <InputWithLabel
          multiline={true}
          rows={6}
          fullWidth
          label={t("edit_personal_data.presentation")}
          value={presentacion}
          setter={(e) => setPresentacion(e.target.value)}
          customClassWrapper={classes.multiline}
          moveLabel={true}
          maxLength={2000}
        />
        {renderBasicData()}
      </div>
    );
  }

  function handleSetPais(value) {
    setPais(value);
    if (value !== 724) {
      const provincia = provincias.find(
        (prov) => prov.id_country === value?.toString()
      );
      setProvincia(Number(provincia?.id));
      setLocalidad(null);
      setCiudad("");
    } else {
      setProvincia(null);
      setLocalidad(null);
      setCiudad("");
    }
  }

  function renderDatosContacto() {
    return (
      <div className={classes.data_section_container}>
        <div className={classes.data_section_title}>
          {t("edit_personal_data.contact_data")}
        </div>
        <div className={classes.basic_data_grid}>
          <InputWithLabel
            fullWidth
            customClass={classes.input}
            value={correoElectronico}
            disabled
          />
          <InputWithLabel
            wrong={!isCorreoElectronicoNotificacionValid}
            fullWidth
            customClass={classes.input}
            label={t("edit_personal_data.notifications_email")}
            value={correoElectronicoNotificacion}
            setter={(e) => {
              updateIsCorreoElecetronicoNotificacionValid(e.target.value);
              setCorreoElectronicoNotificacion(e.target.value);
            }}
            moveLabel={true}
            maxLength={100}
          />
          <div className={classes.section_selector_input}>
            <FormControl variant="outlined" fullWidth>
              <AutocompleteWithLabel
                autoComplete={"new-password"}
                useDefaultIcon={true}
                wrong={!isNuevoPrefijoValid}
                value={nuevoPrefijo}
                setter={(value) => {
                  setNuevoPrefijo(value ? value.prefix.trim() : null);
                }}
                options={prefijos}
                multiple={false}
                placeholder={t("edit_personal_data.prefix")}
                label={t("edit_personal_data.principal_telephone")}
                freeSolo={false}
                getOption={(x, v) => optionEquality(x, v)}
                getLabel={(x) =>
                  typeof x === "object" && x !== null
                    ? `+${x.prefix}`
                    : x
                      ? `+${prefijos.find((pr) => pr.prefix.trim() === x.trim())
                        ? prefijos.find((pr) => pr.prefix.trim() === x.trim())
                          ?.prefix ?? ""
                        : ""
                      }`
                      : `+${x}`
                }
              />
            </FormControl>
            <InputWithLabel
              wrong={!isNuevoTelefonoValid}
              fullWidth
              label={t("edit_personal_data.telephone")}
              customClass={`${classes.input} ${classes.marginAuto}`}
              customClassWrapper={classes.marginAuto}
              value={nuevoTelefono}
              setter={(e) => {
                updateIsNuevoTelefonoValid(e.target.value);
                setNuevoTelefono(e.target.value);
              }}
              moveLabel={true}
              maxLength={10}
              type="tel"
            />
          </div>
          <div className={classes.section_selector_input}>
            <FormControl variant="outlined" fullWidth>
              <AutocompleteWithLabel
                autoComplete={"new-password"}
                useDefaultIcon={true}
                wrong={!isPrefijoOtroTelefonoValid}
                value={prefijoOtroTelefono}
                setter={(value) => {
                  setPrefijoOtroTelefono(value ? value.prefix.trim() : null);
                }}
                options={prefijos}
                placeholder={t("edit_personal_data.prefix")}
                label={t("edit_personal_data.secondary_telephone")}
                getOption={(x, v) => optionEquality(x, v)}
                getLabel={(x) =>
                  typeof x === "object" && x !== null
                    ? `+${x.prefix}`
                    : x
                      ? `+${prefijos.find((pr) => pr.prefix.trim() === x.trim())
                        ? prefijos.find((pr) => pr.prefix.trim() === x.trim())
                          .prefix
                        : ""
                      }`
                      : `+${x}`
                }
              />
            </FormControl>
            <InputWithLabel
              wrong={!isOtroTelefonoValid}
              fullWidth
              label={t("edit_personal_data.telephone")}
              customClass={`${classes.input} ${classes.marginAuto}`}
              customClassWrapper={classes.marginAuto}
              value={otroTelefono}
              setter={(e) => {
                updateIsOtroTelefonoValid(e.target.value);
                setOtroTelefono(e.target.value);
              }}
              moveLabel={true}
              maxLength={10}
              type="tel"
            />
          </div>
          <InputWithLabel
              fullWidth
              label={t("edit_personal_data.international_telephone")}
              customClass={`${classes.input} ${classes.marginAuto}`}
              customClassWrapper={classes.marginAuto}
              value={telefonoInternacional}
              setter={(e) => {
                setTelefonoInternacional(e.target.value);
              }}
              moveLabel={true}
              maxLength={20}
              wrong={!validateInternationalPhone(telefonoInternacional)}
            />
        </div>
        {renderDireccion()}
      </div>
    );
  }

  function renderDireccion() {
    return (
      <>
      <div className={classes.data_section_container}>
        <div className={classes.direction_grid}>
          <div className={classes.section_30_70}>
            <FormControl variant="outlined" fullWidth>
              <AutocompleteWithLabel
                autoComplete={"new-password"}
                useDefaultIcon={true}
                value={tipoVia}
                setter={(value) => {
                  setTipoVia(value ? value.id.trim() : null);
                }}
                options={tiposVia}
                placeholder={t("edit_personal_data.street_type_placeholder")}
                label={t("edit_personal_data.address")}
                getOption={(x, v) => optionEquality(x, v)}
                getLabel={(x) =>
                  typeof x === "object" && x !== null
                    ? capitalize(x.name)
                    : x
                    ? capitalize(
                        tiposVia.find((n) => n.id.trim() === x.trim())?.name ??
                          ""
                      )
                    : ""
                }
                wrong={
                  nombreVia && nombreVia.replace(/\s/g, "") !== "" && !tipoVia
                }
              />
            </FormControl>
            <InputWithLabel
              variant="outlined"
              customClass={classes.input}
              label={t("edit_personal_data.street_name")}
              value={nombreVia}
              setter={(e) => {
                setNombreVia(e.target.value);
              }}
              moveLabel={true}
              maxLength={100}
              wrong={
                tipoVia && (!nombreVia || nombreVia.replace(/\s/g, "") === "")
              }
            />
          </div>
          <div className={classes.address_grid}>
            <InputWithLabel
              fullWidth
              customClass={classes.input}
              type="number"
              label={t("edit_personal_data.number")}
              value={numeroVia}
              setter={(e) => setNumeroVia(e.target.value)}
              moveLabel={true}
              maxLength={4}
            />
            <InputWithLabel
              fullWidth
              customClass={classes.input}
              label={t("edit_personal_data.block")}
              value={bloque}
              setter={(e) => setBloque(e.target.value)}
              moveLabel={true}
              maxLength={2}
            />
            <InputWithLabel
              fullWidth
              customClass={classes.input}
              label={t("edit_personal_data.floor")}
              value={piso}
              setter={(e) => setPiso(e.target.value)}
              moveLabel={true}
              maxLength={2}
            />
            <InputWithLabel
              fullWidth
              customClass={classes.input}
              label={t("edit_personal_data.door")}
              value={puerta}
              setter={(e) => setPuerta(e.target.value)}
              moveLabel={true}
              maxLength={1}
            />
            <InputWithLabel
              wrong={!isCodigoPostalValid}
              fullWidth
              customClass={classes.input}
              type={pais && Number(pais) !== SPAIN ? "text" : "number"}
              onInput={(event) => {
                if (event.target.value.length > 10)
                  event.target.value = event.target.value.slice(0, 10);
              }}
              name="codigoPostal"
              label={t("edit_personal_data.postal_code")}
              value={codigoPostal}
              setter={(e) => {
                setCodigoPostal(e.target.value);
              }}
              moveLabel={true}
              maxLength={10}
            />
          </div>
        </div>
        <div className={classes.location_grid}>
          <AutocompleteWithLabel
            id={"pais_autocomplete"}
            autoComplete={"new-password"}
            useDefaultIcon={true}
            value={pais}
            setter={(value) => {
              handleSetPais(value ? Number(value.id) : null);
            }}
            label={t("edit_personal_data.country_placeholder")}
            options={paises}
            getOption={(x, v) => optionEquality(x, v)}
            getLabel={(x) =>
              typeof x === "object" && x !== null
                ? x.name
                : x
                  ? paises.find((n) => Number(n.id) === Number(x))?.name ?? ""
                  : ""
            }
            moveLabel={true}
            wrong={!pais}
          />
          <AutocompleteWithLabel
            id={"provincia_autocomplete"}
            autoComplete={"new-password"}
            useDefaultIcon={true}
            value={pais ? provincia : ""}
            disabled={!pais}
            setter={(value) => {
              setProvincia(value ? Number(value.id) : null);
              setLocalidad(null);
            }}
            label={pais == SPAIN || !pais ? t("edit_personal_data.province_placeholder") : t("edit_personal_data.region")}
            options={getProvinceOptions(provincias, pais, paises)}
            getOption={(x, v) => optionEquality(x, v)}
            getLabel={(x) =>
              typeof x === "object" && x !== null
                ? x.name
                : x
                  ? handleProvince(provincias, paises, x, pais)
                  : ""
            }
            moveLabel={true}
            wrong={pais && !provincia}
          />
          {pais == SPAIN || !pais ? 
          <AutocompleteWithLabel
            autoComplete={"new-password"}
            useDefaultIcon={true}
            disabled={!pais || !provincia || pais !== 724}
            value={pais && provincia ? localidad : null}
            setter={(value) => {
              setLocalidad(value ? Number(value.id) : null);
            }}
            label={t("edit_personal_data.city_placeholder")}
            options={localidades.filter(
              (loc) => Number(loc.id_province) === Number(provincia)
            )}
            getOption={(x, v) => optionEquality(x, v)}
            getLabel={(x) =>
              typeof x === "object" && x !== null
                ? x.name
                : x
                ? localidades.find((n) => Number(n.id) === Number(x))?.name ??
                  ""
                : ""
            }
            moveLabel={true}
            wrong={pais && provincia && !localidad}
          />
         : <InputWithLabel
              variant="outlined"
              customClass={classes.input}
              label={t("edit_personal_data.city_abroad")}
              value={ciudad}
              setter={(e) => {
                setCiudad(e.target.value);
              }}
              wrong={pais && !ciudad}
              moveLabel={true}
            />}
        </div>
      </div>
      </>
    );
  }

  function deletePerfilDigital(perfil) {
    var auxList = [...perfilesDigitales];
    var index = perfilesDigitales.indexOf(perfil);
    if (index !== -1) {
      auxList.splice(index, 1);
      setPerfilesDigitales(auxList);
    }
  }

  function renderPerfilDigital(perfil) {
    return (
      <div className={classes.single_perfil_digital}>
        <div
          className={classes.icon_text_perfil_digital}
          onClick={() => window.open(perfil.url)}
          style={{ cursor: "pointer" }}
        >
          {renderSwitchDigitalProfile(perfil.tipoPerfilDigital)}
          <p className={classes.icon_text_perfil_digital_texto}>{perfil.url}</p>
        </div>
        <div
          onClick={() => deletePerfilDigital(perfil)}
          style={{ cursor: "pointer" }}
        >
          <Trash title={t("icons_tooltips.delete")} />
        </div>
      </div>
    );
  }

  function handleButtonAddDigitalProfiles() {
    const visibilityButton = newUriPerfilDigital &&
      newPerfilDigital &&
      newUriPerfilDigital.replace(/\s/g, "") !== "" ? "visible" : "hidden";
    return (
      !matchesMobile ?
        <div className={classes.button_add_profiles}
          onClick={() => addPerfilDigital()}
          style={{
            visibility: visibilityButton 
          }}>
          <Add title={t("icons_tooltips.add")} />
          <Typography color="primary"
            className={classes.text_add_profiles}
          > {t("edit_personal_data.add_digital_profiles")}
          </Typography>
        </div>
        :
        <div style={{ visibility: visibilityButton }}>
          <ButtonOutlined
            disabled={!visibilityButton}
            size="100%"
            text={t("edit_personal_data.add_digital_profiles")}
            onClick={() => addPerfilDigital()} />
        </div>
    );
  }

  function renderPerfilesDigitales() {
    return (
      <div className={classes.data_section_container}>
        <div className={classes.data_section_title}>
          {t("edit_personal_data.digital_profiles")}
        </div>
        <div className={classes.digital_profiles_grid}>
          <FormControl variant="outlined">
            <AutocompleteWithLabel
              id={"red_social_autocomplete"}
              autoComplete={"new-password"}
              useDefaultIcon={true}
              value={newPerfilDigital}
              setter={(value) => {
                setNewPerfilDigital(value ? Number(value.id) : null);
              }}
              options={tiposPerfilDigital}
              label={t("edit_personal_data.social_network_placeholder")}
              getOption={(x, v) => optionEquality(x, v)}
              getLabel={(x) =>
                typeof x === "object" && x !== null
                  ? x.name
                  : x
                  ? tiposPerfilDigital.find((n) => Number(n.id) === Number(x))
                      ?.name ?? ""
                  : ""
              }
              moveLabel={true}
              wrong={
                newUriPerfilDigital &&
                newUriPerfilDigital.replace(/\s/g, "") !== "" &&
                !newPerfilDigital
              }
            />
          </FormControl>
          <InputWithLabel
            fullWidth
            wrong={
              newPerfilDigital &&
              (!newUriPerfilDigital ||
                newUriPerfilDigital.replace(/\s/g, "") === "")
            }
            InputProps={{
              classes: {
                root: classes.inputRoot_textField,
                input: classes.inputRoot_textField,
              },
            }}
            customClass={classes.input}
            label={t("edit_personal_data.url")}
            value={newUriPerfilDigital}
            setter={(e) => setNewUriPerfilDigital(e.target.value)}
            moveLabel={true}
            maxLength={500}
          />
         {handleButtonAddDigitalProfiles()}
          <div />
          <div className={classes.digital_profiles_list_container}>
            {perfilesDigitales &&
              perfilesDigitales.map((perfil) => renderPerfilDigital(perfil))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Layout history={props.history} loading={loading}>
      <div className={classes.principal_container}>
        {!matchesMobile ? (
          <TuCurriculumHeader
            nombre={nombre}
            primerApellido={primerApellido}
            segundoApellido={segundoApellido}
            correoElectronico={correoElectronico}
            prefijo={prefijo}
            telefono={telefono}
            fechaModificacion={fechaModificacion}
            isPhotoEditable={true}
          />
        ) : (
          <div />
        )}
        <div className={classes.col2}>
          <div className={classes.data_sections_container}>
            {renderDatosPersonales()}
            {renderDatosContacto()}
            {renderPerfilesDigitales()}
          </div>
          <AddToCVActionButtons
            saveAndActionText={t("edit_personal_data.save_and_attach")}
            onSaveClick={() => {
              validateActualizarDatosPersonales(false);
            }}
            onSaveAndActionClick={() => {
              validateActualizarDatosPersonales(true);
            }}
            onCancelClick={() => {
              props.history.goBack();
            }}
          />
        </div>
      </div>
      <Snackbar
        text={snackbarText}
        isSnackBarOpen={isSnackBarOpen}
        handleIsSnackBarOpen={setIsSnackBarOpen}
      />
    </Layout>
  );
}

export default withSnackbar(EditarDatosPersonales);
