import React, { useState, useEffect } from "react";
import Loading from "../components/loading";
import { agregarAlerta } from "../api/candidato";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../components/basicLayout";
import { withSnackbar } from "notistack";
import { Select, MenuItem, FormControl } from "@material-ui/core";
import ButtonFull from "../components/buttonFull";
import ButtonOutlined from "../components/buttonOutlined";
import i18next from "../i18n.js";
import { getFrecuenciasNotificacion, getProvincias, isForeingCountry, returnLangId } from "../utils/functions";
import SearchIcon from "../assets/icons/searchIcon";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import InputWithLabel from "../components/inputWithLabelAnimation";
import AutocompleteWithLabel from "../components/autocompleteWithLabelAnimation";

const frecuenciasNotificacion=getFrecuenciasNotificacion(i18next.languages[0]);
const dataListProvincias = getProvincias(i18next.languages[0]);

const useStyles = makeStyles((theme) => ({
  principal_container: {
    gridArea: "body_container",
    display: "grid",
    gridTemplateColumns: "30% 65%",
    columnGap: "20px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "auto",
    },
  },
  form_container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "repeat(3, min-content)",
    padding: 20,
    rowGap: "30px",
  },
  text_header: {
    fontSize: 22,
    fontWeight: 900,
  },
  form_text: {
    fontSize: 14,
    fontWeight: 900,
  },
  inner_containers: {
    display: "grid",
    gridTemplateColumns: "1fr",
    rowGap: "15px",
    gridTemplateRows: "repeat(2, min-content)",
  },
}));

function SaveSearch(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [frecuenciaSeleccionada, setFrecuenciaSeleccionada] = useState(null);
  const [tituloBusqueda, setTituloBusqueda] = useState("");
  const [emptyTitle, setEmptyTitle] = useState(false);
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [palabraClave, setPalabraClave] = useState(null);
  const [provincias, setProvincias] = useState(null);
  const [localidades, setLocalidades] = useState(null);
  const [sectores, setSectores] = useState(null);
  const [puestos, setPuestos] = useState(null);
  const [tiposJornada, setTiposJornada] = useState(null);
  const [tiposContrato, setTiposContrato] = useState(null);
  const [ciudades, setCiudades] = useState(null);

  const { state, actions } = props;
  useEffect(() => {
    if (state) {
      if (state.filtersAction) {
        setFilters(state.filtersAction);
      }
    }
  }, [state]);

  function setFilters(data) {
    setPalabraClave(data.palabraClave ? data.palabraClave : null);
    setProvincias(data.provincias ? data.provincias : null);
    setLocalidades(data.localidades ? data.localidades : null);
    setSectores(data.sectores ? data.sectores : null);
    setPuestos(data.puestos ? data.puestos : null);
    setTiposJornada(data.tiposJornada ? data.tiposJornada : null);
    setTiposContrato(data.tiposContrato ? data.tiposContrato : null);
    setCiudades(data.ciudades ? data.ciudades : null);
  }

  async function saveSearch() {
    setLoading(true);
    const data = {
      Nombre: tituloBusqueda,
      IdFrecuenciaNotificacionAlerta: frecuenciaSeleccionada.id,
      PalabraClave: palabraClave,
      Provincias: provincias,
      Localidades: localidades,
      Sectores: sectores,
      Puestos: puestos,
      TiposJornada: tiposJornada,
      TiposContrato: tiposContrato,
      ciudades: ciudades.map((c)=> c.trim().slice(0,-3).trim()),
    };
    const response = await agregarAlerta(
      data,
      returnLangId(i18next.languages[0])
    );
    setLoading(false);
    if (response.status === 200) {
      actions.setSuccesfulSave(tituloBusqueda);
      props.history.push(
        `search_offers/${provincias.length > 1 ? isForeingCountry(dataListProvincias,provincias) : provincias}/${
          palabraClave ? palabraClave : ""
        }`
      );
    } else {
      var result = await response.json();
      props.enqueueSnackbar(
        result.errorMessage ? result.errorMessage : t("offers.error"),
        {
          variant: "error",
        }
      );
    }
  }

  const handleInputChange = (value) => {
    setEmptyTitle(false);
    setTituloBusqueda(value);
  };

  function validateSave() {
    tituloBusqueda &&
    tituloBusqueda.trim() !== "" &&
    tituloBusqueda.length <= 50
      ? saveSearch()
      : requiredTitle();
  }

  function requiredTitle() {
    setEmptyTitle(true);
  }

  useEffect(() => {
    if (!frecuenciaSeleccionada) {
      setFrecuenciaSeleccionada(frecuenciasNotificacion[0]);
    }
  }, [frecuenciaSeleccionada]);

  return (
    <Layout history={props.history} loading={loading} hasBackgroundImage={true}>
      <div className={classes.principal_container}>
        <div className={classes.form_container}>
          <div className={classes.inner_containers}>
            <div style={{ display: matchesMobile ? "none" : "block" }}>
              <SearchIcon />
            </div>
            <div className={classes.text_header}>
              {t("saveSearch.saveSearchDialogHeader")}
            </div>
          </div>
          <div className={classes.inner_containers}>
            <div className={classes.form_text}>
              {t("saveSearch.saveSearchDialogText1")}
            </div>
            <InputWithLabel
              fullWidth={true}
              customClassWrapper={classes.input}
              value={tituloBusqueda}
              setter={(e) => handleInputChange(e.target.value)}
              wrong={emptyTitle}
              maxLength={50}
            />
          </div>
          <div className={classes.inner_containers}>
            <div className={classes.form_text}>
              {t("saveSearch.saveSearchDialogText2")}
            </div>
            <FormControl variant="outlined">
              <AutocompleteWithLabel
                options={frecuenciasNotificacion}
                getLabel={(option) => option.name}
                value={frecuenciaSeleccionada}
                setter={setFrecuenciaSeleccionada}
              />
            </FormControl>
          </div>
          <div className={classes.inner_containers}>
            <ButtonFull
              text={t("common.save")}
              onClick={() => validateSave()}
            />
            <ButtonOutlined
              text={t("common.cancel")}
              onClick={() => {
                props.history.push(
                  `search_offers/${provincias.length > 1 ?  isForeingCountry(dataListProvincias,provincias)  : provincias}/${
                    palabraClave ? palabraClave : ""
                  }`
                );
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default withSnackbar(SaveSearch);
