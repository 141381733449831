import React from "react";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import ButtonOutlined from "../components/buttonOutlined";
import ButtonFull from "../components/buttonFull";
import Clear from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialog_container: {
    width:480,
    padding: 50,
    position: "relative",
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  close_icon: {
    position: "absolute",
    top: 14,
    right: 14,
    cursor: "pointer",
    width: 14,
    height: 14
  },
  dialog_title: {
    marginTop: 10,
    padding: 0,
    '& h2':{
      fontSize: 22,
      lineHeight: "30px",
      color: "black",
      fontWeight: 900,
    }
  },
  dialog_text:{
    fontSize: 14,
    color: "black"
  },
  dialog_content: {
    padding: 0,
    marginBottom:30,
  },
  card_button_row: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display:"grid",
      gridTemplateColumns: "1fr",
      gridTemplateAreas: `
      'delete'
      'cancel'`
    },
  },
  button: {
    width: "48%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "20px"
    },
  },
}));

export default function AlertDialog(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    open,
    handleClose,
    title,
    handleAccept,
    description = null,
    confirmDeleteButtonText = t("common.delete"),
    customClass = ""
  } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: `${classes.dialog_container} ${customClass}`}}
      fullScreen={matchesMobile}
    >
      <div title={t(`icons_tooltips.clear`)}>
        <Clear
          color="primary"
          className={classes.close_icon}
          onClick={handleClose}
        />
      </div>
      <DialogContent className={classes.dialog_content}>
        <DialogTitle 
          id="alert-dialog-title" 
          className={classes.dialog_title}
        >
          {title}
        </DialogTitle>
        {description && 
          <DialogContentText
            id="alert-dialog-description"
            className={classes.dialog_text}
          >
            {description}
          </DialogContentText>
        }
      </DialogContent>
      <DialogActions className={classes.dialog_actions}>
        <div className={classes.card_button_row}>
          <div
            style={{gridArea: 'cancel'}}
            className={classes.button} >
          <ButtonOutlined
            text={t("alertDialog.cancel")}
            size="100%"
            onClick={handleClose}
          />
          </div>
          <div
            style={{gridArea: 'delete'}}  
            className={classes.button} >
          <ButtonFull
            text={confirmDeleteButtonText}
            size="100%"
            onClick={handleAccept}         
          />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}
