import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  a: {
    fill: "#fc030b",
  },
  b: {
    fill: "none",
  },
}));

function Cancel(props) {
  const { t } = useTranslation();
  const { title = t(`my_documents.attach_document`), customClass = "", onClick = () => true, size = "36px" } = props;
  const classes = useStyles();

  return (
    <Tooltip title={title}>
      <svg xmlns="http://www.w3.org/2000/svg" className={`${customClass}`} onClick={onClick} width={size} height={size} viewBox="0 0 36 36">
        <g transform="translate(2.5 -1.25)">
            <path className={classes.a} d="M261.572,230.957l-15.4,7.848a6.012,6.012,0,1,1-5.459-10.714l16.741-8.531a3.758,3.758,0,1,1,3.412,6.7L246.8,233.422a1.5,1.5,0,1,1-1.365-2.679l12.722-6.482-1.023-2.009-12.723,6.483a3.758,3.758,0,1,0,3.412,6.7l14.062-7.165a6.012,6.012,0,1,0-5.459-10.714l-16.741,8.53a8.267,8.267,0,0,0,7.507,14.732l15.4-7.848Z" transform="translate(-235.172 -210.145)"/>
        </g>
        <rect className={classes.b} width={size} height={size}/>
      </svg>
    </Tooltip>
    
  );
}

export default Cancel;
