import React , { useContext } from 'react';
import { getIdentificationTypes, getTypeTimetable } from '../api/maestro';
import { formatMaestro } from '../utils/functions';
import { useTranslation } from 'react-i18next';
import { MaestroContext } from "../contexts/maestrosContext";

function useMaestros() {
    const { t } = useTranslation();
    const { tipoHorario, tipoIdentificacion ,actions, enqueueSnackbar } = useContext(MaestroContext);

    const listMaestros = {
        "tipoHorario": {
            value: tipoHorario,
            function: async () => await getTypeTimetable(),
            setter: (e) => actions.setTipoHorario(e),
            messageError: t("curriculum.timetable_type_error")
        },
        "tipoIdentificacion": {
            value: tipoIdentificacion,
            function: async () => await getIdentificationTypes(),
            setter: (e) => actions.setTipoIdentificacion(e),
            messageError: t("curriculum.identification_type_error")
        },
    }

    async function handleGetMaestros(peticion, errorMsg) {
        const response = await peticion;
        if (response.status === 200) {
            const result = await response.json();
            const formatedResult = formatMaestro(result);
            return formatedResult;
        } else {
            enqueueSnackbar(errorMsg, { variant: "error" });
        }
    }

    const GetMaestro = async (maestro) => {
        const datosMaestro = listMaestros[maestro]
        if (!datosMaestro.value) {
            datosMaestro.value = await handleGetMaestros(datosMaestro.function(), datosMaestro.messageError);
            datosMaestro.setter(datosMaestro.value);
        }
        return datosMaestro.value;
    }

    return {
        getTipoHorario: () => GetMaestro("tipoHorario"),
        getTipoIdentificacion: () => GetMaestro("tipoIdentificacion"),
    }
}

export default useMaestros;