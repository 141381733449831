import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import { setPersonalDataAction, setCandidateWorkExperience } from "../actions/webCandidate"
import workExperienceCV from "../../Views/workExperienceCV";
import { mapPrefixes } from "../../utils/functions";

function mapDispatchToProps(dispatch) {
  return {
    // actions: {
    //   setPersonalDataAction: bindActionCreators(setPersonalDataAction, dispatch),
    //   setCandidateWorkExperience: bindActionCreators(setCandidateWorkExperience, dispatch),
    // }
  };
}

function mapStateToProps(state) {
  return {
    state: state.webCandidate,
    paises: state?.maestros.paises,
    phonePrefixes: mapPrefixes(state?.maestros.paises),
    localidades: state?.maestros?.localidades
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(workExperienceCV);
