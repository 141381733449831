import React from "react";
import { useTranslation } from "react-i18next";

function PcMonitor(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.virtual_office")} = props;

    return(
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="pc-monitor" transform="translate(-1132.000000, -20.000000)">
                    <g transform="translate(1132.000000, 20.000000)">
                        <path id="Shape" fill="#DA291C" d="M11,0H1C0.4,0,0,0.4,0,1v7c0,0.6,0.4,1,1,1h4v1H2v2h8v-2H7V9h4c0.6,0,1-0.4,1-1V1C12,0.4,11.6,0,11,0z M10,7H2V2h8V7z"/>
                    </g>
                </g>
            </g>
        </svg>   
    )
}

export default PcMonitor;