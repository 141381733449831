
import {sendHeader,dataHeader,responseCheck, dataHeaderWithoutAuthorization} from "../utils/functions";

const baseUrlMaestros = window._env_.BASE_URL_MAESTROS;

sendHeader().then(dato=>dataHeader.Authorization=`Bearer ${dato}`);

export async function downloadDocumentInform(idDocumento, tryNumber = 0) {
  
  return fetch(`${baseUrlMaestros}/api/documentos/${idDocumento}`, {
    method: "GET",
    headers: dataHeader,
  }).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => downloadDocumentInform(idDocumento,tryNumerIncremented))
  );
}

export async function getCertificate(
  idCertificate = "",
  filter = "",
  tryNumber = 0
) {
 
  return fetch(
    `${baseUrlMaestros}/api/certificados?certificado=${idCertificate}&filtro=${filter}`,
    {
      method: "GET",
      headers: dataHeader,
    }
  ).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => getCertificate(idCertificate ,filter,tryNumerIncremented))
  );
}

// Obtener conocimientos
export async function getKnowledge(
  idKnowledge = "",
  filter = "",
  tryNumber = 0
) {
 
  return fetch(
    `${baseUrlMaestros}/api/conocimientos?conocimiento=${idKnowledge}&filtro=${filter}`,
    {
      method: "GET",
      headers:dataHeader,
    }
  ).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => getKnowledge(idKnowledge ,filter,tryNumerIncremented))
  );
}

// Obtener etts
export async function getEtts(tryNumber = 0) {
  
  return fetch(
    `${baseUrlMaestros}/api/ett`,
    {
      method: "GET",
      headers: dataHeader,
    }
  ).then((response) => 
   responseCheck(response,tryNumber,(tryNumerIncremented) => getEtts(tryNumerIncremented)));
}

// Obtener funciones
export async function getFunction(idFunction = "", filter = "", tryNumber = 0) {

  return fetch(
    `${baseUrlMaestros}/api/funciones?funcion=${idFunction}&filtro=${filter}`,
    {
      method: "GET",
      headers:dataHeader,
    }
  ).then((response) => 
   
    responseCheck(response,tryNumber,(tryNumerIncremented) => getFunction(idFunction,filter,tryNumerIncremented))
  );
}

export async function getLevelExperiencie(idLevel = "", filter = "", tryNumber = 0) {

  return fetch(
    `${baseUrlMaestros}/api/niveles?nivel=${idLevel}&filtro=${filter}`,
    {
      method: "GET",
      headers:dataHeader,
    }
  ).then((response) => 
   
    responseCheck(response,tryNumber,(tryNumerIncremented) => getLevelExperiencie(idLevel,filter,tryNumerIncremented))
  );
}

export async function getStaffInCharge(idStaff = "", filter = "", tryNumber = 0) {

  return fetch(
    `${baseUrlMaestros}/api/personalACargo?personalACargo=${idStaff}&filtro=${filter}`,
    {
      method: "GET",
      headers:dataHeader,
    }
  ).then((response) => 
   
    responseCheck(response,tryNumber,(tryNumerIncremented) => getStaffInCharge(idStaff,filter,tryNumerIncremented))
  );
}

export async function getDirectManger(idManager = "", filter = "", tryNumber = 0) {

  return fetch(
    `${baseUrlMaestros}/api/nivelesResponsable?nivelResponsable=${idManager}&filtro=${filter}`,
    {
      method: "GET",
      headers:dataHeader,
    }
  ).then((response) => 
   
    responseCheck(response,tryNumber,(tryNumerIncremented) => getDirectManger(idManager,filter,tryNumerIncremented))
  );
}

//Obtener puestos
export async function getPlace(idIdioma, idPosition = "", des = "", tryNumber) {

  return fetch(
    `${baseUrlMaestros}/api/puestos?puesto=${idPosition}&filtro=${des}&idioma=${idIdioma}`,
    {
      method: "GET",
      headers: dataHeader,
    }
  ).then((response) => 
    responseCheck(response,tryNumber,(tryNumerIncremented) => getPlace(idIdioma, idPosition, des, tryNumerIncremented))
    );
}

//Obtener paises
export function getPaises(tryNumber = 0) {
      return fetch(
        `${baseUrlMaestros}/api/paises`,
        {
          method: "GET",
          headers: dataHeaderWithoutAuthorization,
        }
      ).then((response) => {
        return response;
      });
}

//Obtener sectores
export async function getSectors(idIdioma, idSector = "", des = "", tryNumber) {
  
  return fetch(
    `${baseUrlMaestros}/api/sectores?sector=${idSector}&filtro=${des}&idioma=${idIdioma}`,
    {
      method: "GET",
      headers: dataHeader,
    }
  ).then((response) => 
  responseCheck(response,tryNumber,(tryNumerIncremented) => getSectors(idIdioma, idSector, des, tryNumerIncremented))
  );
}

export async function getCertificateLanguages(
  idCertificate = "",
  filter = "",
  idIdioma = "",
  tryNumber = 0
) {

  return fetch(
    `${baseUrlMaestros}/api/certificadosIdiomas?certificado=${idCertificate}&filtro=${filter}&idioma=${idIdioma}`,
    {
      method: "GET",
      headers:dataHeader,
    }
  )
    .then((response) =>   responseCheck(response,tryNumber,(tryNumerIncremented) => getCertificateLanguages(idCertificate ,filter ,idIdioma , tryNumerIncremented)))
    .catch((error) => {
      return { status: "errorNoApi" };
    });
}

export function getIdentificationTypes() {
  return fetch(
    `${baseUrlMaestros}/api/tipoIdentificacion`,
    {
      method: "GET",
      headers: dataHeaderWithoutAuthorization,
    }
  ).then((response) => {
    return response;
  });
}

export function getLocalidades() {
  return fetch(
    `${baseUrlMaestros}/api/localidad`,
    {
      method: "GET",
      headers: dataHeaderWithoutAuthorization,
    }
  ).then((response) => {
    return response;
  });
}

export function getTypeTimetable() {
  return fetch(
    `${baseUrlMaestros}/api/tipoHorario`,
    {
      method: "GET",
      headers: dataHeaderWithoutAuthorization,
    }
  ).then((response) => {
    return response;
  });
}