import React from "react";
import { useTranslation } from "react-i18next";

function MoveUpGrey(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.upload_document")} = props;

  return (
    <svg width="129px" height="128px" viewBox="0 0 129 128" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <defs>
            <filter id="filter-1">
                <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0.580392 0 0 0 0 0.580392 0 0 0 0 0.580392 0 0 0 1.000000 0"></feColorMatrix>
            </filter>
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="move-up" transform="translate(-548.000000, -415.000000)" filter="url(#filter-1)">
                <g transform="translate(548.000000, 421.000000)">
                    <line x1="64" y1="91" x2="64" y2="0" id="Path" stroke="#000000" strokeWidth="8"></line>
                    <polyline id="Path" stroke="#000000" strokeWidth="8" strokeLinecap="square" points="99 32 64 0 29 32"></polyline>
                    <line x1="129" y1="118" x2="0" y2="118" id="Path" stroke="#000000" strokeWidth="8"></line>
                </g>
            </g>
        </g>
    </svg>
  );
}

export default MoveUpGrey;
