import React from "react";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    color: "green",
    padding: "13px 0",
  },
  mark: {
    width: "8px !important",
    height: "8px !important",
    borderRadius: "50% !important",
    color: "#696969",
    top: "10px",
  },
  thumb: {
    color: "#d9291c",
    width: "16px",
    height: "16px",
    marginTop: "-8px",
    "&:hover": {
      boxShadow: "0px 0px 0px 8px rgb(217 41 28 / 16%) !important",
    },
  },
  thumbFocusVisible: {
    boxShadow: "0px 0px 0px 8px rgb(217 41 28 / 16%) !important",
  },
  thumbActive: {
    boxShadow: "0px 0px 0px 14px rgb(217 41 28 / 16%) !important",
  },
  valueLabel: {
    left: "calc(-50%)",
  },
});

function AutoevalSlider(props) {
  const { marks, setter, value } = props;
  const classes = useStyles();
  const formatedMarks = marks.map((m) => ({
    value: m.valor,
    label: m.nombre.charAt(0).toUpperCase() + m.nombre.slice(1),
  }));
  return (
    <Slider
      aria-label="autoeval"
      value={value}
      step={1}
      valueLabelDisplay="off"
      marks={formatedMarks}
      min={1}
      max={4}
      track={true}
      onChangeCommitted={(event, value) => setter(value)}
      classes={{
        root: classes.root,
        rail: classes.rail,
        mark: classes.mark,
        thumb: classes.thumb,
        active: classes.thumbActive,
        focusVisible: classes.thumbFocusVisible,
        valueLabel: classes.valueLabel,
      }}
    />
  );
}

export default AutoevalSlider;
