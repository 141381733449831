import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../components/loading";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Typography, Box, Icon, Button } from "@material-ui/core";
import AutocompleteWithLabel from "../components/autocompleteWithLabelAnimation";
import {
  getCandidateDocuments,
  uploadCandidateDocuments,
  deleteCandidateDocuments,
  downloadDocumentInform,
} from "../api/candidato";
import Folder from "../assets/icons/folder";
import MoveUp from "../assets/icons/moveUp";
import TrashRed from "../assets/icons/trashRed";
import MoveUpGrey from "../assets/icons/moveUpGrey";
import DownloadDocument from "../assets/icons/downloadDocument";
import Layout from "../components/basicLayout";
import { withSnackbar } from "notistack";
import i18next from "../i18n.js";
import { getDocTypes, returnLangId } from "../utils/functions";
import { returnMimeData, base64ToArrayBuffer } from "../utils/functions";
import AlertDialog from "../components/alertDialog";
import { ID_APLICACION_GESTOR } from "../constants";
import InputWithLabel from "../components/inputWithLabelAnimation.js";

const useStyles = makeStyles((theme) => ({
  body: {
    gridArea: "body_container",
    display: "grid",
    gridTemplateColumns:
      "minMax(0,1fr) clamp(1000px, 1100px, 1200px) minMax(0, 1fr)",
    gridTemplateAreas: "'leftMarg content rightMarg'",
    backgroundImage: "url(images/tus-documentos-1920.jpg)",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateRows: "auto",
      gridTemplateColumns: "0% minMax(0, 1fr) 0%",
      backgroundImage: "none",
      backgroundAttachment: "none",
      backgroundPosition: "none",
      backgroundRepeat: "none",
      backgroundSize: "none",
    },
    overflow: "auto",
  },
  body_container: {
    display: "grid",
    gridArea: "content",
    gridTemplateAreas: "'bodyLeft separator bodyRight filled'",
    gridTemplateColumns: "3fr 1fr 4fr 4fr",
    gridTemplateRows: "1fr",
    paddingTop: "7px",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "150px auto",
      gridTemplateAreas: "'bodyLeft' 'bodyRight'",
    },
  },
  body_left_col: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gridArea: "bodyLeft",
  },
  body_right_col: {
    display: "flex",
    gridArea: "bodyRight",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0",
    },
    paddingTop: "125px",
  },
  body_right_col_container: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
   
  },
  attach_icon: {
    float: "right",
    cursor: "pointer",
  },
  attach_icon_disabled: {
    float: "right",
  },
  attach_section: {
    margin: "20px 0px",
  },
  attach_section_container_1: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "136px",
    },
  },
  attach_section_container_2: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "92px",
    },
  },
  attach_section_empty: {
    margin: "20px 0px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "180px",
    },
  },
  attach_section_empty_2: {
    margin: "20px 0px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "56px",
    },
  },
  attach_pointer: {
    cursor: "pointer",
  },
  attach_label: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  attach_label_black: {
    color: "#000000",
    textDecoration: "none",
  },
  attach_label_disabled: {
    color: "grey",
    textDecoration: "underline",
  },
  list: {
    paddingLeft: "0px",
    marginTop: "-16px",
    "list-style-type": "none",
  },
  list_item: {
    paddingTop: "16px",
    paddingBottom: "16px",
    borderBottom: "1px solid #EFEFEF",
  },
  list_item_break_words: {
    wordBreak: "break-word",
    maxWidth: "82%",
  },
  list_item_document_actions: {
    marginTop: "-6px",
  },
  list_item_document_actions_delete: {
    cursor: "pointer",
  },
  list_item_document_actions_download: {
    marginRight: "16px",
    cursor: "pointer",
  },
  list_item_right_justified: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "5px",
  },
  list_item_right_justified_less_space: {
    paddingBottom: "0px",
  },
  no_margin: {
    margin: "0px",
  },
  no_offers_container: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },
  no_offers_icon: {
    marginTop: "51px",
  },
  title: {
    marginBottom: "35px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      marginBottom: "0px",
    },
  },
  title_icon: {
    marginBottom: "35px",
    "& svg": {
      height: "54px",
      width: "60px",
    },
  },
  uploader: {},
  upload_button: {
    marginTop: "20px",
    width: "100%",
    height: "36px",
    textTransform: "none",
    fontWeight: "bold",
  },
  uploader_title: {
    marginBottom: "25px",
  },
  bold: {
    fontWeight: 'bold'
  }
}));
const docTypeFotografia = 16;
const docTypeFormacionReglada = 19;
const docTypeOthers = 32;
const docTypeCarnetCarretillero = 34;

function sortDocTypes(docTypes) {
  docTypes.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });

  return docTypes;
}

function sortedDocsForType(docsForType) {
  if (docsForType.length !== 1) {
    if (docsForType[0].idTipoDocumento === docTypeFormacionReglada || docsForType[0].idTipoDocumento === docTypeOthers) {
      docsForType.sort((a, b) => {
        if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) {
          return 1;
        }
        if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else {
      docsForType.sort((a, b) => {
        if (a.nombre.includes("_a.")) {
          return 1;
        }
        return 0;
      });
    }
  }

  return docsForType;
}

const docTypes=getDocTypes(i18next.languages[0]);
sortDocTypes(docTypes);

function MyDocuments(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(true);
  const [loadingSaveDocuments, setLoadingSaveDocuments] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [docType, setDocType] = useState(null);
  const [otherTitle, setOtherTitle] = useState("");
  const [docToUpload, setDocToUpload] = useState(null);
  const [docBackToUpload, setDocBackToUpload] = useState(null);
  const [selectedDocumentToDelete, setSelectedDocumentToDelete] = useState([]);
  const [selectedDocumentToDownload, setSelectedDocumentToDownload] = useState(
    []
  );
  const refs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);
  const { actions } = props;
  const vinculacionLaboral = localStorage.getItem("vinculacionLaboral");

  useEffect(() => {
    handleGetDocuments();
  }, []);

  useEffect(() => {
    if (
      docType &&
      (docType.docs === 2 || docType.docs === 1) &&
      documents.filter((doc) => doc.idTipoDocumento === docType.id).length > 0
    ) {
      props.enqueueSnackbar(
        `${t("my_documents.doc_already_uploaded")} ${docType.name}. ${t(
          "my_documents.doc_already_uploaded2"
        )}`,
        {
          variant: "warning",
        }
      );
    }
  }, [docType]);

  const clearDocsToUpload = () => {
    setDocToUpload(null);
    setDocBackToUpload(null);
  };

  async function handleGetDocuments() {
    setLoading(true);
    const response = await getCandidateDocuments(
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      const result = await response.json();
      setDocuments(result);
    } else {
      props.enqueueSnackbar(t("my_documents.get_error"), {
        variant: "error",
      });
      setDocuments([]);
    }
    setLoading(false);
  }

  function getText(text, id) {
    return id === docTypeOthers ? `${text.titulo} - ${text.nombre}` : text.nombre;
  }

  async function handleSaveDocuments() {
    setLoadingSaveDocuments(true);
    let docsToUpload = [docToUpload];
    if (docBackToUpload) {
      docsToUpload = [...docsToUpload, docBackToUpload];
    }
    const body = { idTipoDocumento: docType.id, documentos: docsToUpload };
    if (docType.id === docTypeOthers) {
      body.documentos[0].titulo = otherTitle
    }
    let response = await uploadCandidateDocuments(
      body,
      returnLangId(i18next.languages[0])
    );
    setOtherTitle("");
    if (response.status === 200) {
      const result = await response.json();
      const uploadedDocuments = result.documentos.map((uploadedDoc) => ({
        idDocumento: uploadedDoc.idDocumento,
        nombre: uploadedDoc.nombre,
        titulo: uploadedDoc.titulo,
        idTipoDocumento: result.idTipoDocumento,
      }));
      if (docType.id !== docTypeFotografia) {
        setDocuments([...uploadedDocuments, ...documents]);
      } else {
        const newDocuments = documents.filter(
          (doc) => doc.idTipoDocumento !== docTypeFotografia
        );

        setDocuments([...uploadedDocuments, ...newDocuments]);
      }
      setDocType(null);
    } else {
      switch (response.status) {
        case 400:
          props.enqueueSnackbar(
            t("my_documents.error_bad_request_update_documents"),
            {
              variant: "error",
            }
          );
          break;
        case 404:
          props.enqueueSnackbar(
            t("my_documents.error_not_found_update_documents"),
            {
              variant: "error",
            }
          );
          break;
        default:
          props.enqueueSnackbar(t("my_documents.update_error"), {
            variant: "error",
          });
          break;
      }
    }
    clearDocsToUpload();
    setDocType(null);
    setLoadingSaveDocuments(false);
  }

  async function handleDeleteDocument(document) {
    let index = 0;
    let response;
    let notDeletedDocuments = documents;
    do {
      response = await deleteCandidateDocuments(
        document[index].idDocumento,
        returnLangId(i18next.languages[0])
      );
      if (response.status === 200) {
        if (document[index].idTipoDocumento === 16) {
          actions.clearCandidateIdPhotography();
        }
        notDeletedDocuments = notDeletedDocuments.filter(
          (doc) => doc.idDocumento !== document[index].idDocumento
        );
        index++;
      } else {
        break;
      }
    } while (index < document.length);
    setDocuments(notDeletedDocuments);
    if (response.status !== 200) {
      switch (response.status) {
        case 400:
          props.enqueueSnackbar(
            t("my_documents.error_bad_request_delete_documents"),
            {
              variant: "error",
            }
          );
          break;
        case 404:
          props.enqueueSnackbar(
            t("my_documents.error_not_found_delete_documents"),
            {
              variant: "error",
            }
          );
          break;
        default:
          props.enqueueSnackbar(t("my_documents.delete_error"), {
            variant: "error",
          });
          break;
      }
    }
    setSelectedDocumentToDelete([]);
  }

  async function handleDownloadDocument(documentToDownload) {
    let index = 0;
    let response;
    do {
      response = await downloadDocumentInform(
        documentToDownload[index].idDocumento,
        returnLangId(i18next.languages[0])
      );
      if (response.status === 200) {
        const result = await response.json();
        const url = URL.createObjectURL(
          new Blob([base64ToArrayBuffer(result)], {
            type: returnMimeData(documentToDownload[index].nombre),
          })
        );
        let fileLink = document.createElement("a");
        fileLink.href = url;
        fileLink.download = documentToDownload[index].nombre;
        fileLink.click();
        index++;
      } else {
        break;
      }
    } while (index < documentToDownload.length);
    if (response.status !== 200) {
      props.enqueueSnackbar(t("my_documents.error_download"), {
        variant: "error",
      });
    }
    setSelectedDocumentToDownload([]);
  }

  const handleUploaderChange = (files, isBackDocument) => {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    if (!/\./.test(files[0].name)) {
      props.enqueueSnackbar(t("error_docs.error_not_valid4"), {
        variant: "error",
      });
      return;
    };
    if (files[0].size > 20971520) {
      props.enqueueSnackbar(t("error_docs.error_not_valid5"), {
        variant: "error",
      });
      return;
    };
    if (isBackDocument) {
      reader.onload = function () {
        const replacement = "_r.";
        setDocBackToUpload({
          nombre: files[0].name.replace(/.([^.]*)$/, replacement + "$1"),
          fichero: reader.result.substring(reader.result.indexOf(",") + 1),
        });
      };
    } else {
      reader.onload = function () {
        const replacement = docType.docs === 2 ? "_a." : ".";
        setDocToUpload({
          nombre: files[0].name.replace(/.([^.]*)$/, replacement + "$1"),
          fichero: reader.result.substring(reader.result.indexOf(",") + 1),
        });
      };
    }
  };

  const handleDeleteProvisionalDocument = (isBackDocument) => {
    if (isBackDocument) {
      setDocBackToUpload(null);
    } else {
      setDocToUpload(null);
    }
  };

  const Text14 = ({
    text,
    lineHeight,
    fontWeight,
    className,
    onClick = null,
  }) => (
    <Box
      display="inline-block"
      fontSize={14}
      lineHeight={lineHeight}
      m={1}
      className={className}
      fontWeight={fontWeight}
      onClick={onClick}
    >
      {text}
    </Box>
  );

  const ActionIcons = ({ docsForType }) => {
    let canDelete = [13,14,15].includes(docsForType[0].idTipoDocumento);
    return (
      <div className={classes.list_item_document_actions}>
        {docsForType[0].idDocumento !==
          selectedDocumentToDelete[0]?.idDocumento &&
          docsForType[0].idDocumento !==
          selectedDocumentToDownload[0]?.idDocumento ? (
          selectedDocumentToDelete.length === 0 ? (
            <>
              <Icon
                className={classes.list_item_document_actions_download}
                onClick={() => {
                  setSelectedDocumentToDownload(docsForType);
                  handleDownloadDocument(docsForType);
                }}
              >
                <DownloadDocument title = {t("icons_tooltips.download")}/>
              </Icon>
              {
                vinculacionLaboral !== "true" && canDelete ? (
                  null
                ) : (
                  <Icon
                    className={classes.list_item_document_actions_delete}
                    onClick={() => {
                      setSelectedDocumentToDelete(docsForType);
                      setIsDialogOpen(true);
                    }}
                  >
                   {!isDocumentFromGestor(docsForType[0].idAplicacion) && <TrashRed title = {t("icons_tooltips.delete")}/>}
                  </Icon>
                )
              }
            </>
          ) : null
        ) : (
          <div style={{ marginTop: "10px" }}>
            <Loading height={16} width={16} />
          </div>
        )}
      </div>
    );
  };

  const isDocumentFromGestor = (idAplicacion) =>{
    return idAplicacion === ID_APLICACION_GESTOR;
  }

  function renderNoDocuments() {
    return !matchesMobile ? (
      <div className={classes.no_offers_container}>
        <Text14 text={t("my_documents.no_documents")} lineHeight="20px" />
        <Text14 text={t("my_documents.no_documents_2")} lineHeight="20px" />
        <Box
          display="inline-block"
          lineHeight={"24px"}
          m={1}
          className={classes.no_offers_icon}
        >
          <Icon color="primary">
            <MoveUpGrey title = {t("icons_tooltips.upload_document")}/>
          </Icon>
        </Box>
      </div>
    ) : null;
  }

  function filterDocTypes()
  {
    let result = null;
    if (vinculacionLaboral === "true")
    {
      result = docTypes.filter((d) => Number(d.id) > 11);
    } else {
      result = docTypes.filter((d) => Number(d.id) > 15 || Number(d.id) === 12);
    }
    return result;
  }

  function renderDocuments() {
    return (
      <ul className={classes.list}>
        {docTypes.filter((d) => Number(d.id) > 11)
          .map((docType, index) => {
            const docsForType = documents.filter(
              (doc) => doc.idTipoDocumento === docType.id
            );
            if (docsForType.length > 0) {
              return (
                <li key={index}>
                  <div className={classes.list_item}>
                    <div
                      className={`${classes.list_item_right_justified} ${docType.id !== docTypeFormacionReglada || docType.id !== docTypeOthers
                        ? classes.list_item_right_justified_less_space
                        : ""
                        }`}
                    >
                      <Text14
                        text={docType.name}
                        lineHeight="21px"
                        fontWeight="900"
                        className={`${classes.no_margin} ${classes.breakWords}`}
                      />
                      {docType.id !== docTypeFormacionReglada && docType.id !== docTypeOthers ? (
                        <ActionIcons docsForType={docsForType} />
                      ) : null}
                    </div>
                    {sortedDocsForType(docsForType).map((doc, index) => (
                      <div
                        key={index}
                        className={`${classes.list_item_right_justified} ${doc.idTipoDocumento === docTypeFormacionReglada || docType.id !== docTypeOthers
                          ? classes.list_item_right_justified_less_space
                          : ""
                          }`}
                      >
                        <Text14
                          text={getText(doc, docType.id)}
                          lineHeight="24px"
                          className={`${classes.no_margin} ${classes.list_item_break_words}`}
                        />
                        {doc.idTipoDocumento === docTypeFormacionReglada || docType.id === docTypeOthers ? (
                          <ActionIcons
                            docsForType={[
                              docsForType.find(
                                (docType) =>
                                  docType.idDocumento === doc.idDocumento
                              ),
                            ]}
                          />
                        ) : null}
                      </div>
                    ))}
                  </div>
                </li>
              );
            }
          })}
      </ul>
    );
  }

  function tittle() {
    return (
      <div className={classes.title}>
        <Box
          display="inline-block"
          fontSize={36}
          lineHeight={"46px"}
          fontWeight="900"
          m={1}
          className={classes.no_margin}
        >
          {t("my_documents.your_documents")}
        </Box>
      </div>
    );
  }

  function tittle_icon() {
    return (
      <Typography
        className={classes.title_icon}
        align={matchesMobile ? "center" : "left"}
      >
        <Icon color="primary">
          <Folder />
        </Icon>
      </Typography>
    );
  }

  const FileUploader = ({ label, inputRef, disabled }) => {
    const isBackDocument = label === t("my_documents.back");
    let labelOrFileName;
    if (isBackDocument) {
      labelOrFileName = docBackToUpload ? docBackToUpload.nombre : label;
    } else {
      labelOrFileName = docToUpload ? docToUpload.nombre : label;
    }
    if (!disabled) {
      return (
        <div className={classes.attach_section}>
          <Text14
            text={labelOrFileName}
            lineHeight="24px"
            className={`${classes.no_margin} ${classes.attach_label} ${labelOrFileName === label
              ? classes.attach_pointer
              : classes.attach_label_black
              }`}
            onClick={() => labelOrFileName === label && inputRef.current.click()}
          />
          <Box
            display="inline-block"
            lineHeight={"24px"}
            m={1}
            className={`${classes.attach_icon} ${classes.no_margin}`}
          >
            {labelOrFileName === label ? (
              <label htmlFor={label} style={{ cursor: "pointer" }}>
                <input
                  style={{ display: "none" }}
                  id={label}
                  ref={inputRef}
                  name={label}
                  type="file"
                  accept={
                    docType.id === docTypeFotografia
                      ? ".jpeg, .jpg, .png"
                      : ".doc, .docx, .pdf, .jpeg, .jpg, .png"
                  }
                  onChange={(event) =>
                    handleUploaderChange(event.target.files, isBackDocument)
                  }
                />
                <Icon color="primary">
                  <MoveUp title = {t("icons_tooltips.upload_document")}/>
                </Icon>
              </label>
            ) : loadingSaveDocuments ? (
              <Loading height={22} width={22} />
            ) : (
              <Icon
                color="primary"
                onClick={() => handleDeleteProvisionalDocument(isBackDocument)}
              >
                <TrashRed title = {t("icons_tooltips.delete")}/>
              </Icon>
            )}
          </Box>
        </div>
      );
    } else {
      return (
        <div className={classes.attach_section}>
          <Text14
            text={labelOrFileName}
            lineHeight="24px"
            className={`${classes.no_margin} ${classes.attach_label} ${classes.attach_label_disabled}`}
          />
          <Box
            display="inline-block"
            lineHeight={"24px"}
            m={1}
            className={`${classes.attach_icon_disabled} ${classes.no_margin}`}
          >
            <label htmlFor={label}>
              <MoveUp title = {t("icons_tooltips.upload_document")} fill={"grey"}/>
            </label>
          </Box>
        </div>
      );
    }
  };

  function renderAttacher() {
    if (!docType) {
      return (
        <div
          className={
            documents.length === 0
              ? classes.attach_section_empty
              : classes.attach_section_empty_2
          }
        />
      );
    } else if (docType && docType.docs !== 2) {
      return (
        <div className={classes.attach_section_container_1}>
          <FileUploader
            label={t("my_documents.attach_document")}
            inputRef={refs.current[0]}
            disabled={documents.filter((doc) => doc.idTipoDocumento === docType.id).length > 0 && docType.id !== 19 && docType.id !== docTypeOthers}
          />
        </div>
      );
    } else if (docType && docType.docs === 2) {
      return (
        <div className={classes.attach_section_container_2}>
          <FileUploader
            label={t("my_documents.back")}
            inputRef={refs.current[1]}
            disabled={documents.filter((doc) => doc.idTipoDocumento === docType.id).length > 0}
          />
          <FileUploader
            label={t("my_documents.obverse")}
            inputRef={refs.current[2]}
            disabled={documents.filter((doc) => doc.idTipoDocumento === docType.id).length > 0}
          />
        </div>
      );
    }
  }
  
  function getCustomClassOptions(option) {
    if (option.id == docTypeCarnetCarretillero) 
      return classes.bold;
    return "";
  }

  function documentUploader() {
    return (
      <>
        <div className={classes.uploader_title}>
          <Box
            display="inline-block"
            fontSize={22}
            lineHeight={"30px"}
            fontWeight="900"
            m={1}
            className={classes.no_margin}
          >
            {t("my_documents.add_new")}
          </Box>
        </div>
        <AutocompleteWithLabel
          id="doc_types_autocomplete"
          options={filterDocTypes()}
          getLabel={(option) => option.name}
          disabled={loadingSaveDocuments}
          label={t("my_documents.doc_type")}
          value={docType}
          setter={(newValue) => {
            setDocType(newValue);
            clearDocsToUpload();
          }}
          moveLabel={true}
          getCustomClassOptions={getCustomClassOptions}
        />
        {docType?.id === docTypeOthers ? 
          <Box mt={3}>
            <InputWithLabel
            fullWidth
            customClass={classes.input}
            label={t("my_documents.doc_title")}
            value={otherTitle}
            setter={(e) => {
              setOtherTitle(e.target.value);
            }}
            moveLabel={true}
            maxLength={20}
            />
          </Box> : null }
        {renderAttacher()}
        <Button
          variant="contained"
          color="primary"
          className={classes.upload_button}
          disabled={
            !docType ||
            (docType.docs === 1 && docToUpload === null) ||
            (docType.docs === 2 &&
              (docToUpload === null || docBackToUpload === null)) ||
            ((docType.docs === 2 || docType.docs === 1) &&
              documents.filter((doc) => doc.idTipoDocumento === docType.id)
                .length > 0) ||
            loadingSaveDocuments ||
            (docType.id === 19 && docToUpload === null)
            || (docType?.id === docTypeOthers && (!otherTitle?.trim()?.length || docToUpload === null))
          }
          onClick={() => handleSaveDocuments()}
        >
          {t("my_documents.save")}
        </Button>
      </>
    );
  }

  return (
    <Layout history={props.history} loading={loading} fullBody={true}>
      <div className={classes.body}>
        <div className={classes.body_container}>
          <div className={classes.body_left_col}>
            <div>
              {tittle_icon()}
              {tittle()}
            </div>
            {!matchesMobile ? (
              <div className={classes.uploader}>{documentUploader()}</div>
            ) : null}
          </div>
          <div className={classes.body_right_col}>
            <div className={classes.body_right_col_container}>
              {matchesMobile && documents.length === 0
                ? null
                : documents.length === 0
                  ? renderNoDocuments()
                  : renderDocuments()}
              {matchesMobile ? (
                <div className={classes.uploader}>{documentUploader()}</div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <AlertDialog
        title={t("my_documents.delete_confirm_message")}
        open={isDialogOpen}
        handleClose={() => {
          setSelectedDocumentToDelete([]);
          setIsDialogOpen(false);
        }}
        handleAccept={() => {
          handleDeleteDocument(selectedDocumentToDelete);
          setIsDialogOpen(false);
        }}
      />
    </Layout>
  );
}

export default withSnackbar(MyDocuments);
