import React from "react";
import { useTranslation } from "react-i18next";

function YourLanguages(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.your_languages")} = props;

    return (
        <svg width="16px" height="17px" viewBox="0 0 16 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="39-Tu-CV-sin-cubrir" transform="translate(-548.000000, -463.000000)" fill="#000000" fillRule="nonzero">
                    <g id="translation" transform="translate(548.000000, 463.000000)">
                        <path d="M7.548,9.55 L8.182,7.745 C7.52619344,7.54482299 6.89671336,7.26684459 6.307,6.917 C6.74654098,6.42940903 7.11946018,5.8856666 7.416,5.3 C7.72721006,4.68890607 7.95716015,4.03973413 8.1,3.369 C8.689,3.369 9.079,3.389 9.519,3.429 L9.519,1.62 C9.03747981,1.67203304 8.55329318,1.69540757 8.069,1.69 L5.687,1.69 L5.687,1.08 C5.68665416,0.875176806 5.70675379,0.670830533 5.747,0.47 L3.738,0.47 C3.7792181,0.674060847 3.79932404,0.881822171 3.798,1.09 L3.798,1.69 L1.589,1.69 C1.10504438,1.69514611 0.621205944,1.67177227 0.14,1.62 L0.14,3.429 C0.64,3.389 0.979,3.369 1.569,3.369 C1.73854722,3.96124785 1.96981525,4.53405519 2.259,5.078 C2.60814762,5.74060906 3.04139668,6.3553589 3.548,6.907 C2.45070933,7.54688059 1.24994731,7.98989003 8.8817842e-16,8.216 C0.420414786,8.74520779 0.744976737,9.34390444 0.959,9.985 C2.3811007,9.60505269 3.72711766,8.98295538 4.938,8.146 C5.74428009,8.72384783 6.62146089,9.1957106 7.548,9.55 Z M3.488,3.369 L6.137,3.369 C5.92418805,4.2650373 5.49500043,5.09525782 4.887,5.787 C4.22731929,5.10952055 3.74654776,4.27856589 3.488,3.369 L3.488,3.369 Z" id="Shape"></path>
                        <path d="M13.717,16 L12.992,13.621 L9.349,13.621 L8.624,16 L6.341,16 L9.868,5.965 L12.459,5.965 L16,16 L13.717,16 Z M12.486,11.844 C11.8193333,9.688 11.4423333,8.469 11.355,8.187 C11.271,7.904 11.21,7.681 11.174,7.517 C11.0233333,8.09966667 10.5926667,9.542 9.882,11.844 L12.486,11.844 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default YourLanguages;