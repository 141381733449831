import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    flexDirection: "column",
    alignItems: "end",
  },
  root: (props) => ({
    padding: props.moveLabel ? "0px 0px" : "8px 12px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    border: "1px solid #D8D8D8",
    width: "100%",
    position: "relative",
    zIndex: 10,
    backgroundColor: props.disabled ? "#F5F5F5" : "#FFFFFF",
    "&:hover": {
      [theme.breakpoints.up("md")]: {
        border: "1px solid #000000",
      },
    },
  }),
  root_wrong: (props) => ({
    padding: props.moveLabel ? "0px 0px" : "0px 12px",
    height: 36,
    display: "flex",
    alignItems: "center",
    border: "1px solid red",
    width: "100%",
    position: "relative",
    zIndex: 10,
    "&:focus-within": {
      border: "1px solid rgba(192,192,192, .4)!important",
    },
  }),
  root_multiple: (props) => ({
    height: props.multiple ? "auto" : "36px",
    padding: "0px 0px",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    border: "1px solid #D8D8D8",
    width: "100%",
    position: "relative",
    zIndex: 10,
    backgroundColor: props.disabled ? "#F5F5F5" : "#FFFFFF",
    "&:hover": {
      [theme.breakpoints.up("md")]: {
        border: "1px solid #000000",
      },
    },
  }),
  root_multiple_wrong: (props) => ({
    height: props.multiple ? "auto" : "36px",
    padding: "0px 0px",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    border: "1px solid red",
    width: "100%",
    position: "relative",
    zIndex: 10,
    backgroundColor: props.disabled ? "#F5F5F5" : "#FFFFFF",
  }),
  label: {
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
      lineHeight: "22px",
    },
    fontSize: 12,
    lineHeight: "16px",
    display: "inline-block",
    marginBottom: "2px",
    fontSize: 14,
    marginLeft: "5px",
    width: "max-content",
  },
  redBorder: {
    borderColor: "#FA020B !important",
  },
  root_rows: (props) => ({
    padding: props.moveLabel ? "0px 0px" : "8px 12px",
    display: "flex",
    borderRadius: "4px",
    border: "1px solid #D8D8D8",
    width: "100%",
    position: "relative",
    zIndex: 10,
    backgroundColor: props.disabled ? "#F5F5F5" : "#FFFFFF",
    "&:hover": {
      [theme.breakpoints.up("md")]: {
        border: "1px solid #000000",
      },
    },
  }),
  root_wrong_rows: (props) => ({
    padding: props.moveLabel ? "0px 0px" : "0px 12px",
    display: "flex",
    border: "1px solid red",
    width: "100%",
    position: "relative",
    zIndex: 10,
    "&:focus-within": {
      border: "1px solid rgba(192,192,192, .4)!important",
    },
  }),
  asterisk: {
    color: "#f44336"
  }
}));

function InputWrapper(props) {
  const {
    children,
    label,
    wrong = false,
    customClass = "",
    type = "normal",
    disabled = false,
    passRef = null,
    required = false,
    border,
    moveLabel = false,
    rows = false,
    multiple = false,
    hasValue = false,
  } = props;
  const classes = useStyles({
    disabled: disabled,
    moveLabel: moveLabel,
    rows: rows,
    multiple: multiple,
  });

  function returnRoot() {
    if (rows) {
      if (wrong) {
        return classes.root_wrong_rows;
      } else {
        return classes.root_rows;
      }
    } else if (type !== "normal") {
      if (wrong) {
        return classes.root_multiple_wrong;
      } else {
        return classes.root_multiple;
      }
    } else {
      if (wrong) {
        return classes.root_wrong;
      } else {
        return classes.root;
      }
    }
  }

  return (
    <div
      className={classes.container + " " + customClass}
      ref={passRef}
      style={{ marginTop: moveLabel && hasValue ? "16px" : "auto" }}
    >
      {label !== "" ? (
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.label}
        >
          {label} {required ? <span className={classes.asterisk}>*</span> : <span/>}
        </Typography>
      ) : null}
      <Paper
        component="div"
        className={`${returnRoot()} ${border ? classes.redBorder : null}`}
        elevation={0}
      >
        {children}
      </Paper>
    </div>
  );
}

export default InputWrapper;
